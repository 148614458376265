import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles(({
  root: {},
  codeText: {
    marginRight: 5,
  },
  textField: {
    "&$disabled": {
      color: "#263238",
    }
  },
  disabled: {},
  chip: {
    marginLeft: 30
  }
}));

const Lesson = (props) => {
  const classes = useStyles();
  const [values] = useState(props.lesson);

  return (
    <form>
      <Card>
        <CardHeader
          title={
            values.voteMode === Constants.voteModeNonRealtime ? (
              <>基本情報<Chip className={classes.chip} label="オンデマンド" size="small" color="primary" variant="outlined" /></>
            ) : '基本情報'
          }
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="講義名"
                name="name"
                disabled
                type="text"
                value={values.name}
                variant="standard"
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="開催日"
                name="date"
                disabled
                type="text"
                value={values.date}
                variant="standard"
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="受講者数"
                name="session"
                disabled
                type="text"
                value={Object.keys(values.session).length}
                variant="standard"
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
            {/*
            {values.accessKeyRequired === true ? (
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="アクセスキー"
                  name="accessKey"
                  disabled
                  type="text"
                  value={values.accessKey}
                  variant="standard"
                  InputProps={{
                    classes: {
                      root: classes.textField,
                      disabled: classes.disabled
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.textField,
                      disabled: classes.disabled
                    }
                  }}
                />
              </Grid>
            ) : ''
            }
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="セミナーコード"
                name="code"
                disabled
                type="text"
                value={values.code}
                variant="standard"
                className={classes.codeText}
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="講師氏名"
                name="accountName"
                value={values.accountName}
                variant="standard"
                disabled
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="講師所属"
                name="accountOrganization"
                value={values.accountOrganization}
                variant="standard"
                disabled
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
              */}
          </Grid>
        </CardContent>
      </Card>
    </form >
  );
};

export default Lesson;

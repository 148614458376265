import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';

const useStyles = makeStyles(({
  root: {},
  closeBtn: {
    marginRight: 10,
    fontWeight: 600
  },
  bold: {
    fontWeight: 600
  }
}));

const Form = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    fname: '',
    lname: '',
    organization: '',
    mail: '',
    firebaseUid: ''
  });
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});

  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };


  // --------------------------------------------------
  // アカウント登録処理
  // --------------------------------------------------
  const handleSave = async (event) => {

    // 入力文字のtrim処理
    const fname = Util.trim(values.fname);
    const lname = Util.trim(values.lname);
    const organization = Util.trim(values.organization);
    const mail = Util.trim(values.mail);
    const firebaseUid = Util.trim(values.firebaseUid);

    let check = {};
    // バリデーション
    // 必須チェック
    if (!fname) {
      check.fname = '必須項目です';
    }
    if (!lname) {
      check.lname = '必須項目です';
    }
    if (!organization) {
      check.organization = '必須項目です';
    }
    if (!mail) {
      check.mail = '必須項目です';
    }
    if (!firebaseUid) {
      check.firebaseUid = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // メールアドレス形式チェック
    if (!Util.checkMailaddressFormat(mail)) {
      check.mail = 'メールアドレスの形式が正しくありません';
      setErrors(check);
      return;
    }

    // TODO
    // パスワード強度チェック

    // メールアドレス重複チェック
    const array = await Model.getAccount(mail);
    if (array.length > 0) {
      check.mail = '既に利用されているメールアドレスです';
      setErrors(check);
      return;
    }

    // データの登録
    // ・作成日時の追加
    let data = {};
    data.fname = Util.escape(fname);
    data.lname = Util.escape(lname);
    data.organization = Util.escape(organization);
    data.mail = Util.escape(mail);
    data.addDate = Util.getCurrentDayTime();
    data.system = checked;
    await Model.addAccount(firebaseUid, data);

    // ダイアログを閉じる
    props.handleSave();
  };

  return (
    <form>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={3}
          xs={12}
        >
          <TextField
            fullWidth
            label="氏名（姓）"
            name="fname"
            onChange={handleValueChange}
            required
            value={values.fname}
            variant="outlined"
            error={errors.fname ? true : false}
            helperText={errors.fname ? errors.fname : ''}
          />
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
        >
          <TextField
            fullWidth
            label="氏名（名）"
            name="lname"
            onChange={handleValueChange}
            required
            value={values.lname}
            variant="outlined"
            error={errors.lname ? true : false}
            helperText={errors.lname ? errors.lname : ''}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            fullWidth
            label="法人名／組織名"
            name="organization"
            onChange={handleValueChange}
            required
            value={values.organization}
            variant="outlined"
            error={errors.organization ? true : false}
            helperText={errors.organization ? errors.organization : ''}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            fullWidth
            label="メールアドレス"
            name="mail"
            onChange={handleValueChange}
            required
            value={values.mail}
            variant="outlined"
            error={errors.mail ? true : false}
            helperText={errors.mail ? errors.mail : ''}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            fullWidth
            label="Firebase uid"
            name="firebaseUid"
            onChange={handleValueChange}
            required
            value={values.firebaseUid}
            variant="outlined"
            error={errors.firebaseUid ? true : false}
            helperText={errors.firebaseUid ? errors.firebaseUid : ''}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <Box
            alignItems="center"
            display="flex"
            ml={-1}
          >
            <Checkbox
              name="system"
              checked={checked}
              onChange={handleCheckChange}
            />
            <Typography variant="h5" >システム管理者</Typography>
          </Box>
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Box>
        </Box>
        <Box>
          <Button
            color="primary"
            variant="outlined"
            onClick={props.handleClose}
            className={classes.closeBtn}
          >
            閉じる
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            className={classes.bold}
          >
            保存する
          </Button>
        </Box>
      </Box>
    </form >
  );
};

export default Form;

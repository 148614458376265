import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Constants from 'src/utils/Constants';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  answer: {
    padding: 5,
  },
  answerCard: {
    paddingTop: 10,
  },
  buttonArea: {
    marginTop: 30,
    marginBottom: 10,
  },
  nextButton: {
    fontWeight: 600,
    marginLeft: 5,
    width: "100%"
  },
  backButton: {
    marginRight: 5,
    width: "100%"
  },
}));

const AnswerText = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState({ anonymous: true });
  const [errors, setErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(props.lesson.ondemandConfirmMessage);

  const screenOpenTimeRef = useRef();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerText', 'useEffect()');
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerText', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerText', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerText', 'props.student.oneTimeId=' + props.student.oneTimeId);
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerText', 'props.student.index=' + props.index);
    getPostedAnswer();

    // 画面を表示した時間を保存
    screenOpenTimeRef.current = Util.getCurrentDayTimeSec();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 回答済みデータの取得処理
  // --------------------------------------------------
  const getPostedAnswer = async () => {
    const data = await Model.getAnswerByKey(props.lesson.id, props.question.id, props.student.oneTimeId);
    if (!data) {
      return;
    }
    Object.values(data).forEach(function (value) {
      setValue(value.text);
      setSelectedValue({ anonymous: value.anonymous });
      return;
    });
  }

  // --------------------------------------------------
  // テキスト入力処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // --------------------------------------------------
  // 匿名投稿チェックボックス選択処理
  // --------------------------------------------------
  const handleCheckChange = (event) => {
    setSelectedValue({ ...selectedValue, [event.target.name]: event.target.checked });
  }

  // --------------------------------------------------
  // 次へボタン押下処理
  // --------------------------------------------------
  const handleNextButton = (event) => {
  
    // 入力文字のtrim処理
    let text = Util.trim(value);

    let check = {};
    // 必須チェック
    if (props.question.isRequired === Constants.questionRequired) {
      if (!text) {
        check.text = '必須項目です';
      }
    }
    
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    if(props.lesson.isOndemandConfirmMessageOpen){
      if(props.mode === 'modeEdit'){
        setDialogOpen(true);
        setDialogMessage(props.lesson.ondemandConfirmMessage);
      }
      else if(props.index + 1 === props.questionSize){
        setDialogOpen(true);
        setDialogMessage(props.lesson.ondemandConfirmMessage);
      }
      else {
        executeNextButton();
      }
    }
    else {
      executeNextButton();
    }
  }

  // --------------------------------------------------
  // 戻るボタン押下処理
  // --------------------------------------------------
  const handleBackButton = (event) => {
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerText', 'handleBackButton()');
    setValue('');
    props.handleBackButton();
  }

  // --------------------------------------------------
  // 確認ダイアログの「いいえ」処理
  // --------------------------------------------------
  const handleDisagree = () => {
    setDialogOpen(false);
  }

  // --------------------------------------------------
  // 確認ダイアログの「はい」処理
  // --------------------------------------------------
  const handleagree = () => {
    setDialogOpen(false);
    executeNextButton();
  }

  // --------------------------------------------------
  // 次へ処理
  // --------------------------------------------------
  const executeNextButton = () => {
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerText', 'executeNextButton()');

    // 回答データの作成
    // Path: answer/講義ID/設問ID/ワンタイムID/answers/key
    // 
    // ・講義ID ← 削除
    // ・設問ID ← 削除
    // ・ワンタイムID ← 削除
    // ・タイムスタンプ
    // ・回答内容（Array）※Pushで追加
    //    ・テキスト
    //    ・タイムスタンプ ← 記述型のみ
    //    ・匿名フラグ ← 記述型のみ
    //    ・氏名 ← 記述型のみ
    //    ・ワンタイムID ← 記述型のみ
    //    ・支持（Array）※Pushで追加 ← 記述型のみ
    //    　　・ワンタイムID
    //
    // 選択型：追加と削除を監視
    // 記述型：追加と更新を監視

    // 入力文字のtrim処理
    let text = Util.trim(value);
    const timestamp = Util.getCurrentDayTimeSec();

    const answer = {
      text: Util.escape(text),
      timestamp: timestamp,
      anonymous: selectedValue["anonymous"] === true ? true : false,
      sname: props.student.fname + ' ' + props.student.lname,
      oneTimeId: props.student.oneTimeId,
    };

    const data = {
      answers: { [Util.getUid()]: answer },
      timestamp: timestamp,
      timestampOpen: screenOpenTimeRef.current,
      countdown: false
    };
    Model.addAnswer(props.lesson.id, props.question.id, props.student.oneTimeId, data);

    setValue('');
    setErrors({});
    props.handleNextButton();
  }

  return (

    <Card variant="outlined" className={classes.answer}>
      <CardContent
        className={classes.answerCard}
      >
        <Box>
          <TextField
            variant="outlined"
            fullWidth={true}
            multiline={props.question.type === Constants.questionTypeTextArea ? true : false}
            minRows={props.question.type === Constants.questionTypeTextArea ? 10 : 1}
            name="text"
            value={value}
            onChange={handleChange}
            error={errors.text ? true : false}
            helperText={errors.text ? errors.text : ''}
          />
        </Box>
        {props.lesson.profileRequired === true ? (
        <>
          <label >
            <Box
              alignItems="center"
              display="flex"
              ml={-1}
            >
              <Checkbox
                checked={selectedValue["anonymous"] === true ? true : false}
                name="anonymous"
                onChange={handleCheckChange}
                disabled={!props.lesson.profileRequired}
              />
              <Typography
                variant="h5"
              >
                匿名で投稿する
              </Typography>
            </Box>
          </label>
          <Typography variant="h6">
            {selectedValue["anonymous"] === true ? '投稿者名は他の学生には非公開。' : '投稿者名を他の学生にも公開。'}
          </Typography>
          <Typography variant="h6" style={{ height: 20 }}>
            {selectedValue["anonymous"] === true ? '講師は閲覧可能。' : ''}
          </Typography>
        </>
        ) : ''}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.buttonArea}
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.backButton}
            onClick={handleBackButton}
            style={{ visibility: props.index === 0 && props.mode === 'modeAnswer' ? 'hidden' : 'visible' }}
          >
            戻る
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.nextButton}
            onClick={handleNextButton}
          >
            次へ
            {props.question.isRequired === Constants.questionRequired ? "（必須）" : "（任意）"}
          </Button>
        </Box>
      </CardContent>
      {/* ======================================== */}
      {/* 確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={dialogOpen}
        title="送信前の確認"
        description={dialogMessage}
        disagree={handleDisagree}
        agree={handleagree}
      />
    </Card>
  );
};

AnswerText.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  student: PropTypes.object,
  index: PropTypes.number,
  handleBackButton: PropTypes.func,
  handleNextButton: PropTypes.func,
  mode: PropTypes.string,
  questionSize: PropTypes.number,
};

export default AnswerText;
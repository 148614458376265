import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Paper,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  result: {
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 30,
  },
  tableCell: {
    paddingTop: 5,
    paddingBottom: 2,
    borderBottomStyle: "none",
    borderTopStyle: "none",
  },
  resultOption: {
    width: "90%"
  },
  ratioBarBorder: {
    marginBottom: 5,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#989DB9",
    backgroundColor: 'transparent',
    width: "100%",
  },
  ratioBar: {
    background: "linear-gradient(to bottom, #989DB9, #bec5e8 50%, #989DB9 )",
    height: "100%"
  },
  ratioNumber: {
    marginLeft: 10,
    color: '#8589A2',
    width: 50,
  },
  dummy: {
    color: 'transparent',
  },
}));

const ResultGraph = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = useState([]);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.ResultGraph', 'useEffect()');
    Log.trace('Student.MainView Enquete.ResultGraph', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.ResultGraph', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView Enquete.ResultGraph', 'props.student.oneTimeId=' + props.student.oneTimeId);
    createGraph();

    // 振り返りモードの場合は回答データの監視をしない
    if (props.student.oneTimeId !== 'LessonReviewView') {
      watchAnswer();
    }

    return function cleanup() {
      Log.trace('Student.MainView Enquete.ResultGraph', 'useEffect() cleanup()');

      // 振り返りモードの場合は回答データの監視をしない
      if (props.student.oneTimeId !== 'LessonReviewView') {
        watchCancelAnswer();
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 回答データの取得処理
  // --------------------------------------------------
  const createGraph = async () => {
    const data = await Model.getAnswerByQuestionId(props.lesson.id, props.question.id);
    Log.trace('Student.MainView Enquete.ResultGraph', 'props.lesson.id :' + props.lesson.id);
    Log.trace('Student.MainView Enquete.ResultGraph', 'props.question.id :' + props.question.id);

    // キー：選択肢名、値：件数のオブジェクトを生成
    const tmp = {};
    let count = 0;
    Object.values(data).forEach(function (value) {
      count++;
      const answers = value.answers;
      if (answers) {
        Object.values(answers).forEach(function (answer) {
          if (tmp[answer.text]) {
            tmp[answer.text] = tmp[answer.text] + 1;
          } else {
            tmp[answer.text] = 1;
          }
        })
      }
    });

    // グラフデータ用配列
    const graph = [];
    let idx = 0;
    for (const option of props.question.options) {
      const d = {
        id: idx,
        option: option,
        count: tmp[option] ? tmp[option] : 0,
        ratio: tmp[option] ? Math.round((tmp[option] / count) * 100) : 0
      };
      idx++;
      graph.push(d);
    }
    setGraphData(graph);
  }

  // --------------------------------------------------
  // 回答データの監視処理実行
  // --------------------------------------------------
  const watchAnswer = async () => {
    await Model.watchAnswerAR(props.lesson.id, props.question.id, callback);
  }

  // --------------------------------------------------
  // 回答データの監視処理キャンセル実行
  // --------------------------------------------------
  const watchCancelAnswer = async () => {
    await Model.watchCancelAnswer(props.lesson.id, props.question.id);
  }

  // --------------------------------------------------
  // 回答データの変更処理待受
  // --------------------------------------------------
  const callback = (key, val) => {
    Log.trace('Student.MainView Enquete.ResultGraph', 'callback Answer key: ' + key);
    createGraph();
  }

  return (
    <Paper variant="outlined" className={classes.result}>
      <Table>
        <TableBody>
          {graphData.map((data) => (
            <TableRow
              key={data.id}
            >
              <TableCell className={classes.tableCell}>
                <Box className={classes.resultOption}>
                  <Typography variant="h6" >{data.option}</Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                >
                  <Box
                    className={classes.ratioBarBorder}
                  >
                    <Box
                      className={classes.ratioBar}
                      style={{
                        width: `${data.ratio}`.concat('%')
                      }}
                    >
                      <Typography variant={props.student.oneTimeId === 'LessonStartView' || props.student.oneTimeId === 'LessonReviewView' ? "h5" : "h3"} className={classes.dummy}>&nbsp;</Typography>
                    </Box>
                  </Box>
                  <Box
                    className={classes.ratioNumber}
                  >
                    <Typography variant="h5" >{data.ratio}%</Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

ResultGraph.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  student: PropTypes.object,
};


export default ResultGraph;
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Bar as BarChart } from "react-chartjs-2";
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {},
  rangeFormControl: {
    marginLeft: 15,
  },
  select: {
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: 400,
  }
}));

const ActiveLog = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = useState({});
  const [values, setValues] = useState({
    startTime: '07:00',
    range: '1'
  });
  const [operationSelected, setOperationSelected] = useState({
    [Constants.operationTypeTop]: true,
    [Constants.operationTypeProfile]: true,
    [Constants.operationTypeAccessKey]: true,
    [Constants.operationTypeStart]: true,
    [Constants.operationTypeWaitReload]: true,
    [Constants.operationTypeSendAnswerSelect]: true,
    [Constants.operationTypeSendAnswerText]: true,
    [Constants.operationTypeSendAnswerGood]: true,
    [Constants.operationTypeSendFaq]: true,
    [Constants.operationTypeSendFaqGood]: true,
  });

  const logDataRef = useRef();
  const graphDataRef = useRef();
  const studentCountRef = useRef();
  const startTimeRef = useRef();
  const rangeRef = useRef();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('LessonReviewView Log', 'useEffect()');
    initialize();
    return function cleanup() {
      Log.trace('LessonReviewView Log', 'useEffect() cleanup()');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 初期化処理
  // --------------------------------------------------
  const initialize = async () => {
    // 受講者数の取得
    studentCountRef.current = props.lesson.session ? Object.keys(props.lesson.session).length : 0;

    // ログデータの取得
    logDataRef.current = await Model.getLog(props.lesson.id);
    if (Object.keys(logDataRef.current).length > 0) {
      extractGraphData(operationSelected);
    }
  }

  // --------------------------------------------------
  // グラフデータ抽出処理
  // --------------------------------------------------
  const extractGraphData = async (selected) => {
    Log.trace('LessonReviewView Log', 'extractGraphData()');

    // 30秒単位で集計
    const data = {};
    Object.values(logDataRef.current).forEach(function (value) {
      // 講義日以外のデータを除外
      if (value.timestamp.substr(0, 10) !== props.lesson.date) {
        return;
      }
      // 未選択の操作データを除外
      if (!selected[value.operation] || selected[value.operation] === false) {
        return;
      }
      const timeStr = value.timestamp.substr(11, 5);
      if (data[timeStr]) {
        data[timeStr] = data[timeStr] + 1;
      }
      else {
        data[timeStr] = 1;
      }
    });

    // グラフデータ配列に変換
    const dataArray = [];
    Object.keys(data).forEach(function (key) {
      const percentage = Util.calcPercentage(data[key], studentCountRef.current);
      dataArray.push({
        x: key,
        y: percentage > 100 ? 100 : percentage
      });
    });

    // タイムスタンプ順にソート
    dataArray.sort(function (a, b) {
      return a.x < b.x ? -1 : 1;
    });

    graphDataRef.current = dataArray;

    // スケールの初期値が未設定の場合、データから初期値を設定する
    if (!startTimeRef.current) {

      if (dataArray.length > 0) {
        const str = dataArray[0].x.split(':');

        // 範囲チェック
        const strNum = Number(str[0]);
        if (strNum >= 7 && strNum <= 21) {
          startTimeRef.current = str[0] + ':00';
          rangeRef.current = '3';
          setValues({
            startTime: startTimeRef.current,
            range: rangeRef.current
          });
        }
        else {
          startTimeRef.current = '07:00';
          rangeRef.current = '1';
        }
      }
      else {
        startTimeRef.current = '07:00';
        rangeRef.current = '1';
      }

    }
    drawGraph();
  }

  // --------------------------------------------------
  // グラフ描画処理
  // --------------------------------------------------
  const drawGraph = async () => {
    
    const str = startTimeRef.current.split(':');
    const max = ('0' + (Number(str[0]) + Number(rangeRef.current))).substr(-2) + ':' + str[1];

    // グラフ描画オブジェクトを構成
    const graphData = {
      data: {
        datasets: [
          {
            type: "bar",
            fill: false,
            data: graphDataRef.current,
            borderWidth: 1,
            borderColor: "#3f51b5",
            yAxisID: "y-axis-2",
          },
        ]
      },
      config: {
        type: 'bar',
        data: graphDataRef.current,
        options: {
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                id: "y-axis-2",
                position: "left",
                ticks: {
                  beginAtZero: true,
                  max: 100,
                  min: 0,
                  fontColor: '#3f51b5',
                },
                scaleLabel: {
                  display: true,
                  labelString: 'アクティブ率',
                  fontColor: '#3f51b5',
                },
              },
            ],
            xAxes: [{
              type: 'time',
              time: {
                parser: 'HH:mm',
                unit: 'minute',
                stepSize: 15,
                displayFormats: {
                  'minute': 'HH:mm',
                }
              },
              ticks: {
                min: startTimeRef.current,
                max: max
              }
            }]
          }
        },
      },
    };
    setGraphData(graphData);
  }

  // --------------------------------------------------
  //  操作種別チェックボックスの更新
  // --------------------------------------------------
  const handleCheckChange = (event) => {
    const selected = { ...operationSelected, [event.target.name]: event.target.checked };
    setOperationSelected(selected);
    extractGraphData(selected);
  }

  // --------------------------------------------------
  //  操作種別チェックボックスの全選択・全解除
  // --------------------------------------------------
  const handleAllCheckChange = (event) => {

    const selected = {
      [Constants.operationTypeTop]: event.target.checked,
      [Constants.operationTypeProfile]: event.target.checked,
      [Constants.operationTypeAccessKey]: event.target.checked,
      [Constants.operationTypeStart]: event.target.checked,
      [Constants.operationTypeWaitReload]: event.target.checked,
      [Constants.operationTypeSendAnswerSelect]: event.target.checked,
      [Constants.operationTypeSendAnswerText]: event.target.checked,
      [Constants.operationTypeSendAnswerGood]: event.target.checked,
      [Constants.operationTypeSendFaq]: event.target.checked,
      [Constants.operationTypeSendFaqGood]: event.target.checked,
    }
    setOperationSelected(selected);
    extractGraphData(selected);
  }

  // --------------------------------------------------
  //  操作種別チェックボックスが全選択されているか判定
  // --------------------------------------------------
  const isAllCheckSelected = (event) => {
    let result = true;
    for (let key in operationSelected) {
      if (operationSelected[key] === false) {
        result = false;
        break;
      }
    }
    return result;
  }

  // --------------------------------------------------
  // 入力項目の変更
  // --------------------------------------------------
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    if (event.target.name === 'startTime') {
      startTimeRef.current = event.target.value;
    }
    if (event.target.name === 'range') {
      rangeRef.current = event.target.value;
    }
    drawGraph();
  };

  return (
    <form>
      {graphData.data ? (
        <Card>
          <CardHeader
            title="アクティブ率"
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={2}
              className={classes.root}
            >
              {/* ======================================== */}
              {/* 時間指定 */}
              {/* ======================================== */}
              <Grid
                item
                md={12}
                xs={12}
              >
                <FormControl>
                  <Select
                    name="startTime"
                    value={values.startTime}
                    onChange={handleChange}
                    className={classes.select}
                  >
                    <MenuItem value={"07:00"}>07:00</MenuItem>
                    <MenuItem value={"07:30"}>07:30</MenuItem>
                    <MenuItem value={"08:00"}>08:00</MenuItem>
                    <MenuItem value={"08:30"}>08:30</MenuItem>
                    <MenuItem value={"09:00"}>09:00</MenuItem>
                    <MenuItem value={"09:30"}>09:30</MenuItem>
                    <MenuItem value={"10:00"}>10:00</MenuItem>
                    <MenuItem value={"10:30"}>10:30</MenuItem>
                    <MenuItem value={"11:00"}>11:00</MenuItem>
                    <MenuItem value={"11:30"}>11:30</MenuItem>
                    <MenuItem value={"12:00"}>12:00</MenuItem>
                    <MenuItem value={"12:30"}>12:30</MenuItem>
                    <MenuItem value={"13:00"}>13:00</MenuItem>
                    <MenuItem value={"13:30"}>13:30</MenuItem>
                    <MenuItem value={"14:00"}>14:00</MenuItem>
                    <MenuItem value={"14:30"}>14:30</MenuItem>
                    <MenuItem value={"15:00"}>15:00</MenuItem>
                    <MenuItem value={"15:30"}>15:30</MenuItem>
                    <MenuItem value={"16:00"}>16:00</MenuItem>
                    <MenuItem value={"16:30"}>16:30</MenuItem>
                    <MenuItem value={"17:00"}>17:00</MenuItem>
                    <MenuItem value={"17:30"}>17:30</MenuItem>
                    <MenuItem value={"18:00"}>18:00</MenuItem>
                    <MenuItem value={"18:30"}>18:30</MenuItem>
                    <MenuItem value={"19:00"}>19:00</MenuItem>
                    <MenuItem value={"19:30"}>19:30</MenuItem>
                    <MenuItem value={"20:00"}>20:00</MenuItem>
                    <MenuItem value={"20:30"}>20:30</MenuItem>
                    <MenuItem value={"21:00"}>21:00</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  className={classes.rangeFormControl}
                >
                  <Select
                    name="range"
                    value={values.range}
                    onChange={handleChange}
                    className={classes.select}
                  >
                    <MenuItem value={"1"}>1h</MenuItem>
                    <MenuItem value={"2"}>2h</MenuItem>
                    <MenuItem value={"3"}>3h</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* ======================================== */}
              {/* グラフ */}
              {/* ======================================== */}
              <Grid
                item
                md={8}
                xs={12}
              >
                <Box>
                  <BarChart
                    data={graphData.data ? graphData.data : {}}
                    options={graphData.config ? graphData.config.options : {}}
                  />
                </Box>
              </Grid>

              {/* ======================================== */}
              {/* データ切り替え */}
              {/* ======================================== */}
              <Grid
                item
                md={4}
                xs={12}
              >
                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={isAllCheckSelected() === true ? true : false}
                      name={Constants.operationTypeAll}
                      onChange={handleAllCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      全選択/全解除
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeTop] === true ? true : false}
                      name={Constants.operationTypeTop}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [画面表示]トップ
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeAccessKey] === true ? true : false}
                      name={Constants.operationTypeAccessKey}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [画面表示]アクセスキー
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeProfile] === true ? true : false}
                      name={Constants.operationTypeProfile}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [画面表示]プロフィール
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeStart] === true ? true : false}
                      name={Constants.operationTypeStart}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [画面表示]リアルタイム投票
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeWaitReload] === true ? true : false}
                      name={Constants.operationTypeWaitReload}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [画面表示]リアルタイム投票（再読み込み）
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeSendAnswerSelect] === true ? true : false}
                      name={Constants.operationTypeSendAnswerSelect}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [操作]リアルタイム投票（選択）
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeSendAnswerText] === true ? true : false}
                      name={Constants.operationTypeSendAnswerText}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [操作]リアルタイム投票（自由記述）
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeSendAnswerGood] === true ? true : false}
                      name={Constants.operationTypeSendAnswerGood}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [操作]リアルタイム投票（いいね）
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeSendFaq] === true ? true : false}
                      name={Constants.operationTypeSendFaq}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [操作]質問（質問投稿）
                    </Typography>
                  </Box>
                </label>

                <label>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Checkbox
                      size="small"
                      checked={operationSelected[Constants.operationTypeSendFaqGood] === true ? true : false}
                      name={Constants.operationTypeSendFaqGood}
                      onChange={handleCheckChange}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      [操作]質問（いいね）
                    </Typography>
                  </Box>
                </label>

              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : ''}
    </form >
  );
};

ActiveLog.propTypes = {
  lesson: PropTypes.object,
};

export default ActiveLog;

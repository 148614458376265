import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Lesson from './Lesson';
import Faq from './Faq';
import Question from './Question';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LessonStartView = () => {
  const classes = useStyles();
  const location = useLocation();

  const lesson = location.state.lesson;

  return (
    <Page
      className={classes.root}
      title="講義開始 | Active Poll"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Lesson data={lesson} />
        </Box>
        <Box mt={3}>
          <Question data={lesson} />
        </Box>
        <Box mt={3}>
          <Footer />
        </Box>
      </Container>
      {lesson.useFaQ === true ? (
        <Faq lesson={lesson} />
      ) : ''}
    </Page>
  );
};

export default LessonStartView;

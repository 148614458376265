import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Result from './Result';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  faqAres: {
    marginLeft: 12,
    marginRight: 12,
    marginTop: 24,
    marginBottom: 24,
  },
}));

const Faq = (props) => {

  const classes = useStyles();

  // --------------------------------------------------
  // 画面表示
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Faq.index', 'useEffect()');
    return function cleanup() {
      Log.trace('Student.MainView Faq.index', 'cleanup()');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={1}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
        >
          <Card>
            <Box className={classes.faqAres}>
              <Result
                lesson={props.lesson}
                student={props.student}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box >
  );
};

Faq.propTypes = {
  lesson: PropTypes.object,
  student: PropTypes.object
};

export default Faq;
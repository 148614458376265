import React, { useEffect } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  makeStyles,
  Slide,
} from '@material-ui/core';
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  url: {
    width: 600,
    marginTop: 50,
    marginBottom: 50,
    textAlign: "center",
    fontWeight: "bold"
  },
  bold: {
    fontWeight: 600
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// --------------------------------------------------
// 画面表示処理
// --------------------------------------------------
const EnqueteDownloadDialogView = (props) => {
  const classes = useStyles();

  const [progress, setProgress] = React.useState(0);

  useEffect(() => {

    Log.trace('EnqueteDownloadDialogView', 'useEffect() props.open=' + props.open);
    if (!props.open) {
      return;
    }

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          getLessonResultCsv();
        }
        const diff = Math.random() * 40;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  // --------------------------------------------------
  // 講義結果CSVデータ作成処理
  // --------------------------------------------------
  const getLessonResultCsv = async () => {
    Log.trace('EnqueteDownloadDialogView', 'getLessonResultCsv()');
    if (!props.lesson.id) {
      return;
    }
    const questions = await Model.getQuestionList(props.lesson.id);
    const sessions = await Model.getSession(props.lesson.id);
    const answers = await Model.getAnswerByLessonId(props.lesson.id);
    const confirms = await Model.getLogConfirm(props.lesson.id);
    const goods = {};

    // 設問の並び替え
    questions.sort(function (a, b) {
      return a.order < b.order ? -1 : 1;
    });

    // いいねの集計
    // {uid : { qid : [{timestamp : toUid}]}}
    Object.keys(answers).forEach(function (tmpQuestionId) {
      const tmpQuestion = answers[tmpQuestionId];
      Object.keys(tmpQuestion).forEach(function (tmpUid) {
        if (tmpQuestion[tmpUid].answers) {
          const tmpAnswers = tmpQuestion[tmpUid].answers;
          Object.keys(tmpAnswers).forEach(function (tmpAnswerId) {
            if (tmpAnswers[tmpAnswerId].good) {
              const tmpGoods = tmpAnswers[tmpAnswerId].good;
              Object.keys(tmpGoods).forEach(function (tmpSessionUid) {
                const obj = {
                  timestamp: tmpGoods[tmpSessionUid].timestamp,
                  toUid: tmpUid
                };
                // 対象Uidが連想配列に存在する
                if (goods[tmpSessionUid]) {
                  // 対象Uidの設問情報が連想配列に存在する
                  if (goods[tmpSessionUid][tmpQuestionId]) {
                    goods[tmpSessionUid][tmpQuestionId].push(obj);
                  }
                  // 対象Uidの設問情報が連想配列に存在しない
                  else {
                    goods[tmpSessionUid][tmpQuestionId] = [obj];
                  }
                }
                // 対象Uidが連想配列に存在しない
                else {
                  goods[tmpSessionUid] = { [tmpQuestionId]: [obj] };
                }
              });
            }
          });
        }
      });
    });

    // CSV行データ
    const csvRowArray = [];
    //文字コード
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

    //　CSVデータ生成
    Object.keys(sessions).forEach(function (key) {
      const csvColumnArray = [];
      csvColumnArray.push('"' + sessions[key].oneTimeId + '"');
      if (props.lesson.profileRequired) {
        csvColumnArray.push('"' + sessions[key].fname + '"');
        csvColumnArray.push('"' + sessions[key].lname + '"');
        if (props.lesson.studentIdRequired) {
          csvColumnArray.push('"' + sessions[key].code + '"');
        }
        csvColumnArray.push('"' + sessions[key].mail + '"');
      }
      csvColumnArray.push('"' + sessions[key].accessDate + '"');

      for (const question of questions) {
        const array = [];
        // 回答開始ボタン操作の書き出し
        if (props.lesson.useConfirm) {
          Object.values(confirms).forEach(function (confirm) {
            if (confirm.oneTimeId === sessions[key].oneTimeId && confirm.questionId === question.id) {
              const ts = confirm.timestamp ? '[' + Util.getDayFormatHms(confirm.timestamp) + ']' : '';
              array.push(ts + '回答開始');
            }
          });
        }

        if (answers[question.id]) {
          //　回答結果の書き出し
          if (answers[question.id][sessions[key].oneTimeId]) {
            if (answers[question.id][sessions[key].oneTimeId].answers) {
              const answerObj = answers[question.id][sessions[key].oneTimeId].answers;
              Object.values(answerObj).forEach(function (answer) {
                if(props.lesson.voteMode === Constants.voteModeRealtime){
                  const ts = answer.timestamp ? '[' + Util.getDayFormatHms(answer.timestamp) + ']' : '';
                  const g = answer.good ? '（獲得いいね：' + Object.keys(answer.good).length + '件）' : '';
                  array.push(ts + answer.text + g);
                }
                else {
                  array.push(answer.text);
                }

              });
            }
          }
          //　いいねの書き出し
          if (goods[sessions[key].oneTimeId]) {
            if (goods[sessions[key].oneTimeId][question.id]) {
              for (const good of goods[sessions[key].oneTimeId][question.id]) {
                const ts = '[' + Util.getDayFormatHms(good.timestamp) + ']';
                array.push(ts + 'いいね → ' + good.toUid);
              }
            }
          }
          //　操作順にソートする
          array.sort();
        }
        csvColumnArray.push(array ? '"' + array.join('\n') + '"' : '');
      }
      csvRowArray.push(csvColumnArray);
    });

    //　生成
    csvRowArray.sort(function (a, b) {
      return a[5] < b[5] ? -1 : 1;
    });

    //　CSVヘッダ生成
    let csvHeader;
    if (props.lesson.profileRequired === true) {
      if (props.lesson.studentIdRequired === true) {
        csvHeader = ["識別子", "氏名（姓）", "氏名（名）", "学籍番号", "メールアドレス", "アクセス日時"];
      } else {
        csvHeader = ["識別子", "氏名（姓）", "氏名（名）", "メールアドレス", "アクセス日時"];
      }
    } else {
      csvHeader = ["識別子", "アクセス日時"];
    }

    for (const question of questions) {
      csvHeader.push(question.title);
    }
    csvRowArray.unshift(csvHeader);

    // 配列データをCSVフォーマットに変換
    let csvRowData = csvRowArray.map(function (l) { return l.join(',') }).join('\r\n');

    //BLOB生成してダウンロード実行
    const blob = new Blob([bom, csvRowData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = Util.getCurrentDayTimeSecForCsv() + '.csv';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
  };


  return (
    <Dialog maxWidth="lg" open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        {props.lesson.voteMode === Constants.voteModeRealtime ? 'リアルタイム投票結果ダウンロード' : 'アンケート結果ダウンロード'}
      </DialogTitle>

      <DialogContent dividers>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Box className={classes.url}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={props.handleClose}
          color="primary"
          className={classes.bold}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default EnqueteDownloadDialogView;

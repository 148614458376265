import firebase from "firebase/app";
import 'firebase/auth';
import Log from 'src/utils/Log';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD9O95eMu6U8KPxEQCn-PMfUWBY3FppdrM",
  authDomain: "active-learning-tool.firebaseapp.com",
  databaseURL: "https://active-learning-tool-default-rtdb.firebaseio.com",
  projectId: "active-learning-tool",
  storageBucket: "active-learning-tool.appspot.com",
  messagingSenderId: "102220876749",
  appId: "1:102220876749:web:21e8e7bf214741f6699284",
  measurementId: "G-C22693BG4D"
};

firebase.initializeApp(firebaseConfig);
Log.trace('firebaseConfig', 'Initialized firebase.');

// 認証状態の永続性設定
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    Log.trace('firebaseConfig', 'setPersistence LOCAL success');
  })
  .catch((error) => {
    Log.trace('firebaseConfig', 'setPersistence LOCAL fail : ' + error.message);
  });

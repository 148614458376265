import React from 'react';
import {
  Box,
  Breadcrumbs,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  bold: {
    fontWeight: 600
  }
}));

const Toolbar = () => {
  const classes = useStyles();

  return (
    <div>
      <Box
        display="flex"
        justifyContent="flex-start"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" variant="h6">トップ</Typography>
          <Typography color="primary" variant="h5" className={classes.bold}>アカウント設定</Typography>
        </Breadcrumbs>
      </Box>
    </div>
  );
};

export default Toolbar;

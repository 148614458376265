import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import QuestionTitle from '../QuestionTitle'
import ResultGraph from 'src/views/student/MainView/Enquete/ResultGraph';
import ResultText from 'src/views/student/MainView/Enquete/ResultText';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600
  },
  result: {
    paddingTop: 15
  },
  closeBtn: {
    marginRight: 10,
    fontWeight: 600
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResultDialog = (props) => {
  const classes = useStyles();

  // --------------------------------------------------
  // 設問タイプが選択型かどうかの判定
  // --------------------------------------------------
  const isSelectType = (type) => {
    let isSelect = false;
    switch (type) {
      case Constants.questionTypeRadio:
      case Constants.questionTypeSelect:
      case Constants.questionTypeCheckBox:
        isSelect = true;
        break;
      default:
        break;
    }
    return isSelect;
  }

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    props.handleClose();
  }

  return (
    <Dialog fullScreen open={props.open} onClose={handleDialogClose} TransitionComponent={Transition} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h4" className={classes.title}>結果確認</Typography>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth={false}>
        <Box mt={3}>
          <QuestionTitle question={props.question} />
          <Box className={classes.result}>
            {isSelectType(props.question.type) === true ? (
              <ResultGraph
                key={props.question.id}
                question={props.question}
                lesson={props.lesson}
                student={props.student}
              />
            ) : (
              <ResultText
                key={props.question.id}
                question={props.question}
                lesson={props.lesson}
                student={props.student}
                mode={""}
              />
            )}
          </Box>
        </Box>
      </Container>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        p={2}
      >
        <Box>
          <Button
            color="primary"
            variant="outlined"
            onClick={props.handleClose}
            className={classes.closeBtn}
          >
            閉じる
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

ResultDialog.propTypes = {
  open: PropTypes.bool,
  lesson: PropTypes.object,
  question: PropTypes.object,
  handleClose: PropTypes.func,
  studentCount: PropTypes.number,
};

export default ResultDialog;

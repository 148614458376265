import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  Snackbar,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Util from 'src/utils/Util';
import AuthUtil from 'src/utils/AuthUtil';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600
  },
  container: {
    paddingBottom: 50
  },
  description: {
    marginBottom: 20,
  },
  closeBtn: {
    marginRight: 10,
    fontWeight: 600
  },
  bold: {
    fontWeight: 600
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReAuthenticate = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({ mail: '', password: '' });
  const [errors, setErrors] = useState({});
  const [messageOpen, setMessageOpen] = useState(false);

  //--------------------------------------------------
  // 入力内容の変換
  //--------------------------------------------------
  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  //--------------------------------------------------
  // 次へ処理
  //--------------------------------------------------
  const handleNext = async () => {

    // 入力文字のtrim処理
    const mail = Util.trim(values.mail);
    const password = Util.trim(values.password);

    let check = {};
    // バリデーション
    // 必須チェック
    if (!mail) {
      check.mail = '必須項目です';
    }
    if (!password) {
      check.password = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    const user = AuthUtil.getAuthUser();
    const credential = AuthUtil.getCredential(mail, password);
    user.reauthenticateWithCredential(credential).then(function () {
      Log.trace('AccountView.ReAuthenticate', 'reauthenticateWithCredential success');
      setValues({})
      setErrors({});
      props.handleSave();
      props.handleClose();
    }).catch(function (error) {
      Log.trace('AccountView.ReAuthenticate', 'reauthenticateWithCredential fail');
      setValues({ ...values, password: '' });
      setMessageOpen(true);
    });
  };

  // --------------------------------------------------
  //  閉じる処理
  // --------------------------------------------------
  const handleClose = () => {
    setValues({})
    setErrors({});
    props.handleClose();
  }

  //--------------------------------------------------
  // 再認証失敗メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    props.handleClose();
  }

  return (
    <Dialog open={props.open} onClose={handleDialogClose} TransitionComponent={Transition} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            本人確認
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Box mt={3}>
          <form>
            <Typography className={classes.description}>メールアドレスまたはパスワードが変更されています。<br />ログインに関わる操作であるため、本人確認を行います。</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="現在のメールアドレス"
                  required
                  name="mail"
                  onChange={handleValueChange}
                  type="text"
                  value={values.mail}
                  variant="outlined"
                  error={errors.mail ? true : false}
                  helperText={errors.mail ? errors.mail : ''}
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="現在のパスワード"
                  required
                  name="password"
                  onChange={handleValueChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  error={errors.password ? true : false}
                  helperText={errors.password ? errors.password : ''}
                />
              </Grid>
            </Grid>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
            >
              <Box>
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                  className={classes.closeBtn}
                >
                  キャンセル
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleNext}
                  className={classes.bold}
                >
                  次へ
                </Button>
              </Box>
            </Box>
          </form >
        </Box>
      </Container>

      {/* ======================================== */}
      {/* 再認証失敗メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="error">
          メールアドレスまたはパスワードが正しくありません。
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ReAuthenticate;

const SESSION_KEY_ADMIN_LOGIN_ACCOUNT = 'session_key_admin_login_account';
const SESSION_KEY_STUDENT_INPUT_DATA = 'session_key_student_input_data';

// --------------------------------------------------
// 管理システム ログイン
// --------------------------------------------------
const setLoginAccount = (account) => {
  sessionStorage.setItem(SESSION_KEY_ADMIN_LOGIN_ACCOUNT, JSON.stringify(account));
};

// --------------------------------------------------
// 管理システム ログアウト
// --------------------------------------------------
const removeLoginAccount = () => {
  sessionStorage.removeItem(SESSION_KEY_ADMIN_LOGIN_ACCOUNT);
};

// --------------------------------------------------
// 管理システム ログイン情報の取得
// --------------------------------------------------
const getLoginAccount = () => {
  const s = sessionStorage.getItem(SESSION_KEY_ADMIN_LOGIN_ACCOUNT);
  if (s) {
    return JSON.parse(s);
  }
  return {};
};

// --------------------------------------------------
// 学生向けシステム 入力情報の設定
// --------------------------------------------------
const setInputData = (input) => {
  localStorage.setItem(SESSION_KEY_STUDENT_INPUT_DATA, JSON.stringify(input));
};

// --------------------------------------------------
// 学生向けシステム 入力情報の破棄
// --------------------------------------------------
const removeInputData = () => {
  localStorage.removeItem(SESSION_KEY_STUDENT_INPUT_DATA);
};

// --------------------------------------------------
// 学生向けシステム 入力情報の取得
// --------------------------------------------------
const getInputData = () => {
  const s = localStorage.getItem(SESSION_KEY_STUDENT_INPUT_DATA);
  if (s) {
    return JSON.parse(s);
  }
  return {};
};

const SessionUtil = {
  setLoginAccount,
  getLoginAccount,
  removeLoginAccount,
  setInputData,
  removeInputData,
  getInputData,
};

export default SessionUtil;

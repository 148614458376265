import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  makeStyles
} from '@material-ui/core';
import {
  CornerDownLeft as BackIcon,
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  bold: {
    fontWeight: 600
  }
}));

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  // --------------------------------------------------
  // 戻る処理
  // --------------------------------------------------
  const handleLink = () => {
    navigate('/admin/lessons', { replace: false, });
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleLink()}
          startIcon={<BackIcon />}
          className={classes.bold}
        >
          戻る
        </Button>
      </Box>
    </div>
  );
};

export default Footer;

import React from 'react';
import {
  Card,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleArea: {
    marginLeft: 12,
    marginRight: 12,
    marginTop: 24,
    marginBottom: 24,
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    borderLeftStyle: "solid",
    borderLeftWidth: 8,
    borderLeftColor: theme.palette.primary.main,
  },
  title: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const FinishTitle = (props) => {
  const classes = useStyles();

  return (
    <Card>
      <div elevation={0} className={classes.titleArea}>
        <Typography variant="h4" className={classes.title}>全ての回答を完了しました</Typography>
      </div>
    </Card>
  );
};

export default FinishTitle;
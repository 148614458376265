import React from 'react';
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
  },
}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      <Typography
        color="textPrimary"
        variant="h6"
      >
      </Typography>
    </Box>
  );
};

export default Footer;

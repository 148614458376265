import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';

import FormRadio from '../form/FormRadio';
import FormCheckbox from '../form/FormCheckbox';
import FormSelect from '../form/FormSelect';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Constants from 'src/utils/Constants';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  answer: {
    padding: 5,
  },
  answerCard: {
    paddingTop: 10,
  },
  buttonArea: {
    marginTop: 30,
    marginBottom: 10,
  },
  nextButton: {
    fontWeight: 600,
    marginLeft: 5,
    width: "100%"
  },
  backButton: {
    marginRight: 5,
    width: "100%"
  },
}));

const AnswerSelect = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState([]);
  const [postedAnswer, setPostedAnswer] = useState([]);
  const [voteButtonEnable, setVoteButtonEnable] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(props.lesson.ondemandConfirmMessage);

  const screenOpenTimeRef = useRef();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerSelect', 'useEffect()');
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerSelect', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerSelect', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerSelect', 'props.student.oneTimeId=' + props.student.oneTimeId);
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerSelect', 'props.student.index=' + props.index);
    getPostedAnswer();

    // 画面を表示した時間を保存
    screenOpenTimeRef.current = Util.getCurrentDayTimeSec();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 回答済みデータの取得処理
  // --------------------------------------------------
  const getPostedAnswer = async () => {
    const data = await Model.getAnswerByKey(props.lesson.id, props.question.id, props.student.oneTimeId);
    if (!data) {
      return;
    }

    // 回答済みデータを選択状態にする
    const array = [];
    Object.values(data).forEach(function (value) {
      array.push(value.text);
    });
    setPostedAnswer(array);

    // 入力値としても保存する
    const idxArray = [];
    for (const ans of array) {
      for (let i = 0; i < props.question.options.length; i++) {
        if (ans === props.question.options[i]) {
          idxArray.push(i.toString());
        }
      }
    }
    setValue(idxArray);

    // 次へボタンの活性判定
    // 必須設問であれば選択されている場合のみ押下可能
    if (props.question.isRequired === Constants.questionRequired) {
      if (array.length > 0) {
        setVoteButtonEnable(true);
      }
    }
    else {
      setVoteButtonEnable(true);
    }
  }

  // --------------------------------------------------
  // 入力処理
  // --------------------------------------------------
  const handleChange = (value) => {
    setValue(value);
    // 次へボタンの活性判定
    // 必須設問であれば常に押下可能、そうでなければ選択されている場合のみ押下可能
    if (props.question.isRequired === Constants.questionRequired) {
      if (value.length > 0) {
        setVoteButtonEnable(true);
      }
      else {
        setVoteButtonEnable(false);
      }
    }
    else {
      setVoteButtonEnable(true);
    }
  };

  // --------------------------------------------------
  // 次へボタン押下処理
  // --------------------------------------------------
  const handleNextButton = (event) => {
    if(props.lesson.isOndemandConfirmMessageOpen){
      if(props.mode === 'modeEdit'){
        setDialogOpen(true);
        setDialogMessage(props.lesson.ondemandConfirmMessage);
      }
      else if(props.index + 1 === props.questionSize){
        setDialogOpen(true);
        setDialogMessage(props.lesson.ondemandConfirmMessage);
      }
      else {
        executeNextButton();
      }
    }
    else {
      executeNextButton();
    }
  }

  // --------------------------------------------------
  // 戻るボタン押下処理
  // --------------------------------------------------
  const handleBackButton = (event) => {
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerSelect', 'handleBackButton()');
    setValue();
    setPostedAnswer([]);
    setVoteButtonEnable(false);
    props.handleBackButton();
  }

  // --------------------------------------------------
  // 確認ダイアログの「いいえ」処理
  // --------------------------------------------------
  const handleDisagree = () => {
    setDialogOpen(false);
  }

  // --------------------------------------------------
  // 確認ダイアログの「はい」処理
  // --------------------------------------------------
  const handleagree = () => {
    setDialogOpen(false);
    executeNextButton();
  }

  // --------------------------------------------------
  // 次へ処理
  // --------------------------------------------------
  const executeNextButton = () => {
    Log.trace('Student.MainView Enquete.NonRealtime.AnswerSelect', 'executeNextButton()');

    // 回答データの作成
    // Path: answer/講義ID/設問ID/ワンタイムID/answers/key
    // 
    // ・講義ID ← 削除
    // ・設問ID ← 削除
    // ・ワンタイムID ← 削除
    // ・タイムスタンプ
    // ・回答内容（Array）※Pushで追加
    //    ・テキスト
    //    ・タイムスタンプ ← 記述型のみ
    //    ・匿名フラグ ← 記述型のみ
    //    ・氏名 ← 記述型のみ
    //    ・ワンタイムID ← 記述型のみ
    //    ・支持（Array）※Pushで追加 ← 記述型のみ
    //    　　・ワンタイムID
    //
    // 選択型：追加と削除を監視
    // 記述型：追加と更新を監視

    const obj = [];
    for (const idx of value) {
      obj[Util.getUid()] = { text: props.question.options[idx] };
    }

    const data = {
      answers: obj,
      timestamp: Util.getCurrentDayTimeSec(),
      timestampOpen: screenOpenTimeRef.current,
      countdown: false
    };

    Model.addAnswer(props.lesson.id, props.question.id, props.student.oneTimeId, data)
    setVoteButtonEnable(false);
    props.handleNextButton();
  }

  return (
    <Card variant="outlined" className={classes.answer}>
      <CardContent
        className={classes.answerCard}
      >
        {
          props.question.type === Constants.questionTypeRadio ? (
            <FormRadio
              question={props.question}
              answer={postedAnswer}
              handleChange={handleChange}
              isDisabled={false}
            />
          ) :
            props.question.type === Constants.questionTypeCheckBox ? (
              <FormCheckbox
                question={props.question}
                answer={postedAnswer}
                handleChange={handleChange}
                isDisabled={false}
              />
            ) :
              props.question.type === Constants.questionTypeSelect ? (
                <FormSelect
                  question={props.question}
                  answer={postedAnswer}
                  handleChange={handleChange}
                  isDisabled={false}
                />
              ) : ''
        }
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.buttonArea}
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.backButton}
            onClick={handleBackButton}
            style={{ visibility: props.index === 0 && props.mode === 'modeAnswer' ? 'hidden' : 'visible' }}
          >
            戻る
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.nextButton}
            onClick={handleNextButton}
            disabled={voteButtonEnable === true ? false : true}
          >
            次へ
            {props.question.isRequired === Constants.questionRequired ? "（必須）" : "（任意）"}
          </Button>
        </Box>
      </CardContent>

      {/* ======================================== */}
      {/* 確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={dialogOpen}
        title="送信前の確認"
        description={dialogMessage}
        disagree={handleDisagree}
        agree={handleagree}
      />
    </Card>
  );
};

AnswerSelect.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  student: PropTypes.object,
  index: PropTypes.number,
  handleBackButton: PropTypes.func,
  handleNextButton: PropTypes.func,
  mode: PropTypes.string,
  questionSize: PropTypes.number,
};

export default AnswerSelect;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Tabs,
  Tab,
  makeStyles,
} from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import Realtime from './Enquete/Realtime';
import NonRealtime from './Enquete/NonRealtime';
import Faq from './Faq';
import Constants from 'src/utils/Constants';
import Log from 'src/utils/Log';
import Model from 'src/models/Model';

const useStyles = makeStyles(() => ({
  root: {},
  bold: {
    fontWeight: 600
  },
  appBar: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  tabPanelBox: {
    paddingTop: 8
  },
  tabs: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 2,
  },
  tab: {
    flexDirection: "row",
    width: 150
  },
  tabIcon: {
    minHeight: "inherit",
  },
  tabLabel: {
    marginLeft: 5,
    fontWeight: 600
  },
  paper: {
    padding: 10,
    marginBottom: 1,
  },
  studentName: {
    marginRight: 10,
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanelBox}>
          {children}
        </Box>
      )}
    </div>
  );
}

const MainTab = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [questions, setQuestions] = useState([]);

  // --------------------------------------------------
  //  画面表示時の処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView MainTab', 'useEffect()');
    Log.trace('Student.MainView MainTab', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView MainTab', 'props.student.oneTimeId=' + props.student.oneTimeId);

    getQuestionList();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 設問一覧取得処理
  // --------------------------------------------------
  const getQuestionList = async () => {
    const array = await Model.getQuestionList(props.lesson.id);
    setQuestions(array);
  };


  // --------------------------------------------------
  //  タブ操作処理
  // --------------------------------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color="primary" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabs}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#FFFFFF",
              height: 6
            }
          }}
        >
          {props.lesson.useFaQ === true && questions.length === 0 ? '' : (
            <Tab
              label={<span className={classes.tabLabel}>{props.lesson.voteMode === Constants.voteModeRealtime ? 'リアルタイム投票' : 'アンケート'}</span>}
              icon={<EqualizerIcon />}
              classes={{ wrapper: classes.tab, labelIcon: classes.tabIcon }}
            />
          )}
          {props.lesson.useFaQ === true ? (
            <Tab
              label={<span className={classes.tabLabel}>質問</span>}
              icon={<QuestionAnswerOutlinedIcon />}
              classes={{ wrapper: classes.tab, labelIcon: classes.tabIcon }}
            />
          ) : ''}
        </Tabs>
      </AppBar>
      {props.lesson.useFaQ === true && questions.length === 0 ? '' : (
        <TabPanel value={value} index={0}>
          {props.lesson.voteMode === Constants.voteModeRealtime ? (
            <Realtime
              student={props.student}
              lesson={props.lesson}
            />
          ) : (
            <NonRealtime
              student={props.student}
              lesson={props.lesson}
            />
          )}
        </TabPanel>
      )}
      {props.lesson.useFaQ === true ? (
        <TabPanel value={value} index={props.lesson.useFaQ === true && questions.length === 0 ? 0 : 1}>
          <Faq
            student={props.student}
            lesson={props.lesson}
          />
        </TabPanel>
      ) : ''}
    </>
  );
};

MainTab.propTypes = {
  lesson: PropTypes.object,
  student: PropTypes.object
};

export default MainTab;

import firebase from "firebase/app";
import 'firebase/auth';
import Log from 'src/utils/Log';

// --------------------------------------------------
// 認証中のユーザー情報を取得する
// --------------------------------------------------
const getAuthUser = () => {
  Log.trace('AuthUtil.getAuthUser', '');
  const user = firebase.auth().currentUser;
  return user;
};

// --------------------------------------------------
// 認証中のユーザー情報を取得する
// --------------------------------------------------
const updateAuthUserPassword = async (pw) => {
  Log.trace('AuthUtil.updateAuthUserPassword', 'pw: ' + pw);
  const user = firebase.auth().currentUser;
  await user.updatePassword(pw);
};

// --------------------------------------------------
// 認証情報を取得する
// --------------------------------------------------
const getCredential = (mail, pw) => {
  const credential = firebase.auth.EmailAuthProvider.credential(
    mail,
    pw
  );
  return credential;
}


const AuthUtil = {
  getAuthUser,
  updateAuthUserPassword,
  getCredential,
};

export default AuthUtil;
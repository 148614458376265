import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Fab,
  makeStyles,
} from '@material-ui/core';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import FaqResultDialogView from '../dialog/FaqResultDialogView';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';

const useStyles = makeStyles(({
  root: {},
  faqButton: {
    position: 'fixed',
    margin: 0,
    top: 80,
    right: 30,
    bottom: 'auto',
    left: 'auto',
    fontWeight: 600
  },
  icon: {
    marginRight: 10,
  },
  disabled: {}
}));

const Faq = (props) => {
  const classes = useStyles();
  const [faqDialogOpen, setFaqDialogOpen] = useState(false);
  const [faqCount, setFaqCount] = useState(0);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('LessonStartView Faq', 'useEffect()');
    countFaq();
    watchFaq();
    return function cleanup() {
      Log.trace('LessonStartView Faq', 'cleanup()');
      watchCancelFaq();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 質問件数のカウント
  // --------------------------------------------------
  const countFaq = async (event) => {
    const data = await Model.getFaqByLessonId(props.lesson.id);
    setFaqCount(Object.keys(data).length);
  };

  // --------------------------------------------------
  // 質問データの監視処理実行
  // --------------------------------------------------
  const watchFaq = async () => {
    await Model.watchFaqAC(props.lesson.id, callback);
  }

  // --------------------------------------------------
  // 質問データの監視処理キャンセル実行
  // --------------------------------------------------
  const watchCancelFaq = async () => {
    await Model.watchCancelFaq(props.lesson.id);
  }

  // --------------------------------------------------
  // 質問データの変更処理待受
  // --------------------------------------------------
  const callback = (key, val) => {
    Log.trace('Student.MainView Faq.Result', 'callback Faq key: ' + key);
    countFaq();
  }

  // --------------------------------------------------
  // 質問データの確認アイコン押下処理
  // --------------------------------------------------
  const handleFaqButton = (event) => {
    setFaqDialogOpen(true);
  };

  // --------------------------------------------------
  // 質問データの確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleFaqDialogClose = () => {
    setFaqDialogOpen(false);
  }

  return (
    <>
      <Fab
        variant="extended"
        color="primary"
        className={classes.faqButton}
        onClick={handleFaqButton}
      >
        <QuestionAnswerOutlinedIcon className={classes.icon} />
      参加者からの質問（{faqCount}件）
      </Fab>

      {/* ======================================== */}
      {/* 質問確認ダイアログ */}
      {/* ======================================== */}
      <FaqResultDialogView
        open={faqDialogOpen}
        lesson={props.lesson}
        student={{ oneTimeId: 'LessonStartView' }}
        handleClose={handleFaqDialogClose}
      />
    </>
  );
};

Faq.propTypes = {
  lesson: PropTypes.object,
};

export default Faq;

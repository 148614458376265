import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Lesson from './Lesson';
import Download from './Download';
import ActiveLog from './ActiveLog';
import Question from './Question';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LessonReviewView = () => {
  const classes = useStyles();
  const location = useLocation();

  const data = location.state.lesson;

  return (
    <Page
      className={classes.root}
      title="講義振り返り | Active Poll"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Lesson lesson={data} />
        </Box>
        <Box mt={3}>
          <Download lesson={data} />
        </Box>
        <Box mt={3}>
          <Question lesson={data} />
        </Box>
        <Box mt={3}>
          <ActiveLog lesson={data} />
        </Box>
        <Box mt={3}>
          <Footer />
        </Box>
      </Container>
    </Page>
  );
};

export default LessonReviewView;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  wrapper: {
    width: '500'
  },
  buttonArea: {
    marginTop: 10,
    marginBottom: 20,
  },
  sendButton: {
    fontWeight: 600
  }
}));

const Send = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState({ anonymous: true });
  const [errors, setErrors] = useState({});

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Faq.Input', 'useEffect()');
    return function cleanup() {
      Log.trace('Student.MainView Faq.Input', 'useEffect() cleanup()');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // テキスト入力処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // --------------------------------------------------
  // 送信ボタン押下処理
  // --------------------------------------------------
  const handleSendButton = async (event) => {

    Log.trace('Student.MainView Faq.Input', 'handleSendButton()');

    // 入力文字のtrim処理
    let text = Util.trim(value);

    let check = {};
    // 必須チェック
    if (!text) {
      check.text = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 質問データの作成
    // Path: faq/講義ID/
    // 
    // ・テキスト
    // ・タイムスタンプ
    // ・匿名フラグ
    // ・氏名
    // ・ワンタイムID
    // ・支持（Array）
    //       ・ワンタイムID
    //       ・タイムスタンプ
    //
    // 選択型：追加と削除を監視
    // 記述型：追加と更新を監視

    const timestamp = Util.getCurrentDayTimeSec();

    const data = {
      text: Util.escape(text),
      timestamp: timestamp,
      anonymous: selectedValue["anonymous"] === true ? true : false,
      name: props.student.fname + ' ' + props.student.lname,
      oneTimeId: props.student.oneTimeId,
    };

    await Model.addFaq(props.lesson.id, data)

    setValue('');
    setErrors({});
    props.handleClose(true);

    // アクティブ度計測ログの追加
    Model.addLog(props.lesson.id, props.student.oneTimeId, Constants.operationTypeSendFaq);
  }

  // --------------------------------------------------
  // 匿名投稿チェックボックス選択処理
  // --------------------------------------------------
  const handleCheckChange = (event) => {
    setSelectedValue({ ...selectedValue, [event.target.name]: event.target.checked });
  }

  return (

    <Box className={classes.wrapper}>
      <Box>
        <TextField
          variant="outlined"
          fullWidth={true}
          multiline={true}
          minRows={5}
          name="text"
          value={value}
          placeholder="質問を入力してください"
          onChange={handleChange}
          error={errors.text ? true : false}
          helperText={errors.text ? errors.text : ''}
        />
      </Box>
      <Grid
        container
        spacing={2}
        className={classes.buttonArea}
      >
        {props.lesson.profileRequired === true ? (
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
        >
          <label >
            <Box
              alignItems="center"
              display="flex"
              ml={-1}
            >
              <Checkbox
                checked={selectedValue["anonymous"] === true ? true : false}
                name="anonymous"
                onChange={handleCheckChange}
                disabled={!props.lesson.profileRequired}
              />
              <Typography
                variant="h5"
              >
                匿名で投稿する
              </Typography>
            </Box>
          </label>
          <Typography variant="h6">
            {selectedValue["anonymous"] === true ? '投稿者名は他の学生には非公開。' : '投稿者名を他の学生にも公開。'}
          </Typography>
          <Typography variant="h6" style={{ height: 20 }}>
            {selectedValue["anonymous"] === true ? '講師は閲覧可能。' : ''}
          </Typography>
        </Grid>
        ) : ''}
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              className={classes.sendButton}
              onClick={handleSendButton}
              endIcon={<SendIcon />}
            >
              送信する
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Send.propTypes = {
  lesson: PropTypes.object,
  student: PropTypes.object,
  handleClose: PropTypes.func,
};

export default Send;
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  Trash2 as Trash2Icon,
} from 'react-feather';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    textAlign: 'left',
  },
  title: {
    flex: 1,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  container: {
    paddingTop: 20,
    paddingBottom: 20
  },

  menu: {
    display: "flex",
    flexDirection: "column",
  },
  menuItemBlock: {
    display: "flex",
    flexDirection: "row",
  },
  menuItemBlockLast: {
    display: "flex",
    flexDirection: "row",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    width: 200,
    marginTop: 5,
    borderRadius: 5,
    padding: 10,
    '&:hover': {
      backgroundColor: "#f5f5f5"
    },
    marginBottom: 5,
    cursor: "pointer"
  },
  menuItemDisabled: {
    display: "flex",
    flexDirection: "row",
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    padding: 10,
    color: 'rgba(0, 0, 0, 0.26)'
  },
  menuIcon: {
    marginRight: 15,
    color: theme.palette.primary.main
  },
  menuIconDisabled: {
    marginRight: 15,
    color: 'rgba(0, 0, 0, 0.26)'
  },
  menuTextDisabled: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  bold: {
    fontWeight: 600
  }
}));

const MenuDialogView = (props) => {
  const classes = useStyles();

  // --------------------------------------------------
  //  メニュー：削除
  // --------------------------------------------------
  const handleMenuDelete = () => {
    props.handleMenuDelete();
  }

  // --------------------------------------------------
  //  メニュー：アーカイブ復帰
  // --------------------------------------------------
  const handleMenuUnArchive = () => {
    props.handleMenuUnArchive();
  };

  // --------------------------------------------------
  //  閉じる処理
  // --------------------------------------------------
  const handleClose = () => {
    props.handleClose();
  }

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" className={classes.title}>
          {props.archive.name}
        </Typography>
      </DialogTitle>


      <DialogContent dividers className={classes.container}>
        <Box className={classes.menu}>
          <Box className={classes.menuItemBlock}>

            {/* ======================================== */}
            {/* 復元 */}
            {/* ======================================== */}
            <Box className={classes.menuItem} onClick={() => handleMenuUnArchive()}>
              <ArchiveOutlinedIcon className={classes.menuIcon} />
              <Typography color="textPrimary" variant="h6">アーカイブ復元</Typography>
            </Box>

            {/* ======================================== */}
            {/* 削除 */}
            {/* ======================================== */}
            <Box className={classes.menuItem} onClick={() => handleMenuDelete()}>
              <Trash2Icon className={classes.menuIcon} />
              <Typography color="textPrimary" variant="h6">削除</Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          className={classes.bold}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog >
  );
};

MenuDialogView.propTypes = {
  open: PropTypes.bool.isRequired,
  archive: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleMenuDelete: PropTypes.func.isRequired,
  handleMenuUnArchive: PropTypes.func.isRequired,
};

export default MenuDialogView;

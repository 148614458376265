import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SendIcon from '@material-ui/icons/Send';
import CountDown from './CountDown';
import FormRadio from '../form/FormRadio';
import FormCheckbox from '../form/FormCheckbox';
import FormSelect from '../form/FormSelect';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Constants from 'src/utils/Constants';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  answerCard: {
    marginLeft: 12,
    marginRight: 12,
    marginTop: 24,
    marginBottom: 24,
  },
  buttonArea: {
    marginTop: 20,
    marginRight: 16,
    marginBottom: 20,
  },
  answerButton: {
    fontWeight: 600,
  },
  cancelButton: {
    marginLeft: 10,
  },
  inputArea: {
    marginBottom: 60,
  }
}));

const AnswerSelect = (props) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState([]);
  const [postedAnswer, setPostedAnswer] = useState([]);
  const [voteButtonEnable, setVoteButtonEnable] = useState(false);
  const [voted, setVoted] = useState([]);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState('');

  const selectedValueRef = useRef(selectedValue);
  selectedValueRef.current = selectedValue;
  const timerRef = useRef();
  const screenOpenTimeRef = useRef();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.RealtimeSelect', 'useEffect()');
    Log.trace('Student.MainView Enquete.RealtimeSelect', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.RealtimeSelect', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView Enquete.RealtimeSelect', 'props.student.oneTimeId=' + props.student.oneTimeId);
    getPostedAnswer();
    addView();

    // 画面を表示した時間を保存
    screenOpenTimeRef.current = Util.getCurrentDayTimeSec();

    return function cleanup() {
      Log.trace('Student.MainView Enquete.AnswerRadio', 'useEffect() cleanup');
      clearTimeout(timerRef.current);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 回答済みデータの取得処理
  // --------------------------------------------------
  const getPostedAnswer = async () => {
    const data = await Model.getAnswerByKey(props.lesson.id, props.question.id, props.student.oneTimeId);
    if (!data) {
      return;
    }

    const array = [];
    Object.values(data).forEach(function (value) {
      array.push(value.text);
    });
    setPostedAnswer(array);
    if (array.length > 0) {
      setVoted(array);
      setVoteButtonEnable(true);

      // 親コンポーネントに回答済み状態を送信
      if (props.sendIdVoted) {
        props.sendIdVoted(true);
      }
    }
  }

  // --------------------------------------------------
  // 閲覧情報の登録処理
  // --------------------------------------------------
  const addView = async () => {
    await Model.addView(props.lesson.id, props.question.id, { oneTimeId: props.student.oneTimeId, isConfirm: false });
  }

  // --------------------------------------------------
  // 選択処理
  // --------------------------------------------------
  const handleChange = (value) => {
    setSelectedValue(value);
    clearTimeout(timerRef.current);
    if (value.length > 0) {
      setVoteButtonEnable(true);
      if (props.question.type !== Constants.questionTypeCheckBox) {
        timerRef.current = setTimeout(handleSendButton, Constants.auteSendmillsec);
      }
    }
    else {
      setVoteButtonEnable(false);
    }
  };

  // --------------------------------------------------
  // 送信ボタン押下処理
  // --------------------------------------------------
  const handleSendButton = (event) => {

    Log.trace('Student.MainView Enquete.AnswerRadio', 'handleSendButton()');
    clearTimeout(timerRef.current);

    // 回答データの作成
    // Path: answer/講義ID/設問ID/ワンタイムID/answers/key
    // 
    // ・講義ID ← 削除
    // ・設問ID ← 削除
    // ・ワンタイムID ← 削除
    // ・タイムスタンプ
    // ・回答内容（Array）※Pushで追加
    //    ・テキスト
    //    ・タイムスタンプ ← 記述型のみ
    //    ・匿名フラグ ← 記述型のみ
    //    ・氏名 ← 記述型のみ
    //    ・ワンタイムID ← 記述型のみ
    //    ・支持（Array）※Pushで追加 ← 記述型のみ
    //    　　・ワンタイムID
    //
    // 選択型：追加と削除を監視
    // 記述型：追加と更新を監視

    const obj = [];
    for (const idx of selectedValueRef.current) {
      obj[Util.getUid()] = { text: props.question.options[idx] };
    }

    const data = {
      answers: obj,
      timestamp: Util.getCurrentDayTimeSec(),
      timestampOpen: screenOpenTimeRef.current,
      countdown: props.countDownStart
    };

    Model.addAnswer(props.lesson.id, props.question.id, props.student.oneTimeId, data)
    setMessageOpen(true);
    setMessageText('送信しました');
    setVoteButtonEnable(false);
    setVoted(selectedValueRef.current);

    // 親コンポーネントに回答済み状態を送信
    if (props.sendIdVoted) {
      props.sendIdVoted(true);
    }

    // アクティブ度計測ログの追加
    Model.addLog(props.lesson.id, props.student.oneTimeId, Constants.operationTypeSendAnswerSelect);
  }

  // --------------------------------------------------
  // 送信キャンセルボタン押下処理
  // --------------------------------------------------
  const handleCancelButton = (event) => {

    Log.trace('Student.MainView Enquete.AnswerRadio', 'handleCancelButton()');
    Model.deleteAnswer(props.lesson.id, props.question.id, props.student.oneTimeId);
    setMessageOpen(true);
    setMessageText('送信をキャンセルしました');

    setSelectedValue();
    setPostedAnswer([]);
    setVoteButtonEnable(false);
    setVoted([]);

    // 親コンポーネントに回答済み状態を送信
    if (props.sendIdVoted) {
      props.sendIdVoted(false);
    }
  }

  //--------------------------------------------------
  // メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
    setMessageText('');
  }

  return (
    <Card>
      <Box className={classes.answerCard}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.buttonArea}
        >
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.answerButton}
              onClick={handleSendButton}
              disabled={voted.length === 0 && voteButtonEnable === true ? false : true}
              endIcon={voted.length === 0 ? <SendIcon /> : ""}
            >
              {voted.length === 0 ? "送信する" : "送信済み"}
            </Button>
            <Button
              color="primary"
              className={classes.cancelButton}
              onClick={handleCancelButton}
              style={{ display: voted.length > 0 ? '' : 'none' }}
            >
              送信をやりなおす
            </Button>
          </Box>
          <CountDown countDownStart={props.countDownStart} />
        </Box>
        <Box
          className={classes.inputArea}
        >
          {
            props.question.type === Constants.questionTypeRadio ? (
              <FormRadio
                question={props.question}
                answer={postedAnswer}
                handleChange={handleChange}
                isDisabled={voted.length > 0 ? true : false}
              />
            ) :
              props.question.type === Constants.questionTypeCheckBox ? (
                <FormCheckbox
                  question={props.question}
                  answer={postedAnswer}
                  handleChange={handleChange}
                  isDisabled={voted.length > 0 ? true : false}
                />
              ) :
                props.question.type === Constants.questionTypeSelect ? (
                  <FormSelect
                    question={props.question}
                    answer={postedAnswer}
                    handleChange={handleChange}
                    isDisabled={voted.length > 0 ? true : false}
                  />
                ) : ''
          }
        </Box>

        {/* ======================================== */}
        {/* データ更新成功メッセージ */}
        {/* ======================================== */}
        <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
          <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
            {messageText}
          </Alert>
        </Snackbar>
      </Box>
    </Card >
  );
};

AnswerSelect.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  student: PropTypes.object,
  countDownStart: PropTypes.bool,
  sendIdVoted: PropTypes.func,
};

export default AnswerSelect;
import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import SessionUtil from 'src/utils/SessionUtil';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles(({
  root: {},
  closeBtn: {
    marginLeft: 10,
    fontWeight: 600
  },
  bold: {
    fontWeight: 600
  },
  strech: {
    width: "100%"
  },
}));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "yyyy年MM月dd日", { locale: this.locale });
  }
  getCalendarHeaderText(date) {
    return format(date, "yyyy年MM月", { locale: this.locale });
  }
}

const Form = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    voteMode: Constants.voteModeRealtime
  });
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedStartDate, handleStartDateChange] = useState(new Date());
  const [selectedEndDate, handleEndDateChange] = useState(new Date());
  const [errors, setErrors] = useState({});

  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // --------------------------------------------------
  // 授業登録処理
  // --------------------------------------------------
  const handleSave = async (event) => {

    // 入力文字のtrim処理
    const name = Util.trim(values.name);

    let check = {};
    // 必須チェック
    if (!name) {
      check.name = '必須項目です';
    }
    if (values.voteMode === Constants.voteModeRealtime) {
      if (!selectedDate) {
        check.date = '必須項目です';
      }
    }
    if (values.voteMode === Constants.voteModeNonRealtime) {
      if (!selectedStartDate) {
        check.startDate = '必須項目です';
      }
      if (!selectedEndDate) {
        check.endDate = '必須項目です';
      }
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 登録データの作成
    // ・作成日時を追加
    let data = {};
    data.name = Util.escape(name);
    data.accessKey = Util.getRandamCode(8);

    if (values.voteMode === Constants.voteModeRealtime) {
      data.date = Util.getDayFormat(selectedDate);
      data.startDate = data.date;
      data.endDate = data.date;
    }
    if (values.voteMode === Constants.voteModeNonRealtime) {
      data.startDate = Util.getDayFormat(selectedStartDate);
      data.endDate = Util.getDayFormat(selectedEndDate);
      data.date = data.startDate;
    }
    data.addDate = Util.getCurrentDayTime();
    data.updateDate = Util.getCurrentDayTime();
    // ・講義コード（12桁 + チェックディジット）
    const a = Util.getRandamNumber(8);
    const b = Util.getCheckDigit(a);
    data.code = a + b;

    // ・講義作成時点のアカウント情報
    const s = SessionUtil.getLoginAccount();
    data.accountId = s.id;
    data.accountName = s.fname + ' ' + s.lname;
    data.accountOrganization = s.organization;
    //　投票モード
    data.voteMode = values.voteMode;

    // ・カスタマイズ設定
    data.accessKeyRequired = false;
    data.profileRequired = true;
    data.useConfirm = false;
    data.useFaQ = false;
    data.useScene = Constants.useSceneSchool;
    data.studentIdRequired = true;
    data.isOndemandShare = true;
    data.isOndemandEdit = false;

    // データを追加
    await Model.addLesson(data);

    // ダイアログを閉じる
    props.handleSave();
  };

  return (
    <form>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="講義名"
            required
            name="name"
            onChange={handleValueChange}
            type="text"
            value={values.name}
            variant="outlined"
            error={errors.name ? true : false}
            helperText={errors.name ? errors.name : ''}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <FormControl variant="outlined" className={classes.strech}>
            <InputLabel>投票モード</InputLabel>
            <Select
              name="voteMode"
              value={values.voteMode}
              label="投票モード"
              onChange={handleValueChange}
            >
              <MenuItem value={Constants.voteModeRealtime}>リアルタイム</MenuItem>
              <MenuItem value={Constants.voteModeNonRealtime}>オンデマンド</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {values.voteMode === Constants.voteModeRealtime ? (
          <Grid
            item
            md={6}
            xs={12}
          >
            <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                variant="inline"
                required
                inputVariant="outlined"
                label="開催日"
                name="date"
                format="yyyy/MM/dd"
                value={selectedDate}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => handleDateChange(date)}
                invalidDateMessage="日付の形式が正しくありません"
                error={errors.date ? true : false}
                helperText={errors.date ? errors.date : ''}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        ) : ''
        }
        {values.voteMode === Constants.voteModeNonRealtime ? (
          <>
            <Grid
              item
              md={3}
              xs={12}
            >
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  variant="inline"
                  required
                  inputVariant="outlined"
                  label="開催期間（開始）"
                  name="startDate"
                  format="yyyy/MM/dd"
                  value={selectedStartDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={startDate => handleStartDateChange(startDate)}
                  invalidDateMessage="日付の形式が正しくありません"
                  error={errors.startDate ? true : false}
                  helperText={errors.startDate ? errors.startDate : ''}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  variant="inline"
                  required
                  inputVariant="outlined"
                  label="開催期間（終了）"
                  name="endDate"
                  format="yyyy/MM/dd"
                  value={selectedEndDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={endDate => handleEndDateChange(endDate)}
                  invalidDateMessage="日付の形式が正しくありません"
                  error={errors.endDate ? true : false}
                  helperText={errors.endDate ? errors.endDate : ''}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </>
        ) : ''
        }
      </Grid>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Box>
        </Box>
        <Box>

          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            className={classes.bold}
          >
            作成する
          </Button>

          <Button
            color="primary"
            variant="outlined"
            onClick={props.handleClose}
            className={classes.closeBtn}
          >
            キャンセル
          </Button>
        </Box>
      </Box>
    </form >
  );
};

export default Form;

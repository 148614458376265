import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  Snackbar,
  Grid,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Edit3 as Edit3Icon } from 'react-feather';
import EditAnswerTextDialog from './Realtime/EditAnswerTextDialog';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  sortOperation: {
  },
  sortTab: {
    backgroundColor: theme.palette.primary.main,
    paddingLeft: 10,
    paddingBottom: 2,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  sortButtonLabel: {
    color: '#FFFFFF',
    fontWeight: 600
  },
  result: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 30,
    height: 600,
    overflowY: "scroll",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  commentOther: {
    padding: 10,
    whiteSpace: "pre-line",
    // backgroundColor: "#fafafa",
    // borderLeft: "solid 6px #58ad5a",
  },
  commentMe: {
    padding: 10,
    whiteSpace: "pre-line",
    backgroundColor: "#BEC5E8",
    // backgroundColor: "#f8f6ff",
    // borderLeft: "solid 6px #7e5bfc",
  },
  user: {
    marginTop: 10,
    marginLeft: 5,
    color: '#616161',
    fontSize: 13
  },
  good: {
    marginTop: 10,
  },
  goodIcon: {
    width: 15,
    height: 15
  },
  goodCount: {
    fontSize: 13
  },
  goodIconMe: {
    width: 15,
    height: 15
  },
  editIcon: {
    width: 15,
    height: 15
  },
  goodCountMe: {
    fontSize: 15
  },
  bold: {
    fontWeight: 600,
  },
}));

// 付箋風CSS
// https://mlog.wlaboratory.com/entry/category/css/tag-style-heading

const ResultText = (props) => {
  const classes = useStyles();
  const [cardData, setCardData] = useState([]);
  const [sort, setSort] = useState(0);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [editAnswerTextDialogOpen, setEditAnswerTextDialogOpen] = useState(false);
  const [editAnswer, setEditAnswer] = useState({});

  const sortRef = useRef(0);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.ResultText', 'useEffect()');
    Log.trace('Student.MainView Enquete.ResultText', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.ResultText', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView Enquete.ResultText', 'props.student.oneTimeId=' + props.student.oneTimeId);

    sortRef.current = 0;
    createCard();

    // 振り返りモードの場合は回答データの監視をしない
    if (props.student.oneTimeId !== 'LessonReviewView') {
      watchAnswer();
    }

    return function cleanup() {
      Log.trace('Student.MainView Enquete.ResultText', 'useEffect() cleanup()');

      // 振り返りモードの場合は回答データの監視をしない
      if (props.student.oneTimeId !== 'LessonReviewView') {
        watchCancelAnswer();
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 回答データの取得処理
  // --------------------------------------------------
  const createCard = async () => {
    const data = await Model.getAnswerByQuestionId(props.lesson.id, props.question.id);

    // キー：選択肢名、値：件数のオブジェクトを生成
    const card = [];
    Object.values(data).forEach(function (value) {
      const answers = value.answers;

      Object.keys(answers).forEach(function (key) {
        const d = {
          id: key,
          text: answers[key].text,
          user: answers[key].anonymous ? '' : answers[key].sname,
          isMe: answers[key].oneTimeId === props.student.oneTimeId ? true : false,
          timestamp: answers[key].timestamp,
          oneTimeId: answers[key].oneTimeId,
          good: answers[key].good,
          anonymous: answers[key].anonymous,
        };
        card.push(d);
      })
    });

    if (sortRef.current === 0) {
      card.sort(function (a, b) {
        return a.timestamp < b.timestamp ? -1 : 1;
      });
    }
    else if (sortRef.current === 1) {
      card.sort(function (a, b) {
        const alength = a.good ? Object.keys(a.good).length : 0;
        const blength = b.good ? Object.keys(b.good).length : 0;
        if (alength > blength) {
          return -1;
        }
        else if (alength < blength) {
          return 1;
        }
        else {
          return a.timestamp < b.timestamp ? -1 : 1;
        }
      });
    }

    setCardData(card);
  }

  // --------------------------------------------------
  // 回答データの監視処理実行
  // --------------------------------------------------
  const watchAnswer = async () => {
    await Model.watchAnswerAC(props.lesson.id, props.question.id, callback);
  }

  // --------------------------------------------------
  // 回答データの監視処理キャンセル実行
  // --------------------------------------------------
  const watchCancelAnswer = async () => {
    await Model.watchCancelAnswer(props.lesson.id, props.question.id);
  }

  // --------------------------------------------------
  // 回答データの変更処理待受
  // --------------------------------------------------
  const callback = (key, val) => {
    Log.trace('Student.MainView Enquete.ResultText', 'callback Answer key: ' + key);
    createCard();
  }

  // --------------------------------------------------
  // ソート順切り替え
  // --------------------------------------------------
  const handleSortChange = (event, newValue) => {
    const card = [...cardData];

    if (newValue === 0) {
      card.sort(function (a, b) {
        return a.timestamp < b.timestamp ? -1 : 1;
      });
    }
    else if (newValue === 1) {
      card.sort(function (a, b) {
        const alength = a.good ? Object.keys(a.good).length : 0;
        const blength = b.good ? Object.keys(b.good).length : 0;
        if (alength > blength) {
          return -1;
        }
        else if (alength < blength) {
          return 1;
        }
        else {
          return a.timestamp < b.timestamp ? -1 : 1;
        }
      });
    }
    sortRef.current = newValue;
    setSort(newValue);
    setCardData(card);
    setMessageText(newValue === 0 ? '投稿順に並べ替えました' : '人気順に並べ替えました');
    setMessageOpen(true);
  };

  //--------------------------------------------------
  // ソート順切り替え成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
    setMessageText('');
  }

  // --------------------------------------------------
  // いいね追加処理
  // --------------------------------------------------
  const addGood = async (data) => {
    Log.trace('Student.MainView Enquete.ResultText', 'addGood: ' + data.id);
    const d = {
      oneTimeId: props.student.oneTimeId,
      timestamp: Util.getCurrentDayTimeSec()
    };
    await Model.addAnswerGood(props.lesson.id, props.question.id, data.oneTimeId, data.id, props.student.oneTimeId, d);
    //
    //
    // 自分の投稿
    //　　枠のみ：イイネもらっていない
    //　　赤色：イイネもらった
    //　　disabled
    // 他の人の投稿
    //　　枠のみ：イイネしていない
    //　　灰色：イイネした

    // アクティブ度計測ログの追加
    Model.addLog(props.lesson.id, props.student.oneTimeId, Constants.operationTypeSendAnswerGood);
  }

  // --------------------------------------------------
  // いいね取り外し処理
  // --------------------------------------------------
  const removeGood = async (data) => {
    Log.trace('Student.MainView Enquete.ResultText', 'removeGood: ' + data.id);
    await Model.deleteAnswerGood(props.lesson.id, props.question.id, data.oneTimeId, data.id, props.student.oneTimeId);
  }

  // --------------------------------------------------
  //  投稿編集ダイアログオープン
  // --------------------------------------------------
  const handleEditButton = (data) => {
    setEditAnswer(data);
    setEditAnswerTextDialogOpen(true);
  };

  // --------------------------------------------------
  //  投稿編集ダイアログクローズ
  // --------------------------------------------------
  const handleEditAnswerTextDialogOpenClose = (isSend) => {
    setEditAnswerTextDialogOpen(false);
    setEditAnswer({});
    if (isSend === true) {
      setMessageOpen(true);
      setMessageText('送信しました');
    }
  };

  return (
    <Box>
      <Box className={classes.sortOperation}>
        <Tabs
          value={sort}
          onChange={handleSortChange}
          className={classes.sortTab}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#FFFFFF",
              height: 6
            }
          }}
        >
          <Tab label="投稿順" className={classes.sortButtonLabel} />
          <Tab label="人気順" className={classes.sortButtonLabel} />
        </Tabs>
      </Box>
      <Paper variant="outlined" className={classes.result}>
        <Grid
          container
          spacing={2}
        >
          {cardData.map((data, index) => (
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
              key={data.id}
            >
              <Paper variant="elevation" elevation={10} className={data.isMe === true ? classes.commentMe : classes.commentOther} square>
                <Typography variant="h6">
                  {data.text}
                </Typography>
                <Box
                  className={classes.user}
                  style={{ display: data.user ? '' : 'none' }}
                >
                  {data.user ? '投稿者 ' + data.user : ''}
                </Box>

                {/* ======================================== */}
                {/* イイネボタン 教師画面 */}
                {/* ======================================== */}
                {props.student.oneTimeId === 'LessonStartView' || props.student.oneTimeId === 'LessonReviewView' ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    className={classes.good}
                  >
                    {data.good && Object.keys(data.good).length > 0 ? (
                      <IconButton
                        size="small"
                        disabled={true}
                      >
                        <FavoriteIcon className={classes.goodIcon} style={{ color: 'rgb(220, 0, 78)' }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        disabled={true}
                      >
                        <FavoriteBorderIcon className={classes.goodIcon} />
                      </IconButton>
                    )}
                    <Typography className={classes.goodCount} style={{ color: 'rgb(220, 0, 78)' }}>
                      {data.good ? Object.keys(data.good).length : ''}
                    </Typography>
                  </Box>
                ) : ''}

                {/* ======================================== */}
                {/* イイネボタン 他の人の投稿 */}
                {/*   イイネをしたかどうかで表示切り替え */}
                {/* ======================================== */}
                {props.student.oneTimeId !== 'LessonStartView' && props.student.oneTimeId !== 'LessonReviewView' ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    className={classes.good}
                    style={{ display: data.isMe === true ? 'none' : '' }}
                  >
                    {data.good && data.good[props.student.oneTimeId] ? (
                      <IconButton
                        size="small"
                        onClick={() => removeGood(data)}
                        disabled={props.student.oneTimeId === 'LessonStartView' || props.student.oneTimeId === 'LessonReviewView' ? true : false}
                      >
                        <FavoriteIcon className={classes.goodIcon} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => addGood(data)}
                        disabled={props.student.oneTimeId === 'LessonStartView' || props.student.oneTimeId === 'LessonReviewView' ? true : false}
                      >
                        <FavoriteBorderIcon className={classes.goodIcon} />
                      </IconButton>
                    )}
                    <Typography className={classes.goodCount}>
                      {data.good ? Object.keys(data.good).length : ''}
                    </Typography>
                  </Box>
                ) : ''}

                {/* ======================================== */}
                {/* イイネボタン 自身の投稿 */}
                {/*   イイネをもらったかどうかで表示切り替え */}
                {/*   自身へのイイネは不可とする */}
                {/* ======================================== */}
                {props.student.oneTimeId !== 'LessonStartView' && props.student.oneTimeId !== 'LessonReviewView' ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    className={classes.good}
                    style={{ display: data.isMe === true ? '' : 'none' }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                    >
                      {data.good && Object.keys(data.good).length > 0 ? (
                        <IconButton
                          size="small"
                          onClick={() => removeGood(data)}
                          disabled={true}
                        >
                          <FavoriteIcon className={classes.goodIconMe} style={{ color: 'rgb(220, 0, 78)' }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => addGood(data)}
                          disabled={true}
                        >
                          <FavoriteBorderIcon className={classes.goodIcon} />
                        </IconButton>
                      )}
                      <Typography className={classes.goodCountMe} style={{ color: 'rgb(220, 0, 78)' }} >
                        {data.good ? Object.keys(data.good).length : ''}
                      </Typography>
                    </Box>
                    {/* 編集機能 */}
                    {props.mode === Constants.processModeRealtime || props.mode === Constants.processModeRealtime2 ? (
                      <Box>
                        <IconButton
                          size="small"
                          onClick={() => handleEditButton(data)}
                        >
                          <Edit3Icon className={classes.editIcon} />
                        </IconButton>
                      </Box>
                    ) : ''}
                  </Box>
                ) : ''}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper >

      {/* ======================================== */}
      {/* 投稿編集ダイアログ */}
      {/* ======================================== */}
      <EditAnswerTextDialog
        open={editAnswerTextDialogOpen}
        lesson={props.lesson}
        question={props.question}
        student={props.student}
        answer={editAnswer}
        handleClose={handleEditAnswerTextDialogOpenClose}
      />

      {/* ======================================== */}
      {/* 完了メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={2000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          {messageText}
        </Alert>
      </Snackbar>
    </Box>
  );
};

ResultText.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  student: PropTypes.object,
  mode: PropTypes.string,
};

export default ResultText;
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SendIcon from '@material-ui/icons/Send';
import CountDown from './CountDown';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Constants from 'src/utils/Constants';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  answerCard: {
    marginLeft: 12,
    marginRight: 12,
    marginTop: 24,
    marginBottom: 24,
  },
  buttonArea: {
    marginTop: 20,
    marginRight: 16,
    marginBottom: 20,
  },
  answerButton: {
    fontWeight: 600,
  },
  cancelButton: {
    marginLeft: 10,
  },
  inputArea: {
    marginBottom: 60,
  }
}));

const AnswerText = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [voted, setVoted] = useState(false);
  const [selectedValue, setSelectedValue] = useState({ anonymous: true });
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [errors, setErrors] = useState({});

  const screenOpenTimeRef = useRef();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.AnswerText', 'useEffect()');
    Log.trace('Student.MainView Enquete.AnswerText', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.AnswerText', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView Enquete.AnswerText', 'props.student.oneTimeId=' + props.student.oneTimeId);
    getPostedAnswer();
    addView();

    // 画面を表示した時間を保存
    screenOpenTimeRef.current = Util.getCurrentDayTimeSec();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 回答済みデータの取得処理
  // --------------------------------------------------
  const getPostedAnswer = async () => {
    const data = await Model.getAnswerByKey(props.lesson.id, props.question.id, props.student.oneTimeId);
    if (Object.keys(data).length === 0) {
      return;
    }
    // 回答済みの場合、状態を記憶
    setVoted(true);

    // 親コンポーネントに回答済み状態を送信
    if (props.sendIdVoted) {
      props.sendIdVoted(true);
    }
  }

  // --------------------------------------------------
  // 閲覧情報の登録処理
  // --------------------------------------------------
  const addView = async () => {
    await Model.addView(props.lesson.id, props.question.id, { oneTimeId: props.student.oneTimeId, isConfirm: false });
  }

  // --------------------------------------------------
  // テキスト入力処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // --------------------------------------------------
  // 送信ボタン押下処理
  // --------------------------------------------------
  const handleSendButton = (event) => {

    Log.trace('Student.MainView Enquete.AnswerText', 'handleSendButton()');

    // 入力文字のtrim処理
    let text = Util.trim(value);

    let check = {};
    // 必須チェック
    if (!text) {
      check.text = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 回答データの作成
    // Path: answer/講義ID/設問ID/ワンタイムID/answers/key
    // 
    // ・講義ID ← 削除
    // ・設問ID ← 削除
    // ・ワンタイムID ← 削除
    // ・タイムスタンプ
    // ・回答内容（Array）※Pushで追加
    //    ・テキスト
    //    ・タイムスタンプ ← 記述型のみ
    //    ・匿名フラグ ← 記述型のみ
    //    ・氏名 ← 記述型のみ
    //    ・ワンタイムID ← 記述型のみ
    //    ・支持（Array）※Pushで追加 ← 記述型のみ
    //    　　・ワンタイムID
    //
    // 選択型：追加と削除を監視
    // 記述型：追加と更新を監視

    const timestamp = Util.getCurrentDayTimeSec();

    const answer = {
      text: Util.escape(text),
      timestamp: timestamp,
      anonymous: selectedValue["anonymous"] === true ? true : false,
      sname: props.student.fname + ' ' + props.student.lname,
      oneTimeId: props.student.oneTimeId,
    };

    if (voted) {
      Model.addAnswerPush(props.lesson.id, props.question.id, props.student.oneTimeId, answer)
    }
    else {
      const data = {
        answers: { [Util.getUid()]: answer },
        timestamp: timestamp,
        timestampOpen: screenOpenTimeRef.current,
        countdown: props.countDownStart
      };
      Model.addAnswer(props.lesson.id, props.question.id, props.student.oneTimeId, data);
    }

    setMessageOpen(true);
    setMessageText('送信しました');

    setValue('');
    setVoted(true);
    setErrors({});

    // 親コンポーネントに回答済み状態を送信
    if (props.sendIdVoted) {
      props.sendIdVoted(true);
    }

    // アクティブ度計測ログの追加
    Model.addLog(props.lesson.id, props.student.oneTimeId, Constants.operationTypeSendAnswerText);
  }

  // // --------------------------------------------------
  // // 送信キャンセルボタン押下処理
  // // --------------------------------------------------
  // const handleCancelButton = (event) => {

  //   const l = SessionUtil.getLessonInfo();
  //   const s = SessionUtil.getStudentInfo();
  //   Model.deleteAnswer(l.id, props.question.id, s.oneTimeId);

  //   setVoted(false);
  //   if (value === '') {
  //     setVoteButtonEnable(false);
  //   }
  //   else {
  //     setVoteButtonEnable(true);
  //   }

  //   setMessageOpen(true);
  //   setMessageText('送信をキャンセルしました');
  // }

  // --------------------------------------------------
  // 匿名投稿チェックボックス選択処理
  // --------------------------------------------------
  const handleCheckChange = (event) => {
    setSelectedValue({ ...selectedValue, [event.target.name]: event.target.checked });
  }

  //--------------------------------------------------
  // メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
    setMessageText('');
  }

  return (

    <Card>
      <Box className={classes.answerCard}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.buttonArea}
        >
          {/*
          <Button
            color="primary"
            variant="contained"
            className={classes.answerButton}
            onClick={handleSendButton}
            disabled={voteButtonEnable === true ? false : true}
            endIcon={voted === false ? <SendIcon /> : ""}
          >
            {voted === false ? "送信する" : "送信済み"}
          </Button>
          <Button
            color="primary"
            className={classes.cancelButton}
            onClick={handleCancelButton}
            style={{ display: voted !== false ? '' : 'none' }}
          >
            送信をやりなおす
           </Button>
          */}
          <Box>
            <Button
              color="primary"
              variant="contained"
              className={classes.answerButton}
              onClick={handleSendButton}
              endIcon={<SendIcon />}
            >
              送信する
            </Button>
          </Box>
          <CountDown countDownStart={props.countDownStart} />
        </Box>
        <Box
          className={classes.inputArea}
        >
          <TextField
            variant="outlined"
            fullWidth={true}
            multiline={props.question.type === Constants.questionTypeTextArea ? true : false}
            minRows={props.question.type === Constants.questionTypeTextArea ? 10 : 1}
            name="text"
            value={value}
            onChange={handleChange}
            error={errors.text ? true : false}
            helperText={errors.text ? errors.text : ''}
          />
        {props.lesson.profileRequired === true ? (
        <>
          <label>
            <Box
              alignItems="center"
              display="flex"
              ml={-1}
            >
              <Checkbox
                checked={selectedValue["anonymous"] === true ? true : false}
                name="anonymous"
                onChange={handleCheckChange}
                disabled={!props.lesson.profileRequired}
              />
              <Typography
                variant="h5"
              >
                匿名で投稿する
              </Typography>
            </Box>
          </label>
          <Typography variant="h6">
            {selectedValue["anonymous"] === true ? '投稿者名は他の学生には非公開。' : '投稿者名を他の学生にも公開。'}
          </Typography>
          <Typography variant="h6" style={{ height: 20 }}>
            {selectedValue["anonymous"] === true ? '講師は閲覧可能。' : ''}
          </Typography>
        </>
        ) : ''}
        </Box>

        {/* ======================================== */}
        {/* データ更新成功メッセージ */}
        {/* ======================================== */}
        <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
          <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
            {messageText}
          </Alert>
        </Snackbar>
      </Box>
    </Card>
  );
};

AnswerText.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  student: PropTypes.object,
  countDownStart: PropTypes.bool,
  sendIdVoted: PropTypes.func,
};

export default AnswerText;
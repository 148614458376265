import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import Model from 'src/models/Model';
import SessionUtil from 'src/utils/SessionUtil';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LessonArchiveListView = () => {
  const classes = useStyles();
  const [archives, setArchives] = useState([]);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    getArchiveList();
  }, []);

  // --------------------------------------------------
  // アーカイブ講義一覧取得処理
  // --------------------------------------------------
  const getArchiveList = async () => {
    const array = await Model.getArchiveList(SessionUtil.getLoginAccount().id);
    array.sort(function (a, b) {
      return a.date < b.date ? -1 : 1;
    });
    setArchives(array);
  };

  return (
    <Page
      className={classes.root}
      title="アーカイブ | Active Poll"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results
            reload={getArchiveList}
            archives={archives} />
        </Box>
      </Container>
    </Page>
  );
};

export default LessonArchiveListView;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import Lesson from './Lesson';
import Question from './Question';
import Log from 'src/utils/Log';

const useStyles = makeStyles(({
  mt3: {
    marginTop: 24
  },
}));

const Edit = (props) => {
  const classes = useStyles();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Admin.Lesson.LessonDitailView.Edit', 'useEffect()');
    return function cleanup() {
      Log.trace('Admin.Lesson.LessonDitailView.Edit', 'useEffect() cleanup');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box>
        <Lesson 
          lesson={props.lesson} 
          handleUpdateLesson={props.handleUpdateLesson} 
        />
      </Box>
      <Box className={classes.mt3}>
        <Question lesson={props.lesson} />
      </Box>
      </Box>
  );
};

Edit.propTypes = {
  lesson: PropTypes.object,
  handleUpdateLesson: PropTypes.func
};

export default Edit;

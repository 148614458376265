import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Collapse,
  InputAdornment,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import MenuIcon from '@material-ui/icons/Menu';
import { User as UserIcon } from 'react-feather';
import firebase from "firebase/app";
import 'firebase/auth';
import LogoB from 'src/components/LogoB';
import Page from 'src/components/Page';
// import Language from 'src/views/common/Language';
import Util from 'src/utils/Util';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';
import Constants from 'src/utils/Constants';

const date = new Date();
const second = date.getSeconds();
const topImage = second % 2 === 0 ? "/static/images/top/top-1.jpg" : "/static/images/top/top-2.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    // height: '100%',
    backgroundImage: `url(${topImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'rgba(255,255,255,0.1)',
    backgroundBlendMode: 'lighten',
    width: '100vw',
    height: '100vh'
  },
  header: {
    paddingRight: 10,
    backgroundColor: 'rgba(255,255,255)',
  },
  logoWrapper: {
    height: 64,
    paddingLeft: 24,
    paddingTop: 5,
  },
  logo: {
    width: 180,
  },
  admin: {
    color: "#757575",
    width: 35,
    height: 35,
  },
  form: {
    marginTop: 30
  },
  text: {
    paddingLeft: 20,
    fontSize: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 50,
  },
  button: {
    color: theme.palette.primary.main,
    width: 35,
    height: 35,
  },
  dummy: {
    color: 'rgba(255,255,255,0)',
    marginRight: 40
  },
  messageArea: {
    marginTop: 20,
    height: 20,
    textAlign: 'center'
  },
  errorMessage: {
    color: "#f44336",
    fontWeight: 600
  }
}));

const SeminarCodeTextField = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        borderRadius: 50,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      },
    },
  },
}))(TextField);

const IndexView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // const { t } = useTranslation();

  const [values, setValues] = useState({ seminarCode: '' });
  const [errors, setErrors] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  let authUserRef = useRef();

  // --------------------------------------------------
  //  画面表示時の処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.IndexView', 'useEffect()');

    // 匿名ログイン処理
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        Log.trace('Student.IndexView', 'firebase.auth().signInAnonymously()');
        firebase.auth().signInAnonymously()
          .then(() => {
          });
      }
      else {
        Log.trace('Student.IndexView', 'signIned: ' + user.uid);
        authUserRef.current = user;
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  //  入力値の更新
  // --------------------------------------------------
  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({});
  };

  // --------------------------------------------------
  //  ユーザーアイコンの押下処理
  // --------------------------------------------------
  const handleUserIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // --------------------------------------------------
  //  メニューのクローズ処理
  // --------------------------------------------------
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // --------------------------------------------------
  //  管理システムを表示
  // --------------------------------------------------
  const navigateAdminTool = () => {
    navigate('/admin/login', { replace: false });
  }

  // --------------------------------------------------
  //  管理システム（最新バージョン）を表示
  // --------------------------------------------------
  const navigateAdminToolRecent = () => {
    window.location = 'https://admin.actipo.com/';
    return null;
  }

  

  // --------------------------------------------------
  //  次へボタンの押下処理
  // --------------------------------------------------
  const hundleNextButton = async () => {

    Log.trace('Student.IndexView', 'hundleNextButton()');

    // 入力文字のtrim処理
    const seminarCode = Util.deleteWhiteSpace(values.seminarCode);

    // 必須チェック
    let check = {};
    if (!seminarCode) {
      check.seminarCode = 'セミナーコードを入力してください';
      setErrors(check);
      return;
    }

    // セミナーコードフォーマットチェック
    const checkDigit = Util.getCheckDigit(seminarCode.slice(0, 8));
    if (checkDigit !== seminarCode.slice(-1)) {
      check.seminarCode = 'セミナーコードが正しくありません';
      setErrors(check);
      return;
    }

    // セミナーコード存在チェック
    const array = await Model.getLessonByCode(seminarCode);
    if (array.length !== 1) {
      check.seminarCode = 'セミナーコードが正しくありません';
      setErrors(check);
      return;
    }

    // 開催日チェック
    if (array[0].voteMode === Constants.voteModeRealtime) {
      if (Util.isDayPassed(array[0].date)) {
        check.seminarCode = 'このセミナーは' + Util.getDayFormatMD(array[0].date) + 'に終了しました';
        setErrors(check);
        return;
      }
    }
    else {
      if (array[0].ondemandEntryStatus === Constants.ondemandEntryStatusInvalid) {
        if(array[0].isOndemandEntryStatusInvalidMessageEdit && array[0].ondemandEntryStatusInvalidMessage){
          check.seminarCode = array[0].ondemandEntryStatusInvalidMessage;
        } else {
          check.seminarCode = 'このセミナーは現在受け付けておりません。';
        }
        setErrors(check);
        return;
      }
      if (Util.isDayPassed(array[0].endDate)) {
        check.seminarCode = 'このセミナーは' + Util.getDayFormatMD(array[0].endDate) + 'に受付を終了しました。';
        setErrors(check);
        return;
      }
      if (Util.isDayReached(array[0].startDate)) {
        check.seminarCode = 'このセミナーは' + Util.getDayFormatMD(array[0].startDate) + 'から受付を開始します。';
        setErrors(check);
        return;
      }
    }

    // セミナーコードから特定した講義をセット
    const lesson = array[0];

    // 次画面の判定
    let nextPath;
    let student = { oneTimeId: authUserRef.current.uid };
    if (lesson.session && lesson.session[authUserRef.current.uid]) {
      // 既にアクセス済みの場合
      nextPath = '/student/main';
      student = lesson.session[authUserRef.current.uid];
      // セッション保存データの保存
      student.accessDate = Util.getCurrentDayTimeSec();
      await Model.addSession(lesson.id, student);
    }
    else if (lesson.accessKeyRequired === true) {
      nextPath = '/student/security';
    }
    else if (lesson.profileRequired === true) {
      nextPath = '/student/profile';
    }
    else {
      nextPath = '/student/main';
      // セッション保存データの保存
      const data = {};
      data.oneTimeId = authUserRef.current.uid;
      data.accessDate = Util.getCurrentDayTimeSec();
      await Model.addSession(lesson.id, data);
    }

    // アクティブ度計測ログの追加
    Model.addLog(lesson.id, authUserRef.current.uid, Constants.operationTypeTop);

    // データ量を節約するため削除
    delete lesson.session;

    navigate(nextPath,
      {
        replace: false,
        state: {
          lesson: lesson,
          student: student
        }
      }
    );
  }

  return (
    <Page
      className={classes.root}
      //title={t('student_index_title')}
      title='Active Poll | リアルタイム意見共有ツール'
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
      >

        {/* ======================================== */}
        {/* ヘッダー */}
        {/* ======================================== */}
        <Box
          className={classes.header}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box className={classes.logoWrapper} >
            <LogoB className={classes.logo} />
          </Box>
          <Box>
            {/*
            <Language />
            */}
            <IconButton onClick={handleUserIconClick}>
              <UserIcon className={classes.admin} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {/*<MenuItem onClick={navigateAdminTool}>{t('student_index_management_system')}</MenuItem>*/}
              <MenuItem onClick={navigateAdminTool}>管理システムへ</MenuItem>
              <MenuItem onClick={navigateAdminToolRecent}>管理システム（最新バージョン）へ</MenuItem>
            </Menu>
          </Box>
        </Box>

        {/* ======================================== */}
        {/* セミナーコード入力 */}
        {/* ======================================== */}
        <Container maxWidth="xs" className={classes.form}>
          <SeminarCodeTextField
            fullWidth
            //placeholder={t('student_index_placeholder')}
            placeholder='# セミナーコード'
            margin="normal"
            name="seminarCode"
            onChange={handleValueChange}
            value={values.seminarCode}
            variant="outlined"
            InputProps={{
              className: classes.text,
              inputMode: 'numeric',
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={hundleNextButton}>
                    <PlayCircleFilledIcon className={classes.button} />
                  </IconButton>
                </InputAdornment>
            }}
            onKeyPress={(event) => event.key === 'Enter' ? hundleNextButton(event) : false}
          />
          {/* ======================================== */}
          {/* エラーメッセージ */}
          {/* ======================================== */}
          <Box className={classes.messageArea}>
            <Collapse in={errors.seminarCode !== ''}>
              <Box>
                <Typography className={classes.errorMessage}>{errors.seminarCode}</Typography>
              </Box>
            </Collapse>
          </Box>
        </Container>

        {/* ======================================== */}
        {/* フッター */}
        {/* ======================================== */}
        <Box>
          <IconButton>
            <MenuIcon className={classes.dummy} />
          </IconButton>
        </Box>
      </Box >
    </Page >
  );
};

export default IndexView;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { Bar as BarChart } from "react-chartjs-2";
import Log from 'src/utils/Log';
import Util from 'src/utils/Util';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  result: {
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 30,
  },
}));


const TrendPanel = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = useState({});

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('QuestionReviewDialogView.TrendPanel', 'useEffect()');
    drawGraph();

    return function cleanup() {
      Log.trace('QuestionReviewDialogView.TrendPanel', 'useEffect() cleanup()');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // グラフ描画
  // --------------------------------------------------
  const drawGraph = async () => {

    // グラフのX軸
    // 5秒、 10秒、15秒、30秒、  45秒、 60秒、 90秒、120秒、150秒、180秒以上
    const barData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    // 配列に変換
    const answerArray = [];
    Object.values(props.question.answer).forEach(function (value) {
      answerArray.push(value);
    });
    // 回答日順にソート
    answerArray.sort(function (a, b) {
      return a.timestamp < b.timestamp ? -1 : 1;
    });

    // 暫定基準時間を設定
    const dummyTimestampOpen = !answerArray[0].timestampOpen ? Util.addSecond(answerArray[0].timestamp, -10) : '';

    // 棒グラフデータを作成
    for (const answer of answerArray) {
      const diff = Util.diffSecond(answer.timestamp, answer.timestampOpen ? answer.timestampOpen : dummyTimestampOpen);

      if (diff <= 5) {
        barData[0] = barData[0] + 1;
      } else if (diff <= 10) {
        barData[1] = barData[1] + 1;
      } else if (diff <= 15) {
        barData[2] = barData[2] + 1;
      } else if (diff <= 30) {
        barData[3] = barData[3] + 1;
      } else if (diff <= 45) {
        barData[4] = barData[4] + 1;
      } else if (diff <= 60) {
        barData[5] = barData[5] + 1;
      } else if (diff <= 90) {
        barData[6] = barData[6] + 1;
      } else if (diff <= 120) {
        barData[7] = barData[7] + 1;
      } else if (diff <= 150) {
        barData[8] = barData[8] + 1;
      } else if (diff <= 180) {
        barData[9] = barData[9] + 1;
      } else {
        barData[10] = barData[10] + 1;
      }
    }
    // 累積折れ線グラフデータを作成
    let total = 0;
    const lineData = barData.map(function (value) {
      total = total + value;
      return Math.floor(total / props.studentCount * 100);
    });

    // グラフ描画オブジェクトを構成
    const graphData = {
      data: {
        labels: ['5秒', '10秒', '15秒', '30秒', '45秒', '60秒', '90秒', '120秒', '150秒', '180秒', '180秒以上'],
        datasets: [
          {
            label: '回答数',
            type: "bar",
            data: barData,
            borderWidth: 1,
            yAxisID: "y-axis-1",
          },
          {
            label: '回答率推移',
            type: "line",
            fill: false,
            data: lineData,
            borderWidth: 1,
            borderColor: "#3f51b5",
            yAxisID: "y-axis-2",
          },
        ]
      },
      config: {
        type: 'bar',
        data: barData,
        options: {
          legend: {
            reverse: true,
          },
          scales: {
            yAxes: [
              {
                id: "y-axis-1",
                position: "right",
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
                scaleLabel: {
                  display: true,
                  labelString: '回答数（件数）'
                }
              },
              {
                id: "y-axis-2",
                position: "left",
                ticks: {
                  beginAtZero: true,
                  max: 100,
                  min: 0,
                  fontColor: '#3f51b5',
                },
                scaleLabel: {
                  display: true,
                  labelString: '回答率（％）',
                  fontColor: '#3f51b5',
                },
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
          }
        },
      },
    };
    setGraphData(graphData);
  }

  return (
    <Paper variant="outlined" className={classes.result}>
      <Box>
        <BarChart
          data={graphData.data ? graphData.data : {}}
          options={graphData.config ? graphData.config.options : {}}
        />
      </Box>
    </Paper>
  );
};

TrendPanel.propTypes = {
  question: PropTypes.object,
  studentCount: PropTypes.number,
};


export default TrendPanel;
import React from 'react';
import {
  Box,
  Card,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  answer: {
    padding: 5,
  },
  icon: {
    marginTop: 100,
    marginBottom: 100,
    width: 100,
    height: 100,
    color: "#bdbdbd",
  },
}));

const WaitLoding = (props) => {
  const classes = useStyles();

  return (
    <Card>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={classes.answer}
      >
        <CircularProgress className={classes.icon} />
      </Box>
    </Card>
  );
};

export default WaitLoding;
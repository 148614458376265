import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import Model from 'src/models/Model';
import SessionUtil from 'src/utils/SessionUtil';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LessonListView = () => {
  const classes = useStyles();
  const location = useLocation();

  const [lessons, setLessons] = useState([]);
  const [displayMode, setDisplayMode] = useState('List');
  const [messageOpen, setMessageOpen] = useState(false);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {

    // 認証チェック
    if (!SessionUtil.getLoginAccount().id) {
      return;
    }

    if (location.state && location.state.deleted) {
      setMessageOpen(true);
    }
    getLessonList();
  }, [location.state]);

  // --------------------------------------------------
  // 講義一覧取得処理
  // --------------------------------------------------
  const getLessonList = async () => {
    const array = await Model.getLessonList(SessionUtil.getLoginAccount().id);
    array.sort(function (a, b) {
      return a.date < b.date ? 1 : -1;
    });
    setLessons(array);
  };

  //--------------------------------------------------
  // 削除成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  //--------------------------------------------------
  // 表示モード変更
  //--------------------------------------------------
  const changeDisplayMode = (displayMode) => {
    setDisplayMode(displayMode);
  }

  return (
    <Page
      className={classes.root}
      title="講義一覧 | Active Poll"
    >
      <Container maxWidth={false}>
        <Toolbar
          reload={getLessonList}
          changeMode={changeDisplayMode}
        />
        <Box mt={3}>
          <Results
            displayMode={displayMode}
            reload={getLessonList}
            lessons={lessons} />
        </Box>
      </Container>

      {/* ======================================== */}
      {/* 講義削除成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          講義を削除しました。
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default LessonListView;

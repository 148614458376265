import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Result from 'src/views/student/MainView/Faq/Result';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600
  },
  closeBtn: {
    marginRight: 10,
    fontWeight: 600
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FaqResultDialogView = (props) => {
  const classes = useStyles();

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    props.handleClose();
  }

  return (
    <Dialog fullScreen open={props.open} onClose={handleDialogClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h4" className={classes.title}>質問</Typography>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth={false} className={classes.container}>
        <Box mt={3}>
          <Result
            lesson={props.lesson}
            student={props.student}
          />
        </Box>
      </Container>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        p={2}
      >
        <Box>
          <Button
            color="primary"
            variant="outlined"
            onClick={props.handleClose}
            className={classes.closeBtn}
          >
            閉じる
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

FaqResultDialogView.propTypes = {
  open: PropTypes.bool,
  lesson: PropTypes.object,
  student: PropTypes.object,
  handleClose: PropTypes.func,
};

export default FaqResultDialogView;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Select,
  Snackbar,
  Tabs,
  Tab,
  TextField,
  makeStyles,
} from '@material-ui/core';
import {
  ExternalLink as ExternalLink3Icon,
} from 'react-feather';
import TuneIcon from '@material-ui/icons/Tune';
import Alert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import QRcodeDialogView from '../../dialog/QRcodeDialogView';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles(({
  root: {},
  bold: {
    fontWeight: 600
  },
  strech: {
    width: "100%"
  },
  option: {
    marginTop: 50
  },
  textField: {
    "&$disabled": {
      color: "#546e7a",
    }
  },
  disabled: {},
  chip: {
    marginLeft: 30
  },
  tabwrapper: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.12)'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "yyyy年MM月dd日", { locale: this.locale });
  }
  getCalendarHeaderText(date) {
    return format(date, "yyyy年MM月", { locale: this.locale });
  }
}

const Lesson = (props) => {
  const classes = useStyles();

  const [values, setValues] = useState(props.lesson);
  const [errors, setErrors] = useState({});
  const [messageOpen, setMessageOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [qrcodeDialogOpen, setQRcodeDialogOpen] = useState(false);
  const [customizeSettingOpen, setCustomizeSettingOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  // --------------------------------------------------
  // 入力項目の変更
  // --------------------------------------------------
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setIsChanged(true);
  };

  // --------------------------------------------------
  // 入力項目の変更（チェックボックス）
  // --------------------------------------------------
  const handleCheckChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked
    });
    setIsChanged(true);
  };

  // --------------------------------------------------
  // 日付項目の変更
  // --------------------------------------------------
  const handleDateChange = (name, date) => {
    setValues({
      ...values,
      [name]: date
    });
    setIsChanged(true);
  }

  // --------------------------------------------------
  // 授業更新処理
  // --------------------------------------------------
  const handleSave = async (event) => {

    // 入力文字のtrim処理
    const name = Util.trim(values.name);
    const accessKey = Util.trim(values.accessKey);
    const accountName = Util.trim(values.accountName);
    const accountOrganization = Util.trim(values.accountOrganization);
    const ondemandEntryStatusInvalidMessage = Util.trim(values.ondemandEntryStatusInvalidMessage);
    const ondemandConfirmMessage = Util.trim(values.ondemandConfirmMessage);

    let check = {};
    // 必須チェック
    if (!name) {
      check.name = '必須項目です';
    }
    if (values.voteMode === Constants.voteModeRealtime && !values.date) {
      check.date = '必須項目です';
    }
    if (values.voteMode === Constants.voteModeNonRealtime && !values.startDate) {
      check.startDate = '必須項目です';
    }
    if (values.voteMode === Constants.voteModeNonRealtime && !values.endDate) {
      check.endDate = '必須項目です';
    }
    if (values.accessKeyRequired === true && !accessKey) {
      check.accessKey = '必須項目です';
    }
    if (!accountName) {
      check.accountName = '必須項目です';
    }
    if (!accountOrganization) {
      check.accountOrganization = '必須項目です';
    }
    if (values.isOndemandEntryStatusInvalidMessageEdit === true && !ondemandEntryStatusInvalidMessage) {
      check.ondemandEntryStatusInvalidMessage = '必須項目です';
    }
    if (values.isOndemandConfirmMessageOpen === true && !ondemandConfirmMessage) {
      check.ondemandConfirmMessage = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 日付フォーマットチェック
    if (values.voteMode === Constants.voteModeRealtime) {
      if (!Util.isValidDate(values.date)) {
        check.date = '不正な日付です';
      }
    }
    else {
      if (!Util.isValidDate(values.startDate)) {
        check.startDate = '不正な日付です';
      }
      if (!Util.isValidDate(values.endDate)) {
        check.endDate = '不正な日付です';
      }
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 日付範囲チェック
    if (values.voteMode === Constants.voteModeNonRealtime) {
      if (!Util.isDayAfter(values.startDate, values.endDate)) {
        check.endDate = '開始日よりも前の日付です';
      }
      if (Object.keys(check).length > 0) {
        setErrors(check);
        return;
      }
    }


    // 更新データの作成
    // ・作成日時を追加
    // ・アカウントを追加
    let data = {};
    data.name = Util.escape(name);
    data.accessKey = accessKey;

    if (values.voteMode === Constants.voteModeRealtime) {
      data.date = Util.getDayFormat(values.date);
      data.startDate = data.date;
      data.endDate = data.date;
    }
    else {
      data.startDate = Util.getDayFormat(values.startDate);
      data.endDate = Util.getDayFormat(values.endDate);
      data.date = data.startDate;
    }
    data.updateDate = Util.getCurrentDayTime();
    data.accountName = Util.escape(accountName);
    data.accountOrganization = Util.escape(accountOrganization);
    data.accessKeyRequired = values.accessKeyRequired;
    data.profileRequired = values.profileRequired;
    data.voteMode = values.voteMode;
    data.useConfirm = values.useConfirm;
    data.useFaQ = values.useFaQ;
    data.useScene = values.useScene;
    data.studentIdRequired = values.studentIdRequired;
    data.isOndemandShare = values.isOndemandShare;
    data.isOndemandEdit = values.isOndemandEdit;
    data.ondemandEntryStatus = values.ondemandEntryStatus;
    data.isOndemandEntryStatusInvalidMessageEdit = values.isOndemandEntryStatusInvalidMessageEdit;
    data.ondemandEntryStatusInvalidMessage = Util.escape(ondemandEntryStatusInvalidMessage);
    data.isOndemandConfirmMessageOpen = values.isOndemandConfirmMessageOpen;
    data.ondemandConfirmMessage = Util.escape(ondemandConfirmMessage);

    // 更新処理
    await Model.updateLesson(values.id, data);
    setErrors({});
    setMessageOpen(true);
    setIsChanged(false);

    // 親コンポーネントに講義情報の更新を伝達
    props.handleUpdateLesson(values.id);
  };

  //--------------------------------------------------
  // 講義更新成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  // --------------------------------------------------
  // アクセス情報表示ボタン押下処理
  // --------------------------------------------------
  const handleQRCodeButtonClick = (event) => {
    setQRcodeDialogOpen(true);
  }

  // --------------------------------------------------
  // アクセス情報ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDialogClose = () => {
    setQRcodeDialogOpen(false);
  }

  // --------------------------------------------------
  // カスタマイズ設定オープン・クローズ処理
  // --------------------------------------------------
  const handleCustomizeSetting = () => {
    setCustomizeSettingOpen(!customizeSettingOpen);
  }

  // --------------------------------------------------
  //  タブ操作処理
  // --------------------------------------------------
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <form>
      <Card>
        <CardHeader
          title={
            values.voteMode === Constants.voteModeNonRealtime ? (
              <>基本情報<Chip className={classes.chip} label="オンデマンド" size="small" color="primary" variant="outlined" /></>
            ) : '基本情報'
          }
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="講義名"
                required
                margin="normal"
                name="name"
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
                error={errors.name ? true : false}
                helperText={errors.name ? errors.name : ''}
              />
            </Grid>
            {values.voteMode === Constants.voteModeRealtime ? (
              <Grid
                item
                md={3}
                xs={12}
              >
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    required
                    name='date'
                    label="開催日"
                    format="yyyy/MM/dd"
                    value={values.date}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={date => handleDateChange('date', date)}
                    invalidDateMessage="日付の形式が正しくありません"
                    error={errors.date ? true : false}
                    helperText={errors.date ? errors.date : ''}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) :
              <>
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      required
                      name='startDate'
                      label="開催期間（開始）"
                      format="yyyy/MM/dd"
                      value={values.startDate}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={date => handleDateChange('startDate', date)}
                      invalidDateMessage="日付の形式が正しくありません"
                      error={errors.startDate ? true : false}
                      helperText={errors.startDate ? errors.startDate : ''}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      required
                      name='endDate'
                      label="開催期間（終了）"
                      format="yyyy/MM/dd"
                      value={values.endDate}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={date => handleDateChange('endDate', date)}
                      invalidDateMessage="日付の形式が正しくありません"
                      error={errors.endDate ? true : false}
                      helperText={errors.endDate ? errors.endDate : ''}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                    <InputLabel>受付状態</InputLabel>
                    <Select
                      name="ondemandEntryStatus"
                      value={values.ondemandEntryStatus}
                      onChange={handleChange}
                      label="受付状態"
                    >
                      <MenuItem value={Constants.ondemandEntryStatusValid}>受付中</MenuItem>
                      <MenuItem value={Constants.ondemandEntryStatusInvalid}>一時停止中</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            }
            <Grid
              item
              md={3}
              xs={12}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <TextField
                  fullWidth
                  label="セミナーコード"
                  name="code"
                  value={values.code}
                  variant="outlined"
                  disabled
                  InputLabelProps={{
                    classes: {
                      root: classes.textField,
                      disabled: classes.disabled
                    }
                  }}
                />
                <IconButton
                  onClick={(event) => handleQRCodeButtonClick(event)}
                  color="primary" >
                  <ExternalLink3Icon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="講師氏名"
                name="accountName"
                onChange={handleChange}
                required
                variant="outlined"
                value={values.accountName}
                error={errors.accountName ? true : false}
                helperText={errors.accountName ? errors.accountName : ''}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="講師所属"
                onChange={handleChange}
                required
                name="accountOrganization"
                value={values.accountOrganization}
                variant="outlined"
                error={errors.accountOrganization ? true : false}
                helperText={errors.accountOrganization ? errors.accountOrganization : ''}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            item
            md={3}
            xs={12}
          >
            <Button
              color="primary"
              className={classes.bold}
              onClick={handleCustomizeSetting}
              startIcon={<TuneIcon />}
            >
              カスタマイズ設定　{customizeSettingOpen ? '▲' : '▼'}
            </Button>
          </Grid>
        </CardContent>
        <Collapse in={customizeSettingOpen}>
          <CardContent>
            <Box className={classes.tabwrapper}>
              <Tabs textColor="primary" value={tabValue} onChange={handleTabChange}>
                <Tab label="アクセスキー" />
                <Tab label="受講生情報" />
                {values.voteMode === Constants.voteModeRealtime ? (
                  <Tab label="リアルタイム投票" />
                ) : ''}
                {values.voteMode === Constants.voteModeRealtime ? (
                  <Tab label="拡張機能" />
                ) : ''}
                {values.voteMode === Constants.voteModeNonRealtime ? (
                  <Tab label="オンデマンド投票" />
                ) : ''}
              </Tabs>
            </Box>

            {/* ======================================== */}
            {/* アクセスキータブ */}
            {/* ======================================== */}
            <TabPanel value={tabValue} index={0}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <FormGroup>
                    <FormControlLabel
                      label="アクセスキーの設定"
                      control={
                        <Checkbox
                          name="accessKeyRequired"
                          checked={values.accessKeyRequired}
                          onChange={handleCheckChange}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              {values.accessKeyRequired === true ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="アクセスキー"
                    name="accessKey"
                    onChange={handleChange}
                    required={values.accessKeyRequired === true ? true : false}
                    value={values.accessKey}
                    variant="outlined"
                    error={errors.accessKey ? true : false}
                    helperText={errors.accessKey ? errors.accessKey : ''}
                  />
                </Grid>
              </Grid>
              ) : ''
              }
            </TabPanel>
            {/* ======================================== */}
            {/* 受講生情報タブ */}
            {/* ======================================== */}
            <TabPanel value={tabValue} index={1}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <FormGroup>
                    <FormControlLabel
                      label="受講生情報の登録"
                      control={
                        <Checkbox
                          name="profileRequired"
                          checked={values.profileRequired}
                          onChange={handleCheckChange}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              {values.profileRequired === true ? (
                <Grid
                  container
                  spacing={3}
                >

                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <FormGroup>
                      <FormControlLabel
                        label="学籍番号の入力"
                        control={
                          <Checkbox
                            name="studentIdRequired"
                            checked={values.studentIdRequired}
                            onChange={handleCheckChange}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              ) : ''
              }
            </TabPanel>
            {/* ======================================== */}
            {/* リアルタイム投票 */}
            {/* ======================================== */}
            {values.voteMode === Constants.voteModeRealtime ? (
              <TabPanel value={tabValue} index={2}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <FormGroup>
                      <FormControlLabel
                        label="回答開始の確認ポップアップ表示"
                        control={
                          <Checkbox
                            name="useConfirm"
                            checked={values.useConfirm}
                            onChange={handleCheckChange}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </TabPanel>
            ) : ''}
            {/* ======================================== */}
            {/* 拡張機能 */}
            {/* ======================================== */}
            {values.voteMode === Constants.voteModeRealtime ? (
              <TabPanel value={tabValue} index={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <FormGroup>
                      <FormControlLabel
                        label="質問機能の利用"
                        control={
                          <Checkbox
                            name="useFaQ"
                            checked={values.useFaQ}
                            onChange={handleCheckChange}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </TabPanel>
            ) : ''}
            {/* ======================================== */}
            {/* オンデマンド投票 */}
            {/* ======================================== */}
            {values.voteMode === Constants.voteModeNonRealtime ? (
              <TabPanel value={tabValue} index={2}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <FormGroup>
                      <FormControlLabel
                        label="回答者への結果表示"
                        control={
                          <Checkbox
                            name="isOndemandShare"
                            checked={values.isOndemandShare}
                            onChange={handleCheckChange}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <FormGroup>
                      <FormControlLabel
                        label="回答内容の修正"
                        control={
                          <Checkbox
                            name="isOndemandEdit"
                            checked={values.isOndemandEdit}
                            onChange={handleCheckChange}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <FormGroup>
                      <FormControlLabel
                        label="一時停止中メッセージの変更"
                        control={
                          <Checkbox
                            name="isOndemandEntryStatusInvalidMessageEdit"
                            checked={values.isOndemandEntryStatusInvalidMessageEdit}
                            onChange={handleCheckChange}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                {values.isOndemandEntryStatusInvalidMessageEdit === true ? (
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="一時停止中メッセージ"
                        name="ondemandEntryStatusInvalidMessage"
                        onChange={handleChange}
                        required={values.isOndemandEntryStatusInvalidMessageEdit === true ? true : false}
                        value={values.ondemandEntryStatusInvalidMessage  || Constants.ondemandEntryStatusInvalidDefaultMessage}
                        variant="outlined"
                        minRows={3}
                        multiline
                        error={errors.ondemandEntryStatusInvalidMessage ? true : false}
                        helperText={errors.ondemandEntryStatusInvalidMessage ? errors.ondemandEntryStatusInvalidMessage : ''}
                      />
                    </Grid>
                  </Grid>
                ) : ''
                }
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <FormGroup>
                      <FormControlLabel
                        label="確認メッセージの表示"
                        control={
                          <Checkbox
                            name="isOndemandConfirmMessageOpen"
                            checked={values.isOndemandConfirmMessageOpen}
                            onChange={handleCheckChange}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                {values.isOndemandConfirmMessageOpen === true ? (
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="確認メッセージ"
                        name="ondemandConfirmMessage"
                        onChange={handleChange}
                        required={values.isOndemandConfirmMessageOpen === true ? true : false}
                        value={values.ondemandConfirmMessage || Constants.ondemandConfirmDefaultMessage}
                        variant="outlined"
                        minRows={3}
                        multiline
                        error={errors.ondemandConfirmMessage ? true : false}
                        helperText={errors.ondemandConfirmMessage ? errors.ondemandConfirmMessage : ''}
                      />
                    </Grid>
                  </Grid>
                ) : ''
                }
              </TabPanel>
            ) : ''}
          </CardContent>
        </Collapse>
        {/*
        <Divider className={classes.option} />
        <CardHeader
          title="オプション設定"
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.strech}>
                <InputLabel id="option-profile">プロフィール入力</InputLabel>
                <Select
                  labelId="option-profile"
                  id="option-profile"
                  value={option1}
                  onChange={handleSelectChange1}
                  label="プロフィール入力"

                >
                  <MenuItem value={10}>氏名・学籍番号を入力する</MenuItem>
                  <MenuItem value={20}>氏名・学籍番号を入力しない</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.strech}>
                <InputLabel id="option-qa">QA機能の利用</InputLabel>
                <Select
                  labelId="option-qa"
                  id="option-qa"
                  value={option2}
                  onChange={handleSelectChange2}
                  label="QA機能の利用"

                >
                  <MenuItem value={10}>QA機能を使用する</MenuItem>
                  <MenuItem value={20}>QA機能を使用しない</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        */}
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.bold}
            onClick={handleSave}
            disabled={isChanged ? false : true}
          >
            保存する
          </Button>
        </Box>
      </Card>

      {/* ======================================== */}
      {/* アクセス情報ダイアログ */}
      {/* ======================================== */}
      <QRcodeDialogView
        open={qrcodeDialogOpen}
        handleClose={handleDialogClose}
        code={values.code}
        accessKeyText={values.accessKey}
        accessKeyRequired={values.accessKeyRequired}
      />

      {/* ======================================== */}
      {/* 講義更新成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          講義を更新しました。
        </Alert>
      </Snackbar>
    </form >
  );
};
Lesson.propTypes = {
  lesson: PropTypes.object,
  handleUpdateLesson: PropTypes.func
};

export default Lesson;

import Util from './Util';

const DEBUG = String(window.location.href).lastIndexOf('http://localhost:3000/', 0) === 0 ? true : false;


// --------------------------------------------------
// トレースログ出力
// --------------------------------------------------
const trace = (source, message) => {
  if (DEBUG) {
    console.log('[TRACE] ' + Util.getCurrentDayTimeSec() + ' [' + source + '] ' + message);
  }
};

const Log = {
  trace,
}

export default Log;

import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Container,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Send from './Send';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditDialog = (props) => {
  const classes = useStyles();

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    props.handleClose();
  }

  return (
    <Dialog fullWidth maxWidth='lg' open={props.open} onClose={handleDialogClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h4" className={classes.title}>質問編集</Typography>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth={false} className={classes.container}>
        <Box mt={3}>
          <Send
            lesson={props.lesson}
            student={props.student}
            editFaq={props.editFaq}
            handleClose={props.handleClose}
          />
        </Box>
      </Container>
    </Dialog>
  );
};

EditDialog.propTypes = {
  open: PropTypes.bool,
  lesson: PropTypes.object,
  student: PropTypes.object,
  editFaq: PropTypes.object,
  handleClose: PropTypes.func,
};

export default EditDialog;

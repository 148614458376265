import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ReplayIcon from '@material-ui/icons/Replay';
import Log from 'src/utils/Log';
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  answer: {
    padding: 10,
  },
  reloadIconBox: {
    textAlign: 'right',
    marginTop: 20,
    marginRight: 20,
  },
  imageIcon: {
    marginTop: 100,
    width: 100,
    height: 100,
    color: "#bdbdbd",
  },
  text: {
    marginTop: 40,
    marginBottom: 60,
    color: "#bdbdbd",
    fontWeight: 600,
  },
  description: {
    marginBottom: 100,
    color: "#bdbdbd",
    fontWeight: 600,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Wait = (props) => {
  const classes = useStyles();
  const [backdropOpen, setBackdropOpen] = useState(false);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.EnqueteWait', 'useEffect()');

    return function cleanup() {
      Log.trace('Student.MainView Enquete.EnqueteWait', 'useEffect() cleanup()');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // リロード処理
  // --------------------------------------------------
  const handleReload = () => {
    Log.trace('Student.MainView Enquete.EnqueteWait', 'reload');
    setBackdropOpen(true);
    props.reload();
    setTimeout(handleClose, 2000);
  };

  // --------------------------------------------------
  // 読み込みバックドロップの消去処理
  // --------------------------------------------------
  const handleClose = () => {
    setBackdropOpen(false);
    // アクティブ度計測ログの追加
    Model.addLog(props.lesson.id, props.student.oneTimeId, Constants.operationTypeWaitReload);
  };

  return (
    <Card>
      <Box
        className={classes.reloadIconBox}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleReload()}
          startIcon={<ReplayIcon />}
        >
          再読み込み
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-between"
        alignItems="center"
        className={classes.answer}
      >
        <HourglassEmptyIcon className={classes.imageIcon} />
        <Typography variant="h3" className={classes.text}>しばらくお待ちください</Typography>
        <Typography variant="h4" className={classes.description}>講義の進行に応じて自動で画面が切り替わります。 <br />講義中はこのまま、画面を閉じないでください。</Typography>
      </Box>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Card>
  );
};

Wait.propTypes = {
  lesson: PropTypes.object,
  student: PropTypes.object,
  reload: PropTypes.func
};

export default Wait;
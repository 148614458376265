import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles(({
  root: {},
  codeText: {
    marginRight: 5,
  },
  textField: {
    "&$disabled": {
      color: "#263238",
    }
  },
  disabled: {},
  chip: {
    marginLeft: 30
  }
}));

const Lesson = (props) => {
  const classes = useStyles();
  const [values] = useState(props.lesson);

  return (
    <form>
      <Card>
        <CardHeader
          title={
            values.voteMode === Constants.voteModeNonRealtime ? (
              <>基本情報<Chip className={classes.chip} label="オンデマンド" size="small" color="primary" variant="outlined" /></>
            ) : '基本情報'
          }
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="講義名"
                name="name"
                disabled
                type="text"
                value={values.name}
                variant="standard"
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label={values.voteMode === Constants.voteModeRealtime ? '開催日' : '開催期間'}
                name="date"
                disabled
                type="text"
                value={values.voteMode === Constants.voteModeRealtime ? values.date : `${values.startDate} 〜 ${values.endDate}`}
                variant="standard"
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="受講者数"
                name="session"
                disabled
                type="text"
                value={Object.keys(values.session).length}
                variant="standard"
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form >
  );
};

Lesson.propTypes = {
  lesson: PropTypes.object,
};

export default Lesson;

import React ,{ useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import {
  CornerDownLeft as BackIcon,
  Edit3 as Edit3Icon,
  Play as PlayIcon,
  BarChart as BarChartIcon,
  File as FileIcon,
} from 'react-feather';
import Util from 'src/utils/Util';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  LinkButton: {
    padding: 0
  },
  tab: {
    flexDirection: "row",
  },
  tabIcon: {
    minHeight: "inherit",
  },
  tabLabel: {
    marginLeft: 10,
    fontWeight: 600,
  },
  readcrumbsWithIcon: {
    display: "flex",
    alignItems: "center",
  },
  readcrumbsIcon: {
    marginLeft: 0.5,
    color: theme.palette.text.primary,
  },
}));

const StyledTab = withStyles({
  selected: {
    //backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  wrapper:{},
  labelIcon:{},
})(Tab);

const Toolbar = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [value, setValue] = useState(props.index);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    setValue(props.index);
    return function cleanup() {
    };
  }, [props.index]);

  // --------------------------------------------------
  // タブ切り替え処理
  // --------------------------------------------------
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    props.handleTabChange(newValue);
  };

  // --------------------------------------------------
  // 戻る処理
  // --------------------------------------------------
  const handleLink = () => {
    navigate('/admin/lessons', { replace: false, });
  };

  return (
    <div>
      {/* ======================================== */}
      {/* パンくずリスト・戻るボタン  */}
      {/* ======================================== */}
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" variant="h6">トップ</Typography>
          <Typography color="textPrimary" variant="h6">
            <Button color="inherit" underline="always" className={classes.LinkButton} component={Link} onClick={() => handleLink()}>
              講義一覧
            </Button>
          </Typography>
          <Typography color="textPrimary" variant="h6" className={classes.readcrumbsWithIcon} >
            <FileIcon className={classes.readcrumbsIcon} size={18}/>
            {props.lesson.name}
          </Typography>
        </Breadcrumbs>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleLink()}
          startIcon={<BackIcon />}
          className={classes.bold}
        >
          戻る
        </Button>
      </Box>
      {/* ======================================== */}
      {/* タブメニュー   */}
      {/* ======================================== */}
      <Box mt={3}>
      <Paper>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            height: 5
          }
        }}
        TabScrollButtonProps={{
          style: {
            backgroundColor: "red"
          }
        }}
      >
        <StyledTab 
          icon={<Edit3Icon />}
          label={<span className={classes.tabLabel}>編集</span>}
          classes={{ wrapper: classes.tab, labelIcon: classes.tabIcon }}
          disabled={props.isLessonStart}
        />
        {props.lesson.voteMode  === Constants.voteModeRealtime ? (
          <StyledTab 
            icon={<PlayIcon />}
            label={<span className={classes.tabLabel}>開始</span>}
            classes={{ wrapper: classes.tab, labelIcon: classes.tabIcon }}
            disabled={Util.isDayPassed(props.lesson.date)}
          />
        ) : ''}
        <StyledTab 
          icon={<BarChartIcon />}
          label={<span className={classes.tabLabel}>結果確認</span>}
          classes={{ wrapper: classes.tab, labelIcon: classes.tabIcon }}
          disabled={props.isLessonStart}
        />
      </Tabs>
      </Paper>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  index: PropTypes.number,
  handleTabChange: PropTypes.func,
  lesson: PropTypes.object,
  isLessonStart: PropTypes.bool
};

export default Toolbar;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Model from 'src/models/Model';
import SessionUtil from 'src/utils/SessionUtil';

const useStyles = makeStyles(({
  root: {},
  closeBtn: {
    marginLeft: 10,
    fontWeight: 600
  },
  bold: {
    fontWeight: 600
  },
  strech: {
    width: "100%"
  },
}));

const Form = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    lessonId: 'none'
  });
  const [lessons, setLessons] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [copyButtonEnable, setCopyButtonEnable] = useState(false);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    getLessonList();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 講義一覧取得処理
  // --------------------------------------------------
  const getLessonList = async () => {
    const array = await Model.getLessonList(SessionUtil.getLoginAccount().id);
    array.sort(function (a, b) {
      return a.date < b.date ? 1 : -1;
    });
    setLessons(array);
  };

  // --------------------------------------------------
  // 設問一覧取得処理
  // --------------------------------------------------
  const getQuestionList = async (lessonId) => {
    const array = await Model.getQuestionList(lessonId);
    array.sort(function (a, b) {
      return a.order < b.order ? -1 : 1;
    });
    setQuestions(array);
  };

  // --------------------------------------------------
  // 講義を選択
  // --------------------------------------------------
  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    if(event.target.value !== 'none'){
      getQuestionList(event.target.value);
    }
    else {
      setQuestions([]);
    }
    setSelectedValue({});
    setCopyButtonEnable(false);
  };

  // --------------------------------------------------
  // 設問チェックボックス選択処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setSelectedValue({ ...selectedValue, [event.target.name]: event.target.checked });
    const array = [];
    if (event.target.checked === true) {
      for (let key in selectedValue) {
        if (selectedValue[key] === true) {
          array.push(key)
        }
      }
      array.push(event.target.name)
    }
    else {
      for (let key in selectedValue) {
        if (selectedValue[key] === true && key !== event.target.name) {
          array.push(key)
        }
      }
    }
    if(array.length > 0){
      setCopyButtonEnable(true);
    }
    else {
      setCopyButtonEnable(false);
    }
  };

  // --------------------------------------------------
  // インポート実行処理
  // --------------------------------------------------
  const handleSave = async (event) => {
    let currentOrder = props.order;
    // チェックされた設問分、実行する
    for (let selectedValueId in selectedValue) {
      if (selectedValue[selectedValueId] === true) {
        for (let question of questions) {
          if (question.id === selectedValueId) {
            question.order = currentOrder;
            delete question.id;
            currentOrder++;
            await Model.addQuestion(props.lesson.id, question);
          }
        }
      }
    }

    // ダイアログを閉じる
    props.handleSave();
  };

  // --------------------------------------------------
  // キャンセル処理
  // --------------------------------------------------
  const handleClose = async (event) => {
    // ダイアログを閉じる
    props.handleClose();
  };

  return (
    <form>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <FormControl variant="outlined" className={classes.strech}>
            <InputLabel>コピー元の講義</InputLabel>
            <Select
              name="lessonId"
              value={values.lessonId}
              label="コピー元の講義"
              onChange={handleValueChange}
            >
              <MenuItem value="none">選択してください</MenuItem>
              {lessons.map((lesson) => (
                <MenuItem key={lesson.id} value={lesson.id}>{lesson.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          {questions.map((question, index) => (
            <label key={index}>
              <Box
                alignItems="center"
                display="flex"
                className={classes.answerOption}
              >
                <Checkbox
                  name={question.id}
                  checked={selectedValue[question.id] === true ? true : false}
                  onChange={handleChange}
                />
                <Typography variant="h5" >{question.title}</Typography>
              </Box>
            </label>
          ))}
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Box>
        </Box>
        <Box>

          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            className={classes.bold}
            disabled={copyButtonEnable === true ? false : true}
          >
            コピーする
          </Button>

          <Button
            color="primary"
            variant="outlined"
            onClick={handleClose}
            className={classes.closeBtn}
          >
            キャンセル
          </Button>
        </Box>
      </Box>
    </form >
  );
};

Form.propTypes = {
  order: PropTypes.number,
  lesson: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default Form;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  bold: {
    fontWeight: 600
  },
  description: {
    whiteSpace: "pre-line",
  },
}));

const ConfirmDialogView = (props) => {
  const classes = useStyles();

  const [selected, setSelected] = useState({
    confirm: false
  });

  // --------------------------------------------------
  //  チェックボックスの更新
  // --------------------------------------------------
  const handleCheckChange = (event) => {
    setSelected({ ...selected, [event.target.name]: event.target.checked });
  }

  // --------------------------------------------------
  //  「はい」の処理
  // --------------------------------------------------
  const agree = () => {
    setSelected({ confirm: false });
    props.agree();
  }

  // --------------------------------------------------
  //  「いいえ」の処理
  // --------------------------------------------------
  const disagree = () => {
    setSelected({ confirm: false });
    props.disagree();
  }

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleDialogClose}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.description}>
          {props.description}
        </DialogContentText>
      </DialogContent>
      {props.confirmText ?
        (
          <DialogContent>

            <Box
              alignItems="center"
              display="flex"
              ml={-1}
            >
              <Checkbox
                checked={selected.confirm === true ? true : false}
                name="confirm"
                onChange={handleCheckChange}
              />
              {props.confirmText}
            </Box>
          </DialogContent>
        ) : ''
      }
      <DialogActions>
        <Button
          onClick={agree}
          color="primary"
          className={classes.bold}
          disabled={props.confirmText && selected.confirm === false ? true : false}
        >
          はい
        </Button>
        {props.disagree ?
          (
            <Button
              onClick={disagree}
              color="primary"
              className={classes.bold}
            >
              いいえ
            </Button>
          ) : ''
        }
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialogView.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  confirmText: PropTypes.string,
  disagree: PropTypes.func,
  agree: PropTypes.func,
};

export default ConfirmDialogView;
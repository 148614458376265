import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  makeStyles,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600
  },
  answer: {
    padding: 10,
  },
  button: {
    marginTop: 200,
    marginBottom: 300,
    fontWeight: 600,
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = (props) => {
  const classes = useStyles();

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
  }

  return (
    <Dialog fullWidth maxWidth='lg' open={props.open} onClose={handleDialogClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h4" className={classes.title}>回答開始の確認</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth={false} className={classes.container}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-between"
          alignItems="center"
          className={classes.answer}
        >
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => props.handleConfirm()}
            startIcon={<PlayArrowIcon />}
            className={classes.button}
          >
            質問を表示する
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  handleConfirm: PropTypes.func,
};

export default ConfirmDialog;

import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import {
  Download as DownloadIcon,
} from 'react-feather';
import EnqueteDownloadDialogView from '../../dialog/EnqueteDownloadDialogView';
import FaqDownloadDialogView from '../../dialog/FaqDownloadDialogView';
import FaqResultDialogView from '../../dialog/FaqResultDialogView';
import LogDownloadDialogView from '../../dialog/LogDownloadDialogView';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles(({
  root: {},
  tableCell: {
    padding: 0,
    fontSize: 15
  },
  faqPreveiwButton: {
    marginLeft: 50,
    fontSize: 12,
    fontWeight: 600,
  },
  disabled: {}
}));

const Download = (props) => {
  const classes = useStyles();
  const [enqueteDownloadDialogOpen, setEnqueteDownloadDialogOpen] = useState(false);
  const [faqDownloadDialogOpen, setFaqDownloadDialogOpen] = useState(false);
  const [faqPreviewDialogOpen, setFaqPreviewDialogOpen] = useState(false);
  const [logDownloadDialogOpen, setLogDownloadDialogOpen] = useState(false);
  const [downloadTarget, setDownloadTarget] = useState({});

  // --------------------------------------------------
  // リアルタイム投票・アンケートのダウンロードアイコン押下処理
  // --------------------------------------------------
  const handleEnqueteDownloadButton = (event) => {
    setDownloadTarget(props.lesson);
    setEnqueteDownloadDialogOpen(true);
  };

  // --------------------------------------------------
  // リアルタイム投票・アンケートのダウンロードダイアログのクローズ処理
  // --------------------------------------------------
  const handleEnqueteDownloadialogClose = () => {
    setDownloadTarget({});
    setEnqueteDownloadDialogOpen(false);
  }

  // --------------------------------------------------
  // 質問のダウンロードアイコン押下処理
  // --------------------------------------------------
  const handleFaqDownloadButton = (event) => {
    setDownloadTarget(props.lesson);
    setFaqDownloadDialogOpen(true);
  };

  // --------------------------------------------------
  // 質問のダウンロードダイアログのクローズ処理
  // --------------------------------------------------
  const handleFaqDownloadialogClose = () => {
    setDownloadTarget({});
    setFaqDownloadDialogOpen(false);
  }

  // --------------------------------------------------
  // 質問データの確認アイコン押下処理
  // --------------------------------------------------
  const handleFaqPreviewButton = (event) => {
    setFaqPreviewDialogOpen(true);
  };

  // --------------------------------------------------
  // 質問データの確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleFaqPreviewDialogClose = () => {
    setFaqPreviewDialogOpen(false);
  }

  // --------------------------------------------------
  // ログデータのダウンロードアイコン押下処理
  // --------------------------------------------------
  const handleLogDownloadButton = (event) => {
    setDownloadTarget(props.lesson);
    setLogDownloadDialogOpen(true);
  };

  // --------------------------------------------------
  // ログデータのダウンロードダイアログのクローズ処理
  // --------------------------------------------------
  const handleLogDownloadialogClose = () => {
    setDownloadTarget({});
    setLogDownloadDialogOpen(false);
  }

  return (
    <form>
      <Card>
        <CardHeader
          title="ダウンロード"
        />
        <Divider />
        <CardContent>

          <Table>
            <TableBody>

              {/* ======================================== */}
              {/* リアルタイム投票・アンケート */}
              {/* ======================================== */}
              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  style={{ width: 200 }}
                >
                  {props.lesson.voteMode === Constants.voteModeRealtime ? 'リアルタイム投票' : 'アンケート'}
                </TableCell>
                <TableCell className={classes.tableCell} >
                  <IconButton
                    onClick={(event) => handleEnqueteDownloadButton(event)}
                    color="primary" >
                    <DownloadIcon />
                  </IconButton>
                </TableCell>
              </TableRow>

              {/* ======================================== */}
              {/* 質問 */}
              {/* ======================================== */}
              {props.lesson.useFaQ === true ? (
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: 200 }}
                  >
                    質問
                  </TableCell>
                  <TableCell className={classes.tableCell} >
                    <IconButton
                      onClick={(event) => handleFaqDownloadButton(event)}
                      color="primary" >
                      <DownloadIcon />
                    </IconButton>

                    <Button
                      color="primary"
                      className={classes.faqPreveiwButton}
                      onClick={(event) => handleFaqPreviewButton(event)}
                    >
                      [プレビュー]
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}

              {/* ======================================== */}
              {/* ログ */}
              {/* ======================================== */}
              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  style={{ width: 200 }}
                >
                  ログ
                </TableCell>
                <TableCell className={classes.tableCell} >
                  <IconButton
                    onClick={(event) => handleLogDownloadButton(event)}
                    color="primary" >
                    <DownloadIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {/* ======================================== */}
      {/* リアルタイム投票・アンケートのダウンロードダイアログ */}
      {/* ======================================== */}
      <EnqueteDownloadDialogView
        open={enqueteDownloadDialogOpen}
        handleClose={handleEnqueteDownloadialogClose}
        lesson={downloadTarget}
        key={'EnqueteDownloadDialogView' + downloadTarget.id}
      />

      {/* ======================================== */}
      {/* 質問のダウンロードダイアログ */}
      {/* ======================================== */}
      <FaqDownloadDialogView
        open={faqDownloadDialogOpen}
        handleClose={handleFaqDownloadialogClose}
        lesson={downloadTarget}
        key={'FaqDownloadDialogView' + downloadTarget.id}
      />

      {/* ======================================== */}
      {/* 質問確認ダイアログ */}
      {/* ======================================== */}
      <FaqResultDialogView
        open={faqPreviewDialogOpen}
        lesson={props.lesson}
        student={{ oneTimeId: 'LessonReviewView' }}
        handleClose={handleFaqPreviewDialogClose}
      />

      {/* ======================================== */}
      {/* ログのダウンロードダイアログ */}
      {/* ======================================== */}
      <LogDownloadDialogView
        open={logDownloadDialogOpen}
        handleClose={handleLogDownloadialogClose}
        lesson={downloadTarget}
        key={'LogDownloadDialogView' + downloadTarget.id}
      />
    </form >
  );
};

export default Download;

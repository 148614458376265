import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Breadcrumbs,
  Snackbar,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Calendar as CalendarIcon,
  FilePlus as FilePlusIcon,
  List as ListIcon
} from 'react-feather';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import LessonAddDialogView from '../dialog/LessonAddDialogView'

const useStyles = makeStyles((theme) => ({
  root: {},
  operationBox: {
    marginTop: 24
  },
  bold: {
    fontWeight: 600
  },
  strech: {
    width: "100%"
  },
}));

const Toolbar = (props) => {
  const classes = useStyles();

  const [display, setDisplay] = useState('List');
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);

  //--------------------------------------------------
  // [講義を追加する]ボタン
  //--------------------------------------------------
  const handleLessonAddButton = (event, id) => {
    setAddDialogOpen(true);
  }

  //--------------------------------------------------
  // 講義追加ダイアログ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleDialogClose = () => {
    setAddDialogOpen(false);
  }

  //--------------------------------------------------
  // 講義追加ダイアログ - [保存する]ボタン処理
  //--------------------------------------------------
  const handleDialogSave = () => {
    setAddDialogOpen(false);
    setMessageOpen(true);
    props.reload();
  }

  //--------------------------------------------------
  // 講義追加成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  //--------------------------------------------------
  // 表示切り替えトグルボタン処理
  //--------------------------------------------------
  const handleDisplay = (event, display) => {
    if (display !== null) {
      setDisplay(display);
      props.changeMode(display);
    }
  };

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="textPrimary" variant="h6">トップ</Typography>
        <Typography color="primary" variant="h5" className={classes.bold}>講義一覧</Typography>
      </Breadcrumbs>

      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.operationBox}
      >
        <ToggleButtonGroup
          exclusive
          value={display}
          onChange={handleDisplay}
          size="small"
        >
          <ToggleButton value="List">
            <Tooltip title="一覧表示">
              <ListIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="Calender">
            <Tooltip title="カレンダー表示">
              <CalendarIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          color="primary"
          variant="contained"
          startIcon={<FilePlusIcon />}
          className={classes.bold}
          onClick={(event) => handleLessonAddButton(event)}
        >
          講義を作成する
        </Button>
      </Box>

      {/* ======================================== */}
      {/* 講義作成ダイアログ */}
      {/* ======================================== */}
      <LessonAddDialogView
        open={addDialogOpen}
        handleClose={handleDialogClose}
        handleSave={handleDialogSave}
      />

      {/* ======================================== */}
      {/* 講義作成成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          講義を追加しました。
        </Alert>
      </Snackbar>
    </Box>
  );
};

Toolbar.propTypes = {
  reload: PropTypes.func,
  changeMode: PropTypes.func,
};

export default Toolbar;

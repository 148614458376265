import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  makeStyles,
  withStyles,
  createStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Edit3 as Edit3Icon, } from 'react-feather';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView'
import Model from 'src/models/Model';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {},
  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  bold: {
    fontWeight: 600
  }
}));

const StyledTableSortLabel = withStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
      '&$active': {
        color: theme.palette.primary.main,
      },
    },
    active: {},
    icon: {
      color: 'inherit !important'
    },
  })
)(TableSortLabel);

const Results = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState([]);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [sortColumnKey, setSortColumnKey] = useState("addDate");
  const [sortOrder, setSortOrder] = useState("desc");

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [selected, setSelected] = useState({});

  //--------------------------------------------------
  // 画面表示処理
  //--------------------------------------------------
  useEffect(() => {
    setAccounts(props.accounts)
  }, [props.accounts]);

  //--------------------------------------------------
  // ページあたり表示件数の切り替え
  //--------------------------------------------------
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  //--------------------------------------------------
  // ページ切り替え
  //--------------------------------------------------
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  //--------------------------------------------------
  // 一覧のソート順変更
  //--------------------------------------------------
  const handleClickSortColumn = (colmunKey) => {
    const isDesc = colmunKey === sortColumnKey && sortOrder === "desc";
    const nextOrder = isDesc ? "asc" : "desc";
    const sortRule = { asc: [1, -1], desc: [-1, 1] };
    const sortedRows = accounts.slice().sort((a, b) => {
      if (a[colmunKey] > b[colmunKey]) {
        return sortRule[nextOrder][0];
      } else if (a[colmunKey] < b[colmunKey]) {
        return sortRule[nextOrder][1];
      } else {
        return 0;
      }
    });
    setSortColumnKey(colmunKey);
    setSortOrder(nextOrder);
    setAccounts(sortedRows);
  }

  //--------------------------------------------------
  // 一覧チェックボックスの選択
  //--------------------------------------------------
  const handleSelect = (event) => {
    setSelected({ ...selected, [event.target.name]: event.target.checked });
  };

  //--------------------------------------------------
  // 一覧チェックボックスの選択有無
  //--------------------------------------------------
  const isSelect = () => {
    let s = false;
    for (let key in selected) {
      if (selected[key] === true) {
        s = true;
      }
    }
    return s;
  };

  // --------------------------------------------------
  // 削除ボタン押下処理
  // --------------------------------------------------
  const handleDeleteButtonClick = (event) => {
    setDeleteDialogOpen(true);
  }

  // --------------------------------------------------
  // 削除確認ダイアログの削除実行処理
  // --------------------------------------------------
  const handleDeleteExecute = async () => {
    for (let key in selected) {
      if (selected[key] === true) {

        // 講義リストの取得
        const lessons = await Model.getLessonList(key);
        for (const lesson of lessons) {
          Log.trace('system.account.AccountListView', 'handleDeleteExecute delete lesson :' + lesson.id);
          await Model.deleteLesson(lesson.id);
        }

        // アーカイブリストの取得
        const archives = await Model.getArchiveList(key);
        for (const archive of archives) {
          Log.trace('system.account.AccountListView', 'handleDeleteExecute delete archive :' + archive.id);
          await Model.deleteArchive(archive.id);
        }

        // アカウントの削除処理
        await Model.deleteAccount(key);
      }
    }
    setDeleteDialogOpen(false);
    setMessageOpen(true);
    props.reload();
    setSelected({});
  }

  // --------------------------------------------------
  // 削除確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  }

  //--------------------------------------------------
  // 削除成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  // --------------------------------------------------
  // 編集アイコン押下処理
  // --------------------------------------------------
  const handleEditButton = (event, account) => {
    navigate('/admin/system/account/edit',
      {
        replace: false,
        state: { account: account }
      }
    );
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 50 }}>
                </TableCell>
                <TableCell style={{ width: 300 }}>
                  氏名
                </TableCell>
                <TableCell>
                  法人名／組織名
                </TableCell>
                <TableCell style={{ width: 200 }}>
                  <StyledTableSortLabel
                    active={sortColumnKey === "addDate"}
                    direction={sortOrder}
                    onClick={() => handleClickSortColumn("addDate")}
                  >
                    登録日時
                  </StyledTableSortLabel>
                </TableCell>
                <TableCell style={{ width: 200 }}>
                  編集
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.slice(page * limit, (page + 1) * limit).map((account) => (
                <TableRow
                  hover
                  key={account.id}
                >
                  <TableCell className={classes.tableCell}>
                    <Checkbox
                      name={account.id}
                      checked={selected[account.id] === true ? true : false}
                      onChange={handleSelect}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {account.fname}&nbsp;{account.lname}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {account.organization}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {account.addDate}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <IconButton
                      onClick={(event) => handleEditButton(event, account)}
                      color="primary" >
                      <Edit3Icon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        p={2}
      >
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={(event) => handleDeleteButtonClick(event)}
            className={classes.bold}
            disabled={isSelect() ? false : true}
          >
            削除する
          </Button>
        </Box>
        <Box>
          <TablePagination
            component="div"
            count={props.accounts.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Box>
      </Box>
      {/* ======================================== */}
      {/* アカウント削除確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={deleteDialogOpen}
        title="アカウントの削除"
        description="このアカウントを削除します。よろしいですか？"
        disagree={handleDeleteDialogClose}
        agree={handleDeleteExecute}
      />

      {/* ======================================== */}
      {/* アカウント削除成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          アカウントを削除しました。
        </Alert>
      </Snackbar>
    </Card >
  );
};

export default Results;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles(({
  root: {},
  closeBtn: {
    marginLeft: 10,
    fontWeight: 600
  },
  bold: {
    fontWeight: 600
  }
}));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "yyyy年MM月dd日", { locale: this.locale });
  }
  getCalendarHeaderText(date) {
    return format(date, "yyyy年MM月", { locale: this.locale });
  }
}

const Form = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: props.lesson.name,
    date: new Date(),
    startDate: new Date(),
    endDate: new Date(),
  });
  const [errors, setErrors] = useState({});

  // --------------------------------------------------
  // 入力項目の変更
  // --------------------------------------------------
  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // --------------------------------------------------
  // 日付項目の変更
  // --------------------------------------------------
  const handleDateChange = (name, date) => {
    setValues({
      ...values,
      [name]: date
    });
  }

  // --------------------------------------------------
  // 授業登録処理
  // --------------------------------------------------
  const handleSave = async (event) => {

    // 入力文字のtrim処理
    const name = Util.trim(values.name);

    let check = {};
    // 必須チェック
    if (!name) {
      check.name = '必須項目です';
    }
    if (props.lesson.voteMode === Constants.voteModeRealtime && !values.date) {
      check.date = '必須項目です';
    }
    if (props.lesson.voteMode === Constants.voteModeNonRealtime && !values.startDate) {
      check.startDate = '必須項目です';
    }
    if (props.lesson.voteMode === Constants.voteModeNonRealtime && !values.endDate) {
      check.endDate = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 日付フォーマットチェック
    if (props.lesson.voteMode === Constants.voteModeRealtime) {
      if (!Util.isValidDate(values.date)) {
        check.date = '不正な日付です';
      }
    }
    else {
      if (!Util.isValidDate(values.startDate)) {
        check.startDate = '不正な日付です';
      }
      if (!Util.isValidDate(values.endDate)) {
        check.endDate = '不正な日付です';
      }
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 日付範囲チェック
    if (props.lesson.voteMode === Constants.voteModeNonRealtime) {
      if (!Util.isDayAfter(values.startDate, values.endDate)) {
        check.endDate = '開始日よりも前の日付です';
      }
      if (Object.keys(check).length > 0) {
        setErrors(check);
        return;
      }
    }

    // 登録データの作成
    const newLesson = { ...props.lesson };
    newLesson.name = Util.escape(name);
    newLesson.accessKey = Util.getRandamCode(8);
    if (props.lesson.voteMode === Constants.voteModeRealtime) {
      newLesson.date = Util.getDayFormat(values.date);
      newLesson.startDate = newLesson.date;
      newLesson.endDate = newLesson.date;
    }
    else {
      newLesson.startDate = Util.getDayFormat(values.startDate);
      newLesson.endDate = Util.getDayFormat(values.endDate);
      newLesson.date = newLesson.startDate;
    }

    newLesson.addDate = Util.getCurrentDayTime();
    // ・講義コード（8桁 + チェックディジット）
    const a = Util.getRandamNumber(8);
    const b = Util.getCheckDigit(a);
    newLesson.code = a + b;

    // 不要データを削除
    delete newLesson.id;
    delete newLesson.session;
    delete newLesson.updateDate;
    // コピー
    await Model.copyLesson(props.lesson.id, newLesson);

    // ダイアログを閉じる
    props.handleSave();
  };

  return (
    <form>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="講義名"
            required
            name="name"
            onChange={handleValueChange}
            type="text"
            value={values.name}
            variant="outlined"
            error={errors.name ? true : false}
            helperText={errors.name ? errors.name : ''}
          />
        </Grid>
        {props.lesson.voteMode === Constants.voteModeRealtime ? (
          <Grid
            item
            md={6}
            xs={12}
          >
            <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                variant="inline"
                required
                inputVariant="outlined"
                label="開催日"
                name="date"
                format="yyyy/MM/dd"
                value={values.date}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => handleDateChange('date', date)}
                invalidDateMessage="日付の形式が正しくありません"
                error={errors.date ? true : false}
                helperText={errors.date ? errors.date : ''}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        ) : (
          <>
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  required
                  name='startDate'
                  label="開催期間（開始）"
                  format="yyyy/MM/dd"
                  value={values.startDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => handleDateChange('startDate', date)}
                  invalidDateMessage="日付の形式が正しくありません"
                  error={errors.startDate ? true : false}
                  helperText={errors.startDate ? errors.startDate : ''}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  required
                  name='endDate'
                  label="開催期間（終了）"
                  format="yyyy/MM/dd"
                  value={values.endDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => handleDateChange('endDate', date)}
                  invalidDateMessage="日付の形式が正しくありません"
                  error={errors.endDate ? true : false}
                  helperText={errors.endDate ? errors.endDate : ''}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </>
        )}
      </Grid>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Box>
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            className={classes.bold}
          >
            作成する
          </Button>

          <Button
            color="primary"
            variant="outlined"
            onClick={props.handleClose}
            className={classes.closeBtn}
          >
            キャンセル
          </Button>
        </Box>
      </Box>
    </form >
  );
};

Form.propTypes = {
  lesson: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default Form;

import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  makeStyles,
  Slide,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Clipboard as ClipboardIcon } from 'react-feather';
import Alert from '@material-ui/lab/Alert';
import QRCode from "qrcode.react"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  url: {
    width: 600,
    marginTop: 30,
    marginBottom: 5,
    textAlign: "center",
    fontWeight: "bold"
  },
  urlText: {
    width: 500,
    marginRight: 10
  },
  option: {
    marginLeft: 20,
  },
  qrcode: {
    marginTop: 10,
    marginBottom: 5,
    textAlign: "center",
  },
  download: {
    marginBottom: 10,
    textAlign: "center",
  },
  bold: {
    fontWeight: 600
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QRcodeDialogView = (props) => {
  const classes = useStyles();

  const [messageOpen, setMessageOpen] = useState(false);
  const [accessUrl, setAccessUrl] = useState('');
  const [containAccessKey, setContainAccessKey] = useState(false);

  const hostname = window.location.hostname;
  const urlBase = hostname === 'localhost' ? 'http://' + hostname + ':3000' : 'https://' + hostname;

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Admin.Lesson.Dialog.QRcodeDialog', 'useEffect()');
    setContainAccessKey(false)
    setAccessUrl(generateUrlWithCode());
  
    return function cleanup() {
      Log.trace('Admin.Lesson.Dialog.QRcodeDialog', 'useEffect() cleanup()');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.code]);

  //--------------------------------------------------
  // 設問追加成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleClipboardCopy = () => {
    setMessageOpen(true);
  }

  //--------------------------------------------------
  // 設問追加成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  // --------------------------------------------------
  // アクセス情報ダイアログのクローズ処理
  // --------------------------------------------------
  const removeWhiteSpace = (str) => {
    return str.replace(/\s+/g, "");
  }

  // --------------------------------------------------
  //  チェックボックスの更新
  // --------------------------------------------------
  const handleCheckChange = () => {
    setContainAccessKey(!containAccessKey);
    setAccessUrl(!containAccessKey ? generateUrlWithCodeAndKey() : generateUrlWithCode());
  }

  // --------------------------------------------------
  //  セミナーコード付きURLの生成
  // --------------------------------------------------
  const generateUrlWithCode = () => {
    return urlBase + '/s/' + removeWhiteSpace(props.code);
  }

  // --------------------------------------------------
  //  セミナーコード＋アクセスキー付きURLの生成
  // --------------------------------------------------
  const generateUrlWithCodeAndKey = () => {
    const text = '' + removeWhiteSpace(props.code) + ',' + props.accessKeyText;
    Log.trace('QRcodeDialog', 'generateUrlWithCodeAndKey text : ' + text);
    return urlBase + '/s/' + Util.encrypt(text);
  }

  // --------------------------------------------------
  //  QRコードダウンロード処理
  // --------------------------------------------------
  const handleQRDownload = () => {
    const canvas = document.getElementById(accessUrl);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  // --------------------------------------------------
  //  閉じる処理
  // --------------------------------------------------
  const handleClose = () => {
    setContainAccessKey(false)
    setAccessUrl(generateUrlWithCode());
    props.handleClose();
  }

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    props.handleClose();
  }

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={handleDialogClose} TransitionComponent={Transition} >
      <DialogTitle>
        アクセス情報
      </DialogTitle>

      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          <Box className={classes.url}>
            <TextField
              value={accessUrl}
              variant="outlined"
              className={classes.urlText}
            />
            <Tooltip title="URLをクリップボードにコピーする">
              <CopyToClipboard
                text={accessUrl}
                onCopy={handleClipboardCopy}
              >
                <IconButton
                  color="primary" >
                  <ClipboardIcon />
                </IconButton>
              </CopyToClipboard>
            </Tooltip>
          </Box>
          {props.accessKeyRequired ? (
            <label>
              <Box
                alignItems="center"
                display="flex"
                ml={-1}
                className={classes.option}
              >
                <Checkbox
                  checked={containAccessKey}
                  name="saveData"
                  onChange={handleCheckChange}
                />
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  アクセスキーを含める
                </Typography>
              </Box>
            </label>
          ) : ''
          }
          <Box className={classes.qrcode}>
            <QRCode
              id={accessUrl}
              value={accessUrl}
              includeMargin={true}
              size={256}
            />
          </Box>
          <Box className={classes.download}>
            <Button
              color="primary"
              className={classes.bold}
              onClick={handleQRDownload}
            >
              ダウンロード
            </Button>
          </Box>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          className={classes.bold}
        >
          閉じる
        </Button>
      </DialogActions>

      {/* ======================================== */}
      {/* クリップボードコピー成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          URLをクリップボードにコピーしました
        </Alert>
      </Snackbar>
    </Dialog >
  );
};

export default QRcodeDialogView;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Chip,
  Divider,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  makeStyles,
  withStyles,
  createStyles,
} from '@material-ui/core';
import {
  Edit3 as Edit3Icon,
  Play as PlayIcon,
  BarChart as BarChartIcon,
} from 'react-feather';
import Alert from '@material-ui/lab/Alert';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import "./Calender.css";
import ConfirmDialogView from 'src/views/common/ConfirmDialogView';
import MenuDialogView from '../dialog/MenuDialogView';
import LessonCopyDialogView from '../dialog/LessonCopyDialogView'
import QRcodeDialogView from '../dialog/QRcodeDialogView';
import EnqueteDownloadDialogView from '../dialog/EnqueteDownloadDialogView';
import Model from 'src/models/Model';
import Constants from 'src/utils/Constants';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {},
  tableRow: {
    cursor: "context-menu"
  },
  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  bold: {
    fontWeight: 600,
  },
  chip: {
    marginLeft: 15
  },
  calendarCard: {
    padding: 16
  },
}));

const StyledTableSortLabel = withStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
      '&$active': {
        color: theme.palette.primary.main,
      },
    },
    active: {},
    icon: {
      color: 'inherit !important'
    },
  })
)(TableSortLabel);

const Results = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [lessons, setLessons] = useState([]);
  const [events, setEvents] = useState([]);

  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [sortColumnKey, setSortColumnKey] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");

  const [targetLesson, setTargetLesson] = useState({});

  const [menuDialogOpen, setMenuDialogOpen] = useState(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [qrcodeDialogOpen, setQRcodeDialogOpen] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [sessionClearDialogOpen, setSessionClearDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);

  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState('');

  //--------------------------------------------------
  // 画面表示処理
  //--------------------------------------------------
  useEffect(() => {
    Log.trace('LessonListView Results', 'useEffect()');
    const calenderEvents = [];
    for (const lesson of props.lessons) {
      const obj = {};
      obj.title = lesson.name;
      obj.start = lesson.startDate ? lesson.startDate.replaceAll('/', '-') : lesson.date.replaceAll('/', '-');
      obj.end = lesson.endDate ? Util.addDay(lesson.endDate, 1).replaceAll('/', '-') : lesson.date.replaceAll('/', '-');
      obj.lesson = lesson;
      calenderEvents.push(obj);
    }
    setLessons(props.lessons);
    setEvents(calenderEvents);
  }, [props.lessons]);

  //--------------------------------------------------
  // ページあたり表示件数の切り替え
  //--------------------------------------------------
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  //--------------------------------------------------
  // ページ切り替え
  //--------------------------------------------------
  const handlePageChange = (event, newPage) => {
    Log.trace('LessonListView Results', 'handlePageChange() newPage : ' + newPage);
    setPage(newPage);
  };

  //--------------------------------------------------
  // 一覧のソート順変更
  //--------------------------------------------------
  const handleClickSortColumn = (colmunKey) => {
    const isDesc = colmunKey === sortColumnKey && sortOrder === "desc";
    const nextOrder = isDesc ? "asc" : "desc";
    const sortRule = { asc: [1, -1], desc: [-1, 1] };
    const sortedRows = lessons.slice().sort((a, b) => {
      if (a[colmunKey] > b[colmunKey]) {
        return sortRule[nextOrder][0];
      } else if (a[colmunKey] < b[colmunKey]) {
        return sortRule[nextOrder][1];
      } else {
        return 0;
      }
    });
    setSortColumnKey(colmunKey);
    setSortOrder(nextOrder);
    setLessons(sortedRows);
  }

  // --------------------------------------------------
  // メニュー表示処理
  // --------------------------------------------------
  const handleMenuOpen = (event, lesson) => {
    event.preventDefault();
    setTargetLesson(lesson);
    setMenuDialogOpen(true);
  };

  // --------------------------------------------------
  // メニュークローズ処理
  // --------------------------------------------------
  const handleMenuClose = () => {
    setTargetLesson({});
    setMenuDialogOpen(false);
  };

  // --------------------------------------------------
  // 編集アイコン押下処理
  // --------------------------------------------------
  const handleEditButton = (event, lesson) => {
    navigate('/admin/lessons/detail',
      {
        replace: false,
        state: { lesson: lesson, mode: Constants.lessonDetailModeEdit }
      }
    );
  };

  // --------------------------------------------------
  // スタートアイコン押下処理
  // --------------------------------------------------
  const handleStartButton = (event, lesson) => {
    navigate('/admin/lessons/detail',
      {
        replace: false,
        state: { lesson: lesson, mode: Constants.lessonDetailModeStart }
      }
    );
  };

  // --------------------------------------------------
  // 結果確認アイコン押下処理
  // --------------------------------------------------
  const handleReviewButton = (event, lesson) => {
    navigate('/admin/lessons/detail',
      {
        replace: false,
        state: { lesson: lesson, mode: Constants.lessonDetailModeReview }
      }
    );
  };

  //--------------------------------------------------
  // コピーして作成ダイアログの表示処理
  //--------------------------------------------------
  const handleLessonCopyButton = () => {
    setMenuDialogOpen(false);
    setCopyDialogOpen(true);
  }

  //--------------------------------------------------
  // コピーして作成ダイアログ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleCopyDialogClose = () => {
    setCopyDialogOpen(false);
    setTargetLesson({});
  }

  //--------------------------------------------------
  // コピーして作成ダイアログ - [保存する]ボタン処理
  //--------------------------------------------------
  const handleCopyExecute = () => {
    setCopyDialogOpen(false);
    setTargetLesson({});
    setMessageOpen(true);
    setMessageText('選択された講義をコピーしました。');
    props.reload();
  }

  // --------------------------------------------------
  // アクセス情報表示ボタン押下処理
  // --------------------------------------------------
  const handleQRCodeButton = () => {
    setMenuDialogOpen(false);
    setQRcodeDialogOpen(true);
  }

  // --------------------------------------------------
  // アクセス情報ダイアログのクローズ処理
  // --------------------------------------------------
  const handleQRCodeDialogClose = () => {
    setQRcodeDialogOpen(false);
    setTargetLesson({});
  }

  // --------------------------------------------------
  // ダウンロードアイコン押下処理
  // --------------------------------------------------
  const handleDownloadButton = () => {
    setMenuDialogOpen(false);
    setDownloadDialogOpen(true);
  };

  // --------------------------------------------------
  // ダウンロードダイアログのクローズ処理
  // --------------------------------------------------
  const handleDownloaDialogClose = () => {
    setDownloadDialogOpen(false);
    setTargetLesson({});
  }

  // --------------------------------------------------
  // 削除確認ダイアログの表示処理
  // --------------------------------------------------
  const handleDeleteButton = () => {
    setMenuDialogOpen(false);
    setDeleteDialogOpen(true);
  }

  // --------------------------------------------------
  // 削除確認ダイアログの削除実行処理
  // --------------------------------------------------
  const handleDeleteExecute = async (lesson) => {
    await Model.deleteLesson(targetLesson.id);
    setTargetLesson({});
    setDeleteDialogOpen(false);
    setMessageOpen(true);
    setMessageText('選択された講義を削除しました');
    props.reload();
  }

  // --------------------------------------------------
  // 削除確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDeleteDialogClose = () => {
    setTargetLesson({});
    setDeleteDialogOpen(false);
  }

  //--------------------------------------------------
  // 削除成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  // --------------------------------------------------
  // 受講者情報クリア確認ダイアログの表示処理
  // --------------------------------------------------
  const handleClearButton = () => {
    setMenuDialogOpen(false);
    setSessionClearDialogOpen(true);
  };

  // --------------------------------------------------
  // 受講者情報クリア確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleSessionClearDialogClose = () => {
    setTargetLesson({});
    setSessionClearDialogOpen(false);
  };

  // --------------------------------------------------
  // 受講者情報クリア確認ダイアログの削除実行処理
  // --------------------------------------------------
  const handleSessionClearExecute = async () => {
    await Model.deleteSession(targetLesson.id);
    setTargetLesson({});
    setSessionClearDialogOpen(false);
    setMessageOpen(true);
    setMessageText('選択された講義の受講者情報を削除しました');
    props.reload();
  };

  // --------------------------------------------------
  // アーカイブ確認ダイアログの表示処理
  // --------------------------------------------------
  const handleArchiveButton = () => {
    setMenuDialogOpen(false);
    setArchiveDialogOpen(true);
  }

  // --------------------------------------------------
  // アーカイブ確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleArchiveDialogClose = () => {
    setTargetLesson({});
    setArchiveDialogOpen(false);
  };

  // --------------------------------------------------
  // アーカイブ確認ダイアログの実行処理
  // --------------------------------------------------
  const handleArchiveExecute = async () => {
    targetLesson.code = Util.deleteWhiteSpace(targetLesson.code)
    await Model.addArchive(targetLesson.id, targetLesson);
    setTargetLesson({});
    setArchiveDialogOpen(false);
    setMessageOpen(true);
    setMessageText('選択された講義をアーカイブしました');
    props.reload();
  };

  // --------------------------------------------------
  // セッション数のカウント
  // --------------------------------------------------
  const getSessionCount = (session) => {
    return session ? Object.keys(session).length : 0;
  }

  // --------------------------------------------------
  // カレンダーのイベントクリック処理
  // --------------------------------------------------
  const handleCalenderEventClick = (info) => {
    Log.trace('LessonListView handleCalenderEventClick', 'Event :' + info.event.extendedProps.lesson.id);
    info.jsEvent.preventDefault();
    setTargetLesson(info.event.extendedProps.lesson);
    setMenuDialogOpen(true);
  }

  return (
    <>
      {props.displayMode === 'List' ? (
        <Card>
          <PerfectScrollbar>
            <Box minWidth={800}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="name"
                      sortDirection={sortColumnKey === "name" ? sortOrder : false}
                      className={classes.sortLabel}
                    >
                      <StyledTableSortLabel
                        active={sortColumnKey === "name"}
                        direction={sortOrder}
                        onClick={() => handleClickSortColumn("name")}
                      >
                        講義名
                      </StyledTableSortLabel>
                    </TableCell>
                    <TableCell
                      key="date"
                      sortDirection={sortColumnKey === "date" ? sortOrder : false}
                      className={classes.sortLabel}
                      style={{ width: 150 }}
                    >
                      <StyledTableSortLabel
                        active={sortColumnKey === "date"}
                        direction={sortOrder}
                        onClick={() => handleClickSortColumn("date")}
                      >
                        開催日
                      </StyledTableSortLabel>
                    </TableCell>
                    <TableCell style={{ width: 100 }}>
                      編集
                    </TableCell>
                    <TableCell style={{ width: 100 }}>
                      開始
                    </TableCell>
                    <TableCell style={{ width: 100 }}>
                      結果確認
                    </TableCell>
                    <TableCell style={{ width: 100 }}>
                      受講者数
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lessons.slice(page * limit, (page + 1) * limit).map((lesson) => (
                    <TableRow
                      hover
                      key={lesson.id}
                      className={classes.tableRow}
                      onContextMenu={(event) => handleMenuOpen(event, lesson)}
                    >
                      <TableCell
                        className={classes.tableCell}>
                        {lesson.name}
                        {lesson.voteMode === Constants.voteModeNonRealtime ? (
                          <Chip className={classes.chip} label="オンデマンド" size="small" color="primary" variant="outlined" />
                        ) : ''}

                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {lesson.voteMode === Constants.voteModeNonRealtime ? (
                          lesson.startDate + ` 〜\n` + lesson.endDate
                        ) : lesson.date}


                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <IconButton
                          onClick={(event) => handleEditButton(event, lesson)}
                          color="primary" >
                          <Edit3Icon />
                        </IconButton>
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                      {lesson.voteMode === Constants.voteModeRealtime ? (
                        <IconButton
                          onClick={(event) => handleStartButton(event, lesson)}
                          color="secondary"
                          disabled={Util.isDayPassed(lesson.date) ? true : false}
                        >
                          <PlayIcon />
                        </IconButton>
                      ) : ''}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <IconButton
                          onClick={(event) => handleReviewButton(event, lesson)}
                          color="secondary"
                          disabled={getSessionCount(lesson.session) === 0 ? true : false}
                        >
                          <BarChartIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getSessionCount(lesson.session)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            <Box>
              <TablePagination
                component="div"
                count={lessons.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage='1ページあたりの行数:'
                labelDisplayedRows={({ from, to, count }) => `全${count}件のうち${from}〜${to}件目を表示`}
              />
            </Box>
          </Box>
        </Card >
      ) : (
        <Card className={classes.calendarCard}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            locale="ja"
            // contentHeight='auto'
            headerToolbar={{ end: 'prevYear prev today next nextYear' }}
            buttonText={{
              today: '今日'
            }}
            events={events}
            eventClick={handleCalenderEventClick}
          />
        </Card >
      )}

      {/* ======================================== */}
      {/* メニューダイアログ */}
      {/* ======================================== */}
      <MenuDialogView
        open={menuDialogOpen}
        lesson={targetLesson}
        handleClose={handleMenuClose}
        handleMenuEdit={handleEditButton}
        handleMenuStart={handleStartButton}
        handleMenuReview={handleReviewButton}
        handleMenuCopy={handleLessonCopyButton}
        handleMenuAccess={handleQRCodeButton}
        handleMenuDownload={handleDownloadButton}
        handleMenuClear={handleClearButton}
        handleMenuDelete={handleDeleteButton}
        handleMenuArchive={handleArchiveButton}
      />

      {/* ======================================== */}
      {/* 講義コピーダイアログ */}
      {/* ======================================== */}
      <LessonCopyDialogView
        open={copyDialogOpen}
        lesson={targetLesson}
        handleClose={handleCopyDialogClose}
        handleSave={handleCopyExecute}
      />

      {/* ======================================== */}
      {/* アクセス情報ダイアログ */}
      {/* ======================================== */}
      <QRcodeDialogView
        open={qrcodeDialogOpen}
        handleClose={handleQRCodeDialogClose}
        code={targetLesson.code || ''}
        accessKeyText={targetLesson.accessKey}
        accessKeyRequired={targetLesson.accessKeyRequired}
      />

      {/* ======================================== */}
      {/* ダウンロードダイアログ */}
      {/* ======================================== */}
      <EnqueteDownloadDialogView
        open={downloadDialogOpen}
        handleClose={handleDownloaDialogClose}
        lesson={targetLesson}
        key={targetLesson ? targetLesson.id : ''}
      />

      {/* ======================================== */}
      {/* 削除確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={deleteDialogOpen}
        title="講義の削除"
        description="選択された講義を削除します。よろしいですか？"
        confirmText="回答情報も全て削除されることを確認"
        disagree={handleDeleteDialogClose}
        agree={handleDeleteExecute}
      />

      {/* ======================================== */}
      {/* 受講者情報クリア確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={sessionClearDialogOpen}
        title="受講者情報の削除"
        description="選択された講義の受講者情報を削除します。よろしいですか？"
        confirmText="回答情報も全て削除されることを確認"
        disagree={handleSessionClearDialogClose}
        agree={handleSessionClearExecute}
      />

      {/* ======================================== */}
      {/* アーカイブ確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={archiveDialogOpen}
        title="アーカイブ"
        description="選択された講義をアーカイブします。よろしいですか？"
        disagree={handleArchiveDialogClose}
        agree={handleArchiveExecute}
      />

      {/* ======================================== */}
      {/* 処理成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          {messageText}
        </Alert>
      </Snackbar>
    </>
  );
};

Results.propTypes = {
  displayMode: PropTypes.string,
  reload: PropTypes.func,
  lessons: PropTypes.array,
};

export default Results;

import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
} from 'react-feather';
import QuestionReviewDialogView from '../../dialog/QuestionReviewDialogView'
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';
import Util from 'src/utils/Util';

const useStyles = makeStyles(({
  root: {
  },
  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomStyle: "none",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: "rgba(224, 224, 224, 1)"
  },
}));

const Question = (props) => {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [target, setTarget] = useState({});
  const [chartDialogOpen, setChartDialogOpen] = useState(false);

  const studentCountRef = useRef();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('LessonReviewView Question', 'useEffect()');
    // 受講者数の取得
    studentCountRef.current = props.lesson.session ? Object.keys(props.lesson.session).length : 0;
    getQuestionList();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 設問一覧取得処理
  // --------------------------------------------------
  const getQuestionList = async () => {
    const array = await Model.getQuestionList(props.lesson.id);
    array.sort(function (a, b) {
      return a.order < b.order ? -1 : 1;
    });

    // 回答結果の取得とマージ
    const answers = await Model.getAnswerByLessonId(props.lesson.id);
    const newArray = [];
    for (const question of array) {
      question.answer = answers[question.id];
      question.answerCount = answers[question.id] ? Object.keys(answers[question.id]).length : 0;
      newArray.push(question);
    }

    setQuestions(newArray);
  };

  // --------------------------------------------------
  // 編集アイコン押下処理
  // --------------------------------------------------
  const handleChartButtonClick = (event, question) => {
    setTarget(question);
    setChartDialogOpen(true);
  }

  // --------------------------------------------------
  // 編集ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDialogClose = () => {
    setTarget({});
    setChartDialogOpen(false);
  }

  return (
    <form>
      <Card>
        <CardHeader
          title="設問一覧"
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    タイトル
                  </TableCell>
                  <TableCell style={{ width: 250 }}>
                    設問タイプ
                  </TableCell>
                  <TableCell style={{ width: 200 }}>
                    回答人数（回答率）
                  </TableCell>
                  <TableCell style={{ width: 100 }}>
                    結果確認
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questions.map((question) => (
                  <Fragment
                    key={question.id}
                  >
                    <TableRow hover>
                      <TableCell className={classes.tableCell}>
                        {question.title}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {
                          question.type === Constants.questionTypeRadio ? Constants.questionTypeLabelRadio :
                            question.type === Constants.questionTypeSelect ? Constants.questionTypeLabelSelect :
                              question.type === Constants.questionTypeCheckBox ? Constants.questionTypeLabelCheckBox :
                                question.type === Constants.questionTypeText ? Constants.questionTypeLabelText :
                                  Constants.questionTypeLabelTextArea
                        }
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {question.answerCount} （{Util.calcPercentage(question.answerCount, studentCountRef.current)}%）
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <IconButton
                          color="primary"
                          onClick={(event) => handleChartButtonClick(event, question)}
                          disabled={question.answerCount === 0 ? true : false}
                        >
                          <BarChartIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </Fragment >
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>

      {/* ======================================== */}
      {/* 結果確認ダイアログ */}
      {/* ======================================== */}
      <QuestionReviewDialogView
        open={chartDialogOpen}
        question={target}
        lesson={props.lesson}
        studentCount={studentCountRef.current}
        handleClose={handleDialogClose}
      />
    </form >
  );
};

Question.propTypes = {
  lesson: PropTypes.object,
};

export default Question;

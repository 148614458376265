import React from 'react';
import {
  Card,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleArea: {
    marginLeft: 12,
    marginRight: 12,
    marginTop: 24,
    marginBottom: 24,
    paddingLeft: 15,
    paddingBottom: 15,
    borderLeftStyle: "solid",
    borderLeftWidth: 8,
    borderLeftColor: theme.palette.primary.main,
  },
  title: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  description: {
    marginTop: 10,
    whiteSpace: "pre-line",
  },
}));

const QuestionTitle = (props) => {
  const classes = useStyles();

  return (
    <Card>
      <div className={classes.titleArea}>
        <Typography variant="h4" className={classes.title}>{props.question.title}</Typography>
        <Typography variant="h5" className={classes.description}>{props.question.description}</Typography>
      </div>
    </Card>
  );
};

export default QuestionTitle;
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import firebase from "firebase/app";
import 'firebase/auth';
import Page from 'src/components/Page';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView'
import Util from 'src/utils/Util';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  circle: {
    marginTop: 200
  }
}));

const FacadeView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { code } = useParams();

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState('終了しました');
  const [errorMessage, setErrorMessage] = useState('');
  let authUserRef = useRef();

  // --------------------------------------------------
  //  画面表示時の処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.FacadeView', 'useEffect()');
    Log.trace('Student.FacadeView', 'parameter : ' + code);

    // 匿名ログイン処理
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        Log.trace('Student.FacadeView', 'firebase.auth().signInAnonymously()');
        firebase.auth().signInAnonymously()
          .then(() => {
          });
      }
      else {
        Log.trace('Student.FacadeView', 'signIned: ' + user.uid);
        authUserRef.current = user;
        distribute();
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  //  振り分け処理
  // --------------------------------------------------
  const distribute = async () => {
    // パラメータ無し
    if (!code) {
      Log.trace('Student.FacadeView.distribute', 'No parameter value');
      navigate('/', { replace: true });
      return;
    }
    // パラメータ桁数が明らかに短い
    if (code.length < 9) {
      Log.trace('Student.FacadeView.distribute', 'Invalid parameter value');
      navigate('/', { replace: true });
      return;
    }

    /* ******************************
     * セミナーコード判定
     * ******************************/
    if (code.length === 9) {
      // 数字以外が含まれる
      if (isNaN(code)) {
        Log.trace('Student.FacadeView.distribute', 'Parameter is not seminer code');
        navigate('/', { replace: true });
        return;
      }

      // セミナーコードフォーマットチェック
      const checkDigit = Util.getCheckDigit(code.slice(0, 8));
      if (checkDigit !== code.slice(-1)) {
        Log.trace('Student.FacadeView.distribute', 'Parameter is invalid seminer code');
        navigate('/', { replace: true });
        return;
      }

      // セミナーコード存在チェック
      const array = await Model.getLessonByCode(code);
      if (array.length !== 1) {
        Log.trace('Student.FacadeView.distribute', 'This seminer code is not exist');
        navigate('/', { replace: true });
        return;
      }

      // 開催日チェック
      if (array[0].voteMode === Constants.voteModeRealtime) {
        if (Util.isDayPassed(array[0].date)) {
          Log.trace('Student.FacadeView.distribute', 'This seminer is finished');
          setErrorMessage('このセミナーは' + Util.getDayFormatMD(array[0].date) + 'に終了しました。初めのページに戻り講師から指示されたセミナーコードを入力してください。');
          setErrorDialogOpen(true);
          return;
        }
      }
      else {
        if (array[0].ondemandEntryStatus === Constants.ondemandEntryStatusInvalid) {
          Log.trace('Student.FacadeView.distribute', 'This seminer is invalid');
          setErrorTitle('受付停止中です');
          if(array[0].isOndemandEntryStatusInvalidMessageEdit && array[0].ondemandEntryStatusInvalidMessage){
            setErrorMessage(array[0].ondemandEntryStatusInvalidMessage);
          } else {
            setErrorMessage('現在受け付けておりません。');
          }
          setErrorDialogOpen(true);
          return;
        }
        if (Util.isDayPassed(array[0].endDate)) {
          Log.trace('Student.FacadeView.distribute', 'This seminer is finished');
          setErrorMessage('このセミナーは' + Util.getDayFormatMD(array[0].endDate) + 'に受付を終了しました。');
          setErrorDialogOpen(true);
          return;
        }
        if (Util.isDayReached(array[0].startDate)) {
          Log.trace('Student.FacadeView.distribute', 'This seminer is not started');
          setErrorTitle('受付開始前です');
          setErrorMessage('このセミナーは' + Util.getDayFormatMD(array[0].startDate) + 'から受付を開始します。');
          setErrorDialogOpen(true);
          return;
        }
      }

      // セミナーコードから特定した講義をセット
      const lesson = array[0];

      // 次画面の判定
      let nextPath;
      let student = { oneTimeId: authUserRef.current.uid };
      if (lesson.session && lesson.session[authUserRef.current.uid]) {
        // 既にアクセス済みの場合
        nextPath = '/student/main';
        student = lesson.session[authUserRef.current.uid];
        // セッション保存データの保存
        student.accessDate = Util.getCurrentDayTimeSec();
        await Model.addSession(lesson.id, student);
      }
      else if (array[0].accessKeyRequired === true) {
        nextPath = '/student/security';
      }
      else if (array[0].profileRequired === true) {
        nextPath = '/student/profile';
      }
      else {
        nextPath = '/student/main';
        // セッション保存データの保存
        const data = {};
        data.oneTimeId = authUserRef.current.uid;
        data.accessDate = Util.getCurrentDayTimeSec();
        await Model.addSession(lesson.id, data);
      }

      // アクティブ度計測ログの追加
      Model.addLog(lesson.id, authUserRef.current.uid, Constants.operationTypeTop);

      // データ量を節約するため削除
      delete lesson.session;

      navigate(nextPath,
        {
          replace: false,
          state: {
            lesson: lesson,
            student: student
          }
        }
      );
      return;
    }

    /* **************************************
     * セミナーコード・アクセスコード暗号化文字列
     * **************************************/
    const decrypted = Util.decrypt(code);
    const decryptedArray = decrypted.split(',');

    // 復号化結果不正
    if (decryptedArray.length !== 2) {
      Log.trace('Student.FacadeView.distribute', 'decrypte failed');
      navigate('/', { replace: true });
      return;
    }

    const dcode = decryptedArray[0];
    const dkey = decryptedArray[1];

    // セミナーコードフォーマットチェック
    const checkDigit = Util.getCheckDigit(dcode.slice(0, 8));
    if (checkDigit !== dcode.slice(-1)) {
      Log.trace('Student.FacadeView.distribute', 'Parameter is invalid seminer code');
      navigate('/', { replace: true });
      return;
    }

    // セミナーコード存在チェック
    const array = await Model.getLessonByCode(dcode);
    if (array.length !== 1) {
      Log.trace('Student.FacadeView.distribute', 'This seminer code is not exist');
      navigate('/', { replace: true });
      return;
    }

    // アクセスコードチェック
    if (dkey !== array[0].accessKey) {
      Log.trace('Student.FacadeView.distribute', 'This seminer code is not exist');
      navigate('/', { replace: true });
      return;
    }

    // 開催日チェック
    if (array[0].voteMode === Constants.voteModeRealtime) {
      if (Util.isDayPassed(array[0].date)) {
        Log.trace('Student.FacadeView.distribute', 'This seminer is finished');
        setErrorMessage('このセミナーは' + Util.getDayFormatMD(array[0].date) + 'に終了しました。初めのページに戻り講師から指示されたセミナーコードを入力してください。');
        setErrorDialogOpen(true);
        return;
      }
    }
    else {
      if (array[0].ondemandEntryStatus === Constants.ondemandEntryStatusInvalid) {
        Log.trace('Student.FacadeView.distribute', 'This seminer is invalid');
        setErrorTitle('受付停止中です');
        if(array[0].isOndemandEntryStatusInvalidMessageEdit && array[0].ondemandEntryStatusInvalidMessage){
          setErrorMessage(array[0].ondemandEntryStatusInvalidMessage);
        } else {
          setErrorMessage('現在受け付けておりません。');
        }
        setErrorDialogOpen(true);
        return;
      }
      if (Util.isDayPassed(array[0].endDate)) {
        Log.trace('Student.FacadeView.distribute', 'This seminer is finished');
        setErrorMessage('このセミナーは' + Util.getDayFormatMD(array[0].endDate) + 'に受付を終了しました。');
        setErrorDialogOpen(true);
        return;
      }
      if (Util.isDayReached(array[0].startDate)) {
        Log.trace('Student.FacadeView.distribute', 'This seminer is not started');
        setErrorTitle('受付開始前です');
        setErrorMessage('このセミナーは' + Util.getDayFormatMD(array[0].startDate) + 'から受付を開始します。');
        setErrorDialogOpen(true);
        return;
      }
    }

    // セミナーコードから特定した講義をセット
    const lesson = array[0];

    // 次画面の判定
    let nextPath;
    let student = { oneTimeId: authUserRef.current.uid };
    if (lesson.session && lesson.session[authUserRef.current.uid]) {
      // 既にアクセス済みの場合
      nextPath = '/student/main';
      student = lesson.session[authUserRef.current.uid];
      // セッション保存データの保存
      student.accessDate = Util.getCurrentDayTimeSec();
      await Model.addSession(lesson.id, student);
    }
    else if (lesson.profileRequired === true) {
      nextPath = '/student/profile';
    }
    else {
      nextPath = '/student/main';
      // セッション保存データの保存
      const data = {};
      data.oneTimeId = authUserRef.current.uid;
      data.accessDate = Util.getCurrentDayTimeSec();
      await Model.addSession(lesson.id, data);
    }

    // データ量を節約するため削除
    delete lesson.session;

    navigate(nextPath,
      {
        replace: false,
        state: {
          lesson: lesson,
          student: student
        }
      }
    );
    return;
  };

  // --------------------------------------------------
  // システムエラーダイアログの処理実行
  // --------------------------------------------------
  const handleDialogExecute = () => {
    setErrorDialogOpen(false);
    navigate('/', { replace: true });
  }

  return (
    <Page
      title='トップ'
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        className={classes.circle}
      >
        <CircularProgress size={80} />
      </Box >

      {/* ======================================== */}
      {/* エラーダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={errorDialogOpen}
        title={errorTitle}
        description={errorMessage}
        agree={handleDialogExecute}
      />
    </Page >
  );
};

export default FacadeView;

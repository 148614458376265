import crypto from 'crypto';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import Constants from './Constants'
import Log from './Log'

// --------------------------------------------------
// ハッシュ値取得
// --------------------------------------------------
const getHash = (data) => {
  return crypto.createHash('sha256').update(data).digest('hex');
};

// --------------------------------------------------
// 暗号化
// --------------------------------------------------
const encrypt = (text) => {
  const cipher = crypto.createCipher('aes-256-cbc', Constants.cryptoKey);
  let crypted = cipher.update(text, 'utf-8', 'hex');
  crypted = crypted + cipher.final('hex');
  return crypted;
};

// --------------------------------------------------
// 復号化
// --------------------------------------------------
const decrypt = (text) => {
  const decipher = crypto.createDecipher('aes-256-cbc', Constants.cryptoKey);
  let decrypted = '';
  try {
    decrypted = decipher.update(text, 'hex', 'utf-8');
    decrypted = decrypted + decipher.final('utf-8');
  }
  catch (e) {
    Log.trace('Util.decrypt', e);
  }
  return decrypted;
};

// --------------------------------------------------
// 現在日時取得
// --------------------------------------------------
const getCurrentDayTime = () => {
  return moment(new Date()).format("YYYY/MM/DD HH:mm");
};

// --------------------------------------------------
// 現在日時秒取得
// --------------------------------------------------
const getCurrentDayTimeSec = () => {
  return moment(new Date()).format("YYYY/MM/DD HH:mm:ss");
};

// --------------------------------------------------
// 現在日時秒取得（CSVファイル用）
// --------------------------------------------------
const getCurrentDayTimeSecForCsv = () => {
  return moment(new Date()).format("YYYYMMDDHHmmss");
};

// --------------------------------------------------
// 日付フォーマット整形
// --------------------------------------------------
const getDayFormat = (date) => {
  return date ? moment(new Date(date)).format("YYYY/MM/DD") : '';
};

// --------------------------------------------------
// 日付フォーマット整形(MM/DD形式)
// --------------------------------------------------
const getDayFormatMD = (date) => {
  return date ? moment(new Date(date)).format("MM/DD") : '';
};

// --------------------------------------------------
// 日付フォーマット整形(HH:mm:ss形式)
// --------------------------------------------------
const getDayFormatHms = (date) => {
  return date ? moment(new Date(date)).format("HH:mm:ss") : '';
};

// --------------------------------------------------
// 日付フォーマット整形(YYYY/MM/DD HH:mm形式)
// --------------------------------------------------
const getDayFormatYMDHm = (date) => {
  return date ? moment(new Date(date)).format("YYYY/MM/DD HH:mm") : '';
};

// --------------------------------------------------
// 日付フォーマット整形(YYYY/MM形式)
// --------------------------------------------------
const getDayFormatYM = (date) => {
  return date ? moment(new Date(date)).format("YYYY/MM") : '';
};


// --------------------------------------------------
// 日付が現在日を経過したか検証
// --------------------------------------------------
const isDayPassed = (date) => {
  const target = moment(new Date(date));
  const now = moment(new Date());
  // Log.trace('Util.isDayPassed target', target);
  // Log.trace('Util.isDayPassed now', now);
  // Log.trace('Util.isDayPassed target.diff(now, "days")', target.diff(now, 'days'));
  return target.diff(now, 'days') < 0 ? true : false;
};

// --------------------------------------------------
// 日付が現在日に達したか検証
// --------------------------------------------------
const isDayReached = (date) => {
  const target = moment(new Date(date));
  const now = moment(new Date());
  // Log.trace('Util.isDayReached target', target);
  // Log.trace('Util.isDayReached now', now);
  // Log.trace('Util.isDayReached target.diff(now, "days", true)', target.diff(now, 'days', true));
  return target.diff(now, 'days', true) > 0 ? true : false;
};

// --------------------------------------------------
// 日付が正しいかを検証
// --------------------------------------------------
const isValidDate = (date) => {
  return date ? moment(date).isValid() : false;
};

// --------------------------------------------------
// 日付が正しいかを検証
// --------------------------------------------------
const isDayAfter = (before, after) => {
  return (before && after) ? moment(before).isSameOrBefore(after) : false;
};

// --------------------------------------------------
// 時間の加算（日）
// --------------------------------------------------
const addDay = (date, day) => {
  const temp = moment(new Date(date)).add(day, 'd');
  return moment(new Date(temp)).format("YYYY/MM/DD");
};

// --------------------------------------------------
// 時間の差分（秒）を取得
// --------------------------------------------------
const diffSecond = (from, to) => {
  const dateFrom = moment(new Date(from));
  const dateTo = moment(new Date(to));
  return dateFrom.diff(dateTo, 'seconds')
};

// --------------------------------------------------
// 時間の加算（秒）
// --------------------------------------------------
const addSecond = (date, sec) => {
  const temp = moment(new Date(date)).add(sec, 's');
  return moment(new Date(temp)).format("YYYY/MM/DD HH:mm:ss");
};

// --------------------------------------------------
// ランダムコード取得
// --------------------------------------------------
const getRandamCode = (nod) => {
  const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  return Array.from(crypto.randomFillSync(new Uint8Array(nod))).map((n) => S[n % S.length]).join('')
};

// --------------------------------------------------
// ランダム数字取得
// --------------------------------------------------
const getRandamNumber = (nod) => {
  const S = "0123456789"
  return Array.from(crypto.randomFillSync(new Uint8Array(nod))).map((n) => S[n % S.length]).join('')
};

// --------------------------------------------------
// チェックディジット取得（JAN/EAN 13）
// --------------------------------------------------
const getCheckDigit = (number) => {
  const str = number.toString();
  let even = 0;
  let odd = 0;
  for (let i = 0; i < str.length; i++) {
    if ((i + 1) % 2 === 0) {
      even = even + parseInt(str[i]);
    }
    else {
      odd = odd + parseInt(str[i]);
    }
  }
  let t = even * 3 + odd;
  t = t.toString().slice(-1);
  if (t === '0') {
    return t;
  }
  else {
    return (10 - parseInt(t)).toString();
  }
};

// --------------------------------------------------
// 講義コード12桁の整形
// --------------------------------------------------
const formatLessonCode = (code) => {
  // 3桁 3桁 3桁 4桁に整形
  return code.slice(0, 3) + ' ' + code.slice(3, 6) + ' ' + code.slice(6, 9);
};

// --------------------------------------------------
// 空白文字の削除
// --------------------------------------------------
const deleteWhiteSpace = (str) => {
  if (str) {
    return str.replace(/\s+/g, "");
  }
  return ""
};

// --------------------------------------------------
// 前後空白文字の削除
// --------------------------------------------------
const trim = (str) => {
  if (str) {
    return str.trim();
  }
  return ""
};

// --------------------------------------------------
// 全角から半角に変換
// --------------------------------------------------
const zenkaku2Hankaku = (str) => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}

// --------------------------------------------------
// サニタイズ処理
// --------------------------------------------------
const escape = (str) => {
  if (!str) {
    return '';
  }
  return String(str).replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
};

// --------------------------------------------------
// メールアドレスフォーマットチェック
// --------------------------------------------------
const checkMailaddressFormat = (str) => {
  if (!str) {
    return '';
  }
  const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
  return reg.test(str);
};

// --------------------------------------------------
// UID取得取得
// --------------------------------------------------
const getUid = () => {
  return uuid();
};

// --------------------------------------------------
// パーセンテージの計算処理
// --------------------------------------------------
const calcPercentage = (numerator, denominator) => {
  if (!numerator || !denominator) {
    return 0;
  }
  else {
    return Math.round((numerator / denominator) * 100)
  }
}

const Util = {
  getHash,
  encrypt,
  decrypt,
  getCurrentDayTime,
  getCurrentDayTimeSec,
  getCurrentDayTimeSecForCsv,
  getDayFormat,
  getDayFormatMD,
  getDayFormatHms,
  getDayFormatYMDHm,
  getDayFormatYM,
  isDayPassed,
  isDayReached,
  isValidDate,
  isDayAfter,
  addDay,
  diffSecond,
  addSecond,
  getRandamCode,
  getRandamNumber,
  getCheckDigit,
  formatLessonCode,
  deleteWhiteSpace,
  trim,
  zenkaku2Hankaku,
  escape,
  checkMailaddressFormat,
  getUid,
  calcPercentage,
}

export default Util;


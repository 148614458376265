import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import firebase from "firebase/app";
import 'firebase/auth';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';

const useStyles = makeStyles(({
  root: {},
  closeBtn: {
    marginLeft: 10,
    fontWeight: 600
  },
  bold: {
    fontWeight: 600
  }
}));

const Form = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    mail: '',
  });
  const [errors, setErrors] = useState({});

  // --------------------------------------------------
  // フォーム入力
  // --------------------------------------------------
  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // --------------------------------------------------
  // 送信ボタン処理
  // --------------------------------------------------
  const handleSend = async (event) => {

    // 入力文字のtrim処理
    const mail = Util.trim(values.mail);

    let check = {};
    // 必須チェック
    if (!mail) {
      check.mail = '必須項目です';
      setErrors(check);
      return;
    }

    // メールアドレス形式チェック
    if (!Util.checkMailaddressFormat(mail)) {
      check.mail = 'メールアドレスの形式が正しくありません';
      setErrors(check);
      return;
    }

    firebase.auth().sendPasswordResetEmail(mail).then(function () {
      // 成功した際の処理
      Log.trace('PasswordResetRequestView', 'firebase.auth().sendPasswordResetEmail() success');
      // ダイアログを閉じる
      props.handleClose(true);
    }).catch(function (error) {
      // 失敗した際の処理
      Log.trace('PasswordResetRequestView', 'firebase.auth().sendPasswordResetEmail() fail');
      check.mail = 'パスワード再設定メールの送信に失敗しました';
      setErrors(check);
    });
  };

  return (
    <form>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="メールアドレス"
            required
            name="mail"
            onChange={handleValueChange}
            type="text"
            value={values.mail}
            variant="outlined"
            error={errors.mail ? true : false}
            helperText={errors.mail ? errors.mail : ''}
          />
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Box>
        </Box>
        <Box>

          <Button
            color="primary"
            variant="contained"
            onClick={handleSend}
            className={classes.bold}
          >
            送信する
          </Button>

          <Button
            color="primary"
            variant="outlined"
            onClick={props.handleClose}
            className={classes.closeBtn}
          >
            キャンセル
          </Button>
        </Box>
      </Box>
    </form >
  );
};

Form.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};


export default Form;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { AlertTriangle as AlertTriangleIcon } from 'react-feather';
import Alert from '@material-ui/lab/Alert';
import firebase from "firebase/app";
import 'firebase/auth';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView'
import ReAuthenticate from './ReAuthenticate';
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import SessionUtil from 'src/utils/SessionUtil';
import AuthUtil from 'src/utils/AuthUtil';
import Log from 'src/utils/Log';

const useStyles = makeStyles(() => ({
  root: {},
  bold: {
    fontWeight: 600
  },
  emailVerified: {
    color: '#f44336',
    marginTop: 5,
  },
  emailVerifiedIcon: {
    width: 16,
    height: 16
  },
  emailVerifiedText: {
    marginLeft: 5,
    color: '#f44336',
    fontSize: 13,
    fontWeight: 600
  },
  LinkButton: {
    marginLeft: 5,
    padding: 0,
    fontSize: 14,
  },
}));

const ProfileDetails = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [values, setValues] = useState(props.data);
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = useState(props.data.system);
  const [isChanged, setIsChanged] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [reAuthenticateOpen, setReAuthenticateOpen] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    const authUser = AuthUtil.getAuthUser();
    if(authUser) {
      setEmailVerified(authUser.emailVerified);
    }
    else {
      SessionUtil.removeLoginAccount();
      navigate('/admin/login', { replace: false, });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 入力処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    setIsChanged(true);
  };

  //--------------------------------------------------
  // システム管理フラグチェック処理
  //--------------------------------------------------
  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
    setIsChanged(true);
  };

  //--------------------------------------------------
  // 成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
    setMessageText('');
  }

  //--------------------------------------------------
  // 失敗メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleErrorMessageClose = () => {
    setErrorMessageOpen(false);
    setMessageText('');
  }

  // --------------------------------------------------
  // アカウント更新処理
  // --------------------------------------------------
  const handleSave = async (event) => {
    Log.trace('AccountView.ProfileDetails', 'handleSave()');

    const authUser = AuthUtil.getAuthUser();

    // 入力文字のtrim処理
    const fname = Util.trim(values.fname);
    const lname = Util.trim(values.lname);
    const organization = Util.trim(values.organization);
    const mail = Util.trim(values.mail);
    const password = Util.trim(values.password);

    let check = {};
    // バリデーション
    // 必須チェック
    if (!fname) {
      check.fname = '必須項目です';
    }
    if (!lname) {
      check.lname = '必須項目です';
    }
    if (!organization) {
      check.organization = '必須項目です';
    }
    if (!mail) {
      check.mail = '必須項目です';
    }
    if (!password) {
      check.password = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 文字数チェック
    if (password.length < 6) {
      check.password = '6文字以上で入力してください';
      setErrors(check);
      return;
    }

    if (mail !== authUser.email && password !== Constants.passwordDummy) {
      check.password = 'メールアドレスと同時に変更できません';
      check.mail = 'パスワードと同時に変更できません';
      setErrors(check);
      return;
    }


    // メールアドレス形式チェック
    if (!Util.checkMailaddressFormat(mail)) {
      check.mail = 'メールアドレスの形式が正しくありません';
      setErrors(check);
      return;
    }

    // パスワード強度チェック

    // メールアドレス重複チェック
    const array = await Model.getAccount(mail);
    if (array.length > 0 && array[0].id !== values.id) {
      check.mail = '既に利用されているメールアドレスです';
      setErrors(check);
      return;
    }

    // システム管理者として更新作業を行う場合はメアドとパスワード変更は対象外
    if (props.sytem === true) {
      // アカウント更新
      accountUpdate();
      return;
    }

    // メールアドレスかパスワードが変更されていた場合は
    // 再認証画面を起動する
    if (mail !== authUser.email || password !== Constants.passwordDummy) {
      setReAuthenticateOpen(true);
      return;
    }

    // アカウント更新
    accountUpdate();
  };

  //--------------------------------------------------
  // アカウント更新実行処理
  //--------------------------------------------------
  const accountUpdate = async () => {

    // 入力文字のtrim処理
    const fname = Util.trim(values.fname);
    const lname = Util.trim(values.lname);
    const organization = Util.trim(values.organization);
    const mail = Util.trim(values.mail);
    const password = Util.trim(values.password);

    // データを更新
    // ・更新日時の追加
    let data = {};
    data.fname = Util.escape(fname);
    data.lname = Util.escape(lname);
    data.organization = Util.escape(organization);
    data.mail = Util.escape(mail);
    data.updateDate = Util.getCurrentDayTime();
    data.system = checked;
    await Model.updateAccount(values.id, data);

    // システム管理者として更新作業を行う場合はメアドとパスワード変更は対象外
    if (props.sytem !== true) {
      const user = AuthUtil.getAuthUser();
      if (mail !== user.email) {
        user.updateEmail(mail).then(() => {
          Log.trace('AccountView.ProfileDetails', 'updateEmail success');
        }).catch((error) => {
          Log.trace('AccountView.ProfileDetails', 'updateEmail fail: ' + error.message);
        })
      }

      if (password !== Constants.passwordDummy) {
        user.updatePassword(password).then(() => {
          Log.trace('AccountView.ProfileDetails', 'updatePassword success');
        }).catch((error) => {
          Log.trace('AccountView.ProfileDetails', 'updatePassword fail: ' + error.message);
        })
      }
    }

    // セッションデータ更新
    const login = SessionUtil.getLoginAccount();
    if (login.id === values.id) {
      data.password = Constants.passwordDummy;
      data.id = values.id;
      data.system = checked;
      SessionUtil.setLoginAccount(data);
    }

    // 画面を再描画
    setValues({ ...values, password: Constants.passwordDummy });
    data.id = values.id;
    navigate(props.reloadPath,
      {
        replace: false,
        state: { account: data }
      });

    // メッセージ表示
    setMessageOpen(true);
    setMessageText('アカウントを更新しました。');
    // エラーを初期化
    setErrors({});
    // 変更状態を初期化
    setIsChanged(false);
  }

  //--------------------------------------------------
  // 再認証ダイアログ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleReAuthenticateClose = () => {
    setReAuthenticateOpen(false);
  }

  //--------------------------------------------------
  // メールアドレスの確認メール送信
  //--------------------------------------------------
  const handleEmailVerify = () => {
    Log.trace('AccountView.ProfileDetails', 'handleEmailVerify()');

    const actionCodeSettings = {
      url: 'https://actipo.com/admin/login',
    };

    const authUser = AuthUtil.getAuthUser();
    authUser.sendEmailVerification(actionCodeSettings).then(function () {
      setConfirmDialogOpen(true);
    }).catch(function (error) {
      Log.trace('AccountView.ProfileDetails', 'handleEmailVerify() error : ' + error.code);
      setErrorMessageOpen(true);
      setMessageText('確認メールの送信に失敗しました。');
    });
  }

  //--------------------------------------------------
  // メールアドレスの確認メール送信ダイアログを閉じる
  //--------------------------------------------------
  const handleConfirmDialogExecute = () => {
    setConfirmDialogOpen(false);

    firebase.auth().signOut().then(() => {
      Log.trace('AccountView.ProfileDetails', 'firebase.auth().signOut() success');
      SessionUtil.removeLoginAccount();
      navigate('/admin/login', { replace: false, });
    }).catch((error) => {
      Log.trace('AccountView.ProfileDetails', 'firebase.auth().signOut() fail : ' + error.message);
    });
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={classes.root}
    >
      <Card>
        <CardHeader
          title="プロフィール"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="氏名（姓）"
                name="fname"
                onChange={handleChange}
                required
                value={values.fname}
                variant="outlined"
                error={errors.fname ? true : false}
                helperText={errors.fname ? errors.fname : ''}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="氏名（名）"
                name="lname"
                onChange={handleChange}
                required
                value={values.lname}
                variant="outlined"
                error={errors.lname ? true : false}
                helperText={errors.lname ? errors.lname : ''}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="法人名／組織名"
                name="organization"
                onChange={handleChange}
                required
                value={values.organization}
                variant="outlined"
                error={errors.organization ? true : false}
                helperText={errors.organization ? errors.organization : ''}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="メールアドレス"
                name="mail"
                onChange={handleChange}
                required
                value={values.mail}
                variant="outlined"
                disabled={props.sytem === true ? true : false}
                error={errors.mail ? true : false}
                helperText={errors.mail ? errors.mail : ''}
              />
              {props.sytem !== true && emailVerified === false ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="end"
                  className={classes.emailVerified}
                >
                  <AlertTriangleIcon className={classes.emailVerifiedIcon} />
                  <Box className={classes.emailVerifiedText}>
                    本人確認が未完了です
                  </Box>
                </Box>
              ) : ''}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="パスワード"
                name="password"
                onChange={handleChange}
                required
                value={values.password}
                variant="outlined"
                type="password"
                disabled={props.sytem === true ? true : false}
                error={errors.password ? true : false}
                helperText={errors.password ? errors.password : ''}
              />
            </Grid>
            {props.sytem === true ?
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Firebase uid"
                  name="firebaseUid"
                  required
                  value={values.id}
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
              : ''}

            {props.sytem === true ?
              <Grid
                item
                md={6}
                xs={12}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    name="system"
                    checked={checked}
                    onChange={handleCheckChange}
                  />
                  <Typography variant="h5" >システム管理者</Typography>
                </Box>
              </Grid>
              : ''}
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
        >
          <Button
            size="small"
            color="primary"
            className={classes.bold}
            onClick={() => handleEmailVerify()}
            style={{ visibility: (props.sytem === true || emailVerified === true) ? 'hidden' : 'visible' }}
          >
            メールアドレスの本人確認
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.bold}
            onClick={handleSave}
            disabled={isChanged ? false : true}
          >
            保存する
          </Button>
        </Box>
      </Card>

      {/* ======================================== */}
      {/* 再認証ダイアログ */}
      {/* ======================================== */}
      <ReAuthenticate
        open={reAuthenticateOpen}
        handleClose={handleReAuthenticateClose}
        handleSave={accountUpdate}
      />

      {/* ======================================== */}
      {/* 確認メールを送信ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={confirmDialogOpen}
        title="本人確認メールを送信しました"
        description="システムからログアウトします。確認メールのリンクをクリックしたのち、再度ログインをお願いします。"
        agree={handleConfirmDialogExecute}
      />

      {/* ======================================== */}
      {/* 成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          {messageText}
        </Alert>
      </Snackbar>

      {/* ======================================== */}
      {/* 失敗メッセージ */}
      {/* ======================================== */}
      <Snackbar open={errorMessageOpen} autoHideDuration={3000} onClose={handleErrorMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleErrorMessageClose} severity="error">
          {messageText}
        </Alert>
      </Snackbar>

    </form>
  );
};

export default ProfileDetails;

const overrides = {
    MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent"
          }
        }
      }
  };
  
  export default overrides;
  
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Snackbar,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';

const useStyles = makeStyles(({
  root: {},
  bold: {
    fontWeight: 600
  },
  textField: {
    "&$disabled": {
      color: "#546e7a",
    }
  },
  disabled: {}
}));



const Parameter = (props) => {
  const classes = useStyles();

  const [values, setValues] = useState(props.parameters);
  const [errors, setErrors] = useState({});
  const [messageOpen, setMessageOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setValues(props.parameters)
  }, [props.parameters]);

  // --------------------------------------------------
  // 入力処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setIsChanged(true);
  };

  // --------------------------------------------------
  // 授業更新処理
  // --------------------------------------------------
  const handleSave = async (event) => {

    let check = {};
    // 必須チェック
    if (!values.parameter001) {
      check.name = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 更新データの作成
    let data = {};
    data.parameter001 = Util.escape(values.parameter001);

    // 更新処理
    await Model.updateParameter(data);

    setMessageOpen(true);
    setIsChanged(false);
    setErrors({});
  };

  //--------------------------------------------------
  // 更新成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  return (
    <form>
      <Card>
        <CardHeader
          title="基本情報"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="デフォルトセミナーコード"
                required
                margin="normal"
                name="parameter001"
                onChange={handleChange}
                type="text"
                value={values.parameter001}
                variant="outlined"
                error={errors.parameter001 ? true : false}
                helperText={errors.parameter001 ? errors.parameter001 : ''}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.bold}
            onClick={handleSave}
            disabled={isChanged ? false : true}
          >
            保存する
          </Button>
        </Box>
      </Card>

      {/* ======================================== */}
      {/* 講義更新成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          パラメータを更新しました。
        </Alert>
      </Snackbar>
    </form >
  );
};

export default Parameter;

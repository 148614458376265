import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import SimpleLayout from 'src/layouts/SimpleLayout';


import NotFoundView from 'src/views/errors/NotFoundView';

import LoginView from 'src/views/admin/auth/LoginView';
import RegisterView from 'src/views/admin/auth/RegisterView';
import AccountView from 'src/views/admin/account/AccountView';
import LessonListView from 'src/views/admin/lesson/LessonListView';
import LessonDetailView from 'src/views/admin/lesson/LessonDetailView';
import LessonEditView from 'src/views/admin/lesson/LessonEditView';
import LessonStartView from 'src/views/admin/lesson/LessonStartView';
import LessonReviewView from 'src/views/admin/lesson/LessonReviewView';
import LessonArchiveListView from 'src/views/admin/lesson/LessonArchiveListView';

import SystemAccountListView from 'src/views/admin/system/account/AccountListView';
import SystemAccountEditView from 'src/views/admin/system/account/AccountEditView';
import SystemParameterEditView from 'src/views/admin/system/parameter/ParameterEditView';

import FacadeView from 'src/views/student/FacadeView';
import IndexView from 'src/views/student/IndexView';
import SecurityView from 'src/views/student/SecurityView';
import ProfileView from 'src/views/student/ProfileView';
import MainView from 'src/views/student/MainView';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'admin',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Navigate to="/admin/lessons" /> },
      { path: 'account', element: <AccountView /> },
      { path: 'lessons', element: <LessonListView /> },
      { path: 'lessons/detail', element: <LessonDetailView /> },
      { path: 'lessons/edit', element: <LessonEditView /> },
      { path: 'lessons/start', element: <LessonStartView /> },
      { path: 'lessons/review', element: <LessonReviewView /> },
      { path: 'archive', element: <LessonArchiveListView /> },
      { path: 'system/account', element: <SystemAccountListView /> },
      { path: 'system/account/edit', element: <SystemAccountEditView /> },
      { path: 'system/parameter', element: <SystemParameterEditView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'admin',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
    ]
  },
  {
    path: 'student',
    element: <SimpleLayout />,
    children: [
      { path: '', element: <IndexView /> },
      { path: 'security', element: <SecurityView /> },
      { path: 'profile', element: <ProfileView /> },
      { path: 'main', element: <MainView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <SimpleLayout />,
    children: [
      { path: 's/:code', element: <FacadeView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '', element: <IndexView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import Model from 'src/models/Model';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

const LessonListView = () => {
  const classes = useStyles();
  const [accounts, setAccounts] = useState([]);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    getAccountList();
  }, []);

  // --------------------------------------------------
  // アカウント一覧取得処理
  // --------------------------------------------------
  const getAccountList = async () => {
    const array = await Model.getAccountList();
    array.sort(function (a, b) {
      return a.addDate > b.addDate ? -1 : 1;
    });
    setAccounts(array);
  };

  return (
    <Page
      className={classes.root}
      title="アカウント管理 | Active Poll"
    >
      <Container maxWidth={false}>
        <Toolbar
          reload={getAccountList}
        />
        <Box mt={3}>
          <Results
            reload={getAccountList}
            accounts={accounts}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default LessonListView;

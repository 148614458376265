import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';

const useStyles = makeStyles(({
  root: {},
  closeBtn: {
    marginLeft: 10,
    fontWeight: 600
  },
  helperText: {
    marginBottom: 20,
  },
  bold: {
    fontWeight: 600
  },
  formControl: {
  },
  textarea: {
    marginTop: 4,
  },
  selectWap: {
    '& .MuiOutlinedInput-input': {
      paddingTop: 12,
      paddingBottom: 9
    },
  },
  selectImageBox: {
    marginRight: 10,
  },
  selectImage: {
    width: 55,
  },
  selectLabel: {
    margin: 'auto 0'
  }
}));

const initModeTable = {
  [Constants.processModeAnswer]: { 'img': '/static/images/lesson/answer-on.png', 'label': Constants.processModeLabelAnswer },
  [Constants.processModeResult]: { 'img': '/static/images/lesson/result-on.png', 'label': Constants.processModeLabelResult },
  [Constants.processModeRealtime]: { 'img': '/static/images/lesson/realtime-on.png', 'label': Constants.processModeLabelRealtime },
  [Constants.processModeRealtime2]: { 'img': '/static/images/lesson/realtime2-on.png', 'label': Constants.processModeLabelRealtime2 }
}

const Question = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState(props.question);
  const [errors, setErrors] = useState({});
  const [isChanged, setIsChanged] = useState(false);

  // --------------------------------------------------
  // 初期状態選択リスト描画
  // --------------------------------------------------
  const renderInitModeSelect = (value) => {
    return (
      <Box
        display="flex"
        flexDirection="row"
      >
        <Box
          className={classes.selectImageBox}
        >
          <img
            className={classes.selectImage}
            src={initModeTable[value].img}
            alt={initModeTable[value].label}
          />
        </Box>
        <Box
          className={classes.selectLabel}
        >
          {initModeTable[value].label}
        </Box>
      </Box>
    );
  };

  // --------------------------------------------------
  // 入力処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setIsChanged(true);
  };

  // --------------------------------------------------
  // 保存ボタン 押下処理
  // --------------------------------------------------
  const handleSave = async (event) => {

    // 入力文字のtrim処理
    const title = Util.trim(values.title);
    const description = Util.trim(values.description);
    const options = Util.trim(values.options);

    let check = {};
    // 必須チェック
    if (!title) {
      check.title = '必須項目です';
    }
    if (!description) {
      check.description = '必須項目です';
    }
    if (!options) {
      if ((values.type !== Constants.questionTypeText) && (values.type !== Constants.questionTypeTextArea)) {
        check.options = '必須項目です';
      }
    }

    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 更新データの作成
    // 選択肢は改行ごとに配列に変換、自由記述の場合は空欄にする
    let data = {};
    data.title = Util.escape(title);
    data.description = Util.escape(description);
    data.type = values.type;
    data.initMode = values.initMode ? values.initMode : Constants.processModeRealtime;
    data.order = values.order;
    data.isRequired = values.isRequired ? values.isRequired : Constants.questionRequired;
    if (values.type === Constants.questionTypeText || values.type === Constants.questionTypeTextArea) {
      data.options = [];
    }
    else {
      data.options = Util.escape(options).split('\n').filter(Boolean);;
    }

    // 選択肢の重複チェック
    const s = new Set(data.options);
    if (s.size !== data.options.length) {
      check.options = '選択肢が重複しています';
      setErrors(check);
      return;
    }


    const updateDate = Util.getCurrentDayTime();
    const updateDateSec = Util.getCurrentDayTimeSec();

    // データ登録
    if (values.id) {
      // 更新処理
      await Model.updateQuestion(props.lesson.id, values.id, data);

      // 学生画面の更新を促すため日付更新
      await Model.updateLessonProcess(props.lesson.id, { updateDate: updateDateSec });
    }
    else {
      // 追加処理
      await Model.addQuestion(props.lesson.id, data);
    }
    // 講義情報の更新日時を更新
    await Model.updateLesson(props.lesson.id, { updateDate: updateDate });

    setIsChanged(false);
    props.handleCloseWithReload(values.id ? '設問を更新しました' : '設問を追加しました');
  }

  return (
    <form>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={4}
          xs={12}
        >
          <FormControl fullWidth={true} margin="normal" variant="outlined" className={classes.formControl}>
            <InputLabel id="option-profile">設問タイプ</InputLabel>
            <Select
              labelId="option-profile"
              name="type"
              value={values.type}
              onChange={handleChange}
              label="設問タイプ"
            >
              <MenuItem value={Constants.questionTypeRadio}>{Constants.questionTypeLabelRadio}</MenuItem>
              <MenuItem value={Constants.questionTypeSelect}>{Constants.questionTypeLabelSelect}</MenuItem>
              <MenuItem value={Constants.questionTypeCheckBox}>{Constants.questionTypeLabelCheckBox}</MenuItem>
              <MenuItem value={Constants.questionTypeTextArea}>{Constants.questionTypeLabelTextArea}</MenuItem>
              <MenuItem value={Constants.questionTypeText}>{Constants.questionTypeLabelText}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {props.lesson.voteMode === Constants.voteModeRealtime ? (
          <Grid
            item
            md={4}
            xs={12}
          >
            <FormControl fullWidth={true} margin="normal" variant="outlined" className={classes.formControl}>
              <InputLabel id="option-profile">回答者への結果表示</InputLabel>
              <Select
                labelId="option-profile"
                name="initMode"
                value={values.initMode}
                onChange={handleChange}
                label="回答者への結果表示"
                renderValue={renderInitModeSelect}
                className={classes.selectWap}
              >
                <MenuItem value={Constants.processModeRealtime}>{renderInitModeSelect(Constants.processModeRealtime)}</MenuItem>
                <MenuItem value={Constants.processModeRealtime2}>{renderInitModeSelect(Constants.processModeRealtime2)}</MenuItem>
                <MenuItem value={Constants.processModeAnswer}>{renderInitModeSelect(Constants.processModeAnswer)}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : ''}
        {props.lesson.voteMode === Constants.voteModeNonRealtime ? (
          <Grid
            item
            md={4}
            xs={12}
          >
            <FormControl fullWidth={true} margin="normal" variant="outlined" className={classes.formControl}>
              <InputLabel id="option-profile">必須回答</InputLabel>
              <Select
                labelId="option-profile"
                name="isRequired"
                value={values.isRequired}
                onChange={handleChange}
                label="必須回答"
              >
                <MenuItem value={Constants.questionRequired}>必須</MenuItem>
                <MenuItem value={Constants.questionOptional}>任意</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : ''}
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="タイトル"
            margin="normal"
            name="title"
            onChange={handleChange}
            required
            type="text"
            value={values.title}
            variant="outlined"
            placeholder="ex. 未来に対する印象について"
            error={errors.title ? true : false}
            helperText={errors.title ? errors.title : "ex. 未来に対する印象について"}
          />
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            className={classes.textarea}
            fullWidth
            label="設問文"
            margin="normal"
            name="description"
            onChange={handleChange}
            required
            multiline
            type="text"
            value={values.description}
            variant="outlined"
            minRows={3}
            placeholder="ex. 未来に対してワクワクしますか？それとも不安ですか？"
            error={errors.description ? true : false}
            helperText={errors.description ? errors.description : "ex. 未来に対してワクワクしますか？それとも不安ですか？"}
          />
        </Grid>
        {values.type !== Constants.questionTypeText && values.type !== Constants.questionTypeTextArea ? (
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              className={classes.textarea}
              fullWidth
              label="選択肢"
              margin="normal"
              name="options"
              onChange={handleChange}
              required
              multiline
              type="text"
              value={values.options}
              variant="outlined"
              minRows={8}
              placeholder="選択肢は改行して入力してください。&#13;&#10;例）&#13;&#10;出席↓&#13;&#10;欠席↓&#13;&#10;不明"
              disabled={values.type === Constants.questionTypeText ? true :
                values.type === Constants.questionTypeTextArea ? true : false}
              error={errors.options ? true : false}
              helperText={errors.options ? errors.options : ''}
            />
            <Box>
              <Typography className={classes.helperText} color="textPrimary" variant="h6">選択肢は改行して入力してください。</Typography>
            </Box>
          </Grid>
        ) : ''}
      </Grid>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Box>
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            className={classes.bold}
            disabled={isChanged ? false : true}
          >
            保存する
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={props.handleClose}
            className={classes.closeBtn}
          >
            キャンセル
          </Button>
        </Box>
      </Box>
    </form >
  );
};

export default Question;

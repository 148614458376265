import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import firebase from "firebase/app";
import 'firebase/auth';
import Page from 'src/components/Page';
import UseOfTermDialogView from './dialog/UseOfTermDialogView';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView'
import Log from 'src/utils/Log';
import Util from 'src/utils/Util';
import AuthUtil from 'src/utils/AuthUtil';
import Model from 'src/models/Model';
import Constants from 'src/utils/Constants';
import demo from './demo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    paddingTop: 50
  },
  LinkButton: {
    padding: 0,
    fontSize: 16
  },
  bold: {
    fontWeight: 600
  },
  primaryAgreeError: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginLeft: 14,
    marginRight: 14
  }
}));

const RegisterView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    fname: '',
    lname: '',
    organization: '',
    mail: '',
    password: ''
  });
  const [selected, setSelected] = useState({
    policy: false
  });
  const [errors, setErrors] = useState({});
  const [useOfTermDialogOpen, setUseOfTermDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  //--------------------------------------------------
  // 画面入力処理
  //--------------------------------------------------
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // --------------------------------------------------
  //  チェックボックスの更新
  // --------------------------------------------------
  const handleCheckChange = (event) => {
    setSelected({ ...selected, [event.target.name]: event.target.checked });
  }

  //--------------------------------------------------
  //　利用規約リンク - クリック処理
  //--------------------------------------------------
  const handleUseOfTermLink = () => {
    setUseOfTermDialogOpen(true);
  }

  //--------------------------------------------------
  //　利用規約ダイアログ　クローズ処理
  //--------------------------------------------------
  const handleUseOfTermDialogClose = () => {
    setUseOfTermDialogOpen(false);
  }

  //--------------------------------------------------
  //　アカウント作成ボタン　押下処理
  //--------------------------------------------------
  const handleSaveButton = () => {

    Log.trace('RegisterView', 'handleSaveButton()');

    // 入力文字のtrim処理
    const fname = Util.trim(values.fname);
    const lname = Util.trim(values.lname);
    const organization = Util.trim(values.organization);
    const mail = Util.trim(values.mail);
    const password = Util.trim(values.password);

    // バリデーション
    // 必須チェック
    let check = {};
    if (!fname) {
      check.fname = '必須項目です';
    }
    if (!lname) {
      check.lname = '必須項目です';
    }
    if (!organization) {
      check.organization = '必須項目です';
    }
    if (!mail) {
      check.mail = '必須項目です';
    }
    if (!password) {
      check.password = '必須項目です';
    }
    // 利用規約同意チェック
    if (selected.policy === false) {
      check.policy = '利用規約を確認してください';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 文字数チェック
    if (password.length < 6) {
      check.password = '6文字以上で入力してください';
      setErrors(check);
      return;
    }

    // メールアドレス形式チェック
    if (!Util.checkMailaddressFormat(mail)) {
      check.mail = 'メールアドレスの形式が正しくありません';
      setErrors(check);
      return;
    }

    // ログイン
    firebase.auth().createUserWithEmailAndPassword(mail, password)
      .then((userCredential) => {
        Log.trace('RegisterView', 'firebase.auth() success: ' + userCredential.user.uid);
        next(userCredential.user.uid);
      })
      .catch((error) => {
        Log.trace('RegisterView', 'firebase.auth() fail: ' + error.code);
        if (error.code === 'auth/invalid-email') {
          check.mail = 'メールアドレスの形式が正しくありません';
          setErrors(check);
        }
        else if (error.code === 'auth/email-already-in-use') {
          check.mail = '既に利用されているメールアドレスです';
          setErrors(check);
        }
        else {
          check.mail = 'アカウント登録に失敗しました';
          setErrors(check);
        }
        setValues({ ...values, password: '' });
        return;
      });
  }

  // --------------------------------------------------
  // 次画面遷移処理
  // --------------------------------------------------
  const next = async (firebaseUid) => {

    // 入力文字のtrim処理
    const fname = Util.trim(values.fname);
    const lname = Util.trim(values.lname);
    const organization = Util.trim(values.organization);
    const mail = Util.trim(values.mail);

    const account = {};
    account.fname = Util.escape(fname);
    account.lname = Util.escape(lname);
    account.organization = Util.escape(organization);
    account.mail = Util.escape(mail);
    account.addDate = Util.getCurrentDayTime();
    account.system = false;
    await Model.addAccount(firebaseUid, account);

    account.password = Constants.passwordDummy;
    account.id = firebaseUid;

    // デモデータの登録
    // 講義情報
    const demoLessonData = demo.lesson.DEMO_LESSON_ID;
    demoLessonData.accountId = account.id;
    demoLessonData.accountName = account.fname + ' ' + account.lname;
    demoLessonData.organization = account.organization;
    demoLessonData.addDate = Util.getCurrentDayTime();
    demoLessonData.updateDate = Util.getCurrentDayTime();
    const a = Util.getRandamNumber(8);
    const b = Util.getCheckDigit(a);
    demoLessonData.code = a + b;
    const demoLessonId = await Model.addLesson(demoLessonData);

    // 設問情報
    const demoQuestionData = demo.question.DEMO_LESSON_ID;
    await Model.setQuestion(demoLessonId, demoQuestionData);

    // 回答情報
    const demoAnswerData = demo.answer.DEMO_LESSON_ID;
    await Model.setAnswer(demoLessonId, demoAnswerData);

    // FaQ情報
    const demoFaqData = demo.faq.DEMO_LESSON_ID;
    await Model.setFaq(demoLessonId, demoFaqData);

    // ログ情報
    const demoLogData = demo.log.DEMO_LESSON_ID;
    await Model.setLog(demoLessonId, demoLogData);

    // メールアドレスの確認メール送信
    const actionCodeSettings = {
      url: 'https://actipo.com/admin/login',
    };

    const authUser = AuthUtil.getAuthUser();
    authUser.sendEmailVerification(actionCodeSettings).then(function () {
      setConfirmDialogOpen(true);
    }).catch(function (error) {
      Log.trace('RegisterView', 'handleEmailVerify() error : ' + error.code);
      setConfirmDialogOpen(true);
    });
  }

  //--------------------------------------------------
  // メールアドレスの確認メール送信ダイアログを閉じる
  //--------------------------------------------------
  const handleConfirmDialogExecute = () => {
    setConfirmDialogOpen(false);

    firebase.auth().signOut().then(() => {
      Log.trace('RegisterView', 'firebase.auth().signOut() success');
      navigate('/admin/login', { replace: false, });
    }).catch((error) => {
      Log.trace('RegisterView', 'firebase.auth().signOut() fail : ' + error.message);
    });
  }

  return (
    <Page
      className={classes.root}
      title="利用登録 | Active Poll"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
      >
        <Container className={classes.container} maxWidth="sm">
          <form>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                トライアルアカウントの作成
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="氏名（姓）"
                  name="fname"
                  onChange={handleChange}
                  required
                  value={values.fname}
                  variant="outlined"
                  error={errors.fname ? true : false}
                  helperText={errors.fname ? errors.fname : ''}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="氏名（名）"
                  name="lname"
                  onChange={handleChange}
                  required
                  value={values.lname}
                  variant="outlined"
                  error={errors.lname ? true : false}
                  helperText={errors.lname ? errors.lname : ''}
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="法人名／組織名"
                  name="organization"
                  onChange={handleChange}
                  required
                  value={values.organization}
                  variant="outlined"
                  error={errors.organization ? true : false}
                  helperText={errors.organization ? errors.organization : ''}
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="メールアドレス"
                  name="mail"
                  onChange={handleChange}
                  required
                  value={values.mail}
                  variant="outlined"
                  disabled={props.sytem === true ? true : false}
                  error={errors.mail ? true : false}
                  helperText={errors.mail ? errors.mail : ''}
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="パスワード"
                  name="password"
                  onChange={handleChange}
                  required
                  value={values.password}
                  variant="outlined"
                  type="password"
                  disabled={props.sytem === true ? true : false}
                  error={errors.password ? true : false}
                  helperText={errors.password ? errors.password : ''}
                />
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={selected.policy === true ? true : false}
                    name="policy"
                    required
                    onChange={handleCheckChange}
                  />

                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    <Button
                      color="primary"
                      className={classes.LinkButton}
                      component={Link}
                      onClick={() => handleUseOfTermLink()}
                    >
                      利用規約
                    </Button>
                    {' '}
                    に同意する
                  </Typography>
                </Box>
                <Box
                  className={classes.primaryAgreeError}
                  style={{ display: errors.policy ? '' : 'none' }}
                >
                  {errors.policy}
                </Box>
              </Grid>
            </Grid>
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                className={classes.bold}
                onClick={() => handleSaveButton()}
              >
                アカウントを作成する
              </Button>
            </Box>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              アカウントをお持ちの方は、
              {' '}
              <Link
                component={RouterLink}
                to="/admin/login"
                variant="h5"
              >
                ログイン
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>

      {/* ======================================== */}
      {/* 利用規約ダイアログ */}
      {/* ======================================== */}
      <UseOfTermDialogView
        open={useOfTermDialogOpen}
        handleClose={handleUseOfTermDialogClose}
      />

      {/* ======================================== */}
      {/* 確認メールを送信ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={confirmDialogOpen}
        title="本人確認メールを送信しました"
        description="確認メールのリンクをクリックした後に、ログインをお願いします。"
        agree={handleConfirmDialogExecute}
      />
    </Page>
  );
};

export default RegisterView;

import React from 'react';
import {
  AppBar,
  Box,
  Container,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Form from './Form';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600
  },
  container: {
    paddingBottom: 50
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LessonAddDialogView = (props) => {
  const classes = useStyles();

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    props.handleClose();
  }

  return (
    <Dialog maxWidth="md" open={props.open} onClose={handleDialogClose} TransitionComponent={Transition} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            講義作成
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Box mt={3}>
          <Form
            handleClose={props.handleClose}
            handleSave={props.handleSave}
          />
        </Box>
      </Container>
    </Dialog >
  );
};

export default LessonAddDialogView;

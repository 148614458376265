import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import MainTab from './MainTab';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  },
  container: {
    maxWidth: "initial",
    paddingTop: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
}));

const MainView = () => {
  const classes = useStyles();
  const location = useLocation();

  // 引継データの保存（アクセスパスワード入力画面から）
  const lesson = location.state.lesson;
  const student = location.state.student;

  return (
    <Page
      className={classes.root}
      title="メイン | Active Poll"
    >
      <Container className={classes.container} pt={1}>
        <Grid
          container
          spacing={0}
        >
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Header
              lesson={lesson}
              student={student}
            />
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <MainTab
              lesson={lesson}
              student={student}
            />
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Footer />
          </Grid>
        </Grid>
      </Container>
    </Page >
  );
};

export default MainView;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import KeyIcon from '@material-ui/icons/VpnKey';
import firebase from "firebase/app";
import 'firebase/auth';
import Page from 'src/components/Page';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView'
// import Language from 'src/views/common/Language';
import Log from 'src/utils/Log';
import Util from 'src/utils/Util';
import Model from 'src/models/Model';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
  },
  header: {
    paddingTop: 30,
    paddingRight: 20,
    paddingLeft: 20,
  },
  icon: {
    width: 60,
    height: 60
  },
  iconFrame: {
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
  },
  iconBox: {
    marginTop: 10,
  },
  lessonName: {
    marginTop: 10,
    marginBottom: 20
  },
  title: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  titleText: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  announcement: {
    paddingLeft: 16,
    alignItems: 'center'
  },
  nextButton: {
    fontWeight: 600
  },
  hide: {
    display: "none",
  }
}));

const SecurityView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  // const { t } = useTranslation();

  const [values, setValues] = useState({
    accessKey: '',
  });
  const [errors, setErrors] = useState({});
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  // 引継データの保存
  const lesson = location.state ? location.state.lesson : {};
  const student = location.state ? location.state.student : {};

  // --------------------------------------------------
  //  画面表示時の処理
  // --------------------------------------------------
  useEffect(() => {

    // パラメータチェック
    if (!lesson || !student) {
      setErrorDialogOpen(true);
      return;
    }
    if (!student.oneTimeId || student.oneTimeId === 'undefined') {
      setErrorDialogOpen(true);
      return;
    }

    // 認証チェック
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        // サインインしていない状態
        firebase.auth().signInAnonymously()
          .then(() => {
          });
      } else {
        // サインイン済み
        initialize();
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  //  初期化処理
  // --------------------------------------------------
  const initialize = () => {
    Log.trace('Student.AccessKeyView', 'useEffect()');
    Log.trace('Student.AccessKeyView', 'location.state.lesson.lessonId=' + (lesson ? lesson.id : 'undefined'));
    Log.trace('Student.AccessKeyView', 'location.state.student.oneTimeId: ' + student.oneTimeId);

    // アクティブ度計測ログの追加
    Model.addLog(lesson.id, student.oneTimeId, Constants.operationTypeAccessKey);
  };

  // --------------------------------------------------
  //  入力値の更新
  // --------------------------------------------------
  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // --------------------------------------------------
  //  次へボタンの押下処理
  // --------------------------------------------------
  const hundleNextButton = async () => {

    Log.trace('Student.ProfileView', 'hundleNextButton()');

    // 入力文字のtrim処理
    const accessKey = Util.trim(values.accessKey);

    let check = {};
    // 必須チェック
    if (!accessKey) {
      check.accessKey = '必須項目です';
      setErrors(check);
      return;
    }

    // アクセスキーチェック
    if (accessKey !== lesson.accessKey) {
      check.accessKey = 'アクセスキーが正しくありません';
      setErrors(check);
      return;
    }

    // 次画面チェック
    let nextPath;

    if (lesson.profileRequired === true) {
      nextPath = '/student/profile';
    }
    else {
      nextPath = '/student/main';
      // セッション保存データの保存
      const data = {};
      data.oneTimeId = student.oneTimeId;
      data.accessDate = Util.getCurrentDayTimeSec();
      await Model.addSession(lesson.id, data);
    }

    // 次画面に展開
    navigate(nextPath,
      {
        replace: false,
        state: {
          lesson: lesson,
          student: student
        }
      }
    );
  }

  // --------------------------------------------------
  // システムエラーダイアログの処理実行
  // --------------------------------------------------
  const handleDialogExecute = () => {
    setErrorDialogOpen(false);
    window.history.back();
  }

  return (
    <Page
      className={classes.root}
      title='アクセスキー | Active Poll'
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
      >
        {/* ======================================== */}
        {/* ヘッダー */}
        {/* ======================================== */}
        <Box
          className={classes.header}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Box>
            {/*
            <Language />
            */}
          </Box>
        </Box>

        <Container maxWidth="sm">
          {/* ======================================== */}
          {/* 講義情報 */}
          {/* ======================================== */}
          <Box>
            <Card>
              <CardContent className={classes.lessonCard}>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                >
                  <Box className={classes.lessonName}>
                    <Typography
                      color="textPrimary"
                      variant="h4"
                    >
                      {lesson ? lesson.name : ''}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h5"
                      className={classes.name}
                    >
                      {lesson ? lesson.accountName : ''}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {lesson ? lesson.accountOrganization : ''}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              <CardContent className={classes.hide}></CardContent>
            </Card>
          </Box>

          {/* ======================================== */}
          {/* アクセスキー入力 */}
          {/* ======================================== */}
          <Box mt={3}>
            <Card>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                className={classes.iconBox}
              >
                <Box
                  className={classes.iconFrame}
                >
                  <LockOutlinedIcon color="primary" className={classes.icon} />
                </Box>
              </Box>
              <CardContent>
                <Box className={classes.title}>
                  <Typography
                    variant="body1"
                    className={classes.titleText}
                  >
                    アクセスキーを入力してください
                  </Typography>
                </Box>
                <Box className={classes.announcement}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {/*{t('student_profile_student_info_announcement')}*/}
                    &nbsp;
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label='アクセスキー'
                      name="accessKey"
                      onChange={handleValueChange}
                      required
                      value={values.accessKey}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <KeyIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      error={errors.accessKey ? true : false}
                      helperText={errors.accessKey ? errors.accessKey : ''}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      className={classes.nextButton}
                      onClick={hundleNextButton}
                      disabled={!lesson ? true : false}
                    >
                      {/*{t('student_profile_next_button')}*/}
                      {lesson.profileRequired === true ? '次へ' : '始める'}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box >

      {/* ======================================== */}
      {/* エラーダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={errorDialogOpen}
        title="システムエラー"
        description="システムエラーが発生しました。大変恐れ入りますが、初めの画面から操作をやり直していただけますでしょうか。"
        agree={handleDialogExecute}
      />
    </Page >
  );
};

export default SecurityView;

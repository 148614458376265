import React ,{ useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Edit from './Edit';
import Start from './Start';
import Review from './Review';
import Footer from './Footer';
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanelBox}>
          {children}
        </Box>
      )}
    </div>
  );
}

const LessonDetailView = () => {
  const classes = useStyles();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(0);
  const [lesson, setLesson] = useState(location.state.lesson);
  const [isLessonStart, setIsLessonStart] = useState(false);
  const mode = location.state.mode;

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Admin.Lesson.LessonDitailView', 'useEffect()');
    initializeTab();
    return function cleanup() {
      Log.trace('Admin.Lesson.LessonDitailView', 'useEffect() cleanup');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // --------------------------------------------------
  // タブ切り替え処理
  // --------------------------------------------------
  const initializeTab = () => {
    let idx = 0;
    // リアルタイム
    if(lesson.voteMode === Constants.voteModeRealtime){
      switch (mode) {
        case Constants.lessonDetailModeEdit: 
          idx = 0;
          break;
        case Constants.lessonDetailModeStart: 
          idx = 1;
          break;
        case Constants.lessonDetailModeReview: 
          idx = 2;
          break;
        default :
          break;
      }
    }
    else {
      switch (mode) {
        case Constants.lessonDetailModeEdit: 
          idx = 0;
          break;
        case Constants.lessonDetailModeReview: 
          idx = 1;
          break;
        default :
          break;
      }
    }
    setTabIndex(idx);
  };

  // --------------------------------------------------
  // タブ切り替え処理
  // --------------------------------------------------
  const handleTabChange = (idx) => {
    setTabIndex(idx);
  };

  // --------------------------------------------------
  // 講義情報の更新
  // --------------------------------------------------
  const handleUpdateLesson = async (lessonId) => {
    const lesson = await Model.getLessonByKey(lessonId);
    setLesson(lesson);
  };

  // --------------------------------------------------
  // 開始中状態の更新
  // --------------------------------------------------
  const handleLessonStart = (isLessonStart) => {
    setIsLessonStart(isLessonStart);
  };

  return (
    <Page
      className={classes.root}
      title="講義詳細 | Active Poll"
    >
      <Container maxWidth={false}>
        <Toolbar
          index={tabIndex}
          handleTabChange={handleTabChange}
          lesson={lesson}
          isLessonStart={isLessonStart}
        />
        <Box mt={3}>
          <TabPanel value={tabIndex} index={0}>
            <Edit 
              lesson={lesson}
              handleUpdateLesson={handleUpdateLesson}
            />
          </TabPanel>
          {lesson.voteMode  === Constants.voteModeRealtime ? (
            <TabPanel value={tabIndex} index={1}>
              <Start 
                lesson={lesson}
                handleLessonStart={handleLessonStart}
              />
            </TabPanel>
          ):''}
          <TabPanel value={tabIndex} index={lesson.voteMode  === Constants.voteModeRealtime ? 2 : 1}>
            <Review 
                lesson={lesson}
              />
          </TabPanel>
        </Box>
        <Box mt={3}>
          <Footer />
        </Box>
      </Container>
    </Page>
  );
};

export default LessonDetailView;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import AnswerSelect from './AnswerSelect';
import AnswerText from './AnswerText';
import ResultGraph from '../ResultGraph';
import ResultText from '../ResultText';
import Constants from 'src/utils/Constants';

const RealtimeGrid = (props) => {

  const [voted, setVoted] = useState(false);


  // --------------------------------------------------
  // 画面表示
  // --------------------------------------------------
  const callbackIsVoted = (voted) => {
    setVoted(voted);
  }

  // リアルタイム２：回答前は1カラム、回答後は2カラム
  //    左：回答画面、右：結果確認画面
  return (
    <>
      {voted ? (
        <>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            {props.question.type === Constants.questionTypeRadio ? <AnswerSelect key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> :
              props.question.type === Constants.questionTypeSelect ? <AnswerSelect key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> :
                props.question.type === Constants.questionTypeCheckBox ? <AnswerSelect key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> :
                  props.question.type === Constants.questionTypeText ? <AnswerText key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> :
                    props.question.type === Constants.questionTypeTextArea ? <AnswerText key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> : ''
            }
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            {props.question.type === Constants.questionTypeRadio ? <ResultGraph key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} /> :
              props.question.type === Constants.questionTypeSelect ? <ResultGraph key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} /> :
                props.question.type === Constants.questionTypeCheckBox ? <ResultGraph key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} /> :
                  props.question.type === Constants.questionTypeText ? <ResultText key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} mode={props.mode} /> :
                    props.question.type === Constants.questionTypeTextArea ? <ResultText key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} mode={props.mode} /> : ''
            }
          </Grid>
        </>
      ) : (
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
        >
          {props.question.type === Constants.questionTypeRadio ? <AnswerSelect key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> :
            props.question.type === Constants.questionTypeSelect ? <AnswerSelect key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> :
              props.question.type === Constants.questionTypeCheckBox ? <AnswerSelect key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> :
                props.question.type === Constants.questionTypeText ? <AnswerText key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> :
                  props.question.type === Constants.questionTypeTextArea ? <AnswerText key={props.question.id} question={props.question} lesson={props.lesson} student={props.student} countDownStart={props.countDownStart} sendIdVoted={callbackIsVoted} /> : ''
          }
        </Grid>
      )}
    </>
  );
};

RealtimeGrid.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  student: PropTypes.object,
  countDownStart: PropTypes.bool,
  mode: PropTypes.string,
};

export default RealtimeGrid;
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Constants from 'src/utils/Constants';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  buttonArea: {
    marginTop: 20,
    marginRight: 16,
    marginBottom: 20,
  },
  answerButton: {
    fontWeight: 600,
  },
  cancelButton: {
    marginLeft: 10,
  },
  inputArea: {
    marginBottom: 60,
  }
}));

const Edit = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState({ anonymous: true });
  const [errors, setErrors] = useState({});

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.ResultText Edit', 'useEffect()');
    Log.trace('Student.MainView Enquete.ResultText Edit', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.ResultText Edit', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView Enquete.ResultText Edit', 'props.answer.key=' + props.answer.id)
    Log.trace('Student.MainView Enquete.ResultText Edit', 'props.student.oneTimeId=' + props.student.oneTimeId);

    setValue(props.answer.text);
    setSelectedValue({ anonymous: props.answer.anonymous })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // テキスト入力処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // --------------------------------------------------
  // 匿名投稿チェックボックス選択処理
  // --------------------------------------------------
  const handleCheckChange = (event) => {
    setSelectedValue({ ...selectedValue, [event.target.name]: event.target.checked });
  }

  // --------------------------------------------------
  // 送信ボタン押下処理
  // --------------------------------------------------
  const handleSendButton = (event) => {

    Log.trace('Student.MainView Enquete.ResultText Edit', 'handleSendButton()');

    // 入力文字のtrim処理
    let text = Util.trim(value);

    let check = {};
    // 必須チェック
    if (!text) {
      check.text = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // 回答データの作成
    // Path: answer/講義ID/設問ID/ワンタイムID/answers/回答ID
    // 
    // ・回答内容（回答ID）
    //    ・テキスト
    //    ・タイムスタンプ
    //    ・匿名フラグ
    //    ・氏名
    //    ・ワンタイムID

    const timestamp = Util.getCurrentDayTimeSec();

    const answer = {
      text: Util.escape(text),
      timestamp: timestamp,
      anonymous: selectedValue["anonymous"] === true ? true : false,
      sname: props.student.fname + ' ' + props.student.lname,
      oneTimeId: props.student.oneTimeId,
    };

    Model.updateAnswer(props.lesson.id, props.question.id, props.student.oneTimeId, props.answer.id, answer)

    props.handleClose(true);

    setValue('');
    setErrors({});

    // アクティブ度計測ログの追加
    Model.addLog(props.lesson.id, props.student.oneTimeId, Constants.operationTypeSendAnswerText);
  }

  return (

    <Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.buttonArea}
      >
        <Box>
          <Button
            color="primary"
            variant="contained"
            className={classes.answerButton}
            onClick={handleSendButton}
            endIcon={<SendIcon />}
          >
            送信する
          </Button>
        </Box>
      </Box>
      <Box
        className={classes.inputArea}
      >
        <TextField
          variant="outlined"
          fullWidth={true}
          multiline={props.question.type === Constants.questionTypeTextArea ? true : false}
          rows={props.question.type === Constants.questionTypeTextArea ? 10 : 1}
          name="text"
          value={value}
          onChange={handleChange}
          error={errors.text ? true : false}
          helperText={errors.text ? errors.text : ''}
        />

        <label>
          <Box
            alignItems="center"
            display="flex"
            ml={-1}
          >
            <Checkbox
              checked={selectedValue["anonymous"] === true ? true : false}
              name="anonymous"
              onChange={handleCheckChange}
            />
            <Typography
              variant="h5"
            >
              匿名で投稿する
            </Typography>
          </Box>
        </label>
        <Typography variant="h6">
          {selectedValue["anonymous"] === true ? '投稿者名は他の学生には非公開。' : '投稿者名を他の学生にも公開。'}
        </Typography>
        <Typography variant="h6" style={{ height: 20 }}>
          {selectedValue["anonymous"] === true ? '講師は閲覧可能。' : ''}
        </Typography>
      </Box>

    </Fragment>
  );
};

Edit.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  student: PropTypes.object,
  answer: PropTypes.object,
  handleClose: PropTypes.func,
};

export default Edit;
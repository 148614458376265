import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  MenuItem,
  makeStyles,
  Select,
} from '@material-ui/core';

import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const FormSelect = (props) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState('');

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.form.FormSelect', 'useEffect()');
    Log.trace('Student.MainView Enquete.form.FormSelect', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.form.FormSelect', 'props.answer.length=' + props.answer.length);

    let value = '';
    for (const ans of props.answer) {
      for (let i = 0; i < props.question.options.length; i++) {
        if (ans === props.question.options[i]) {
          value = i.toString();
        }
      }
    }
    setSelectedValue(value);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answer]);

  // --------------------------------------------------
  // プルダウン選択処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    const array = [];
    if (event.target.value) {
      array.push(event.target.value)
    }
    props.handleChange(array);
  };

  return (
    <Box className={classes.root}>
      <FormControl fullWidth={true} variant="outlined">
        <Select
          labelId="option-profile"
          name="type"
          value={selectedValue}
          onChange={handleChange}
          disabled={props.isDisabled}
          defaultValue=""
        >
          <MenuItem value=''>
            &nbsp;
        </MenuItem>
          {props.question.options.map((option, index) => (
            <MenuItem
              key={index}
              value={index.toString()}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

FormSelect.propTypes = {
  question: PropTypes.object,
  answer: PropTypes.array,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default FormSelect;
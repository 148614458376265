import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Fab,
  Tabs,
  Tab,
  IconButton,
  makeStyles,
  Paper,
  Snackbar,
  Typography,
  Grid,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import { Edit3 as Edit3Icon } from 'react-feather';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddDialog from './AddDialog/';
import EditDialog from './EditDialog/';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  sortOperation: {
    marginBottom: 10,
  },
  sortButtonLabel: {
    fontWeight: 600
  },
  addButton: {
    position: 'fixed',
    margin: 0,
    top: 140,
    right: 30,
    bottom: 'auto',
    left: 'auto',
    fontWeight: 600
  },
  result: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 30,
    height: 600,
    overflowY: "scroll"
  },
  commentOther: {
    padding: 10,
    whiteSpace: "pre-line",
    backgroundColor: "#fafafa"
  },
  commentMe: {
    padding: 10,
    whiteSpace: "pre-line",
    backgroundColor: "#BEC5E8"
  },
  user: {
    marginTop: 10,
    marginLeft: 5,
    color: '#616161',
    fontSize: 13
  },
  good: {
    marginTop: 10,
  },
  goodIcon: {
    width: 15,
    height: 15
  },
  goodCount: {
    fontSize: 13
  },
  goodIconMe: {
    width: 15,
    height: 15
  },
  editIcon: {
    width: 15,
    height: 15
  },
  goodCountMe: {
    fontSize: 15
  },
  bold: {
    fontWeight: 600,
  },
}));

const Result = (props) => {
  const classes = useStyles();
  const [sort, setSort] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [sortMessageOpen, setSortMessageOpen] = useState(false);
  const [sortMessageText, setSortMessageText] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editFaq, setEditFaq] = useState({});

  const sortRef = useRef(0);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Faq.Result', 'useEffect()');

    sortRef.current = 0;
    createCard();

    // 振り返りモードの場合は質問データの監視をしない
    if (props.student.oneTimeId !== 'LessonReviewView') {
      watchFaq();
    }

    return function cleanup() {
      Log.trace('Student.MainView Faq.Result', 'cleanup()');

      // 振り返りモードの場合は質問データの監視をしない
      if (props.student.oneTimeId !== 'LessonReviewView') {
        watchCancelFaq();
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 質問データの取得処理
  // --------------------------------------------------
  const createCard = async () => {
    const data = await Model.getFaqByLessonId(props.lesson.id);

    // キー：選択肢名、値：件数のオブジェクトを生成
    const card = [];
    Object.keys(data).forEach(function (key) {
      const d = {
        id: key,
        text: data[key].text,
        user: data[key].anonymous ? '' : data[key].name,
        isMe: data[key].oneTimeId === props.student.oneTimeId ? true : false,
        timestamp: data[key].timestamp,
        oneTimeId: data[key].oneTimeId,
        good: data[key].good,
        anonymous: data[key].anonymous,
      };
      card.push(d);
    });

    if (sortRef.current === 0) {
      card.sort(function (a, b) {
        return a.timestamp < b.timestamp ? 1 : -1;
      });
    }
    else if (sortRef.current === 1) {
      card.sort(function (a, b) {
        const alength = a.good ? Object.keys(a.good).length : 0;
        const blength = b.good ? Object.keys(b.good).length : 0;
        if (alength > blength) {
          return -1;
        }
        else if (alength < blength) {
          return 1;
        }
        else {
          return a.timestamp < b.timestamp ? 1 : -1;
        }
      });
    }
    setCardData(card)
  }

  // --------------------------------------------------
  // 質問データの監視処理実行
  // --------------------------------------------------
  const watchFaq = async () => {
    await Model.watchFaqAC(props.lesson.id, callback);
  }

  // --------------------------------------------------
  // 質問データの監視処理キャンセル実行
  // --------------------------------------------------
  const watchCancelFaq = async () => {
    await Model.watchCancelFaq(props.lesson.id);
  }

  // --------------------------------------------------
  // 質問データの変更処理待受
  // --------------------------------------------------
  const callback = (key, val) => {
    Log.trace('Student.MainView Faq.Result', 'callback Faq key: ' + key);
    createCard();
  }

  // --------------------------------------------------
  // ソート順切り替え
  // --------------------------------------------------
  const handleSortChange = (event, newValue) => {
    const card = [...cardData];

    if (newValue === 0) {
      card.sort(function (a, b) {
        return a.timestamp < b.timestamp ? 1 : -1;
      });
    }
    else if (newValue === 1) {
      card.sort(function (a, b) {
        const alength = a.good ? Object.keys(a.good).length : 0;
        const blength = b.good ? Object.keys(b.good).length : 0;
        if (alength > blength) {
          return -1;
        }
        else if (alength < blength) {
          return 1;
        }
        else {
          return a.timestamp < b.timestamp ? 1 : -1;
        }
      });
    }
    sortRef.current = newValue;
    setSort(newValue);
    setCardData(card);
    setSortMessageText(newValue === 0 ? '新着順に並べ替えました' : '人気順に並べ替えました');
    setSortMessageOpen(true);
  };

  //--------------------------------------------------
  // 並べ替え成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleSortMessageClose = () => {
    setSortMessageOpen(false);
    setSortMessageText('');
  }

  // --------------------------------------------------
  //  質問投稿ダイアログオープン
  // --------------------------------------------------
  const handleAddButton = (event) => {
    setAddDialogOpen(true);
  };

  // --------------------------------------------------
  //  質問投稿ダイアログクローズ
  // --------------------------------------------------
  const handleAddDialogClose = (isSend) => {
    setAddDialogOpen(false);
    if (isSend === true) {
      setMessageOpen(true);
      setMessageText('送信しました');
    }
  };

  //--------------------------------------------------
  // メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
    setMessageText('');
  }

  // --------------------------------------------------
  // いいね追加処理
  // --------------------------------------------------
  const addGood = async (data) => {
    Log.trace('Student.MainView Faq.Result', 'addGood: ' + data.id);
    const d = {
      oneTimeId: props.student.oneTimeId,
      timestamp: Util.getCurrentDayTimeSec()
    };
    await Model.addFaqGood(props.lesson.id, data.id, props.student.oneTimeId, d);

    // アクティブ度計測ログの追加
    Model.addLog(props.lesson.id, props.student.oneTimeId, Constants.operationTypeSendFaqGood);
  }

  // --------------------------------------------------
  // いいね取り外し処理
  // --------------------------------------------------
  const removeGood = async (data) => {
    Log.trace('Student.MainView Faq.Result', 'removeGood: ' + data.id);
    await Model.deleteFaqGood(props.lesson.id, data.id, props.student.oneTimeId, data);
  }

  // --------------------------------------------------
  //  質問編集ダイアログオープン
  // --------------------------------------------------
  const handleEditButton = (data) => {
    setEditFaq(data);
    setEditDialogOpen(true);
  };

  // --------------------------------------------------
  //  質問編集ダイアログクローズ
  // --------------------------------------------------
  const handleEditDialogClose = (isSend) => {
    setEditDialogOpen(false);
    setEditFaq({});
    if (isSend === true) {
      setMessageOpen(true);
      setMessageText('送信しました');
    }
  };

  return (
    <Box>
      <Box className={classes.sortOperation}>
        <Tabs
          value={sort}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleSortChange}
        >
          <Tab label="新着順" className={classes.sortButtonLabel} />
          <Tab label="人気順" className={classes.sortButtonLabel} />
        </Tabs>

        {props.student.oneTimeId !== 'LessonStartView' && props.student.oneTimeId !== 'LessonReviewView' ? (
          <Fab
            variant="extended"
            color="primary"
            className={classes.addButton}
            onClick={handleAddButton}
          >
            <EditIcon />
            質問を投稿する
          </Fab>
        ) : ''}
      </Box>
      <Paper variant="outlined" className={classes.result}>
        <Grid
          container
          spacing={2}
        >
          {cardData.map((data, index) => (
            <Grid
              item
              lg={3}
              md={3}
              xs={12}
              key={data.id}
            >
              <Paper variant="elevation" elevation={10} className={data.isMe === true ? classes.commentMe : classes.commentOther}>
                <Typography variant="h6">
                  {data.text}
                </Typography>
                <Box
                  className={classes.user}
                  style={{ display: data.user ? '' : 'none' }}
                >
                  {data.user ? '投稿者 ' + data.user : ''}
                </Box>

                {/* ======================================== */}
                {/* イイネボタン 教師画面 */}
                {/* ======================================== */}
                {props.student.oneTimeId === 'LessonStartView' || props.student.oneTimeId === 'LessonReviewView' ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    className={classes.good}
                  >
                    {data.good && Object.keys(data.good).length > 0 ? (
                      <IconButton
                        size="small"
                        disabled={true}
                      >
                        <FavoriteIcon className={classes.goodIcon} style={{ color: 'rgb(220, 0, 78)' }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        disabled={true}
                      >
                        <FavoriteBorderIcon className={classes.goodIcon} />
                      </IconButton>
                    )}
                    <Typography className={classes.goodCount} style={{ color: 'rgb(220, 0, 78)' }}>
                      {data.good ? Object.keys(data.good).length : ''}
                    </Typography>
                  </Box>
                ) : ''}

                {/* ======================================== */}
                {/* イイネボタン 他の人の投稿 */}
                {/*   イイネをしたかどうかで表示切り替え */}
                {/* ======================================== */}
                {props.student.oneTimeId !== 'LessonStartView' && props.student.oneTimeId !== 'LessonReviewView' ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    className={classes.good}
                    style={{ display: data.isMe === true ? 'none' : '' }}
                  >
                    {data.good && data.good[props.student.oneTimeId] ? (
                      <IconButton
                        size="small"
                        onClick={() => removeGood(data)}
                        disabled={props.student.oneTimeId === 'LessonStartView' || props.student.oneTimeId === 'LessonReviewView' ? true : false}
                      >
                        <FavoriteIcon className={classes.goodIcon} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => addGood(data)}
                        disabled={props.student.oneTimeId === 'LessonStartView' || props.student.oneTimeId === 'LessonReviewView' ? true : false}
                      >
                        <FavoriteBorderIcon className={classes.goodIcon} />
                      </IconButton>
                    )}
                    <Typography className={classes.goodCount}>
                      {data.good ? Object.keys(data.good).length : ''}
                    </Typography>
                  </Box>
                ) : ''}

                {/* ======================================== */}
                {/* イイネボタン 自身の投稿 */}
                {/*   イイネをもらったかどうかで表示切り替え */}
                {/*   自身へのイイネは不可とする */}
                {/* ======================================== */}
                {props.student.oneTimeId !== 'LessonStartView' && props.student.oneTimeId !== 'LessonReviewView' ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    className={classes.good}
                    style={{ display: data.isMe === true ? '' : 'none' }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                    >
                      {data.good && Object.keys(data.good).length > 0 ? (
                        <IconButton
                          size="small"
                          onClick={() => removeGood(data)}
                          disabled={true}
                        >
                          <FavoriteIcon className={classes.goodIconMe} style={{ color: 'rgb(220, 0, 78)' }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => addGood(data)}
                          disabled={true}
                        >
                          <FavoriteBorderIcon className={classes.goodIcon} />
                        </IconButton>
                      )}
                      <Typography className={classes.goodCountMe} style={{ color: 'rgb(220, 0, 78)' }} >
                        {data.good ? Object.keys(data.good).length : ''}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        size="small"
                        onClick={() => handleEditButton(data)}
                      >
                        <Edit3Icon className={classes.editIcon} />
                      </IconButton>
                    </Box>
                  </Box>
                ) : ''}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper >

      {/* ======================================== */}
      {/* 質問投稿ダイアログ */}
      {/* ======================================== */}
      <AddDialog
        open={addDialogOpen}
        lesson={props.lesson}
        student={props.student}
        handleClose={handleAddDialogClose}
      />

      {/* ======================================== */}
      {/* 質問編集ダイアログ */}
      {/* ======================================== */}
      <EditDialog
        open={editDialogOpen}
        lesson={props.lesson}
        student={props.student}
        editFaq={editFaq}
        handleClose={handleEditDialogClose}
      />

      {/* ======================================== */}
      {/* 質問投稿成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          {messageText}
        </Alert>
      </Snackbar>

      {/* ======================================== */}
      {/* 並べ替え変更成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={sortMessageOpen} autoHideDuration={2000} onClose={handleSortMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleSortMessageClose} severity="success">
          {sortMessageText}
        </Alert>
      </Snackbar>
    </Box>
  );
};

Result.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  student: PropTypes.object
};

export default Result;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  CornerDownLeft as BackIcon,
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  bold: {
    fontWeight: 600
  },
  LinkButton: {
    padding: 0
  }
}));

const Toolbar = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // --------------------------------------------------
  // 戻る処理
  // --------------------------------------------------
  const handleLink = () => {
    navigate('/admin/lessons', { replace: false, });
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" variant="h6">トップ</Typography>
          <Typography color="textPrimary" variant="h6">
            <Button color="inherit" underline="always" className={classes.LinkButton} component={Link} onClick={() => handleLink()}>
              講義一覧
            </Button>
          </Typography>
          <Typography color="primary" variant="h5" className={classes.bold}>編集</Typography>
        </Breadcrumbs>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleLink()}
          startIcon={<BackIcon />}
          className={classes.bold}
        >
          戻る
        </Button>
      </Box>
    </div>
  );
};

export default Toolbar;

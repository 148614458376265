import React, { useState } from 'react';
import {
  Box,
  Button,
  Breadcrumbs,
  Snackbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  UserPlus as UserPlusIcon
} from 'react-feather';
import AccountAddDialogView from '../AccountAddDialogView'

const useStyles = makeStyles((theme) => ({
  root: {},
  bold: {
    fontWeight: 600
  }
}));

const Toolbar = (props) => {
  const classes = useStyles();

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);

  //--------------------------------------------------
  // [アカウントを追加する]ボタン
  //--------------------------------------------------
  const handleAccountAddButton = (event, id) => {
    setAddDialogOpen(true);
  }


  //--------------------------------------------------
  // アカウント追加ダイアログ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleDialogClose = () => {
    setAddDialogOpen(false);
  }

  //--------------------------------------------------
  // アカウント追加ダイアログ - [保存する]ボタン処理
  //--------------------------------------------------
  const handleDialogSave = () => {
    setAddDialogOpen(false);
    setMessageOpen(true);
    props.reload();
  }

  //--------------------------------------------------
  // アカウント追加成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" variant="h6">トップ</Typography>
          <Typography color="primary" variant="h5" className={classes.bold}>アカウント管理</Typography>
        </Breadcrumbs>
        <Button
          color="primary"
          variant="contained"
          startIcon={<UserPlusIcon />}
          className={classes.bold}
          onClick={(event) => handleAccountAddButton(event)}
        >
          アカウントを追加する
        </Button>
      </Box>
      {/*
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search lesson"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      */}

      {/* ======================================== */}
      {/* アカウント追加ダイアログ */}
      {/* ======================================== */}
      <AccountAddDialogView
        open={addDialogOpen}
        handleClose={handleDialogClose}
        handleSave={handleDialogSave}
      />

      {/* ======================================== */}
      {/* アカウント追加成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          アカウントを追加しました。
        </Alert>
      </Snackbar>
    </div>
  );
};


export default Toolbar;

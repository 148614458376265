import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import UserIcon from '@material-ui/icons/PersonOutlineOutlined';
import firebase from "firebase/app";
import 'firebase/auth';
import Page from 'src/components/Page';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView'
// import Language from 'src/views/common/Language';
import SessionUtil from 'src/utils/SessionUtil';
import Util from 'src/utils/Util';
import Model from 'src/models/Model';
import Constants from 'src/utils/Constants';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
  },
  header: {
    paddingTop: 30,
    paddingRight: 20,
    paddingLeft: 20,
  },
  icon: {
    width: 60,
    height: 60
  },
  iconFrame: {
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
  },
  iconBox: {
    marginTop: 10,
  },
  lessonName: {
    marginTop: 10,
    marginBottom: 20
  },
  title: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  titleText: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  announcement: {
    paddingLeft: 16,
    paddingBottom: 32,
    alignItems: 'center'
  },
  inputSpacing: {
    marginTop: 10,
  },
  nextButton: {
    fontWeight: 600
  },
  dummy: {
    color: theme.palette.background.dark,
  },
  hide: {
    display: "none",
  }
}));

const ProfileView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  // const { t } = useTranslation();

  const [values, setValues] = useState({
    fname: '',
    lname: '',
    code: '',
    mail: '',
  });
  const [selected, setSelected] = useState({
    saveData: false
  });
  const [errors, setErrors] = useState({});
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  // 引継データの保存
  const lesson = location.state ? location.state.lesson : {};
  const student = location.state ? location.state.student : {};

  // --------------------------------------------------
  //  画面表示時の処理
  // --------------------------------------------------
  useEffect(() => {

    // パラメータチェック
    if (!lesson || !student) {
      setErrorDialogOpen(true);
      return;
    }
    if (!student.oneTimeId || student.oneTimeId === 'undefined') {
      setErrorDialogOpen(true);
      return;
    }

    // 認証チェック
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        // サインインしていない状態
        firebase.auth().signInAnonymously()
          .then(() => {
          });
      } else {
        // サインイン済み
        initialize();
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  //  初期化処理
  // --------------------------------------------------
  const initialize = () => {
    Log.trace('Student.ProfileView', 'useEffect()');
    Log.trace('Student.ProfileView', 'location.state.lesson.lessonId=' + (lesson ? lesson.id : 'undefined'));
    Log.trace('Student.ProfileView', 'location.state.student.oneTimeId: ' + student.oneTimeId);

    // アクティブ度計測ログの追加
    Model.addLog(lesson.id, student.oneTimeId, Constants.operationTypeProfile);

    // 入力情報の設定
    // まずは引き継がれた値を確認し、存在すればセットする
    // なければ保存住みの値をセットする。
    if (student.fname) {
      setValues(student);
      setSelected({ saveData: student.saveData })
      return;
    }

    const storage = SessionUtil.getInputData();
    if (Object.keys(storage).length > 0) {
      setValues(storage);
      setSelected({ saveData: true })
    }
  };

  // --------------------------------------------------
  //  入力値の更新
  // --------------------------------------------------
  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // --------------------------------------------------
  //  チェックボックスの更新
  // --------------------------------------------------
  const handleCheckChange = (event) => {
    setSelected({ ...selected, [event.target.name]: event.target.checked });
  }

  // --------------------------------------------------
  //  次へボタンの押下処理
  // --------------------------------------------------
  const hundleNextButton = async () => {

    Log.trace('Student.ProfileView', 'hundleNextButton()');

    // 入力文字のtrim処理
    let fname = Util.trim(values.fname);
    let lname = Util.trim(values.lname);
    let code = Util.trim(values.code);
    let mail = Util.trim(values.mail);

    let check = {};
    // 必須チェック
    if (!fname) {
      check.fname = '必須項目です';
    }
    if (!lname) {
      check.lname = '必須項目です';
    }
    if (lesson.studentIdRequired === true && !code) {
      check.code = '必須項目です';
    }
    if (!mail) {
      check.mail = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    if (!Util.checkMailaddressFormat(mail)) {
      check.mail = 'メールアドレスの形式が正しくありません';
      setErrors(check);
      return;
    }

    // 自動変換処理
    code = Util.zenkaku2Hankaku(code);
    fname = Util.escape(fname);
    lname = Util.escape(lname);
    code = Util.escape(code);
    mail = Util.escape(mail);

    // 保存する受講情報の作成
    const data = {
      oneTimeId: student.oneTimeId,
      fname: fname,
      lname: lname,
      code: code,
      mail: mail,
      saveData: selected.saveData,
      accessDate: Util.getCurrentDayTimeSec()
    };

    // 受講情報の保存もしくは削除
    if (selected.saveData) {
      SessionUtil.setInputData(data);
    }
    else {
      SessionUtil.removeInputData();
    }

    // セッション保存データの保存
    data.oneTimeId = student.oneTimeId;
    await Model.addSession(lesson.id, data);

    // 次画面に展開
    navigate('/student/main',
      {
        replace: false,
        state: {
          lesson: lesson,
          student: data
        }
      }
    );
  }

  // --------------------------------------------------
  // システムエラーダイアログの処理実行
  // --------------------------------------------------
  const handleDialogExecute = () => {
    setErrorDialogOpen(false);
    window.history.back();
  }

  return (
    <Page
      className={classes.root}
      // title={t('student_profile_title')}
      title='プロフィール | Active Poll'
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
      >
        {/* ======================================== */}
        {/* ヘッダー */}
        {/* ======================================== */}
        <Box
          className={classes.header}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Box>
            {/*
            <Language />
            */}
          </Box>
        </Box>

        <Container maxWidth="sm">
          {/* ======================================== */}
          {/* 講義情報 */}
          {/* ======================================== */}
          <Box>
            <Card>
              <CardContent className={classes.lessonCard}>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                >
                  <Box className={classes.lessonName}>
                    <Typography
                      color="textPrimary"
                      variant="h4"
                    >
                      {lesson ? lesson.name : ''}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h5"
                      className={classes.name}
                    >
                      {lesson ? lesson.accountName : ''}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {lesson ? lesson.accountOrganization : ''}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              <CardContent className={classes.hide}></CardContent>
            </Card>
          </Box>

          {/* ======================================== */}
          {/* 受講生情報 */}
          {/* ======================================== */}
          <Box mt={3}>
            <Card>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                className={classes.iconBox}
              >
                <Box
                  className={classes.iconFrame}
                >
                  <UserIcon color="primary" className={classes.icon} />
                </Box>
              </Box>
              <CardContent>
                <Box className={classes.title}>
                  <Typography
                    variant="body1"
                    className={classes.titleText}
                  >
                    {lesson.useScene === Constants.useSceneSchool ? '受講生情報を入力してください' :
                      'セミナー登録時の情報を入力してください'}
                  </Typography>
                </Box>
                <Box className={classes.announcement}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {/*{t('student_profile_student_info_announcement')}*/}
                    {lesson.useScene === Constants.useSceneSchool ? '出席確認等に利用します。正確に入力してください。' :
                      '投稿内容の確認等に利用します。'}
                  </Typography>
                </Box>

                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    md={6}
                    xs={6}
                  >
                    <TextField
                      fullWidth
                      //label={t('student_profile_student_info_first_name')}
                      label='氏名（姓）'
                      name="fname"
                      onChange={handleValueChange}
                      required
                      value={values.fname}
                      variant="outlined"
                      error={errors.fname ? true : false}
                      helperText={errors.fname ? errors.fname : ''}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={6}
                  >
                    <TextField
                      fullWidth
                      // label={t('student_profile_student_info_last_name')}
                      label='氏名（名）'
                      name="lname"
                      onChange={handleValueChange}
                      required
                      value={values.lname}
                      variant="outlined"
                      error={errors.lname ? true : false}
                      helperText={errors.lname ? errors.lname : ''}
                    />
                  </Grid>
                  {lesson.studentIdRequired === true ? (
                    <Grid
                      item
                      md={12}
                      xs={12}
                      className={classes.inputSpacing}
                    >
                      <TextField
                        fullWidth
                        // label={t('student_profile_student_info_code')}
                        label='学籍番号'
                        name="code"
                        onChange={handleValueChange}
                        required
                        value={values.code}
                        variant="outlined"
                        error={errors.code ? true : false}
                        helperText={errors.code ? errors.code : ''}
                      />
                    </Grid>
                  ) : ''}
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.inputSpacing}
                  >
                    <TextField
                      fullWidth
                      // label={t('student_profile_student_info_mail')}
                      label='連絡先メールアドレス'
                      name="mail"
                      onChange={handleValueChange}
                      required
                      value={values.mail}
                      variant="outlined"
                      error={errors.mail ? true : false}
                      helperText={errors.mail ? errors.mail : ''}
                    />
                    <label>
                      <Box
                        alignItems="center"
                        display="flex"
                        ml={-1}
                      >
                        <Checkbox
                          checked={selected.saveData === true ? true : false}
                          name="saveData"
                          onChange={handleCheckChange}
                        />
                        <Typography
                          color="textSecondary"
                          variant="body1"
                        >
                          {/*{t('student_profile_student_info_save')}*/}
                          次回からの入力を省略する
                        </Typography>
                      </Box>
                    </label>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      className={classes.nextButton}
                      onClick={hundleNextButton}
                      disabled={!lesson ? true : false}
                    >
                      {/*{t('student_profile_next_button')}*/}
                      始める
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>

        {/* ======================================== */}
        {/* フッター */}
        {/* ======================================== */}
        <Box>
          <UserIcon className={classes.dummy} />
        </Box>
      </Box >

      {/* ======================================== */}
      {/* エラーダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={errorDialogOpen}
        title="システムエラー"
        description="システムエラーが発生しました。大変恐れ入りますが、初めの画面から操作をやり直していただけますでしょうか。"
        agree={handleDialogExecute}
      />
    </Page >
  );
};

export default ProfileView;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Constants from 'src/utils/Constants';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  timer: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
}));

const CountDown = (props) => {
  const classes = useStyles();
  const [countDownNum, setCountDownNum] = useState(Constants.countDownInitial);
  const [countDownStart, setCountDownStart] = useState(false);

  let countDownTimerRef = useRef();

  // --------------------------------------------------
  // タイマー監視
  // --------------------------------------------------
  useEffect(() => {
    if (!props.countDownStart) {
      Log.trace('Student.MainView Enquete.CountDown useEffect()', 'stop count down');
      clearInterval(countDownTimerRef.current);
      setCountDownNum(Constants.countDownInitial);
    }
    else {
      Log.trace('Student.MainView Enquete.CountDown useEffect()', 'start count down');
      countDownTimerRef.current = setInterval(countDown, 1000);
    }
    setCountDownStart(props.countDownStart);

    return function cleanup() {
      Log.trace('Student.MainView Enquete.CountDown useEffect()', 'cleanup');
      clearInterval(countDownTimerRef.current);
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.countDownStart]);


  // --------------------------------------------------
  // カウントダウン処理
  // --------------------------------------------------
  const countDown = () => {
    setCountDownNum((prev) => prev - 1);
  }

  return (
    <Box>
      <Typography
        className={classes.timer}
        style={{ color: countDownNum <= 0 ? 'rgb(220, 0, 78)' : '' }}
      >
        {countDownStart ? countDownNum : ''}
      </Typography>
    </Box>
  );
};

CountDown.propTypes = {
  countDownStart: PropTypes.bool,
};

export default CountDown;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@material-ui/core';
import Wait from '../Wait';
import WaitLoding from '../WaitLoding';
import QuestionTitle from '../QuestionTitle';
import FinishTitle from './FinishTitle';
import AnswerSelect from './AnswerSelect';
import AnswerText from './AnswerText';
import Finish from './Finish';

import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';

// 動作モード
// ロード中、準備中、回答中、完了
const modeLoding = 'modeLoding';
const modeWait = 'modeWait';
const modeAnswer = 'modeAnswer';
const modeFinish = 'modeFinish';
const modeEdit = 'modeEdit';

const NonRealtime = (props) => {
  const [mode, setMode] = useState(modeLoding);
  const [index, setIndex] = useState(0);
  const [questions, setQuestions] = useState([]);

  // --------------------------------------------------
  // 画面表示
  // --------------------------------------------------
  useEffect(() => {
    initialize();

    return function cleanup() {
      Log.trace('Student.MainView Enquete.NonRealtime', 'cleanup');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // オンデマンドアンケートの初期化
  // --------------------------------------------------
  const initialize = async () => {
    const array = await Model.getQuestionList(props.lesson.id);
    array.sort(function (a, b) {
      return a.order < b.order ? -1 : 1;
    });
    setQuestions(array);
    Log.trace('Student.MainView Enquete.NonRealtime', 'initialize questions.length: ' + array.length);
    if (array.length === 0) {
      Log.trace('Student.MainView Enquete.NonRealtime', 'initialize waiting mode');
      setMode(modeWait);
    }
    else {
      const obj = await Model.getAnswerByLessonId(props.lesson.id);
      let count = 0;
      Object.values(obj).forEach(function (value) {
        Object.keys(value).forEach(function (key) {
          if (key === props.student.oneTimeId) {
            count++;
          }
        });
      });
      if (count === array.length) {
        Log.trace('Student.MainView Enquete.NonRealtime', 'initialize answer.length: ' + count);
        Log.trace('Student.MainView Enquete.NonRealtime', 'initialize finish mode');
        setMode(modeFinish);
      }
      else {
        Log.trace('Student.MainView Enquete.NonRealtime', 'initialize answer mode');
        setMode(modeAnswer);
      }
    }
  }

  // --------------------------------------------------
  // 次の設問
  // --------------------------------------------------
  const handleNextButton = () => {
    if (mode === modeEdit) {
      Log.trace('Student.MainView Enquete.NonRealtime.handleNextButton', 'current index: ' + index + ' -> finish mode');
      setMode(modeFinish);
      setIndex(0);
    }
    else if (index + 1 < questions.length) {
      Log.trace('Student.MainView Enquete.NonRealtime.handleNextButton', 'current index: ' + index + ' -> answer mode');
      setIndex(prevIndex => prevIndex + 1);
    }
    else {
      Log.trace('Student.MainView Enquete.NonRealtime.handleNextButton', 'current index: ' + index + ' -> finish mode');
      setMode(modeFinish);
      setIndex(0);
    }
    scrollToTop();
  }

  // --------------------------------------------------
  // 前の設問
  // --------------------------------------------------
  const handleBackButton = () => {
    if (mode === modeEdit) {
      Log.trace('Student.MainView Enquete.NonRealtime.handleBackButton', 'current index: ' + index + ' -> finish mode');
      setMode(modeFinish);
      setIndex(0);
    }
    else if (index > 0) {
      Log.trace('Student.MainView Enquete.NonRealtime.handleBackButton', 'current index: ' + index + ' -> answer mode');
      setIndex(prevIndex => prevIndex - 1);
    }
    scrollToTop();
  }

  // --------------------------------------------------
  // 編集
  // --------------------------------------------------
  const handleEditButton = (order) => {
    setMode(modeEdit);
    setIndex(order);
    scrollToTop();
  }

  // --------------------------------------------------
  // スクロール位置補正
  // --------------------------------------------------
  const scrollToTop = () => {
    const ref = document.getElementById('__header');
    ref.scrollIntoView(true, {
      behavior: 'auto',
      block: 'start',
    });
  }

  // --------------------------------------------------
  // 設問タイプが選択型かどうかの判定
  // --------------------------------------------------
  const isSelectType = (type) => {
    let isSelect = false;
    switch (type) {
      case Constants.questionTypeRadio:
      case Constants.questionTypeSelect:
      case Constants.questionTypeCheckBox:
        isSelect = true;
        break;
      default:
        break;
    }
    return isSelect;
  }

  return (
    <Box mt={1}>
      <Grid
        container
        spacing={2}
      >
        {/* ======================================== */}
        {/* タイトル */}
        {/* ======================================== */}
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
        >
          {mode === modeLoding ? <WaitLoding /> :
            mode === modeWait ? <Wait reload={initialize} /> :
              mode === modeAnswer ? <QuestionTitle question={questions[index]} /> :
                mode === modeEdit ? <QuestionTitle question={questions[index]} /> :
                  mode === modeFinish ? <FinishTitle /> : ''}
        </Grid>

        {/* ======================================== */}
        {/* 回答 */}
        {/* ======================================== */}
        {mode === modeAnswer || mode === modeEdit ? (
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {isSelectType(questions[index].type) === true ? (
              <AnswerSelect
                key={questions[index].id}
                lesson={props.lesson}
                question={questions[index]}
                student={props.student}
                index={index}
                handleBackButton={handleBackButton}
                handleNextButton={handleNextButton}
                mode={mode}
                questionSize={questions.length}
              />
            ) : (
              <AnswerText
                key={questions[index].id}
                lesson={props.lesson}
                question={questions[index]}
                student={props.student}
                index={index}
                handleBackButton={handleBackButton}
                handleNextButton={handleNextButton}
                mode={mode}
                questionSize={questions.length}
              />
            )}
          </Grid>
        ) : ''
        }

        {/* ======================================== */}
        {/* 完了 */}
        {/* ======================================== */}
        {mode === modeFinish ? (
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Finish
              lesson={props.lesson}
              student={props.student}
              questions={questions}
              handleEditButton={handleEditButton}
            />
          </Grid>
        ) : ''
        }
      </Grid>
    </Box >
  );
};

NonRealtime.propTypes = {
  lesson: PropTypes.object,
  student: PropTypes.object
};

export default NonRealtime;
import firebase from "firebase/app";
import "firebase/database";
import Constants from 'src/utils/Constants';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';

// --------------------------------------------------
// アカウント登録処理
// --------------------------------------------------
const addAccount = async (id, data) => {
  Log.trace('Model', 'addAccount called');
  await firebase.database().ref('account/' + id).set(data);
};

// --------------------------------------------------
// アカウント更新処理
// --------------------------------------------------
const updateAccount = async (id, data) => {
  Log.trace('Model', 'updateAccount called');
  await firebase.database().ref('account/' + id).update(data);
};

// --------------------------------------------------
// アカウント削除処理
// --------------------------------------------------
const deleteAccount = async (id) => {
  Log.trace('Model', 'deleteAccount called');
  await firebase.database().ref('account/' + id).remove();
};

// --------------------------------------------------
// アカウント取得処理
// --------------------------------------------------
const getAccount = async (mail) => {
  Log.trace('Model', 'getAccount called');
  const array = [];
  const snapshot = await firebase.database().ref('account').orderByChild("mail").equalTo(mail).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return array;
  }
  Object.keys(obj).forEach(function (key) {
    array.push({
      id: key,
      fname: obj[key].fname,
      lname: obj[key].lname,
      organization: obj[key].organization,
      mail: obj[key].mail,
      password: obj[key].password,
      system: obj[key].system,
    })
  });
  return array;
};

// --------------------------------------------------
// アカウント取得処理
// --------------------------------------------------
const getAccountByKey = async (uid) => {
  Log.trace('Model.getAccountByKey', 'uid :' + uid);
  const snapshot = await firebase.database().ref('account/' + uid).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// アカウント一覧取得処理
// --------------------------------------------------
const getAccountList = async () => {
  Log.trace('Model', 'getAccountList called');
  const array = [];
  const snapshot = await firebase.database().ref('account').orderByChild("addDate").once("value");
  const obj = snapshot.val();
  if (!obj) {
    return array;
  }
  Object.keys(obj).forEach(function (key) {
    array.push({
      id: key,
      fname: obj[key].fname,
      lname: obj[key].lname,
      organization: obj[key].organization,
      mail: obj[key].mail,
      password: Constants.passwordDummy,
      system: obj[key].system,
      addDate: Util.getDayFormatYMDHm(obj[key].addDate),
    })
  });
  return array;
};

// --------------------------------------------------
// 講義登録処理
// --------------------------------------------------
const addLesson = async (data) => {
  Log.trace('Model', 'addLesson called');
  const lessonId = Util.getUid();
  firebase.database().ref('lesson/' + lessonId).set(data);

  const processData = {
    questionId: Constants.processInitialQuestionId,
    mode: Constants.processInitialMode,
    updateDate: data.addDate,
    countdown: false
  };
  firebase.database().ref('process/' + lessonId).set(processData);
  return lessonId;
};

// --------------------------------------------------
// 講義更新処理
// --------------------------------------------------
const updateLesson = async (id, data) => {
  Log.trace('Model', 'updateLesson called');
  firebase.database().ref('lesson/' + id).update(data);
};

// --------------------------------------------------
// 講義削除処理
// --------------------------------------------------
const deleteLesson = async (id) => {
  Log.trace('Model', 'deleteLesson called');
  firebase.database().ref('lesson/' + id).remove();
  firebase.database().ref('question/' + id).remove();
  firebase.database().ref('process/' + id).remove();
  firebase.database().ref('answer/' + id).remove();
  firebase.database().ref('view/' + id).remove();
  firebase.database().ref('faq/' + id).remove();
  firebase.database().ref('log/' + id).remove();
};

// --------------------------------------------------
// 講義一覧取得処理
// --------------------------------------------------
const getLessonList = async (accountId) => {
  Log.trace('Model', 'getLessonList called');
  const array = [];
  const snapshot = await firebase.database().ref('lesson').orderByChild("accountId").equalTo(accountId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return array;
  }
  Object.keys(obj).forEach(function (key) {
    array.push({
      id: key,
      name: obj[key].name,
      code: Util.formatLessonCode(obj[key].code),
      accessKey: obj[key].accessKey,
      date: obj[key].date,
      startDate: obj[key].startDate ? obj[key].startDate : obj[key].date,
      endDate: obj[key].endDate ? obj[key].endDate : obj[key].date,
      addDate: obj[key].addDate,
      updateDate: obj[key].updateDate,
      accountId: obj[key].accountId,
      accountName: obj[key].accountName,
      accountOrganization: obj[key].accountOrganization,
      accessKeyRequired: obj[key].accessKeyRequired,
      profileRequired: obj[key].profileRequired,
      session: obj[key].session ? obj[key].session : {},
      voteMode: obj[key].voteMode,
      useConfirm: typeof obj[key].useConfirm !== "undefined" ? obj[key].useConfirm : false,
      useFaQ: obj[key].useFaQ,
      useScene: obj[key].useScene,
      studentIdRequired: obj[key].studentIdRequired,
      isOndemandShare: typeof obj[key].isOndemandShare !== "undefined" ? obj[key].isOndemandShare : true,
      isOndemandEdit: typeof obj[key].isOndemandEdit !== "undefined" ? obj[key].isOndemandEdit : false,
      ondemandEntryStatus : obj[key].ondemandEntryStatus ? obj[key].ondemandEntryStatus : Constants.ondemandEntryStatusValid,
      isOndemandEntryStatusInvalidMessageEdit : typeof obj[key].isOndemandEntryStatusInvalidMessageEdit !== "undefined" ? obj[key].isOndemandEntryStatusInvalidMessageEdit : false,
      ondemandEntryStatusInvalidMessage : obj[key].ondemandEntryStatusInvalidMessage ? obj[key].ondemandEntryStatusInvalidMessage : Constants.ondemandEntryStatusInvalidDefaultMessage,
      isOndemandConfirmMessageOpen : typeof obj[key].isOndemandConfirmMessageOpen !== "undefined" ? obj[key].isOndemandConfirmMessageOpen : false,
      ondemandConfirmMessage : obj[key].ondemandConfirmMessage ? obj[key].ondemandConfirmMessage : Constants.ondemandConfirmDefaultMessage,
    })
  });
  return array;
};

// --------------------------------------------------
// 講義情報取得処理
// --------------------------------------------------
const getLessonByCode = async (code) => {
  Log.trace('Model', 'getLessonByCode called');
  const array = [];
  const snapshot = await firebase.database().ref('lesson').orderByChild("code").equalTo(code).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return array;
  }
  Object.keys(obj).forEach(function (key) {
    array.push({
      id: key,
      name: obj[key].name,
      code: Util.formatLessonCode(obj[key].code),
      accessKey: obj[key].accessKey,
      date: obj[key].date,
      startDate: obj[key].startDate ? obj[key].startDate : obj[key].date,
      endDate: obj[key].endDate ? obj[key].endDate : obj[key].date,
      addDate: obj[key].addDate,
      updateDate: obj[key].updateDate,
      accountId: obj[key].accountId,
      accountName: obj[key].accountName,
      accountOrganization: obj[key].accountOrganization,
      accessKeyRequired: obj[key].accessKeyRequired,
      profileRequired: obj[key].profileRequired,
      session: obj[key].session,
      voteMode: obj[key].voteMode,
      useConfirm: typeof obj[key].useConfirm !== "undefined" ? obj[key].useConfirm : false,
      useFaQ: obj[key].useFaQ,
      useScene: obj[key].useScene,
      studentIdRequired: obj[key].studentIdRequired,
      isOndemandShare: typeof obj[key].isOndemandShare !== "undefined" ? obj[key].isOndemandShare : true,
      isOndemandEdit: typeof obj[key].isOndemandEdit !== "undefined" ? obj[key].isOndemandEdit : false,
      ondemandEntryStatus : obj[key].ondemandEntryStatus ? obj[key].ondemandEntryStatus : Constants.ondemandEntryStatusValid,
      isOndemandEntryStatusInvalidMessageEdit : typeof obj[key].isOndemandEntryStatusInvalidMessageEdit !== "undefined" ? obj[key].isOndemandEntryStatusInvalidMessageEdit : false,
      ondemandEntryStatusInvalidMessage : obj[key].ondemandEntryStatusInvalidMessage ? obj[key].ondemandEntryStatusInvalidMessage : Constants.ondemandEntryStatusInvalidDefaultMessage,
      isOndemandConfirmMessageOpen : typeof obj[key].isOndemandConfirmMessageOpen !== "undefined" ? obj[key].isOndemandConfirmMessageOpen : false,
      ondemandConfirmMessage : obj[key].ondemandConfirmMessage ? obj[key].ondemandConfirmMessage : Constants.ondemandConfirmDefaultMessage,
    })
  });
  return array;
};

// --------------------------------------------------
// 講義情報取得処理
// --------------------------------------------------
const getLessonByKey = async (lessonId) => {
  Log.trace('Model', 'getLessonByKey called');
  const snapshot = await firebase.database().ref('lesson/' + lessonId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  obj.id = lessonId;
  obj.code = Util.formatLessonCode(obj.code);
  obj.startDate = obj.startDate ? obj.startDate : obj.date;
  obj.endDate = obj.endDate ? obj.endDate : obj.date;
  obj.useConfirm = typeof obj.useConfirm !== "undefined" ? obj.useConfirm : false;
  obj.isOndemandShare = typeof obj.isOndemandShare !== "undefined" ? obj.isOndemandShare : true;
  obj.isOndemandEdit = typeof obj.isOndemandEdit !== "undefined" ? obj.isOndemandEdit : false;
  obj.ondemandEntryStatus = obj.ondemandEntryStatus ? obj.ondemandEntryStatus : Constants.ondemandEntryStatusValid;
  obj.isOndemandEntryStatusInvalidMessageEdit = typeof obj.isOndemandEntryStatusInvalidMessageEdit !== "undefined" ? obj.isOndemandEntryStatusInvalidMessageEdit : false;
  obj.ondemandEntryStatusInvalidMessage = obj.ondemandEntryStatusInvalidMessage ? obj.ondemandEntryStatusInvalidMessage : Constants.ondemandEntryStatusInvalidDefaultMessage;
  obj.isOndemandConfirmMessageOpen = typeof obj.isOndemandConfirmMessageOpen !== "undefined" ? obj.isOndemandConfirmMessageOpen : false;
  obj.ondemandConfirmMessage = obj.ondemandConfirmMessage ? obj.ondemandConfirmMessage : Constants.ondemandConfirmDefaultMessage;
  return obj;
};

// --------------------------------------------------
// 講義コピー登録処理
// --------------------------------------------------
const copyLesson = async (sourceLessonId, newLesson) => {
  Log.trace('Model.copyLesson', 'sourceLessonId : ' + sourceLessonId);
  // 講義データ
  const targetLessonId = await addLesson(newLesson);
  // コピー元の設問データ取得
  const snapshot = await firebase.database().ref('question/' + sourceLessonId).once("value");
  const value = snapshot.val();
  if (value) {
    // 設問データ登録
    await firebase.database().ref('question/' + targetLessonId + '/').set(value);
  }
};

// --------------------------------------------------
// 講義状況の取得処理
// --------------------------------------------------
const getLessonProcess = async (lessonId) => {
  Log.trace('Model', 'getLessonProcess called');
  const snapshot = await firebase.database().ref('process/' + lessonId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// 講義状況の更新処理
// --------------------------------------------------
const updateLessonProcess = async (lessonId, process) => {
  Log.trace('Model', 'updateLessonProcess called');
  firebase.database().ref('process/' + lessonId).update(process);
};

// --------------------------------------------------
// 講義状況の監視処理
// --------------------------------------------------
const watchLessonProcess = async (lessonId, callback) => {
  Log.trace('Model.watchLessonProcess', 'Attach child_changed to process/' + lessonId);
  await firebase.database().ref('process/' + lessonId).on("child_changed", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
};

// --------------------------------------------------
// 講義状況の監視処理
// --------------------------------------------------
const watchChancelLessonProcess = async (lessonId) => {
  Log.trace('Model.watchChancelLessonProcess', 'Detach child_changed from process/' + lessonId);
  await firebase.database().ref('process/' + lessonId).off();
};

// --------------------------------------------------
// 設問登録処理
// --------------------------------------------------
const addQuestion = async (lessonId, data) => {
  Log.trace('Model', 'addQuestion called');
  await firebase.database().ref('question/' + lessonId + '/' + Util.getUid()).set(data);
};

// --------------------------------------------------
// 設問登録処理
// --------------------------------------------------
const setQuestion = async (lessonId, data) => {
  Log.trace('Model', 'setQuestion called');
  await firebase.database().ref('question/' + lessonId + '/').set(data);
};

// --------------------------------------------------
// 設問更新処理
// --------------------------------------------------
const updateQuestion = async (lessonId, questionId, data) => {
  Log.trace('Model', 'updateQuestion called');
  await firebase.database().ref('question/' + lessonId + '/' + questionId).update(data);
};

// --------------------------------------------------
// 設問削除処理
// --------------------------------------------------
const deleteQuestion = async (lessonId, questionId) => {
  Log.trace('Model', 'deleteQuestion called');
  await firebase.database().ref('question/' + lessonId + '/' + questionId).remove();
  await firebase.database().ref('answer/' + lessonId + '/' + questionId).remove();
};

// --------------------------------------------------
// 設問一覧取得処理
// --------------------------------------------------
const getQuestionList = async (lessonId) => {
  Log.trace('Model', 'getQuestionList called');
  const array = [];
  const snapshot = await firebase.database().ref('question/' + lessonId).orderByChild("order").once("value");
  const obj = snapshot.val();
  if (!obj) {
    return array;
  }
  Object.keys(obj).forEach(function (key) {
    array.push({
      id: key,
      order: obj[key].order,
      title: obj[key].title,
      description: obj[key].description,
      type: obj[key].type,
      initMode: obj[key].initMode ? obj[key].initMode : Constants.processModeRealtime,
      options: obj[key].options ? obj[key].options : [],
      isRequired: obj[key].isRequired ? obj[key].isRequired : Constants.questionRequired,
    })
  });
  return array;
};

// --------------------------------------------------
// 設問情報の取得処理
// --------------------------------------------------
const getQuestionByKey = async (lessonId, questionId) => {
  Log.trace('Model', 'getQuestionByKey called');
  const snapshot = await firebase.database().ref('question/' + lessonId + '/' + questionId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// セッション情報登録処理
// --------------------------------------------------
const addSession = async (lessonId, data) => {
  Log.trace('Model', 'addSession called');
  await firebase.database().ref('lesson/' + lessonId + '/session/' + data.oneTimeId).set(data);
};

// --------------------------------------------------
// セッション情報の取得処理
// --------------------------------------------------
const getSession = async (lessonId) => {
  Log.trace('Model', 'getSession called');
  const snapshot = await firebase.database().ref('lesson/' + lessonId + '/session/').once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// セッション情報の削除処理
// --------------------------------------------------
const deleteSession = async (lessonId) => {
  Log.trace('Model', 'deleteSession called');
  await firebase.database().ref('lesson/' + lessonId + '/session').remove();
  await firebase.database().ref('answer/' + lessonId).remove();
  await firebase.database().ref('faq/' + lessonId).remove();
  await firebase.database().ref('log/' + lessonId).remove();
};

// --------------------------------------------------
// 回答情報登録処理
// --------------------------------------------------
const addAnswer = async (lessonId, questionId, oneTimeId, data) => {
  Log.trace('Model', 'addAnswer called');
  await firebase.database().ref('answer/' + lessonId + '/' + questionId + '/' + oneTimeId).set(data);
};

// --------------------------------------------------
// 回答情報登録処理
// --------------------------------------------------
const setAnswer = async (lessonId, data) => {
  Log.trace('Model', 'setAnswer called');
  await firebase.database().ref('answer/' + lessonId).set(data);
};

// --------------------------------------------------
// 回答情報登録処理
// --------------------------------------------------
const addAnswerPush = async (lessonId, questionId, oneTimeId, data) => {
  Log.trace('Model', 'addAnswerPush called');
  await firebase.database().ref('answer/' + lessonId + '/' + questionId + '/' + oneTimeId + '/answers').push(data);
};

// --------------------------------------------------
// 回答情報更新処理
// --------------------------------------------------
const updateAnswer = async (lessonId, questionId, oneTimeId, answerId, data) => {
  Log.trace('Model', 'updateAnswer called');
  await firebase.database().ref('answer/' + lessonId + '/' + questionId + '/' + oneTimeId + '/answers/' + answerId).update(data);
};

// --------------------------------------------------
// 回答情報「いいね」追加処理
// --------------------------------------------------
const addAnswerGood = async (lessonId, questionId, userId, answerId, oneTimeId, data) => {
  Log.trace('Model', 'addAnswer called');
  await firebase.database().ref('answer/' + lessonId + '/' + questionId + '/' + userId + '/answers/' + answerId + '/good/' + oneTimeId).set(data);
};

// --------------------------------------------------
// 回答情報「いいね」追加処理
// --------------------------------------------------
const deleteAnswerGood = async (lessonId, questionId, userId, answerId, oneTimeId) => {
  Log.trace('Model', 'deleteAnswerGood called');
  await firebase.database().ref('answer/' + lessonId + '/' + questionId + '/' + userId + '/answers/' + answerId + '/good/' + oneTimeId).remove();
};

// --------------------------------------------------
// 回答情報削除処理
// --------------------------------------------------
const deleteAnswer = async (lessonId, questionId, oneTimeId) => {
  Log.trace('Model', 'deleteAnswer called');
  await firebase.database().ref('answer/' + lessonId + '/' + questionId + '/' + oneTimeId).remove();
};

// --------------------------------------------------
// 回答情報の取得処理
// --------------------------------------------------
const getAnswerByKey = async (lessonId, questionId, oneTimeId) => {
  Log.trace('Model', 'getAnswerByKey called');
  const snapshot = await firebase.database().ref('answer/' + lessonId + '/' + questionId + '/' + oneTimeId + '/answers').once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// 回答情報の取得処理
// --------------------------------------------------
const getAnswerByQuestionId = async (lessonId, questionId) => {
  Log.trace('Model', 'getAnswerByQuestionId called');
  const snapshot = await firebase.database().ref('answer/' + lessonId + '/' + questionId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// 回答情報の取得処理（講義単位）
// --------------------------------------------------
const getAnswerByLessonId = async (lessonId) => {
  Log.trace('Model', 'getAnswerByLessonId called');
  const snapshot = await firebase.database().ref('answer/' + lessonId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// 回答情報の監視処理（追加と削除）
// --------------------------------------------------
const watchAnswerAR = async (lessonId, questionId, callback) => {
  Log.trace('Model.watchAnswerAR', 'Attach child_added, child_removed to answer/' + lessonId + '/' + questionId);
  const now = Util.getCurrentDayTimeSec();
  await firebase.database().ref('answer/' + lessonId + '/' + questionId).orderByChild('timestamp').startAt(now).on("child_added", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
  await firebase.database().ref('answer/' + lessonId + '/' + questionId).orderByChild('timestamp').on("child_removed", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
};

// --------------------------------------------------
// 回答情報の監視処理（追加と変更）
// --------------------------------------------------
const watchAnswerAC = async (lessonId, questionId, callback) => {
  Log.trace('Model.watchAnswerAC', 'Attach child_added, child_changed to answer/' + lessonId + '/' + questionId);
  const now = Util.getCurrentDayTimeSec();
  await firebase.database().ref('answer/' + lessonId + '/' + questionId).orderByChild('timestamp').startAt(now).on("child_added", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
  await firebase.database().ref('answer/' + lessonId + '/' + questionId).orderByChild('timestamp').on("child_changed", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
};

// --------------------------------------------------
// 回答情報の監視処理の削除（追加と削除）
// --------------------------------------------------
const watchCancelAnswer = async (lessonId, questionId, callback) => {
  Log.trace('Model.watchCancelAnswer', 'Detach child_added, child_removed, child_changed from answer/' + lessonId + '/' + questionId);
  await firebase.database().ref('answer/' + lessonId + '/' + questionId).off();
};

// --------------------------------------------------
// セッション情報の監視処理
// --------------------------------------------------
const watchSession = async (lessonId, callback) => {
  Log.trace('Model.watchSession', 'Attach child_added to lesson/' + lessonId + '/session');
  const now = Util.getCurrentDayTimeSec();
  await firebase.database().ref('lesson/' + lessonId + '/session').orderByChild('accessDate').startAt(now).on("child_added", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
};

// --------------------------------------------------
// セッション情報の監視処理の削除
// --------------------------------------------------
const watchCancelSession = async (lessonId) => {
  Log.trace('Model.watchCancelSession', 'Detach child_added from lesson/' + lessonId + '/session');
  await firebase.database().ref('lesson/' + lessonId + '/session').off();
};

// --------------------------------------------------
// パラメータ更新処理
// --------------------------------------------------
const updateParameter = async (data) => {
  Log.trace('Model', 'updateParameter called');
  await firebase.database().ref('parameter/').update(data);
};

// --------------------------------------------------
// パラメータ取得処理
// --------------------------------------------------
const getParameters = async () => {
  Log.trace('Model', 'getParameters called');
  const snapshot = await firebase.database().ref('parameter/').once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// パラメータ取得処理
// --------------------------------------------------
const getParameterbyKey = async (key) => {
  Log.trace('Model', 'getParameterbyKey called');
  const snapshot = await firebase.database().ref('parameter/' + key).once("value");
  const value = snapshot.val();
  if (!value) {
    return '';
  }
  return value;
};

// --------------------------------------------------
// 回答画面表示情報の追加
// --------------------------------------------------
const addView = async (lessonId, questionId, data) => {
  Log.trace('Model', 'addView called');
  await firebase.database().ref('view/' + lessonId + '/' + questionId + '/' + data.oneTimeId).set(data);
};

// --------------------------------------------------
// 回答画面表示情報の更新
// --------------------------------------------------
const updateView = async (lessonId, questionId, data) => {
  Log.trace('Model', 'updateView called');
  await firebase.database().ref('view/' + lessonId + '/' + questionId + '/' + data.oneTimeId).update(data);
};

// --------------------------------------------------
// 回答画面表示情報の取得
// --------------------------------------------------
const getView = async (lessonId, questionId) => {
  Log.trace('Model', 'getView called');
  const snapshot = await firebase.database().ref('view/' + lessonId + '/' + questionId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// 回答画面表示情報の監視処理
// --------------------------------------------------
const watchView = async (lessonId, questionId, callback) => {
  Log.trace('Model.watchView', 'Attach child_added to view/' + lessonId + '/' + questionId);
  await firebase.database().ref('view/' + lessonId + '/' + questionId).on("child_added", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
  await firebase.database().ref('view/' + lessonId + '/' + questionId).on("child_changed", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
};

// --------------------------------------------------
// 回答画面表示情報の監視処理の削除
// --------------------------------------------------
const watchCancelView = async (lessonId, questionId) => {
  Log.trace('Model.watchCancelView', 'Detach child_added from view/' + lessonId + '/' + questionId);
  await firebase.database().ref('view/' + lessonId + '/' + questionId).off();
};

// --------------------------------------------------
// 回答画面表示情報の削除（設問単位）
// --------------------------------------------------
const deleteViewByQuestionId = async (lessonId, questionId) => {
  Log.trace('Model', 'deleteViewByQuestionId called');
  await firebase.database().ref('view/' + lessonId + '/' + questionId).remove();
};

// --------------------------------------------------
// 回答画面表示情報の削除（講義単位）
// --------------------------------------------------
const deleteViewByLessonId = async (lessonId) => {
  Log.trace('Model', 'deleteViewByLessonId called');
  await firebase.database().ref('view/' + lessonId).remove();
};

// --------------------------------------------------
// 質問情報登録処理
// --------------------------------------------------
const addFaq = async (lessonId, data) => {
  Log.trace('Model', 'addFaq called');
  await firebase.database().ref('faq/' + lessonId).push(data);
};

// --------------------------------------------------
// 質問情報更新処理
// --------------------------------------------------
const updateFaq = async (lessonId, faqId, data) => {
  Log.trace('Model', 'updateFaq called');
  await firebase.database().ref('faq/' + lessonId + '/' + faqId).update(data);
};

// --------------------------------------------------
// 質問情報の取得処理
// --------------------------------------------------
const getFaqByLessonId = async (lessonId) => {
  Log.trace('Model', 'getFaqByLessonId called');
  const snapshot = await firebase.database().ref('faq/' + lessonId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// 質問情報「いいね」追加処理
// --------------------------------------------------
const addFaqGood = async (lessonId, faqId, oneTimeId, data) => {
  Log.trace('Model', 'addFaqGood called');
  await firebase.database().ref('faq/' + lessonId + '/' + faqId + '/good/' + oneTimeId).set(data);
};

// --------------------------------------------------
// 質問情報「いいね」削除処理
// --------------------------------------------------
const deleteFaqGood = async (lessonId, faqId, oneTimeId) => {
  Log.trace('Model', 'deleteFaqGood called');
  await firebase.database().ref('faq/' + lessonId + '/' + faqId + '/good/' + oneTimeId).remove();
};

// --------------------------------------------------
// 質問情報の監視処理（追加と変更）
// --------------------------------------------------
const watchFaqAC = async (lessonId, callback) => {
  Log.trace('Model.watchFaqAC', 'Attach child_added, child_changed to faq/' + lessonId);
  const now = Util.getCurrentDayTimeSec();
  await firebase.database().ref('faq/' + lessonId).orderByChild('timestamp').startAt(now).on("child_added", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
  await firebase.database().ref('faq/' + lessonId).orderByChild('timestamp').on("child_changed", function (snapshot) {
    callback(snapshot.key, snapshot.val());
  });
};

// --------------------------------------------------
// 回答情報の監視処理の削除（追加と削除）
// --------------------------------------------------
const watchCancelFaq = async (lessonId, questionId, callback) => {
  Log.trace('Model.watchCancelFaq', 'Detach child_added, child_removed, child_changed from faq/' + lessonId);
  await firebase.database().ref('faq/' + lessonId).off();
};

// --------------------------------------------------
// 質問情報登録処理
// --------------------------------------------------
const setFaq = async (lessonId, data) => {
  Log.trace('Model', 'setFaq called');
  await firebase.database().ref('faq/' + lessonId).set(data);
};

// --------------------------------------------------
// アクティブ度計測ログ登録処理
// --------------------------------------------------
const addLog = (lessonId, oneTimeId, operation, questionId) => {
  Log.trace('Model', 'addLog called');
  const data = {
    oneTimeId: oneTimeId,
    timestamp: Util.getCurrentDayTimeSec(),
    operation: operation
  };
  if (questionId) {
    data.questionId = questionId;
  }
  firebase.database().ref('log/' + lessonId).push(data);
};

// --------------------------------------------------
// アクティブ度計測ログの取得処理
// --------------------------------------------------
const getLog = async (lessonId) => {
  Log.trace('Model', 'getLog called');
  const snapshot = await firebase.database().ref('log/' + lessonId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// アクティブ度計測ログ登録処理
// --------------------------------------------------
const setLog = async (lessonId, data) => {
  Log.trace('Model', 'seLog called');
  await firebase.database().ref('log/' + lessonId).set(data);
};

// --------------------------------------------------
// アクティブ度計測ログの取得処理(回答前の確認)
// --------------------------------------------------
const getLogConfirm = async (lessonId) => {
  Log.trace('Model', 'getLogConfirm called');
  const snapshot = await firebase.database().ref('log/' + lessonId).orderByChild("operation").equalTo(Constants.operationTypeConfirm).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return {};
  }
  return obj;
};

// --------------------------------------------------
// アーカイブ登録処理
// --------------------------------------------------
const addArchive = async (id, data) => {
  Log.trace('Model', 'addArchive called');
  await firebase.database().ref('archive/' + id).set(data);
  await firebase.database().ref('lesson/' + id).remove();
};

// --------------------------------------------------
// アーカイブ復帰処理
// --------------------------------------------------
const returnArchive = async (id, data) => {
  Log.trace('Model', 'returnArchive called');
  await firebase.database().ref('lesson/' + id).set(data);
  await firebase.database().ref('archive/' + id).remove();
};

// --------------------------------------------------
// アーカイブ削除処理
// --------------------------------------------------
const deleteArchive = async (id) => {
  Log.trace('Model', 'deleteArchive called');
  firebase.database().ref('archive/' + id).remove();
  firebase.database().ref('question/' + id).remove();
  firebase.database().ref('process/' + id).remove();
  firebase.database().ref('answer/' + id).remove();
  firebase.database().ref('view/' + id).remove();
  firebase.database().ref('faq/' + id).remove();
  firebase.database().ref('log/' + id).remove();
};

// --------------------------------------------------
// アーカイブ一覧取得処理
// --------------------------------------------------
const getArchiveList = async (accountId) => {
  Log.trace('Model', 'getArchiveList called');
  const array = [];
  const snapshot = await firebase.database().ref('archive').orderByChild("accountId").equalTo(accountId).once("value");
  const obj = snapshot.val();
  if (!obj) {
    return array;
  }
  Object.keys(obj).forEach(function (key) {
    array.push({
      id: key,
      name: obj[key].name,
      code: obj[key].code,
      accessKey: obj[key].accessKey,
      date: obj[key].date,
      startDate: obj[key].startDate ? obj[key].startDate : obj[key].date,
      endDate: obj[key].endDate ? obj[key].endDate : obj[key].date,
      addDate: obj[key].addDate,
      updateDate: obj[key].updateDate,
      accountId: obj[key].accountId,
      accountName: obj[key].accountName,
      accountOrganization: obj[key].accountOrganization,
      accessKeyRequired: obj[key].accessKeyRequired,
      profileRequired: obj[key].profileRequired,
      session: obj[key].session ? obj[key].session : {},
      voteMode: obj[key].voteMode,
      useConfirm: obj[key].useConfirm ? obj[key].useConfirm : false,
      useFaQ: obj[key].useFaQ,
      useScene: obj[key].useScene,
      studentIdRequired: obj[key].studentIdRequired,
    })
  });
  return array;
};

const Model = {
  addAccount,
  updateAccount,
  deleteAccount,
  getAccount,
  getAccountByKey,
  getAccountList,
  addLesson,
  updateLesson,
  deleteLesson,
  getLessonList,
  getLessonByCode,
  getLessonByKey,
  copyLesson,
  getLessonProcess,
  updateLessonProcess,
  watchLessonProcess,
  watchChancelLessonProcess,
  addQuestion,
  setQuestion,
  updateQuestion,
  deleteQuestion,
  getQuestionList,
  getQuestionByKey,
  addSession,
  getSession,
  deleteSession,
  addAnswer,
  setAnswer,
  addAnswerPush,
  updateAnswer,
  addAnswerGood,
  deleteAnswerGood,
  deleteAnswer,
  getAnswerByKey,
  getAnswerByQuestionId,
  getAnswerByLessonId,
  watchAnswerAR,
  watchCancelAnswer,
  watchAnswerAC,
  watchSession,
  watchCancelSession,
  updateParameter,
  getParameters,
  getParameterbyKey,
  addView,
  updateView,
  getView,
  watchView,
  watchCancelView,
  deleteViewByQuestionId,
  deleteViewByLessonId,
  addFaq,
  updateFaq,
  getFaqByLessonId,
  addFaqGood,
  deleteFaqGood,
  watchFaqAC,
  watchCancelFaq,
  setFaq,
  addLog,
  getLog,
  setLog,
  getLogConfirm,
  addArchive,
  returnArchive,
  deleteArchive,
  getArchiveList,
}

export default Model;


// https://firebase.google.com/docs/database/web/read-and-write?hl=ja
// https://firebase.google.com/docs/database/admin/retrieve-data?hl=ja
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import Log from 'src/utils/Log';
import Util from 'src/utils/Util';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  result: {
    marginTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },
  dataPanel: {
    marginTop: 30
  },
  dataPanelText: {
    fontSize: 16,
  },
  dataPanelTextEndline: {
    fontSize: 16,
    borderWidth: 0
  },
}));


const DataPanel = (props) => {
  const classes = useStyles();
  const [countdownCount, setCountdownCount] = useState();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('QuestionReviewDialogView.DataPanel', 'useEffect()');
    let count = 0;
    Object.values(props.question.answer).forEach(function (value) {
      if (value.countdown) {
        count++;
      }
    });
    setCountdownCount(count);

    return function cleanup() {
      Log.trace('QuestionReviewDialogView.DataPanel', 'useEffect() cleanup()');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper variant="outlined" className={classes.result}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.dataPanelText} style={{ width: 150 }}>回答数</TableCell>
            <TableCell className={classes.dataPanelText} style={{ width: 100 }}>{props.question.answerCount}</TableCell>
            <TableCell className={classes.dataPanelText} style={{ width: 150 }}>回答率</TableCell>
            <TableCell className={classes.dataPanelText} >{Util.calcPercentage(props.question.answerCount, props.studentCount)}%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.dataPanelTextEndline} style={{ width: 150 }}>受講者数</TableCell>
            <TableCell className={classes.dataPanelTextEndline} >{props.studentCount}</TableCell>
            <TableCell className={classes.dataPanelTextEndline} >駆け込み回答数</TableCell>
            <TableCell className={classes.dataPanelTextEndline} >{countdownCount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

DataPanel.propTypes = {
  question: PropTypes.object,
  studentCount: PropTypes.number,
};


export default DataPanel;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Radio,
  Typography,
} from '@material-ui/core';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  answerOption: {
    borderRadius: 5,
    marginBottom: 12,
    padding: 5,
  },
}));

const FormRadio = (props) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.form.FormRadio', 'useEffect()');
    Log.trace('Student.MainView Enquete.form.FormRadio', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.form.FormRadio', 'props.answer.length=' + props.answer.length);

    let value = '';
    for (const ans of props.answer) {
      for (let i = 0; i < props.question.options.length; i++) {
        if (ans === props.question.options[i]) {
          value = i.toString();
        }
      }
    }
    setSelectedValue(value);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answer]);

  // --------------------------------------------------
  // ラジオボタン選択処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    const array = [];
    array.push(event.target.value)
    props.handleChange(array);
  };

  return (
    <Box>
      {props.question.options.map((option, index) => (
        <label key={index}>
          <Box
            alignItems="center"
            display="flex"
            className={classes.answerOption}
            style={selectedValue === index.toString() ? { backgroundColor: "#BEC5E8" } : { backgroundColor: "#f5f5f5" }}
          >
            <Radio
              value={index.toString()}
              checked={selectedValue === index.toString()}
              onChange={handleChange}
              size="small"
              disabled={props.isDisabled}
            />
            <Typography variant="h5" >{option}</Typography>
          </Box>
        </label>
      ))}
    </Box>
  );
};

FormRadio.propTypes = {
  question: PropTypes.object,
  answer: PropTypes.array,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default FormRadio;
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import SessionUtil from 'src/utils/SessionUtil';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();

  let location = useLocation();
  let account;
  if (location.state && location.state.account) {
    account = location.state.account
  }
  else {
    account = SessionUtil.getLoginAccount();
  }

  return (
    <Page
      className={classes.root}
      title="アカウント設定 | Active Poll"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={4}
              xs={12}
            >
              <Profile data={account} />
            </Grid>
            <Grid
              item
              lg={8}
              md={8}
              xs={12}
            >
              <ProfileDetails
                data={account}
                reloadPath="/admin/account"
                sytem={false}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default Account;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  User as UserIcon,
  Users as UsersIcon,
  File as FileIcon,
  Settings as SettingsIcon,
  Archive as ArchiveIcon
} from 'react-feather';
import NavItem from './NavItem';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView'
import SessionUtil from 'src/utils/SessionUtil';
import Log from 'src/utils/Log';

const items = [
  {
    href: '/admin/lessons',
    icon: FileIcon,
    title: '講義一覧',
    system: false,
  },
  {
    href: '/admin/archive',
    icon: ArchiveIcon,
    title: 'アーカイブ',
    system: false,
  },
  {
    href: '/admin/account',
    icon: UserIcon,
    title: 'アカウント設定',
    system: false,
  },
  {
    href: '/admin/system/account',
    icon: UsersIcon,
    title: 'アカウント管理',
    system: true,
  },
  {
    href: '/admin/system/parameter',
    icon: SettingsIcon,
    title: 'パラメータ管理',
    system: true,
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  avatarUserIcon : {
    width: 36,
    height: 36
  },
  name: {
    fontSize: 14,
    marginTop: 10,
    marginBottom: 5
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const account = SessionUtil.getLoginAccount();

  // --------------------------------------------------
  //  画面表示時の処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('DashboardLayout NavBar.index', 'useEffect()');
    Log.trace('DashboardLayout NavBar.index', 'SessionUtil.account: ' + account);
    // パラメータチェック
    if (!account.fname) {
      setErrorDialogOpen(true);
      return;
    }

    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // --------------------------------------------------
  // アカウント押下時の処理実行
  // --------------------------------------------------
  const handleAccountSetting = () => {
    navigate('/admin/account',
      {
        replace: false,
        state: account,
      }
    )
  }

  // --------------------------------------------------
  // 認証エラーダイアログの処理実行
  // --------------------------------------------------
  const handleDialogExecute = () => {
    setErrorDialogOpen(false);
    navigate('/admin/login',
      {
        replace: false,
      }
    )
  }

  // --------------------------------------------------
  // リスト選択処理
  // --------------------------------------------------
  const handleListItemClick = (selectedIndex) => {
    setSelectedIndex(selectedIndex);
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          onClick={handleAccountSetting}
        >
          <UserIcon 
            className={classes.avatarUserIcon}
          />
        </Avatar>
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {account.fname}&nbsp;{account.lname}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {account.organization}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item, index) => (
            item.system === false ?
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                index={index}
                handleClick={handleListItemClick}
                isSelected={index === selectedIndex}
              />
              : ''
          ))}
        </List>
      </Box>
      <Divider />
      {account.system === true ?
        <Box p={2}>
          <List>
            {items.map((item) => (
              item.system === true ?
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
                : ''
            ))}
          </List>
        </Box>
        : ''}
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>

      {/* ======================================== */}
      {/* 認証エラーダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={errorDialogOpen}
        title="認証エラー"
        description="セッションが切れております。大変恐れ入りますが、初めの画面から操作をやり直していただけますでしょうか。"
        agree={handleDialogExecute}
      />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  CornerDownLeft as BackIcon,
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  bold: {
    fontWeight: 600
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // --------------------------------------------------
  // 戻る処理
  // --------------------------------------------------
  const handleLink = () => {
    navigate('/admin/system/account', { replace: false, });
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" variant="h6">トップ</Typography>
          <Typography color="textPrimary" variant="h6">
            <Link color="inherit" underline="always" href="#" onClick={handleLink}>
              アカウント管理
           </Link>
          </Typography>
          <Typography color="primary" variant="h5" className={classes.bold}>編集</Typography>
        </Breadcrumbs>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleLink()}
          startIcon={<BackIcon />}
          className={classes.bold}
        >
          戻る
        </Button>
      </Box>
    </div>
  );
};

export default Toolbar;

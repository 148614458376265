import React, { useEffect } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  makeStyles,
  Slide,
} from '@material-ui/core';
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  url: {
    width: 600,
    marginTop: 50,
    marginBottom: 50,
    textAlign: "center",
    fontWeight: "bold"
  },
  bold: {
    fontWeight: 600
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// --------------------------------------------------
// 操作テキスト
// --------------------------------------------------
const operationText = (operation) => {
  let text = '';
  if (operation === Constants.operationTypeTop) {
    text = '[画面表示]トップ';
  }
  else if (operation === Constants.operationTypeAccessKey) {
    text = '[画面表示]アクセスキー';
  }
  else if (operation === Constants.operationTypeProfile) {
    text = '[画面表示]プロフィール';
  }
  else if (operation === Constants.operationTypeStart) {
    text = '[画面表示]リアルタイム投票';
  }
  else if (operation === Constants.operationTypeWaitReload) {
    text = '[画面表示]リアルタイム投票（再読み込み）';
  }
  else if (operation === Constants.operationTypeSendAnswerSelect) {
    text = '[操作]リアルタイム投票（選択）';
  }
  else if (operation === Constants.operationTypeSendAnswerText) {
    text = '[操作]リアルタイム投票（自由記述）';
  }
  else if (operation === Constants.operationTypeSendAnswerGood) {
    text = '[操作]リアルタイム投票（いいね）';
  }
  else if (operation === Constants.operationTypeSendFaq) {
    text = '[操作]質問（質問投稿）';
  }
  else if (operation === Constants.operationTypeSendFaqGood) {
    text = '[操作]質問（いいね）';
  }
  else if (operation === Constants.operationTypeOpenQuestion) {
    text = '[講師操作]リアルタイム投票回答開始';
  }
  else if (operation === Constants.operationTypeCloseQuestion) {
    text = '[講師操作]リアルタイム投票回答終了';
  }
  else if (operation === Constants.operationTypeStartCountDown) {
    text = '[講師操作]カウントダウン開始';
  }
  else if (operation === Constants.operationTypeConfirm) {
    text = '[操作]投票画面表示';
  }
  return text;
}

// --------------------------------------------------
// 画面表示処理
// --------------------------------------------------
const LogDownloadDialogView = (props) => {
  const classes = useStyles();

  const [progress, setProgress] = React.useState(0);

  useEffect(() => {

    Log.trace('LogDownloadDialogView', 'useEffect() props.open=' + props.open);
    if (!props.open) {
      return;
    }

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          getLessonResultCsv();
        }
        const diff = Math.random() * 40;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  // --------------------------------------------------
  // ログCSVデータ作成処理
  // --------------------------------------------------
  const getLessonResultCsv = async () => {
    Log.trace('LogDownloadDialogView', 'getLessonResultCsv()');
    if (!props.lesson.id) {
      return;
    }
    const logs = await Model.getLog(props.lesson.id);
    const sessions = await Model.getSession(props.lesson.id);

    // CSV行データ
    const csvRowArray = [];
    //文字コード
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

    //　CSVデータ生成
    Object.keys(logs).forEach(function (key) {
      const csvColumnArray = [];
      csvColumnArray.push('"' + logs[key].timestamp + '"');
      csvColumnArray.push('"' + operationText(logs[key].operation) + '"');

      // 受講者情報
      const oneTimeId = logs[key].oneTimeId;
      if (oneTimeId !== 'LessonStartView' && oneTimeId !== 'LessonReviewView') {
        csvColumnArray.push('"' + oneTimeId + '"');
        if (props.lesson.profileRequired) {
          if (sessions[oneTimeId]) {
            csvColumnArray.push('"' + sessions[oneTimeId].fname + '"');
            csvColumnArray.push('"' + sessions[oneTimeId].lname + '"');
            if (props.lesson.studentIdRequired) {
              csvColumnArray.push('"' + sessions[oneTimeId].code + '"');
            }
            csvColumnArray.push('"' + sessions[oneTimeId].mail + '"');
          }
        }
      }
      csvRowArray.push(csvColumnArray);
    });

    //　生成
    csvRowArray.sort(function (a, b) {
      return a[0] < b[0] ? -1 : 1;
    });

    //　CSVヘッダ生成
    let csvHeader;
    if (props.lesson.profileRequired === true) {
      if (props.lesson.studentIdRequired === true) {
        csvHeader = ["アクセス日時", "操作", "識別子", "氏名（姓）", "氏名（名）", "学籍番号", "メールアドレス"];
      } else {
        csvHeader = ["アクセス日時", "操作", "識別子", "氏名（姓）", "氏名（名）", "メールアドレス"];
      }
    } else {
      csvHeader = ["アクセス日時", "操作", "識別子"];
    }
    csvRowArray.unshift(csvHeader);

    // 配列データをCSVフォーマットに変換
    let csvRowData = csvRowArray.map(function (l) { return l.join(',') }).join('\r\n');

    //BLOB生成してダウンロード実行
    const blob = new Blob([bom, csvRowData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = Util.getCurrentDayTimeSecForCsv() + '.csv';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
  };


  return (
    <Dialog maxWidth="lg" open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        ログデータダウンロード
      </DialogTitle>

      <DialogContent dividers>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Box className={classes.url}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={props.handleClose}
          color="primary"
          className={classes.bold}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default LogDownloadDialogView;

import React, { useEffect } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  makeStyles,
  Slide,
} from '@material-ui/core';
import Model from 'src/models/Model';
import Util from 'src/utils/Util';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  url: {
    width: 600,
    marginTop: 50,
    marginBottom: 50,
    textAlign: "center",
    fontWeight: "bold"
  },
  bold: {
    fontWeight: 600
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// --------------------------------------------------
// 画面表示処理
// --------------------------------------------------
const FaqDownloadDialogView = (props) => {
  const classes = useStyles();

  const [progress, setProgress] = React.useState(0);

  useEffect(() => {

    Log.trace('FaqDownloadDialogView', 'useEffect() props.open=' + props.open);
    if (!props.open) {
      return;
    }

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          getLessonResultCsv();
        }
        const diff = Math.random() * 40;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  // --------------------------------------------------
  // 質問CSVデータ作成処理
  // --------------------------------------------------
  const getLessonResultCsv = async () => {
    Log.trace('FaqDownloadDialogView', 'getLessonResultCsv()');
    if (!props.lesson.id) {
      return;
    }
    const faqs = await Model.getFaqByLessonId(props.lesson.id);
    const sessions = await Model.getSession(props.lesson.id);

    // CSV行データ
    const csvRowArray = [];
    //文字コード
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

    //　CSVデータ生成
    Object.keys(faqs).forEach(function (key) {
      const csvColumnArray = [];

      // 質問データ
      csvColumnArray.push('"' + faqs[key].timestamp + '"');
      csvColumnArray.push('"' + faqs[key].text + '"');
      csvColumnArray.push('"' + (faqs[key].good ? Object.keys(faqs[key].good).length : 0) + '"');

      // 質問者データ
      if (props.lesson.profileRequired) {
        const oneTimeId = faqs[key].oneTimeId;
        csvColumnArray.push('"' + sessions[oneTimeId].fname + '"');
        csvColumnArray.push('"' + sessions[oneTimeId].lname + '"');
        if (props.lesson.studentIdRequired) {
          csvColumnArray.push('"' + sessions[oneTimeId].code + '"');
        }
        csvColumnArray.push('"' + sessions[oneTimeId].mail + '"');
      }
      csvRowArray.push(csvColumnArray);
    });

    //　CSVヘッダ生成
    let csvHeader;
    if (props.lesson.profileRequired === true) {
      if (props.lesson.studentIdRequired === true) {
        csvHeader = ["質問日時", "質問内容", "獲得いいね数", "氏名（姓）", "氏名（名）", "学籍番号", "メールアドレス"];
      } else {
        csvHeader = ["質問日時", "質問内容", "獲得いいね数", "氏名（姓）", "氏名（名）", "メールアドレス"];
      }
    } else {
      csvHeader = ["質問日時", "質問内容", "獲得いいね数"];
    }
    csvRowArray.unshift(csvHeader);

    // 配列データをCSVフォーマットに変換
    let csvRowData = csvRowArray.map(function (l) { return l.join(',') }).join('\r\n');

    //BLOB生成してダウンロード実行
    const blob = new Blob([bom, csvRowData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = Util.getCurrentDayTimeSecForCsv() + '.csv';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
  };


  return (
    <Dialog maxWidth="lg" open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        質問
      </DialogTitle>

      <DialogContent dividers>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Box className={classes.url}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={props.handleClose}
          color="primary"
          className={classes.bold}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default FaqDownloadDialogView;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import Lesson from './Lesson';
import Download from './Download';
import Question from './Question';
import ActiveLog from './ActiveLog';


import Log from 'src/utils/Log';

const useStyles = makeStyles(({
  mt3: {
    marginTop: 24
  },
}));

const Review = (props) => {
  const classes = useStyles();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Admin.Lesson.LessonDitailView.Review', 'useEffect()');
    return function cleanup() {
      Log.trace('Admin.Lesson.LessonDitailView.Review', 'useEffect() cleanup');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box>
        <Lesson lesson={props.lesson} />
      </Box>
      <Box className={classes.mt3}>
        <Download lesson={props.lesson} />
      </Box>
      <Box className={classes.mt3}>
        <Question lesson={props.lesson} />
      </Box>
      <Box className={classes.mt3}>
        <ActiveLog lesson={props.lesson} />
      </Box>
    </Box>
  );
};

Review.propTypes = {
  lesson: PropTypes.object,
};

export default Review;

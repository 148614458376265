import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Edit3 as Edit3Icon, Copy as CopyIcon } from 'react-feather';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Alert from '@material-ui/lab/Alert';
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';
import Measure from "react-measure";
import "./style.css";
import ConfirmDialogView from 'src/views/common/ConfirmDialogView';
import QuestionEditDialogView from '../../dialog/QuestionEditDialogView';
import QuestionImportDialogView from '../../dialog/QuestionImportDialogView';
import Constants from 'src/utils/Constants';
import Util from 'src/utils/Util';
import Model from 'src/models/Model';

const useStyles = makeStyles(({
  root: {
  },
  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  tableRow: {
    display: 'table-row'
  },
  bold: {
    fontWeight: 600
  },
  importBtn: {
    marginRight: 10,
    fontWeight: 600
  },
}));

const Question = (props) => {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [target, setTarget] = useState({});
  const [editMode, setEditMode] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [selected, setSelected] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [questionTitleCellLength, setQuestionTitleCellLength] = useState();
  const [importStartOrder, setImportStartOrder] = useState(0);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    getQuestionList();
    return function cleanup() {
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 設問一覧取得処理
  // --------------------------------------------------
  const getQuestionList = async () => {
    const array = await Model.getQuestionList(props.lesson.id);
    array.sort(function (a, b) {
      return a.order < b.order ? -1 : 1;
    });
    setQuestions(array);
  };

  //--------------------------------------------------
  // 設問追加処理
  //--------------------------------------------------
  const handleAddButtonClick = async (event) => {
    // 並び順は既存設問の並び順の最大値+1を設定する
    const order = questions.length > 0 ? Math.max.apply(null, questions.map(function (o) { return o.order; })) + 1 : 1;
    const data = {
      title: '',
      type: Constants.questionTypeRadio,
      initMode: Constants.processModeRealtime,
      order: order,
      isRequired: Constants.questionRequired,
    };
    setTarget(data);
    setEditDialogOpen(true);
    setEditMode(Constants.questionEditModeNew);
  }

  // --------------------------------------------------
  // 編集アイコン押下処理
  // --------------------------------------------------
  const handleEditButtonClick = (event, question) => {
    if (question.options && Array.isArray(question.options)) {
      question.options = question.options.join('\n');
    }
    setTarget(question);
    setEditDialogOpen(true);
    setEditMode(Constants.questionEditModeModify);
  }

  // --------------------------------------------------
  // コピーアイコン押下処理
  // --------------------------------------------------
  const handleCopyButtonClick = (event, question) => {
    // 並び順に既存設問の並び順の最大値+1を設定する
    const order = questions.length > 0 ? Math.max.apply(null, questions.map(function (o) { return o.order; })) + 1 : 1;
    question.order = order;
    // idを初期化する
    question.id = '';
    if (question.options && Array.isArray(question.options)) {
      question.options = question.options.join('\n');
    }
    setTarget(question);
    setEditDialogOpen(true);
    setEditMode(Constants.questionEditModeCopy);
  }

  // --------------------------------------------------
  // 編集ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setEditMode('');
    setTarget({});
  }

  // --------------------------------------------------
  // 編集ダイアログのクローズ処理（再取得）
  // --------------------------------------------------
  const handleDialogCloseWithReload = (message) => {
    setEditDialogOpen(false);
    setEditMode('');
    setTarget({});
    getQuestionList();
    setMessageText(message);
    setMessageOpen(true);
  }

  //--------------------------------------------------
  // 設問追加成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
    setMessageText('');
  }

  //--------------------------------------------------
  // 並び替えドラッグ＆ドロップ完了
  //--------------------------------------------------
  const onDrop = async ({ removedIndex, addedIndex, payload, element }) => {
    const sorted = arrayMove(questions, removedIndex, addedIndex);
    for (let i = 0; i < sorted.length; i++) {
      const d = { order: i + 1 };
      await Model.updateQuestion(props.lesson.id, sorted[i].id, d);
    }
    // 講義情報の更新日時を更新
    const updateDate = Util.getCurrentDayTime();
    await Model.updateLesson(props.lesson.id, { updateDate: updateDate });

    getQuestionList();
    setMessageText('質問の並び替えを確定しました');
    setMessageOpen(true);
  };

  //--------------------------------------------------
  // 一覧チェックボックスの選択
  //--------------------------------------------------
  const handleSelect = (event) => {
    setSelected({ ...selected, [event.target.name]: event.target.checked });
  };

  //--------------------------------------------------
  // 一覧チェックボックスの選択有無
  //--------------------------------------------------
  const isSelect = () => {
    let s = false;
    for (let key in selected) {
      if (selected[key] === true) {
        s = true;
      }
    }
    return s;
  };

  // --------------------------------------------------
  // 削除ボタン押下処理
  // --------------------------------------------------
  const handleDeleteButtonClick = (event) => {
    setDeleteDialogOpen(true);
  }

  // --------------------------------------------------
  // 削除確認ダイアログの削除実行処理
  // --------------------------------------------------
  const handleDeleteExecute = async () => {
    for (let key in selected) {
      if (selected[key] === true) {
        await Model.deleteQuestion(props.lesson.id, key);
      }
    }
    // 講義情報の更新日時を更新
    const updateDate = Util.getCurrentDayTime();
    await Model.updateLesson(props.lesson.id, { updateDate: updateDate });
    // 講義処理状況を初期化
    await Model.updateLessonProcess(props.lesson.id, {
      questionId: Constants.processInitialQuestionId,
      mode: Constants.processInitialMode,
      updateDate: updateDate,
      countdown: false,
    });

    setDeleteDialogOpen(false);
    setMessageText('選択された質問を削除しました');
    setMessageOpen(true);
    getQuestionList();
    setSelected({});
  }

  // --------------------------------------------------
  // 削除確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  }

  // --------------------------------------------------
  // 画面リサイズ時に設問タイトルセルの幅を保存
  // --------------------------------------------------
  const handleResizeTable = (contentRect) => {
    setQuestionTitleCellLength(contentRect.bounds.width);
  }

  //--------------------------------------------------
  // 他講義からの設問インポート処理
  //--------------------------------------------------
  const handleImportButtonClick = async (event) => {
    // 並び順は既存設問の並び順の最大値+1を設定する
    const order = questions.length > 0 ? Math.max.apply(null, questions.map(function (o) { return o.order; })) + 1 : 1;
    setImportStartOrder(order);
    setImportDialogOpen(true);
  }

  //--------------------------------------------------
  // 他講義からの設問インポートダイアログを閉じる
  //--------------------------------------------------
  const handleImportDialogClose = () => {
    setImportDialogOpen(false);
    setImportStartOrder(0);
  }

  //--------------------------------------------------
  // 他講義からの設問インポートを実行後、ダイアログを閉じる
  //--------------------------------------------------
  const handleImportDialogSave = () => {
    setImportDialogOpen(false);
    setImportStartOrder(0);
    getQuestionList();
    setMessageText('質問をコピーしました');
    setMessageOpen(true);
  }

  return (
    <form>
      <Card>
        <CardHeader
          title="設問一覧"
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 50 }}></TableCell>
                  <Measure bounds onResize={(contentRect) => handleResizeTable(contentRect)}>
                    {({ measureRef }) => (
                      <TableCell ref={measureRef} >
                        タイトル
                      </TableCell>
                    )}
                  </Measure>
                  <TableCell style={{ width: 200 }}>
                    設問タイプ
                  </TableCell>
                  {props.lesson.voteMode === Constants.voteModeRealtime ? (
                    <TableCell style={{ width: 200 }}>
                      回答者への結果表示
                    </TableCell>
                  ) :
                    <TableCell style={{ width: 200 }}>
                      必須／任意
                    </TableCell>
                  }
                  <TableCell style={{ width: 100 }}>
                    編集
                  </TableCell>
                  <TableCell style={{ width: 100 }}>
                    コピー
                  </TableCell>
                  <TableCell style={{ width: 100 }}>
                    並び替え
                  </TableCell>
                </TableRow>
              </TableHead>
              <Container
                dragHandleSelector=".drag-handle"
                lockAxis="y"
                onDrop={onDrop}
                render={(ref) => {
                  return (
                    <TableBody ref={ref}>
                      {questions.map((question) => (
                        <Draggable
                          key={question.id}
                          render={(ref) => {
                            return (
                              <TableRow
                                hover
                              >
                                <TableCell className={classes.tableCell} style={{ width: 50 }}>
                                  <Checkbox
                                    name={question.id}
                                    checked={selected[question.id] === true ? true : false}
                                    onChange={handleSelect}
                                  />
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{ width: questionTitleCellLength }}>
                                  {question.title}
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{ width: 200 }}>
                                  {
                                    question.type === Constants.questionTypeRadio ? Constants.questionTypeLabelRadio :
                                      question.type === Constants.questionTypeSelect ? Constants.questionTypeLabelSelect :
                                        question.type === Constants.questionTypeCheckBox ? Constants.questionTypeLabelCheckBox :
                                          question.type === Constants.questionTypeText ? Constants.questionTypeLabelText :
                                            Constants.questionTypeLabelTextArea
                                  }
                                </TableCell>
                                {props.lesson.voteMode === Constants.voteModeRealtime ? (
                                  <TableCell className={classes.tableCell} style={{ width: 200 }}>
                                    {
                                      question.initMode === Constants.processModeAnswer ? Constants.processModeLabelAnswer :
                                        question.initMode === Constants.processModeResult ? Constants.processModeLabelResult :
                                          question.initMode === Constants.processModeRealtime ? Constants.processModeLabelRealtime :
                                            Constants.processModeLabelRealtime2
                                    }
                                  </TableCell>
                                ) :
                                  <TableCell>
                                    {question.isRequired === Constants.questionRequired ? "必須" : "任意"}
                                  </TableCell>
                                }
                                <TableCell className={classes.tableCell} style={{ width: 100 }}>
                                  <IconButton
                                    onClick={(event) => handleEditButtonClick(event, question)}
                                    color="primary" >
                                    <Edit3Icon />
                                  </IconButton>
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{ width: 100 }}>
                                  <IconButton
                                    onClick={(event) => handleCopyButtonClick(event, question)}
                                    color="primary" >
                                    <CopyIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{ width: 100 }}>
                                  <IconButton
                                    color="primary"
                                    className="drag-handle"
                                  >
                                    <DragHandleIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          }}
                        />
                      ))}
                    </TableBody>
                  )
                }}
              />
            </Table>
          </Box>
        </PerfectScrollbar>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={(event) => handleDeleteButtonClick(event)}
            className={classes.bold}
            disabled={isSelect() ? false : true}
          >
            削除する
          </Button>
          <Box>
            <Button
              color="primary"
              variant="outlined"
              onClick={(event) => handleImportButtonClick(event)}
              className={classes.importBtn}
            >
              他の講義から設問をコピーする
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={(event) => handleAddButtonClick(event)}
              className={classes.bold}
            >
              設問を追加する
            </Button>
          </Box>
        </Box>
      </Card>

      {/* ======================================== */}
      {/* 設問編集ダイアログ */}
      {/* ======================================== */}
      <QuestionEditDialogView
        open={editDialogOpen}
        editMode={editMode}
        question={target}
        lesson={props.lesson}
        handleClose={handleDialogClose}
        handleCloseWithReload={handleDialogCloseWithReload}
      />

      {/* ======================================== */}
      {/* 設問削除確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={deleteDialogOpen}
        title="設問の削除"
        description="選択された設問を削除します。よろしいですか？"
        disagree={handleDeleteDialogClose}
        agree={handleDeleteExecute}
      />

      {/* ======================================== */}
      {/* 設問インポートダイアログ */}
      {/* ======================================== */}
      <QuestionImportDialogView
        open={importDialogOpen}
        order={importStartOrder}
        lesson={props.lesson}
        handleClose={handleImportDialogClose}
        handleSave={handleImportDialogSave}
      />

      {/* ======================================== */}
      {/* データ更新成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          {messageText}
        </Alert>
      </Snackbar>
    </form >
  );
};

Question.propTypes = {
  lesson: PropTypes.object,
};

export default Question;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  IconButton,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  Edit3 as Edit3Icon,
  BarChart as BarChartIcon,
} from 'react-feather';
import ResultDialog from './ResultDialog'


const useStyles = makeStyles((theme) => ({
  root: {
  },
  list: {
    marginTop: 16
  },
  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomStyle: "none",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: "rgba(224, 224, 224, 1)"
  },
  dummy: {
    color: 'rgba(255,255,255,0)',
  },
}));

const Finish = (props) => {
  const classes = useStyles();
  const [target, setTarget] = useState({});
  const [resultDialogOpen, setResultDialogOpen] = useState(false);

  // --------------------------------------------------
  // 編集アイコン押下処理
  // --------------------------------------------------
  const handleEditButtonClick = (event, question) => {
    props.handleEditButton(question.order - 1);
  }

  // --------------------------------------------------
  // 結果確認アイコン押下処理
  // --------------------------------------------------
  const handleChartButtonClick = (event, question) => {
    setTarget(question);
    setResultDialogOpen(true);
  }

  // --------------------------------------------------
  // 結果確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDialogClose = () => {
    setTarget({});
    setResultDialogOpen(false);
  }

  return (
    <Card className={classes.list}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              タイトル
            </TableCell>
            {props.lesson.isOndemandEdit === true ? (
              <TableCell style={{ width: 100 }}>
                修正する
              </TableCell>
            ) : <></>}
            {props.lesson.isOndemandShare === true ? (
              <TableCell style={{ width: 100 }}>
                結果確認
              </TableCell>
            ) : <></>}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.questions.map((question) => (
            <TableRow
              key={question.id}
              hover
            >
              <TableCell className={classes.tableCell}>
                {question.title}
                <IconButton className={classes.dummy}><Edit3Icon /></IconButton>
              </TableCell>
              {props.lesson.isOndemandEdit === true ? (
                <TableCell className={classes.tableCell}>
                  <IconButton
                    color="primary"
                    onClick={(event) => handleEditButtonClick(event, question)}
                    disabled={question.answerCount === 0 ? true : false}
                  >
                    <Edit3Icon />
                  </IconButton>
                </TableCell>
              ) : <></>}
              {props.lesson.isOndemandShare === true ? (
                <TableCell className={classes.tableCell}>
                  <IconButton
                    color="primary"
                    onClick={(event) => handleChartButtonClick(event, question)}
                    disabled={question.answerCount === 0 ? true : false}
                  >
                    <BarChartIcon />
                  </IconButton>
                </TableCell>
              ) : <></>}
            </TableRow >
          ))}
        </TableBody>
      </Table>

      {/* ======================================== */}
      {/* 結果確認ダイアログ */}
      {/* ======================================== */}
      <ResultDialog
        open={resultDialogOpen}
        question={target}
        lesson={props.lesson}
        student={props.student}
        handleClose={handleDialogClose}
      />
    </Card>
  );
};

Finish.propTypes = {
  lesson: PropTypes.object,
  student: PropTypes.object,
  questions: PropTypes.array,
  handleEditButton: PropTypes.func,
};

export default Finish;
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';

import QuestionTitle from 'src/views/student/MainView/Enquete/QuestionTitle';
import ResultGraph from 'src/views/student/MainView/Enquete/ResultGraph';
import ResultText from 'src/views/student/MainView/Enquete/ResultText';
import TrendPanel from './TrendPanel';
import DataPanel from './DataPanel';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles(({
  root: {},
  closeArea: {
    marginTop: 10,
  },
  closeBtn: {
    marginRight: 10,
    fontWeight: 600
  },
}));

// --------------------------------------------------
// 結果表示コンポーネントの判定処理
// --------------------------------------------------
const ResultPanel = (props) => {
  let component;
  switch (props.question.type) {
    case Constants.questionTypeRadio:
    case Constants.questionTypeSelect:
    case Constants.questionTypeCheckBox:
      component = (
        <ResultGraph
          key={props.question.id}
          question={props.question}
          lesson={props.lesson}
          student={{ oneTimeId: 'LessonReviewView' }}
        />)
      break;
    case Constants.questionTypeText:
    case Constants.questionTypeTextArea:
      component = (
        <ResultText
          key={props.question.id}
          question={props.question}
          lesson={props.lesson}
          student={{ oneTimeId: 'LessonReviewView' }}
          mode={""}
        />)
      break;
    default:
      component = '';
      break;
  }
  return component;
}

const MainBoard = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        className={classes.root}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <QuestionTitle
            question={props.question}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <TrendPanel
            question={props.question}
            studentCount={props.studentCount}
          />
          <DataPanel
            question={props.question}
            studentCount={props.studentCount}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        // style={{ height: 515 }}
        >
          {ResultPanel(props)}
        </Grid>
      </Grid>
      <Divider className={classes.closeArea} />
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        p={2}
      >
        <Box>
          <Button
            color="primary"
            variant="outlined"
            onClick={props.handleClose}
            className={classes.closeBtn}
          >
            閉じる
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

MainBoard.propTypes = {
  question: PropTypes.object,
  lesson: PropTypes.object,
  studentCount: PropTypes.number,
  handleClose: PropTypes.func,
};

export default MainBoard;

import React from 'react';

const LogoB = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/logo_white.png"
      {...props}
    />
  );
};

export default LogoB;

import React from 'react';
import {
  AppBar,
  Box,
  Container,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Question from './Question';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuestionEditDialogView = (props) => {
  const classes = useStyles();

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    props.handleClose();
  }

  return (
    <Dialog fullScreen open={props.open} onClose={handleDialogClose} TransitionComponent={Transition} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            {props.editMode === Constants.questionEditModeNew ? '設問追加' :
              props.editMode === Constants.questionEditModeModify ? '設問編集' :
                props.editMode === Constants.questionEditModeCopy ? '設問追加（コピー）' : '設問編集'
            }
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth={false} className={classes.container}>
        <Box mt={3}>
          <Question
            lesson={props.lesson}
            editMode={props.editMode}
            question={props.question}
            handleClose={props.handleClose}
            handleCloseWithReload={props.handleCloseWithReload}
          />
        </Box>
      </Container>
    </Dialog>
  );
};

export default QuestionEditDialogView;

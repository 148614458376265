import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Snackbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import firebase from "firebase/app";
import 'firebase/auth';
import Page from 'src/components/Page';
import PasswordResetDialogView from './dialog/PasswordResetDialogView';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView'
import Model from 'src/models/Model';
import SessionUtil from 'src/utils/SessionUtil';
import Constants from 'src/utils/Constants';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    marginTop: 100,
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  LinkButton: {
    padding: 0,
    fontSize: 16
  },
  bold: {
    fontWeight: 600
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    mail: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleValueChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  //--------------------------------------------------
  //　パスワード再設定リンク - クリック処理
  //--------------------------------------------------
  const handlePasswordResetLink = () => {
    setPasswordResetDialogOpen(true);
  }

  //--------------------------------------------------
  //　パスワード再設定ダイアログ　クローズ処理
  //--------------------------------------------------
  const handlePasswordResetDialogClose = (result) => {
    if (result === true) {
      setSuccessMessageOpen(true);
      setMessageText('パスワード再設定メールの送信しました');
    }
    setPasswordResetDialogOpen(false);
  }

  //--------------------------------------------------
  // メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setErrorMessageOpen(false);
    setSuccessMessageOpen(false);
    setMessageText('');
  }

  // --------------------------------------------------
  // ログイン処理
  // --------------------------------------------------
  const handleLogin = async (event) => {
    Log.trace('LoginView', 'handleLogin()');

    let check = {};
    // バリデーション
    // 必須チェック
    if (!values.mail) {
      check.mail = '必須項目です';
    }
    if (!values.password) {
      check.password = '必須項目です';
    }
    if (Object.keys(check).length > 0) {
      setErrors(check);
      return;
    }

    // ログイン
    firebase.auth().signInWithEmailAndPassword(values.mail, values.password)
      .then((userCredential) => {
        Log.trace('LoginView', 'firebase.auth() success: ' + userCredential.user.uid);
        Log.trace('LoginView', 'firebase.auth() emailVerified: ' + userCredential.user.emailVerified);

        // メールアドレス認証前
        if (!userCredential.user.emailVerified) {
          // メールアドレスの確認メール送信
          const actionCodeSettings = {
            url: 'https://actipo.com/admin/login',
          };
          userCredential.user.sendEmailVerification(actionCodeSettings).then(function () {
            setConfirmDialogOpen(true);
          }).catch(function (error) {
            Log.trace('LoginView', 'handleEmailVerify() error : ' + error.code);
            setConfirmDialogOpen(true);
          });
          setValues({ ...values, password: '', mail: '' });
          setErrors({});
        }
        else {
          next(userCredential.user.uid);
        }
      })
      .catch((error) => {
        Log.trace('LoginView', 'firebase.auth() fail: ' + error.message);
        setErrorMessageOpen(true);
        setMessageText('メールアドレスまたはパスワードが正しくありません。');
        setValues({ ...values, password: '' });
        setErrors({});
        return;
      });
  };

  // --------------------------------------------------
  // 次画面遷移処理
  // --------------------------------------------------
  const next = async (uid) => {
    const account = await Model.getAccountByKey(uid);
    account.password = Constants.passwordDummy;
    account.id = uid;
    SessionUtil.setLoginAccount(account);
    // ログイン後画面へ
    navigate('/admin/lessons', { replace: false, })
  }

  //--------------------------------------------------
  // メールアドレスの確認メール送信ダイアログを閉じる
  //--------------------------------------------------
  const handleConfirmDialogExecute = () => {
    setConfirmDialogOpen(false);

    firebase.auth().signOut().then(() => {
      Log.trace('LoginView', 'firebase.auth().signOut() success');
      navigate('/admin/login', { replace: true, });
    }).catch((error) => {
      Log.trace('LoginView', 'firebase.auth().signOut() fail : ' + error.message);
    });
  }

  return (
    <Page
      className={classes.root}
      title="ログイン | Active Poll"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
      >
        <Container className={classes.container} maxWidth="sm">
          <form>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                ログイン
              </Typography>
            </Box>
            <TextField
              fullWidth
              label="メールアドレス"
              margin="normal"
              name="mail"
              type="mail"
              value={values.mail}
              onChange={handleValueChange}
              variant="outlined"
              error={errors.mail ? true : false}
              helperText={errors.mail ? errors.mail : ''}
            />
            <TextField
              fullWidth
              label="パスワード"
              margin="normal"
              name="password"
              type="password"
              onChange={handleValueChange}
              onKeyPress={(event) => event.key === 'Enter' ? handleLogin(event) : false}
              value={values.password}
              variant="outlined"
              error={errors.password ? true : false}
              helperText={errors.password ? errors.password : ''}
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                onClick={handleLogin}
                className={classes.bold}
              >
                ログイン
              </Button>
            </Box>

            <Typography
              color="textSecondary"
              variant="body1"
            >
              パスワードをお忘れの方は、
              {' '}
              <Button
                color="primary"
                className={classes.LinkButton}
                component={Link}
                onClick={() => handlePasswordResetLink()}
              >
                パスワードを再発行
              </Button>
            </Typography>

            <Typography
              color="textSecondary"
              variant="body1"
            >
              アカウントをお持ちでない方は、
              {' '}
              <Link
                component={RouterLink}
                to="/admin/register"
                variant="h5"
              >
                トライアルアカウントを作成
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>

      {/* ======================================== */}
      {/* 失敗メッセージ */}
      {/* ======================================== */}
      <Snackbar open={errorMessageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="error">
          {messageText}
        </Alert>
      </Snackbar>

      {/* ======================================== */}
      {/* 成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={successMessageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          {messageText}
        </Alert>
      </Snackbar>

      {/* ======================================== */}
      {/* パスワード再設定ダイアログ */}
      {/* ======================================== */}
      <PasswordResetDialogView
        open={passwordResetDialogOpen}
        handleClose={handlePasswordResetDialogClose}
      />
      {/* ======================================== */}
      {/* 確認メールを送信ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={confirmDialogOpen}
        title="本人確認メールを送信しました"
        description="メールアドレスの認証が完了しておりません。確認メールのリンクをクリックした後に、再度ログインをお願いします。"
        agree={handleConfirmDialogExecute}
      />
    </Page>
  );
};

export default LoginView;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  answerOption: {
    borderRadius: 5,
    marginBottom: 12,
    padding: 5,
  },
}));

const FormCheckbox = (props) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState({});

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.form.FormCheckbox', 'useEffect()');
    Log.trace('Student.MainView Enquete.form.FormCheckbox', 'props.question.questionId=' + props.question.id);
    Log.trace('Student.MainView Enquete.form.FormCheckbox', 'props.answer.length=' + props.answer.length);

    const obj = {};
    for (const ans of props.answer) {
      for (let i = 0; i < props.question.options.length; i++) {
        if (ans === props.question.options[i]) {
          obj[i] = true;
        }
      }
    }
    setSelectedValue(obj);
    if (Object.keys(obj).length > 0) {
      const array = [];
      for (let key in obj) {
        array.push(key)
      }
      props.handleChange(array);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answer]);

  // --------------------------------------------------
  // チェックボックス選択処理
  // --------------------------------------------------
  const handleChange = (event) => {
    setSelectedValue({ ...selectedValue, [event.target.name]: event.target.checked });
    const array = [];
    if (event.target.checked === true) {
      for (let key in selectedValue) {
        if (selectedValue[key] === true) {
          array.push(key)
        }
      }
      array.push(event.target.name)
    }
    else {
      for (let key in selectedValue) {
        if (selectedValue[key] === true && key !== event.target.name) {
          array.push(key)
        }
      }
    }
    props.handleChange(array);
  };


  return (
    <Box>
      {props.question.options.map((option, index) => (
        <label key={index}>
          <Box
            alignItems="center"
            display="flex"
            className={classes.answerOption}
            style={selectedValue[index] === true ? { backgroundColor: "#BEC5E8" } : { backgroundColor: "#f5f5f5" }}
          >
            <Checkbox
              name={index.toString()}
              checked={selectedValue[index] === true ? true : false}
              onChange={handleChange}
              size="small"
              disabled={props.isDisabled}
            />
            <Typography variant="h5">{option}</Typography>
          </Box>
        </label>
      ))}
    </Box>
  );
};

FormCheckbox.propTypes = {
  question: PropTypes.object,
  answer: PropTypes.array,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default FormCheckbox;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import Lesson from './Lesson';
import Question from './Question';
import Faq from './Faq';
import Log from 'src/utils/Log';

const useStyles = makeStyles(({
  mt3: {
    marginTop: 24
  },
}));

const Start = (props) => {
  const classes = useStyles();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Admin.Lesson.LessonDitailView.Start', 'useEffect()');
    return function cleanup() {
      Log.trace('Admin.Lesson.LessonDitailView.Start', 'useEffect() cleanup');
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box>
        <Lesson lesson={props.lesson} />
      </Box>
      <Box className={classes.mt3}>
        <Question 
          lesson={props.lesson} 
          handleLessonStart={props.handleLessonStart} 
        />
      </Box>
      {props.lesson.useFaQ === true ? (
        <Faq lesson={props.lesson} />
      ) : ''}
    </Box>
  );
};

Start.propTypes = {
  lesson: PropTypes.object,
  handleLessonStart: PropTypes.func
};

export default Start;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@material-ui/core';
import firebase from "firebase/app";
import 'firebase/auth';
import Wait from '../Wait';
import WaitLoding from '../WaitLoding';
import QuestionTitle from '../QuestionTitle';
import AnswerSelect from './AnswerSelect';
import AnswerText from './AnswerText';
import ResultGraph from '../ResultGraph';
import ResultText from '../ResultText';
import RealtimeGrid from './RealtimeGrid';
import ConfirmDialog from './ConfirmDialog';
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';

const Realtime = (props) => {
  const [question, setQuestion] = useState({});
  const [mode, setMode] = useState(Constants.processLoadingMode);
  const [countDownStart, setCountDownStart] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // --------------------------------------------------
  // 画面表示
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Enquete.Realtime.index', 'useEffect()');
    Log.trace('Student.MainView Enquete.Realtime.index', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView Enquete.Realtime.index', 'props.student.oneTimeId=' + props.student.oneTimeId);
    // 認証チェック
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        // サインインしていない状態
        firebase.auth().signInAnonymously()
          .then(() => {
          });
      } else {
        // サインイン済み
        initialize();
      }
    });

    return function cleanup() {
      Log.trace('Student.MainView Enquete.index', 'useEffect() cleanup()');
      watchCancelProcess();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  //  初期化処理
  // --------------------------------------------------
  const initialize = () => {
    getLessonProcess();
    watchProcess();
    // アクティブ度計測ログの追加
    Model.addLog(props.lesson.id, props.student.oneTimeId, Constants.operationTypeStart);
  };

  // --------------------------------------------------
  // 講義プロセスの初期状態取得
  // --------------------------------------------------
  const getLessonProcess = async () => {
    const process = await Model.getLessonProcess(props.lesson.id);
    if (!process.mode) {
      Log.trace('Student Main Enquete index', 'getLessonProcess TODO 削除された講義情報にアクセス');
      setMode(Constants.processInitialMode);
      getQuestion(Constants.processInitialQuestionId);
      return;
    }
    setMode(process.mode);
    getQuestion(process.questionId);
  }

  // --------------------------------------------------
  // 講義プロセスの監視処理実行
  // --------------------------------------------------
  const watchProcess = async () => {
    await Model.watchLessonProcess(props.lesson.id, callback);
  }

  // --------------------------------------------------
  // 講義プロセスの変更処理待受
  // --------------------------------------------------
  const callback = (key, val) => {
    if (key === 'mode') {
      setMode(val);
    }
    else if (key === 'questionId') {
      getQuestion(val);
    }
    else if (key === 'updateDate') {
      getLessonProcess();
    }
    else if (key === 'countdown') {
      setCountDownStart(val);
    }
  }

  // --------------------------------------------------
  // 講義プロセスの監視処理のキャンセル実行
  // --------------------------------------------------
  const watchCancelProcess = async () => {
    await Model.watchChancelLessonProcess(props.lesson.id);
  }

  // --------------------------------------------------
  // 設問情報の取得
  // --------------------------------------------------
  const getQuestion = async (questionId) => {
    if (questionId === Constants.processInitialQuestionId) {
      setQuestion({});
      controllConfirmDialogOpenState(false);
      return;
    }
    const newQuestion = await Model.getQuestionByKey(props.lesson.id, questionId);
    newQuestion.id = questionId;
    setQuestion(newQuestion);
    controllConfirmDialogOpenState(true);
  };

  // --------------------------------------------------
  // 回答前確認ダイアログの表示制御
  // --------------------------------------------------
  const controllConfirmDialogOpenState = (isOpen) => {
    if (props.lesson.useConfirm === true) {
      setConfirmDialogOpen(isOpen);
    }
  };

  // --------------------------------------------------
  // 回答前確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleConfirm = async () => {
    controllConfirmDialogOpenState(false);
    await Model.updateView(props.lesson.id, question.id, { oneTimeId: props.student.oneTimeId, isConfirm: true });
    await Model.addLog(props.lesson.id, props.student.oneTimeId, Constants.operationTypeConfirm, question.id);
  };

  // 待機中
  // 回答受付中：1カラム
  // 結果表示中：1カラム
  // リアルタイム：2カラム
  //    左：回答画面、右：結果確認画面
  return (
    <Box mt={1}>
      <Grid
        container
        spacing={2}
      >
        {/* ======================================== */}
        {/* 設問タイトル */}
        {/* ======================================== */}
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
        >
          {mode === Constants.processLoadingMode ? <WaitLoding /> :
            mode === Constants.processInitialMode ? <Wait lesson={props.lesson} student={props.student} reload={getLessonProcess} /> : <QuestionTitle question={question} />}
        </Grid>

        {/* ======================================== */}
        {/* 回答 */}
        {/* ======================================== */}

        {/* 回答受付中 */}
        {mode === Constants.processModeAnswer ? (
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {question.type === Constants.questionTypeRadio ? <AnswerSelect key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> :
              question.type === Constants.questionTypeSelect ? <AnswerSelect key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> :
                question.type === Constants.questionTypeCheckBox ? <AnswerSelect key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> :
                  question.type === Constants.questionTypeText ? <AnswerText key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> :
                    question.type === Constants.questionTypeTextArea ? <AnswerText key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> : ''
            }
          </Grid>
        )
          : ''
        }

        {/* 結果確認中 */}
        {mode === Constants.processModeResult ? (
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {question.type === Constants.questionTypeRadio ? <ResultGraph key={question.id} question={question} lesson={props.lesson} student={props.student} /> :
              question.type === Constants.questionTypeSelect ? <ResultGraph key={question.id} question={question} lesson={props.lesson} student={props.student} /> :
                question.type === Constants.questionTypeCheckBox ? <ResultGraph key={question.id} question={question} lesson={props.lesson} student={props.student} /> :
                  question.type === Constants.questionTypeText ? <ResultText key={question.id} question={question} lesson={props.lesson} student={props.student} mode={mode} /> :
                    question.type === Constants.questionTypeTextArea ? <ResultText key={question.id} question={question} lesson={props.lesson} student={props.student} mode={mode} /> : ''
            }
          </Grid>
        )
          : ''
        }

        {/* リアルタイム */}
        {mode === Constants.processModeRealtime ? (
          <>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              {question.type === Constants.questionTypeRadio ? <AnswerSelect key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> :
                question.type === Constants.questionTypeSelect ? <AnswerSelect key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> :
                  question.type === Constants.questionTypeCheckBox ? <AnswerSelect key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> :
                    question.type === Constants.questionTypeText ? <AnswerText key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> :
                      question.type === Constants.questionTypeTextArea ? <AnswerText key={question.id} question={question} lesson={props.lesson} student={props.student} countDownStart={countDownStart} /> : ''
              }
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              {question.type === Constants.questionTypeRadio ? <ResultGraph key={question.id} question={question} lesson={props.lesson} student={props.student} /> :
                question.type === Constants.questionTypeSelect ? <ResultGraph key={question.id} question={question} lesson={props.lesson} student={props.student} /> :
                  question.type === Constants.questionTypeCheckBox ? <ResultGraph key={question.id} question={question} lesson={props.lesson} student={props.student} /> :
                    question.type === Constants.questionTypeText ? <ResultText key={question.id} question={question} lesson={props.lesson} student={props.student} mode={mode} /> :
                      question.type === Constants.questionTypeTextArea ? <ResultText key={question.id} question={question} lesson={props.lesson} student={props.student} mode={mode} /> : ''
              }
            </Grid>
          </>
        )
          : ''
        }

        {/* リアルタイム２ */}
        {mode === Constants.processModeRealtime2 ? (
          <RealtimeGrid
            question={question}
            lesson={props.lesson}
            student={props.student}
            countDownStart={countDownStart}
            mode={mode}
          />
        )
          : ''
        }

      </Grid>
      {/* ======================================== */}
      {/* 確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialog
        open={confirmDialogOpen}
        handleConfirm={handleConfirm}
      />
    </Box >
  );
};

Realtime.propTypes = {
  lesson: PropTypes.object,
  student: PropTypes.object
};

export default Realtime;
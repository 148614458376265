import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Parameter from './Parameter'
import Model from 'src/models/Model';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ParameterEditView = () => {
  const classes = useStyles();

  const [parameters, setParameters] = useState();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    getParameters();
  }, []);

  // --------------------------------------------------
  // 講義一覧取得処理
  // --------------------------------------------------
  const getParameters = async () => {
    const parameters = await Model.getParameters();
    setParameters(parameters);
  };

  return (
    <Page
      className={classes.root}
      title="パラメータ管理 | Active Poll"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              {parameters ? <Parameter parameters={parameters} /> : ''}

            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ParameterEditView;

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  makeStyles,
  Snackbar,
  Switch,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  Edit3 as Edit3Icon,
} from 'react-feather';
import CheckIcon from '@material-ui/icons/Check';
import Alert from '@material-ui/lab/Alert';
import QuestionEditDialogView from '../../dialog/QuestionEditDialogView'
import QuestionControllPanel from './QuestionControllPanel';
import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';

const useStyles = makeStyles(({
  root: {
  },
  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomStyle: "none",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: "rgba(224, 224, 224, 1)"
  },
  indent: {
    width: 10
  },
  tablePanel: {
    backgroundColor: "#f5f5f5"
  },
  selectImage: {
    width: 150,
  },
  checkIcon: {
    width: 20,
    textAlign: 'center',
    verticalAlign: 'middle',
    fontWeight: 600
  },
  result: {
    verticalAlign: 'bottom',
    marginLeft: 30,
    marginBottom: 7,
  },
  resultBtn: {
    marginTop: 10,
    fontWeight: 600,
    marginRight: 100
  },
  resultText: {
    fontSize: 12
  }
}));


const Question = (props) => {
  const classes = useStyles();

  const [questions, setQuestions] = useState([]);
  const [target, setTarget] = useState({});
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [process, setProcess] = useState({});
  const [opened, setOpened] = useState({});
  const [sessionCount, setSessionCount] = useState(0);

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Admin.Lesson.LessonDitailView.Start.Question', 'useEffect()');
    getQuestionList();
    getLessonProcess();
    // watchSession();
    // 2021/4/18 受講者数表示を一旦削除
    setSessionCount(props.lesson.session ? countUniqueSession(props.lesson.session) : 0);
    return function cleanup() {
      Log.trace('Admin.Lesson.LessonDitailView.Start.Question', 'useEffect() cleanup()');
      resetLessonProcess();
      // watchCancelSession();
      // 2021/4/18 受講者数表示を一旦削除
      deleteViewByLessonId();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // 設問一覧取得処理
  // --------------------------------------------------
  const getQuestionList = async () => {
    const array = await Model.getQuestionList(props.lesson.id);
    array.sort(function (a, b) {
      return a.order < b.order ? -1 : 1;
    });
    setQuestions(array);
  };

  // --------------------------------------------------
  // 講義状況取得処理
  // --------------------------------------------------
  const getLessonProcess = async () => {
    const process = await Model.getLessonProcess(props.lesson.id);
    setProcess(process);
  };

  // /2021/4/18 受講者数表示を一旦削除
  // // --------------------------------------------------
  // // セッションデータの監視処理実行
  // // --------------------------------------------------
  // const watchSession = async () => {
  //   await Model.watchSession(props.data.id, callback);
  // }

  // // --------------------------------------------------
  // // セッションデータの変更処理待受
  // // --------------------------------------------------
  // const callback = async (key, val) => {
  //   Log.trace('LessonStartView Question', 'callback Session key: ' + key);
  //   const session = await Model.getSession(props.data.id);
  //   setSessionCount(countUniqueSession(session))
  // }

  // // --------------------------------------------------
  // // セッションデータの監視キャンセル処理実行
  // // --------------------------------------------------
  // const watchCancelSession = async () => {
  //   await Model.watchCancelSession(props.data.id);
  // }

  // --------------------------------------------------
  // セッションデータの重複除外処理
  // --------------------------------------------------
  const countUniqueSession = (sessions) => {
    if (!sessions) {
      return 0;
    }

    // 学籍番号、氏名、メアドで重複チェック
    const tmpArray = [];
    Object.values(sessions).forEach(function (value) {
      let exist = false;
      for (const tmp of tmpArray) {
        if (value.code === tmp.code) {
          // 学籍番号が同一のデータが既に存在する場合、ループ終了
          exist = true;
          break;
        }
        else if (value.mail === tmp.mail) {
          // メールアドレスが同一のデータが既に存在する場合、ループ終了
          exist = true;
          break;
        }
        else if (value.fname === tmp.fname && value.lname === tmp.lname) {
          // 氏名のセットが同一のデータが既に存在する場合、ループ終了
          exist = true;
          break;
        }
      }
      if (!exist) {
        tmpArray.push(value);
      }
    });
    return tmpArray.length;
  }

  // --------------------------------------------------
  // 設問の選択処理
  // --------------------------------------------------
  const handleSwitchChange = async (event, question) => {

    const newProcess = {};
    newProcess.countdown = false;

    if (process.questionId === question.id) {
      setProcess({
        ...process,
        questionId: Constants.processInitialQuestionId,
        mode: Constants.processInitialMode,
      });
      // 更新データ作成
      newProcess.questionId = Constants.processInitialQuestionId;
      newProcess.mode = Constants.processInitialMode;
      // 閲覧データをクリアする
      deleteViewByLessonId();

      // アクティブ度計測ログの追加
      Model.addLog(props.lesson.id, 'LessonStartView', Constants.operationTypeCloseQuestion);

      // 編集、結果確認のタブ切り替えを有効化
      props.handleLessonStart(false);
    }
    else {
      let mode;
      if (opened[question.id]) {
        mode = opened[question.id]
      }
      else {
        // 初期表示モードが未設定の場合は、リアルタイムモードでオープンする
        mode = question.initMode ? question.initMode : Constants.processModeRealtime
      }

      setProcess({
        ...process,
        questionId: question.id,
        mode: mode,
      });
      // 更新データ作成
      newProcess.questionId = question.id;
      newProcess.mode = mode;

      // 設問ごとのモードを記憶する
      setOpened({
        ...opened,
        [question.id]: mode,
      });

      // アクティブ度計測ログの追加
      Model.addLog(props.lesson.id, 'LessonStartView', Constants.operationTypeOpenQuestion);

      // 編集、結果確認のタブ切り替えを無効化
      props.handleLessonStart(true);

    }
    await Model.updateLessonProcess(props.lesson.id, newProcess);
    setMessageOpen(true);
    setMessageText('設問の選択を切り替えました');
  };

  // --------------------------------------------------
  // モードの選択処理
  // --------------------------------------------------
  const handleModeSelect = async (mode) => {
    setProcess({
      ...process,
      mode: mode,
    });

    const newProcess = {};
    newProcess.mode = mode;
    await Model.updateLessonProcess(props.lesson.id, newProcess);
    setMessageOpen(true);
    setMessageText('設問の状態を切り替えました');

    // 設問ごとのモードを記憶する
    setOpened({
      ...opened,
      [process.questionId]: mode,
    });
  }

  // --------------------------------------------------
  // 編集アイコン押下処理
  // --------------------------------------------------
  const handleEditButtonClick = (event, question) => {
    if (question.options && Array.isArray(question.options)) {
      question.options = question.options.join('\n');
    }
    setTarget(question);
    setEditDialogOpen(true);
  }

  // --------------------------------------------------
  // 編集ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDialogClose = () => {
    setTarget({});
    setEditDialogOpen(false);
  }

  // --------------------------------------------------
  // 編集ダイアログのクローズ処理（再取得）
  // --------------------------------------------------
  const handleDialogCloseWithReload = (message) => {
    setTarget({});
    setEditDialogOpen(false);
    getQuestionList();
    setMessageOpen(true);
    setMessageText('設問を更新しました');
  }

  //--------------------------------------------------
  // 設問編集成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
    setMessageText('');
  }

  // --------------------------------------------------
  // 講義状況の初期化処理
  // --------------------------------------------------
  const resetLessonProcess = async () => {
    const newProcess = {};
    newProcess.questionId = Constants.processInitialQuestionId;
    newProcess.mode = Constants.processInitialMode;
    newProcess.countdown = false;
    await Model.updateLessonProcess(props.lesson.id, newProcess);
  }

  // --------------------------------------------------
  // 閲覧データの削除処理
  // --------------------------------------------------
  const deleteViewByLessonId = async () => {
    await Model.deleteViewByLessonId(props.lesson.id);
  }

  return (
    <form>
      <Card>
        <CardHeader
          title="設問一覧"
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 100 }}>
                    選択
                  </TableCell>
                  <TableCell>
                    タイトル
                  </TableCell>
                  <TableCell style={{ width: 200 }}>
                    設問タイプ
                  </TableCell>
                  <TableCell style={{ width: 200 }}>
                    回答者への結果表示
                  </TableCell>
                  <TableCell style={{ width: 100 }}>
                    編集
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questions.map((question) => (
                  <Fragment
                    key={question.id}
                  >
                    <TableRow
                      // スクロール位置を制御するためのid属性
                      id={'scroll-' + question.id}
                      hover
                    >
                      <TableCell className={classes.tableCell} style={{ width: 120 }}>
                        <Switch
                          checked={process.questionId === question.id}
                          onChange={(event) => handleSwitchChange(event, question)}
                          name="checkedB"
                          color="primary"
                        />
                        <CheckIcon
                          className={classes.checkIcon}
                          color='primary'
                          style={{ display: opened[question.id] ? '' : 'none' }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {question.title}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: 200 }}>
                        {
                          question.type === Constants.questionTypeRadio ? Constants.questionTypeLabelRadio :
                            question.type === Constants.questionTypeSelect ? Constants.questionTypeLabelSelect :
                              question.type === Constants.questionTypeCheckBox ? Constants.questionTypeLabelCheckBox :
                                question.type === Constants.questionTypeText ? Constants.questionTypeLabelText :
                                  Constants.questionTypeLabelTextArea
                        }
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: 200 }}>
                        {
                          question.initMode === Constants.processModeAnswer ? Constants.processModeLabelAnswer :
                            question.initMode === Constants.processModeResult ? Constants.processModeLabelResult :
                              question.initMode === Constants.processModeRealtime ? Constants.processModeLabelRealtime :
                                Constants.processModeLabelRealtime2
                        }
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: 100 }}>
                        <IconButton
                          onClick={(event) => handleEditButtonClick(event, question)}
                          color="primary" >
                          <Edit3Icon />
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      style={{ display: process.questionId !== question.id ? 'none' : '' }}
                    >
                      <TableCell colSpan={5} className={classes.tablePanel}>
                        <Collapse
                          in={process.questionId === question.id ? true : false}
                        >
                          {process.questionId === question.id ? (
                            <QuestionControllPanel
                              lesson={props.lesson}
                              question={question}
                              process={process}
                              sessionCount={sessionCount}
                              handleModeSelect={handleModeSelect}
                              key={question.id}
                            />
                          )
                            : ''}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment >
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>

      {/* ======================================== */}
      {/* 設問編集ダイアログ */}
      {/* ======================================== */}
      <QuestionEditDialogView
        open={editDialogOpen}
        editMode={Constants.questionEditModeModify}
        question={target}
        lesson={props.lesson}
        handleClose={handleDialogClose}
        handleCloseWithReload={handleDialogCloseWithReload}
      />

      {/* ======================================== */}
      {/* データ更新成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          {messageText}
        </Alert>
      </Snackbar>
    </form >
  );
};

Question.propTypes = {
  lesson: PropTypes.object,
  handleLessonStart: PropTypes.func
};

export default Question;

import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import { User as UserIcon } from 'react-feather';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  avatarUserIcon : {
    width: 48,
    height: 48
  },
  name: {
    marginTop: 10,
    marginBottom: 5
  }
}));

const Profile = (props) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
          >
            <UserIcon 
              className={classes.avatarUserIcon}
            />
          </Avatar>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
            className={classes.name}
          >
            {props.data.fname}&nbsp;{props.data.lname}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {props.data.organization}
          </Typography>
        </Box>
      </CardContent>
      {/*
      <Divider />
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
        >
          Upload picture
        </Button>
      </CardActions>
      */}
    </Card>
  );
};

export default Profile;

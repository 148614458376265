import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Divider,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  makeStyles,
  withStyles,
  createStyles,
} from '@material-ui/core';
import {
  Trash2 as Trash2Icon,
} from 'react-feather';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialogView from 'src/views/common/ConfirmDialogView';
import MenuDialogView from './MenuDialogView';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {},
  tableRow: {
    cursor: "context-menu"
  },
  tableCell: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  bold: {
    fontWeight: 600,
  },
}));

const StyledTableSortLabel = withStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
      '&$active': {
        color: theme.palette.primary.main,
      },
    },
    active: {},
    icon: {
      color: 'inherit !important'
    },
  })
)(TableSortLabel);

const Results = (props) => {
  const classes = useStyles();

  const [archives, setArchives] = useState([]);
  const [menuDialogOpen, setMenuDialogOpen] = useState(false);
  const [unarchiveConfirmDialogOpen, setUnarchiveConfirmDialogOpen] = useState(false);
  const [deleteArchiveConfirmDialogOpen, setDeleteArchiveConfirmDialogOpen] = useState(false);

  const [targetArchive, setTargetArchive] = useState({});

  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [sortColumnKey, setSortColumnKey] = useState("date");
  const [sortOrder, setSortOrder] = useState("asc");

  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState('');

  //--------------------------------------------------
  // 画面表示処理
  //--------------------------------------------------
  useEffect(() => {
    Log.trace('LessonArchiveListView Results', 'useEffect()');
    setArchives(props.archives)
  }, [props.archives]);

  //--------------------------------------------------
  // ページあたり表示件数の切り替え
  //--------------------------------------------------
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  //--------------------------------------------------
  // ページ切り替え
  //--------------------------------------------------
  const handlePageChange = (event, newPage) => {
    Log.trace('LessonArchiveListView Results', 'handlePageChange() newPage : ' + newPage);
    setPage(newPage);
  };

  //--------------------------------------------------
  // 一覧のソート順変更
  //--------------------------------------------------
  const handleClickSortColumn = (colmunKey) => {
    const isDesc = colmunKey === sortColumnKey && sortOrder === "desc";
    const nextOrder = isDesc ? "asc" : "desc";
    const sortRule = { asc: [1, -1], desc: [-1, 1] };
    const sortedRows = archives.slice().sort((a, b) => {
      if (a[colmunKey] > b[colmunKey]) {
        return sortRule[nextOrder][0];
      } else if (a[colmunKey] < b[colmunKey]) {
        return sortRule[nextOrder][1];
      } else {
        return 0;
      }
    });
    setSortColumnKey(colmunKey);
    setSortOrder(nextOrder);
    setArchives(sortedRows);
  }

  // --------------------------------------------------
  // メニュー表示処理
  // --------------------------------------------------
  const handleMenuOpen = (event, archive) => {
    event.preventDefault();
    setTargetArchive(archive);
    setMenuDialogOpen(true);
  };

  // --------------------------------------------------
  // メニュークローズ処理
  // --------------------------------------------------
  const handleMenuClose = () => {
    setTargetArchive({});
    setMenuDialogOpen(false);
  };

  //--------------------------------------------------
  // メニュー：アーカイブ復帰の確認ダイアログ表示
  //--------------------------------------------------
  const handleMenuUnArchiveButton = () => {
    setMenuDialogOpen(false);
    setUnarchiveConfirmDialogOpen(true);
  };

  //--------------------------------------------------
  // メニュー：アーカイブ削除の確認ダイアログ表示
  //--------------------------------------------------
  const handleMenuDeleteButton = () => {
    setMenuDialogOpen(false);
    setDeleteArchiveConfirmDialogOpen(true);
  };

  //--------------------------------------------------
  // アーカイブ復帰の確認ダイアログ表示
  //--------------------------------------------------
  const handleUnArchiveButton = (event, archive) => {
    setTargetArchive(archive);
    setUnarchiveConfirmDialogOpen(true);
  };

  // --------------------------------------------------
  // アーカイブ復帰の確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleUnArchivConfirmDialogClose = () => {
    setUnarchiveConfirmDialogOpen(false);
    setTargetArchive({});
  }

  // --------------------------------------------------
  // アーカイブ復帰の実行処理
  // --------------------------------------------------
  const handleUnArchivExecute = async () => {
    await Model.returnArchive(targetArchive.id, targetArchive);
    setUnarchiveConfirmDialogOpen(false);
    setTargetArchive({});
    setMessageOpen(true);
    setMessageText('選択されたアーカイブを復帰しました。');
    props.reload();
  }

  //--------------------------------------------------
  // アーカイブ削除の確認ダイアログ表示
  //--------------------------------------------------
  const handleDeleteButton = (event, archive) => {
    setTargetArchive(archive);
    setDeleteArchiveConfirmDialogOpen(true);
  };

  // --------------------------------------------------
  // アーカイブ削除の確認ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDeleteArchiveDialogClose = () => {
    setDeleteArchiveConfirmDialogOpen(false);
    setTargetArchive({});
  }

  // --------------------------------------------------
  // アーカイブ削除の実行処理
  // --------------------------------------------------
  const handleDeleteArchiveExecute = async () => {
    await Model.deleteArchive(targetArchive.id, targetArchive);
    setDeleteArchiveConfirmDialogOpen(false);
    setTargetArchive({});
    setMessageOpen(true);
    setMessageText('選択されたアーカイブを削除しました。');
    props.reload();
  }

  //--------------------------------------------------
  // 処理成功メッセージ - [閉じる]ボタン処理
  //--------------------------------------------------
  const handleMessageClose = () => {
    setMessageOpen(false);
  }

  // --------------------------------------------------
  // セッション数のカウント
  // --------------------------------------------------
  const getSessionCount = (session) => {
    return session ? Object.keys(session).length : 0;
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  key="name"
                  sortDirection={sortColumnKey === "name" ? sortOrder : false}
                  className={classes.sortLabel}
                >
                  <StyledTableSortLabel
                    active={sortColumnKey === "name"}
                    direction={sortOrder}
                    onClick={() => handleClickSortColumn("name")}
                  >
                    講義名
                  </StyledTableSortLabel>
                </TableCell>
                <TableCell
                  key="date"
                  sortDirection={sortColumnKey === "date" ? sortOrder : false}
                  className={classes.sortLabel}
                  style={{ width: 150 }}
                >
                  <StyledTableSortLabel
                    active={sortColumnKey === "date"}
                    direction={sortOrder}
                    onClick={() => handleClickSortColumn("date")}
                  >
                    開催日
                  </StyledTableSortLabel>
                </TableCell>
                <TableCell style={{ width: 150 }}>
                  受講者数
                </TableCell>
                <TableCell style={{ width: 150 }}>
                  復元
                </TableCell>
                <TableCell style={{ width: 150 }}>
                  削除
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {archives.slice(page * limit, (page + 1) * limit).map((archive) => (
                <TableRow
                  hover
                  key={archive.id}
                  className={classes.tableRow}
                  onContextMenu={(event) => handleMenuOpen(event, archive)}
                >
                  <TableCell
                    className={classes.tableCell}>
                    {archive.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {archive.date}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {getSessionCount(archive.session)}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <IconButton
                      onClick={(event) => handleUnArchiveButton(event, archive)}
                      color="primary" >
                      <UnarchiveOutlinedIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <IconButton
                      onClick={(event) => handleDeleteButton(event, archive)}
                      color="secondary"
                    >
                      <Trash2Icon />
                    </IconButton>
                  </TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Divider />
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Box>
          <TablePagination
            component="div"
            count={archives.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage='1ページあたりの行数:'
            labelDisplayedRows={({ from, to, count }) => `全${count}件のうち${from}〜${to}件目を表示`}
          />
        </Box>
      </Box>

      {/* ======================================== */}
      {/* メニューダイアログ */}
      {/* ======================================== */}
      <MenuDialogView
        open={menuDialogOpen}
        archive={targetArchive}
        handleClose={handleMenuClose}
        handleMenuDelete={handleMenuDeleteButton}
        handleMenuUnArchive={handleMenuUnArchiveButton}
      />

      {/* ======================================== */}
      {/* アーカイブ復帰確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={unarchiveConfirmDialogOpen}
        title="アーカイブの復帰"
        description="選択された講義をアーカイブから戻します。よろしいですか？"
        disagree={handleUnArchivConfirmDialogClose}
        agree={handleUnArchivExecute}
      />

      {/* ======================================== */}
      {/* 削除確認ダイアログ */}
      {/* ======================================== */}
      <ConfirmDialogView
        open={deleteArchiveConfirmDialogOpen}
        title="アーカイブの削除"
        description="選択されたアーカイブを削除します。よろしいですか？"
        confirmText="回答情報も全て削除されることを確認"
        disagree={handleDeleteArchiveDialogClose}
        agree={handleDeleteArchiveExecute}
      />

      {/* ======================================== */}
      {/* 処理成功メッセージ */}
      {/* ======================================== */}
      <Snackbar open={messageOpen} autoHideDuration={3000} onClose={handleMessageClose}>
        <Alert elevation={6} variant="filled" onClose={handleMessageClose} severity="success">
          {messageText}
        </Alert>
      </Snackbar>
    </Card >
  );
};

Results.propTypes = {
  reload: PropTypes.func.isRequired,
  archives: PropTypes.array.isRequired,
};


export default Results;

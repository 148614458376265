import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  Edit3 as Edit3Icon,
  Play as PlayIcon,
  BarChart as BarChartIcon,
  Download as DownloadIcon,
  ExternalLink as ExternalLinkIcon,
  Copy as CopyIcon,
  UserX as UserXIcon,
  Trash2 as Trash2Icon,
} from 'react-feather';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import Util from 'src/utils/Util';
import Constants from 'src/utils/Constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    textAlign: 'left',
  },
  title: {
    flex: 1,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  container: {
    paddingTop: 20,
    paddingBottom: 20
  },

  menu: {
    display: "flex",
    flexDirection: "column",
  },
  menuItemBlock: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.12)'
  },
  menuItemBlockLast: {
    display: "flex",
    flexDirection: "row",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    width: 200,
    marginTop: 5,
    borderRadius: 5,
    padding: 10,
    '&:hover': {
      backgroundColor: "#f5f5f5"
    },
    marginBottom: 5,
    cursor: "pointer"
  },
  menuItemDisabled: {
    display: "flex",
    flexDirection: "row",
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    padding: 10,
    color: 'rgba(0, 0, 0, 0.26)'
  },
  menuIcon: {
    marginRight: 15,
    color: theme.palette.primary.main
  },
  menuIconDisabled: {
    marginRight: 15,
    color: 'rgba(0, 0, 0, 0.26)'
  },
  menuTextDisabled: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  bold: {
    fontWeight: 600
  }
  // menuListWrapper: {
  //   width: 230,
  //   borderRightWidth: 1,
  //   borderRightStyle: 'solid',
  //   borderRightColor: 'rgba(0, 0, 0, 0.12)'
  // },
  // menuListWrapperLast: {
  //   width: 230,
  // },
  // menuList: {
  //   paddingTop: 0,
  //   paddingBottom: 0,
  //   paddingLeft: 5,
  //   paddingRight: 5,
  // },
  // menuListItem: {
  //   marginTop: 0,
  //   marginBottom: 5,
  //   borderRadius: 5,
  // },
  // menuIcon: {
  //   marginRight: 15,
  // }
}));

const MenuDialogView = (props) => {
  const classes = useStyles();

  // --------------------------------------------------
  //  メニュー：編集
  // --------------------------------------------------
  const handleMenuEdit = (event) => {
    handleClose();
    props.handleMenuEdit(event, props.lesson);
  }

  // --------------------------------------------------
  //  メニュー：開始
  // --------------------------------------------------
  const handleMenuStart = (event) => {
    if (!Util.isDayPassed(props.lesson.date)) {
      handleClose();
      props.handleMenuStart(event, props.lesson);
    }
  }

  // --------------------------------------------------
  //  メニュー：振り返り
  // --------------------------------------------------
  const handleMenuReview = (event) => {
    if (isSessionExist()) {
      handleClose();
      props.handleMenuReview(event, props.lesson);
    }
  }

  // --------------------------------------------------
  //  メニュー：コピー
  // --------------------------------------------------
  const handleMenuCopy = () => {
    props.handleMenuCopy();
  }

  // --------------------------------------------------
  //  メニュー：アクセス情報
  // --------------------------------------------------
  const handleMenuAccess = () => {
    props.handleMenuAccess();
  }

  // --------------------------------------------------
  //  メニュー：結果ダウンロード
  // --------------------------------------------------
  const handleMenuDownload = () => {
    if (isSessionExist()) {
      props.handleMenuDownload();
    }
  }

  // --------------------------------------------------
  //  メニュー：受講者情報クリア
  // --------------------------------------------------
  const handleMenuClear = () => {
    if (isSessionExist()) {
      props.handleMenuClear();
    }
  }

  // --------------------------------------------------
  //  メニュー：削除
  // --------------------------------------------------
  const handleMenuDelete = () => {
    props.handleMenuDelete();
  }

  // --------------------------------------------------
  //  メニュー：アーカイブ
  // --------------------------------------------------
  const handleMenuArchive = () => {
    props.handleMenuArchive();
  };

  // --------------------------------------------------
  //  閉じる処理
  // --------------------------------------------------
  const handleClose = () => {
    props.handleClose();
  }

  // --------------------------------------------------
  //  受講者情報の存在チェック
  // --------------------------------------------------
  const isSessionExist = () => {
    return props.lesson.session && Object.keys(props.lesson.session).length > 0 ? true : false;
  }

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" className={classes.title}>
          {props.lesson.name}
        </Typography>
      </DialogTitle>


      <DialogContent dividers className={classes.container}>
        <Box className={classes.menu}>
          <Box className={classes.menuItemBlock}>

            {/* ======================================== */}
            {/* 編集*/}
            {/* ======================================== */}
            <Box className={classes.menuItem} onClick={(event) => handleMenuEdit(event)}>
              <Edit3Icon className={classes.menuIcon} />
              <Typography color="textPrimary" variant="h6">編集</Typography>
            </Box>

            {/* ======================================== */}
            {/* 開始*/}
            {/* ======================================== */}
            <Box
              className={Util.isDayPassed(props.lesson.date) || props.lesson.voteMode === Constants.voteModeNonRealtime ? classes.menuItemDisabled : classes.menuItem}
              onClick={(event) => handleMenuStart(event)}
            >
              <PlayIcon
                className={Util.isDayPassed(props.lesson.date) || props.lesson.voteMode === Constants.voteModeNonRealtime ? classes.menuIconDisabled : classes.menuIcon}
              />
              <Typography
                color="textPrimary" variant="h6"
                className={Util.isDayPassed(props.lesson.date) || props.lesson.voteMode === Constants.voteModeNonRealtime ? classes.menuTextDisabled : ''}
              >
                開始
              </Typography>
            </Box>

            {/* ======================================== */}
            {/* 振り返り*/}
            {/* ======================================== */}
            <Box
              className={isSessionExist() ? classes.menuItem : classes.menuItemDisabled}
              onClick={(event) => handleMenuReview(event)}
            >
              <BarChartIcon
                className={isSessionExist() ? classes.menuIcon : classes.menuIconDisabled}
              />
              <Typography
                color="textPrimary" variant="h6"
                className={isSessionExist() ? '' : classes.menuTextDisabled}
              >
                振り返り
              </Typography>
            </Box>
          </Box>

          <Box className={classes.menuItemBlock}>
            <Box className={classes.menuItem} onClick={() => handleMenuCopy()}>
              <CopyIcon className={classes.menuIcon} />
              <Typography color="textPrimary" variant="h6">コピーして作成</Typography>
            </Box>

            {/* ======================================== */}
            {/* アクセス情報*/}
            {/* ======================================== */}
            <Box className={classes.menuItem} onClick={() => handleMenuAccess()}>
              <ExternalLinkIcon className={classes.menuIcon} />
              <Typography color="textPrimary" variant="h6">アクセス情報</Typography>
            </Box>

            {/* ======================================== */}
            {/* 結果ダウンロード*/}
            {/* ======================================== */}
            <Box
              className={isSessionExist() ? classes.menuItem : classes.menuItemDisabled}
              onClick={(event) => handleMenuDownload(event)}
            >
              <DownloadIcon
                className={isSessionExist() ? classes.menuIcon : classes.menuIconDisabled}
              />
              <Typography
                color="textPrimary" variant="h6"
                className={isSessionExist() ? '' : classes.menuTextDisabled}
              >
                結果ダウンロード
              </Typography>
            </Box>
          </Box>

          <Box className={classes.menuItemBlockLast}>

            {/* ======================================== */}
            {/* アーカイブ */}
            {/* ======================================== */}
            <Box className={classes.menuItem} onClick={() => handleMenuArchive()}>
              <ArchiveOutlinedIcon className={classes.menuIcon} />
              <Typography color="textPrimary" variant="h6">アーカイブ</Typography>
            </Box>

            {/* ======================================== */}
            {/* 受講者情報クリア*/}
            {/* ======================================== */}
            <Box
              className={isSessionExist() ? classes.menuItem : classes.menuItemDisabled}
              onClick={(event) => handleMenuClear(event)}
            >
              <UserXIcon
                className={isSessionExist() ? classes.menuIcon : classes.menuIconDisabled}
              />
              <Typography
                color="textPrimary" variant="h6"
                className={isSessionExist() ? '' : classes.menuTextDisabled}
              >
                受講者情報クリア
              </Typography>
            </Box>

            {/* ======================================== */}
            {/* 削除 */}
            {/* ======================================== */}
            <Box className={classes.menuItem} onClick={() => handleMenuDelete()}>
              <Trash2Icon className={classes.menuIcon} />
              <Typography color="textPrimary" variant="h6">削除</Typography>
            </Box>
          </Box>
        </Box>

        {/*
        <Box
          display="flex"
          flexDirection="row"
        >
          <Box className={classes.menuListWrapper}>
            <List className={classes.menuList}>
              <ListItem className={classes.menuListItem} button onClick={() => handleMenuCopy()}>
                <Edit3Icon className={classes.menuIcon} />
                <Typography color="textPrimary" variant="h6">編集</Typography>
              </ListItem>
              <ListItem className={classes.menuListItem} button onClick={() => handleMenuCopy()}>
                <PlayIcon className={classes.menuIcon} />
                <Typography color="textPrimary" variant="h6">開始</Typography>
              </ListItem>
              <ListItem className={classes.menuListItem} button onClick={() => handleMenuCopy()}>
                <BarChartIcon className={classes.menuIcon} />
                <Typography color="textPrimary" variant="h6">振り返り</Typography>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.menuListWrapper}>
            <List className={classes.menuList}>
              <ListItem className={classes.menuListItem} button onClick={() => handleMenuCopy()}>
                <CopyIcon className={classes.menuIcon} />
                <Typography color="textPrimary" variant="h6">コピー</Typography>
              </ListItem>
              <ListItem className={classes.menuListItem} button onClick={() => handleMenuCopy()}>
                <ExternalLinkIcon className={classes.menuIcon} />
                <Typography color="textPrimary" variant="h6">アクセス情報</Typography>
              </ListItem>
              <ListItem className={classes.menuListItem} button onClick={() => handleMenuCopy()}>
                <DownloadIcon className={classes.menuIcon} />
                <Typography color="textPrimary" variant="h6">ダウンロード</Typography>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.menuListWrapperLast}>
            <List className={classes.menuList}>
              <ListItem className={classes.menuListItem} button onClick={() => handleMenuCopy()}>
                <UserXIcon className={classes.menuIcon} />
                <Typography color="textPrimary" variant="h6">受講者数クリア</Typography>
              </ListItem>
              <ListItem className={classes.menuListItem} button onClick={() => handleMenuCopy()}>
                <Trash2Icon className={classes.menuIcon} />
                <Typography color="textPrimary" variant="h6">削除</Typography>
              </ListItem>
            </List>
          </Box>
        </Box>
        */}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          className={classes.bold}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog >
  );
};

MenuDialogView.propTypes = {
  open: PropTypes.bool.isRequired,
  lesson: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleMenuEdit: PropTypes.func.isRequired,
  handleMenuStart: PropTypes.func.isRequired,
  handleMenuReview: PropTypes.func.isRequired,
  handleMenuCopy: PropTypes.func.isRequired,
  handleMenuAccess: PropTypes.func.isRequired,
  handleMenuDownload: PropTypes.func.isRequired,
  handleMenuClear: PropTypes.func.isRequired,
  handleMenuDelete: PropTypes.func.isRequired,
  handleMenuArchive: PropTypes.func.isRequired,
};

export default MenuDialogView;

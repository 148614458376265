import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { ExternalLink as ExternalLink3Icon, } from 'react-feather';
import QRcodeDialogView from '../../dialog/QRcodeDialogView';

const useStyles = makeStyles(({
  root: {},
  codeText: {
    marginRight: 5,
  },
  textField: {
    "&$disabled": {
      color: "#263238",
    }
  },
  disabled: {}
}));

const Lesson = (props) => {
  const classes = useStyles();

  const [values] = useState(props.lesson);
  const [qrcodeDialogOpen, setQRcodeDialogOpen] = useState(false);

  // --------------------------------------------------
  // アクセス情報表示ボタン押下処理
  // --------------------------------------------------
  const handleQRCodeButtonClick = (event) => {
    setQRcodeDialogOpen(true);
  }

  // --------------------------------------------------
  // アクセス情報ダイアログのクローズ処理
  // --------------------------------------------------
  const handleDialogClose = () => {
    setQRcodeDialogOpen(false);
  }

  return (
    <form>
      <Card>
        <CardHeader
          title="基本情報"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="講義名"
                name="name"
                disabled
                type="text"
                value={values.name}
                variant="standard"
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="開催日"
                name="date"
                disabled
                type="text"
                value={values.date}
                variant="standard"
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
            {values.accessKeyRequired === true ? (
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="アクセスキー"
                  name="accessKey"
                  disabled
                  type="text"
                  value={values.accessKey}
                  variant="standard"
                  InputProps={{
                    classes: {
                      root: classes.textField,
                      disabled: classes.disabled
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.textField,
                      disabled: classes.disabled
                    }
                  }}
                />
              </Grid>
            ) : ''
            }
            <Grid
              item
              md={3}
              xs={12}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <TextField
                  fullWidth
                  label="セミナーコード"
                  name="code"
                  disabled
                  type="text"
                  value={values.code}
                  variant="standard"
                  className={classes.codeText}
                  InputProps={{
                    classes: {
                      root: classes.textField,
                      disabled: classes.disabled
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.textField,
                      disabled: classes.disabled
                    }
                  }}
                />
                <IconButton
                  onClick={(event) => handleQRCodeButtonClick(event)}
                  color="primary" >
                  <ExternalLink3Icon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="講師氏名"
                name="accountName"
                value={values.accountName}
                variant="standard"
                disabled
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="講師所属"
                name="accountOrganization"
                value={values.accountOrganization}
                variant="standard"
                disabled
                InputProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textField,
                    disabled: classes.disabled
                  }
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* ======================================== */}
      {/* アクセス情報ダイアログ */}
      {/* ======================================== */}
      <QRcodeDialogView
        open={qrcodeDialogOpen}
        handleClose={handleDialogClose}
        code={values.code}
        accessKeyText={props.lesson.accessKey}
        accessKeyRequired={props.lesson.accessKeyRequired}
      />
    </form >
  );
};

Lesson.propTypes = {
  lesson: PropTypes.object,
};

export default Lesson;

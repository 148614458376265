import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import {
  LogOut as LogOutIcon,
  Menu as MenuIcon,
} from 'react-feather';
import firebase from "firebase/app";
import 'firebase/auth';
import LogoW from 'src/components/LogoW';
import SessionUtil from 'src/utils/SessionUtil';
import Log from 'src/utils/Log';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  logoArea: {
    marginTop: 5
  },
  logo: {
    width: 180
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // --------------------------------------------------
  // ログアウト処理
  // --------------------------------------------------
  const handleLogout = () => {
    Log.trace('TopBar', 'handleLogout()');

    firebase.auth().signOut().then(() => {
      Log.trace('TopBar', 'firebase.auth().signOut() success');
      SessionUtil.removeLoginAccount();
      navigate('/admin/login', { replace: false, });
    }).catch((error) => {
      Log.trace('TopBar', 'firebase.auth().signOut() fail : ' + error.message);
    });
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/admin/" className={classes.logoArea} >
          <LogoW className={classes.logo} />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton
            onClick={handleLogout}
            color="inherit">
            <LogOutIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

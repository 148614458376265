// パスワードのダミー文字列
const passwordDummy = 'dummydummy';

// 暗号化鍵
const cryptoKey = 'HkdsJtgsKSuehsdssk0w8a'

// 講義状況データの初期値：設問ID
const processInitialQuestionId = 'initial';

// 講義状況データの初期値：講義モード
const processInitialMode = 'initial';

// 講義状況：ローディング中
const processLoadingMode = 'loading';

// 講義状況：回答受付中
const processModeAnswer = 'answer';

// 講義状況：結果確認中
const processModeResult = 'result';

// 講義状況：リアルタイム
const processModeRealtime = 'realtime';

// 講義状況：リアルタイム２
const processModeRealtime2 = 'realtime2';

// 講義状況ラベル：回答受付中
const processModeLabelAnswer = '非表示';

// 講義状況ラベル：結果表示中
const processModeLabelResult = '回答締切＆結果共有';

// 講義状況ラベル：リアルタイム
const processModeLabelRealtime = '表示（リアルタイム更新）';

// 講義状況ラベル：リアルタイム２
const processModeLabelRealtime2 = '回答後に表示';

// 設問編集モード：新規
const questionEditModeNew = 'new';

// 設問編集モード：変更
const questionEditModeModify = 'modify';

// 設問編集モード：コピー
const questionEditModeCopy = 'copy';

// 設問タイプ：択一選択（ラジオボタン）
const questionTypeRadio = 'radio';

// 設問タイプ：択一選択（プルダウンリスト）
const questionTypeSelect = 'select';

// 設問タイプ：複数選択（チェックボックス）
const questionTypeCheckBox = 'checkbox';

// 設問タイプ：自由記述（単一行）
const questionTypeText = 'text';

// 設問タイプ：自由記述（複数行）
const questionTypeTextArea = 'textarea';

// 設問タイプ：択一選択（ラジオボタン）
const questionTypeLabelRadio = '択一選択';

// 設問タイプ：択一選択（プルダウンリスト）
const questionTypeLabelSelect = '択一選択（プルダウン）';

// 設問タイプ：複数選択（チェックボックス）
const questionTypeLabelCheckBox = '複数選択';

// 設問タイプ：自由記述（単一行）
const questionTypeLabelText = '自由記述（単一行）';

// 設問タイプ：自由記述（複数行）
const questionTypeLabelTextArea = '自由記述';

// 回答締め切りカウントダウン
const countDownInitial = 10;

// 自動送信ミリ秒
const auteSendmillsec = 5000;

// 投票モード＝リアルタイム
const voteModeRealtime = 'realtime';

// 投票モード＝オンデマンド
const voteModeNonRealtime = 'nonRealtime';

// 利用シーン＝学校
const useSceneSchool = 'school';

// 利用シーン＝一般セミナー
const useSceneSeminar = 'seminar';

// 操作ログ＝リアルタイム投票回答開始
const operationTypeOpenQuestion = 'openQuestion';

// 操作ログ＝リアルタイム投票回答終了
const operationTypeCloseQuestion = 'closeQuestion';

// 操作ログ＝カウントダウン開始
const operationTypeStartCountDown = 'startCountDown';

// 操作ログ＝リアルタイム投票回答（選択）
const operationTypeSendAnswerSelect = 'sendAnswerSelect';

// 操作ログ＝リアルタイム投票回答（自由記述）
const operationTypeSendAnswerText = 'sendAnswerText';

// 操作ログ＝リアルタイム投票いいね
const operationTypeSendAnswerGood = 'sendAnswerGood';

// 操作ログ＝質問投稿
const operationTypeSendFaq = 'sendFaq';

// 操作ログ＝質問いいね
const operationTypeSendFaqGood = 'sendFaqGood';

// 操作ログ＝ログイン
const operationTypeStart = 'start';

// 操作ログ＝アクセスキー
const operationTypeTop = 'top';

// 操作ログ＝プロフィール
const operationTypeProfile = 'profile';

// 操作ログ＝アクセスキー
const operationTypeAccessKey = 'accessKey';

// 操作ログ＝再読み込み
const operationTypeWaitReload = 'waitReload';

// 操作ログ＝再読み込み
const operationTypeConfirm = 'confirm';

// 設問＝必須
const questionRequired = 'required';

// 設問＝任意
const questionOptional = 'optional';

// オンデマンド投稿受付状態：有効
const ondemandEntryStatusValid = 'valid';

// オンデマンド投稿受付状態：無効
const ondemandEntryStatusInvalid = 'invalid';

// オンデマンド投稿の一時停止中のデフォルトメッセージ
const ondemandEntryStatusInvalidDefaultMessage = '現在受け付けておりません。';

// オンデマンド投稿の送信確認のデフォルトメッセージ
const ondemandConfirmDefaultMessage = '回答を送信します。よろしいですか？';

// 管理システムの講義詳細画面のタブ選択：編集
const lessonDetailModeEdit = "edit";

// 管理システムの講義詳細画面のタブ選択：開始
const lessonDetailModeStart = "start";

// 管理システムの講義詳細画面のタブ選択：結果確認
const lessonDetailModeReview = "review";


const Constants = {
  passwordDummy,
  cryptoKey,
  processInitialQuestionId,
  processInitialMode,
  processLoadingMode,
  processModeAnswer,
  processModeResult,
  processModeRealtime,
  processModeRealtime2,
  processModeLabelAnswer,
  processModeLabelResult,
  processModeLabelRealtime,
  processModeLabelRealtime2,
  questionEditModeNew,
  questionEditModeModify,
  questionEditModeCopy,
  questionTypeRadio,
  questionTypeSelect,
  questionTypeCheckBox,
  questionTypeText,
  questionTypeTextArea,
  questionTypeLabelRadio,
  questionTypeLabelSelect,
  questionTypeLabelCheckBox,
  questionTypeLabelText,
  questionTypeLabelTextArea,
  countDownInitial,
  auteSendmillsec,
  voteModeRealtime,
  voteModeNonRealtime,
  useSceneSchool,
  useSceneSeminar,
  operationTypeSendAnswerSelect,
  operationTypeSendAnswerText,
  operationTypeSendAnswerGood,
  operationTypeSendFaq,
  operationTypeSendFaqGood,
  operationTypeOpenQuestion,
  operationTypeCloseQuestion,
  operationTypeStartCountDown,
  operationTypeStart,
  operationTypeTop,
  operationTypeProfile,
  operationTypeAccessKey,
  operationTypeWaitReload,
  operationTypeConfirm,
  questionRequired,
  questionOptional,
  ondemandEntryStatusValid,
  ondemandEntryStatusInvalid,
  ondemandEntryStatusInvalidDefaultMessage,
  ondemandConfirmDefaultMessage,
  lessonDetailModeEdit,
  lessonDetailModeStart,
  lessonDetailModeReview
}

export default Constants;
import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600
  },
  bold: {
    fontWeight: 600
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const UseOfTermDialogView = (props) => {
  const classes = useStyles();

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    props.handleClose();
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
      scroll="paper"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            利用規約
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent dividers={true}>
        <DialogContentText>
          {[...new Array(50)]
            .map(
              () => `この規約（以下「本規約」といいます。）は、Active Poll運営者（以下「運営者」といいます。）が提供するサービス（以下「本サービス」といいます。）の利用に関する条件を、本サービスを利用するお客様（以下「お客様」といいます。）と当社との間で定めるものです。`,
            )
            .join('\n')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.bold}
          onClick={props.handleClose}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UseOfTermDialogView.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default UseOfTermDialogView;

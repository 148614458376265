import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography
} from '@material-ui/core';
import { Clock as ClockIcon } from 'react-feather';
import ResultGraph from 'src/views/student/MainView/Enquete/ResultGraph';
import ResultText from 'src/views/student/MainView/Enquete/ResultText';

import Constants from 'src/utils/Constants';
import Model from 'src/models/Model';
import Log from 'src/utils/Log';
import Util from 'src/utils/Util';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  descriptionPanel: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#DBDBDB",
    borderRadius: 5,
  },
  textarea: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  textField: {
    "&$disabled": {
      color: "#263238",
    },
    fontSize: 15,
  },
  disabled: {
  },
  operationPanel: {
    marginTop: 30,
    display: 'flex',
  },
  operationAnswerPanel: {
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  operationAnswerPanelTitle: {
    fontSize: 16,
    marginLeft: 16
  },
  operationResultPanel: {
    paddingLeft: 10,
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: "#989DB9",
    display: 'flex',
    alignItems: 'flex-end'
  },
  countdownPanel: {
    marginTop: 30
  },
  timer: {
    fontSize: 18,
    marginLeft: 20,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  selectImage: {
    width: 150,
  },
  bold: {
    fontWeight: 600
  },
  result: {
    verticalAlign: 'bottom',
    marginLeft: 16,
    marginBottom: 7,
  },
  dataPanel: {
    marginTop: 30
  },
  dataPanelText: {
    fontSize: 16,
  },
  resultPanel: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#DBDBDB",
    borderRadius: 5,
    paddingBottom: 20,
    marginTop: 6
  }
}));


const answerOnImage = "/static/images/lesson/answer-on.png";
const answerOffImage = "/static/images/lesson/answer-off.png";
const resultOnImage = "/static/images/lesson/result-on.png";
const reaultOffImage = "/static/images/lesson/result-off.png";
const realtimeOnImage = "/static/images/lesson/realtime-on.png";
const realtimeOffImage = "/static/images/lesson/realtime-off.png";
const realtime2OnImage = "/static/images/lesson/realtime2-on.png";
const realtime2OffImage = "/static/images/lesson/realtime2-off.png";

const QuestionControllPanel = (props) => {
  const classes = useStyles();
  const [answerCount, setAnswerCount] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [confirmCount, setConfirmCount] = useState(0);
  const [countDownNum, setCountDownNum] = useState(Constants.countDownInitial);
  const [countDownStart, setCountDownStart] = useState(false);

  let countDownTimerRef = useRef();

  // --------------------------------------------------
  // 画面表示処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Admin.Lesson.LessonDitailView.Start.Question.QuestionControllPanel', 'useEffect()');
    getAnswer();
    watchAnswer();
    watchView();

    // 設問位置まで画面をスクロール
    setTimeout(scroll, 300)

    return function cleanup() {
      Log.trace('Admin.Lesson.LessonDitailView.Start.Question.QuestionControllPanel', 'useEffect() cleanup()');
      watchCancelAnswer();
      watchCancelView();
      deleteViewByQuestionId();
      clearInterval(countDownTimerRef.current);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  // スクロール処理
  // --------------------------------------------------
  const scroll = () => {
    const ref = document.getElementById('scroll-' + props.question.id);
    ref.scrollIntoView(true, {
      behavior: 'auto',
      block: 'start',
    });
  }

  // --------------------------------------------------
  // 回答データの取得処理
  // --------------------------------------------------
  const getAnswer = async () => {
    const answers = await Model.getAnswerByQuestionId(props.lesson.id, props.question.id);
    setAnswerCount(answers ? Object.keys(answers).length : 0);
  };

  // --------------------------------------------------
  // 回答データの監視処理実行
  // --------------------------------------------------
  const watchAnswer = async () => {
    await Model.watchAnswerAR(props.lesson.id, props.question.id, callbackAnswer);
  }

  // --------------------------------------------------
  // 回答データの監視処理キャンセル実行
  // --------------------------------------------------
  const watchCancelAnswer = async () => {
    await Model.watchCancelAnswer(props.lesson.id, props.question.id);
  }

  // --------------------------------------------------
  // 回答データの変更処理待受
  // --------------------------------------------------
  const callbackAnswer = (key, val) => {
    Log.trace('LessonStartView Question QuestionControllPanel', 'callback Answer key: ' + key);
    getAnswer();
  }

  // --------------------------------------------------
  // 閲覧データの監視処理実行
  // --------------------------------------------------
  const watchView = async () => {
    await Model.watchView(props.lesson.id, props.question.id, callbackView);
  }

  // --------------------------------------------------
  // 閲覧データの監視処理キャンセル実行
  // --------------------------------------------------
  const watchCancelView = async () => {
    await Model.watchCancelView(props.lesson.id, props.question.id);
  }

  // --------------------------------------------------
  // 閲覧データの変更処理待受
  // --------------------------------------------------
  const callbackView = (key, val) => {
    Log.trace('LessonStartView Question QuestionControllPanel', 'callback View key: ' + key);
    getView();
  }

  // --------------------------------------------------
  // 閲覧データの取得処理
  // --------------------------------------------------
  const getView = async () => {
    const views = await Model.getView(props.lesson.id, props.question.id);
    setViewCount(views ? Object.keys(views).length : 0);

    if (props.lesson.useConfirm === true) {
      let confirmCount = 0;
      Object.values(views).forEach(function (view) {
        if (view.isConfirm === true) {
          confirmCount++;
        }
      });
      setConfirmCount(confirmCount);
    }
  }

  // --------------------------------------------------
  // 閲覧データの削除処理
  // --------------------------------------------------
  const deleteViewByQuestionId = async () => {
    await Model.deleteViewByQuestionId(props.lesson.id, props.question.id);
  }

  // --------------------------------------------------
  // カウントダウン処理
  // --------------------------------------------------
  const countDown = () => {
    setCountDownNum((prev) => prev - 1);
  }

  // --------------------------------------------------
  // カウントダウンボタン押下時の処理
  // --------------------------------------------------
  const handleCountDown = async () => {
    const process = {};
    // タイマー発火
    if (countDownStart) {
      process.countdown = false;
      clearInterval(countDownTimerRef.current);
      setCountDownNum(Constants.countDownInitial);
    } else {
      process.countdown = true;
      countDownTimerRef.current = setInterval(countDown, 1000);
      // アクティブ度計測ログの追加
      Model.addLog(props.lesson.id, 'LessonStartView', Constants.operationTypeStartCountDown);
    }
    await Model.updateLessonProcess(props.lesson.id, process);
    setCountDownStart((prev) => !prev);
  }

  // --------------------------------------------------
  // 結果表示コンポーネントの判定処理
  // --------------------------------------------------
  const ResultPanel = (props) => {
    let component;
    switch (props.question.type) {
      case Constants.questionTypeRadio:
      case Constants.questionTypeSelect:
      case Constants.questionTypeCheckBox:
        component = (
          <ResultGraph
            key={props.question.id}
            question={props.question}
            lesson={props.lesson}
            student={{ oneTimeId: 'LessonStartView' }}
          />)
        break;
      case Constants.questionTypeText:
      case Constants.questionTypeTextArea:
        component = (
          <ResultText
            key={props.question.id}
            question={props.question}
            lesson={props.lesson}
            student={{ oneTimeId: 'LessonStartView' }}
            mode={""}
          />)
        break;
      default:
        component = '';
        break;
    }
    return component;
  }


  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="start"
      alignItems="flex-start"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="start"
      >
        <Box className={classes.descriptionPanel}>
          <TextField
            className={classes.textarea}
            fullWidth
            multiline
            type="text"
            value={props.question.description}
            minRows={3}
            disabled={true}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.textField,
                disabled: classes.disabled,

              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.textField,
                disabled: classes.disabled
              }
            }}
          />
        </Box>

        {/* ======================================== */}
        {/* モード切り替え */}
        {/* ======================================== */}
        <Box className={classes.operationPanel}>
          <Box className={classes.operationAnswerPanel}>
            <Box className={classes.operationAnswerPanelTitle}>
              回答者への結果表示
            </Box>
            <Box>
              <Button
                onClick={() => props.handleModeSelect(Constants.processModeRealtime)}
              >
                <img
                  className={classes.selectImage}
                  src={props.process.mode === Constants.processModeRealtime ? realtimeOnImage : realtimeOffImage}
                  alt={Constants.processModeLabelRealtime}
                />
              </Button>
              <Button
                onClick={() => props.handleModeSelect(Constants.processModeRealtime2)}
              >
                <img
                  className={classes.selectImage}
                  src={props.process.mode === Constants.processModeRealtime2 ? realtime2OnImage : realtime2OffImage}
                  alt={Constants.processModeLabelRealtime2}
                />
              </Button>
              <Button
                onClick={() => props.handleModeSelect(Constants.processModeAnswer)} >
                <img
                  className={classes.selectImage}
                  src={props.process.mode === Constants.processModeAnswer ? answerOnImage : answerOffImage}
                  alt={Constants.processModeLabelAnswer}
                />
              </Button>
            </Box>
          </Box>
          <Box className={classes.operationResultPanel}>
            <Button
              onClick={() => props.handleModeSelect(Constants.processModeResult)}
            >
              <img
                className={classes.selectImage}
                src={props.process.mode === Constants.processModeResult ? resultOnImage : reaultOffImage}
                alt={Constants.processModeLabelResult}
              />
            </Button>
          </Box>
        </Box>

        {/* ======================================== */}
        {/* 回答状況確認 */}
        {/* ======================================== */}
        <Box
          className={classes.dataPanel}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.dataPanelText} >参加人数</TableCell>
                <TableCell className={classes.dataPanelText} >{viewCount}</TableCell>
                <TableCell></TableCell>
              </TableRow>
              {props.lesson.useConfirm === true ? (
                <TableRow>
                  <TableCell className={classes.dataPanelText}>質問表示ボタン押下</TableCell>
                  <TableCell className={classes.dataPanelText}>{confirmCount}</TableCell>
                  <TableCell className={classes.dataPanelText} >{Util.calcPercentage(confirmCount, viewCount)}%</TableCell>
                </TableRow>
              ) : <TableRow></TableRow>}
              <TableRow>
                <TableCell className={classes.dataPanelText} style={{ width: 230 }}>回答済み</TableCell>
                <TableCell className={classes.dataPanelText} style={{ width: 100 }}>{answerCount}</TableCell>
                <TableCell className={classes.dataPanelText} >{Util.calcPercentage(answerCount, viewCount)}%</TableCell>
              </TableRow>
              {/*
              <TableRow>
                <TableCell>受講者数</TableCell>
                <TableCell>{props.sessionCount}</TableCell>
                <TableCell>回答画面表示／受講者数</TableCell>
                <TableCell>{calcPercentage(viewCount, props.sessionCount)}%</TableCell>
              </TableRow>
              */}
            </TableBody>
          </Table>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          className={classes.countdownPanel}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleCountDown()}
            className={classes.bold}
            startIcon={<ClockIcon />}
          >
            {countDownStart ? 'カウントダウン中断' : 'カウントダウン開始（' + Constants.countDownInitial + '秒）'}
          </Button>
          <Typography
            className={classes.timer}
            style={{ color: countDownNum <= 0 ? 'rgb(220, 0, 78)' : '' }}
          >
            {countDownStart ? countDownNum : ''}
          </Typography>

        </Box>
      </Box>

      {/* ======================================== */}
      {/* 結果確認 */}
      {/* ======================================== */}
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        className={classes.result}
      >
        <Box>
          {ResultPanel(props)}
        </Box>
      </Box>
    </Box >
  );
};

export default QuestionControllPanel;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Edit3 as Edit3Icon } from 'react-feather';
import Log from 'src/utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    paddingLeft: 20,
    marginBottom: 8,
  },
  space: {
    marginRight: 10,
    marginLeft: 10,
  },
  edit: {
    width: 20,
    height: 20,
  },
  button: {
    fontWeight: 600,
  }
}));

const Header = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [displayProfile, setDisplayProfile] = useState(true);

  // --------------------------------------------------
  //  画面表示時の処理
  // --------------------------------------------------
  useEffect(() => {
    Log.trace('Student.MainView Header', 'useEffect()');
    Log.trace('Student.MainView Header', 'props.lesson.lessonId=' + props.lesson.id);
    Log.trace('Student.MainView Header', 'props.student.oneTimeId=' + props.student.oneTimeId);

    if (!props.student.fname) {
      setDisplayProfile(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------
  //  修正アイコン押下時の処理
  // --------------------------------------------------
  const handleUserIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // --------------------------------------------------
  //  「修正する」メニューのクローズ
  // --------------------------------------------------
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // --------------------------------------------------
  //  「修正する」メニュー押下時の処理
  // --------------------------------------------------
  const navigateProfileView = () => {
    navigate('/student/profile',
      {
        replace: false,
        state: {
          lesson: props.lesson,
          student: props.student
        }
      }
    );
  }

  return (
    <Paper className={classes.paper}>

      {/* ======================================== */}
      {/* プロフィール登録あり：プロフィールを表示 */}
      {/* プロフィール登録無し：講義名を表示 */}
      {/* ======================================== */}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        id="__header"
      >
        <Box
          style={{ display: displayProfile ? 'none' : '' }}
        >
          <Typography
            color="textPrimary"
            variant="h6"
          >
            {props.lesson.name}
          </Typography>
        </Box>
        <Box
          style={{ display: !displayProfile ? 'none' : '' }}
        >
          <Typography
            color="textPrimary"
            variant="h6"
          >
            {props.student.fname}&nbsp;{props.student.lname}
          </Typography>
        </Box>
        <Box
          style={{ display: !displayProfile ? 'none' : '' }}
          className={classes.space}
        >
          <Typography
            color="textPrimary"
            variant="h6"
          >
            ／
          </Typography>
        </Box>
        <Box
          style={{ display: !displayProfile ? 'none' : '' }}
        >
          <Typography
            color="textPrimary"
            variant="h6"
          >
            {props.student.code}
          </Typography>
        </Box>

        {/* 画面幅を考慮してスマホの場合はメアド非表示 */}
        <Hidden mdDown>
          <Box
            style={{ display: !displayProfile ? 'none' : '' }}
            className={classes.space}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              ／
            </Typography>
          </Box>
          <Box
            style={{ display: !displayProfile ? 'none' : '' }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              {props.student.mail}
            </Typography>
          </Box>
        </Hidden>
        <Box
          style={{ visibility: !displayProfile ? 'hidden' : 'visible' }}
        >
          <IconButton onClick={handleUserIconClick} fontSize="small" color="primary" >
            <Edit3Icon className={classes.edit} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={navigateProfileView}>修正する</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Paper>
  );
};

Header.propTypes = {
  lesson: PropTypes.object,
  student: PropTypes.object
};

export default Header;
